import React, { useState } from 'react';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { Chart } from 'react-chartjs-2';

import colors from '../../theme/colors';
import { GraphData } from '../types/Graph';

import { Tooltip } from './Graph';

type StatisticsGraphProps = {
  graphData?: GraphData;
  unit?: string;
  tooltipContentGenerator?: (count: number) => string;
  momentFormat?: string;
};

const StatisticsGraph: React.FC<StatisticsGraphProps> = ({
  graphData,
  unit,
  tooltipContentGenerator,
  momentFormat = 'D MMM YYYY',
}) => {
  const [[tooltipTitle, tooltipContent], setTooltip] = useState<Tooltip>([
    '',
    '',
  ]);
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});
  const [lastIndex, setLastIndex] = useState<number>();
  const [lastOpacity, setLastOpacity] = useState<number>();

  return (
    <div className="relative">
      <div
        className="rounded-lg p-2 absolute pointer-events-none text-white"
        style={{
          backgroundColor: colors.safeLifeDarker,
          transform: 'translate(-50%, -130%)',
          opacity: 0,
          zIndex: 10,
          transition: 'all .1s ease',
          ...tooltipStyle,
        }}>
        <span className="font-medium" style={{ whiteSpace: 'nowrap' }}>
          {tooltipTitle}
        </span>
        <br />
        <span>{tooltipContent}</span>
      </div>
      <Chart
        type="line"
        data={{
          labels: graphData?.entries.map((entry) => entry.label),
          datasets: [
            {
              data: graphData?.entries.map((entry) => entry.value),
              borderColor: '#2A5C96',
              borderWidth: 3,
              pointStyle: 'circle',
              pointBackgroundColor: '#2A5C96',
              pointRadius: 0,
              pointHoverRadius: 6,
              tension: 0.3,
            },
          ],
        }}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
              external: ({ tooltip, chart }) => {
                if (!tooltip?.dataPoints?.length) return;

                const index: number = tooltip.dataPoints[0].parsed.x;
                const opacity: number = tooltip.opacity ? 1 : 0;
                if (index === lastIndex && opacity === lastOpacity) return;

                setLastIndex(index);
                setLastOpacity(opacity);

                if (!opacity) {
                  return setTooltipStyle({
                    opacity,
                    left: tooltipStyle?.left,
                    top: tooltipStyle?.top,
                  });
                }

                setTooltipStyle({
                  opacity,
                  left: `${chart.canvas.offsetLeft + tooltip.caretX}px`,
                  top: `${chart.canvas.offsetTop + tooltip.caretY}px`,
                });

                const dataPoint = tooltip.dataPoints[0];
                setTooltip([
                  capitalize(dayjs(dataPoint.label).format(momentFormat)),
                  tooltipContentGenerator?.(dataPoint.raw as number) ??
                    `${dataPoint.raw} ${unit}`,
                ]);
              },
            },
          },
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 10,
              },
            },
            y: {
              type: 'linear',
              beginAtZero: true,
              offset: true,
              ticks: {
                maxTicksLimit: 5,
              },
            },
          },
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
        }}
      />
    </div>
  );
};

export default StatisticsGraph;
