import { CaretUpFilled, FilterFilled, PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import InvoiceRecipientAPI, {
  InvoiceRecipientViewApiFiler,
} from '../../bookingOrders/api/InvoiceRecipientAPI';
import CreateInvoiceRecipientModal from '../../bookingOrders/components/CreateInvoiceRecipientModal';
import { ViewInvoiceRecipient } from '../../bookingOrders/types/InvoiceRecipient';
import { Permission } from '../../user/types/Permission';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import InvoiceRecipientResults from '../components/InvoiceRecipientResults';
import PaginationMeta from '../../types/PaginationMeta';
import {
  getSearchParam,
  getSearchParamsAnyOf,
  stringifyValues,
} from '../../utils/searchParams';
import InvoiceRecipientFilter from '../components/InvoiceRecpientFilter';

const InvoiceRecipients: React.FC = () => {
  const [invoiceRecipients, setInvoiceRecipients] =
    useState<ViewInvoiceRecipient[]>();
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>();
  const [loadingInvoiceRecipients, setLoadingInvoiceRecipients] =
    useState(true);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterPristine, setFilterPristine] = useState(true);

  const [currentUser] = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchInvoiceRecipients = useCallback(
    async (filter?: InvoiceRecipientViewApiFiler, page = 1) => {
      setLoadingInvoiceRecipients(true);
      try {
        const { data } = await InvoiceRecipientAPI.getViewInvoiceRecipients(
          {
            subsidiaryCompanyId: currentUser?.preferredSubsidiaryCompany?.id,
            ...filter,
          },
          { page },
        );
        setInvoiceRecipients(data.data);
        setPaginationMeta(data.meta);
      } finally {
        setLoadingInvoiceRecipients(false);
      }
    },
    [currentUser?.preferredSubsidiaryCompany?.id],
  );

  useEffect(() => {
    let page = Number(getSearchParam(searchParams, 'page', 'first'));
    if (!isFinite(page) || page < 1) page = 1;

    const filter: InvoiceRecipientViewApiFiler = {
      name: searchParams.get('invoiceRecipientName') || undefined,
      clientCompanyIds: searchParams.getAll('clientCompanies').map(Number),
      organizationNumber: searchParams.get('organizationNumber') || undefined,
      vismaCustomerId: searchParams.get('vismaId') || undefined,
      email: searchParams.get('email') || undefined,
      sortBy: getSearchParamsAnyOf(searchParams, 'sortBy', [
        'TITLE',
        'BOOKING_ORDERS_COUNT',
      ]),
      sortOrder: getSearchParamsAnyOf(searchParams, 'sortOrder', [
        'ASC',
        'DESC',
      ]),
    };

    setFilterPristine(
      Object.values(filter).every(
        (value) =>
          value === undefined || (Array.isArray(value) && value.length === 0),
      ),
    );
    fetchInvoiceRecipients(filter, page);
  }, [fetchInvoiceRecipients, searchParams]);

  return (
    <>
      <div className="flex justify-between items-center">
        <h2>{t('common.invoiceRecipient')}</h2>
        <div className="flex gap-2">
          <Badge dot={!filterPristine}>
            <Tooltip placement="top" title={t('common.filter')}>
              <Button
                type="primary"
                shape="circle"
                icon={filterVisible ? <CaretUpFilled /> : <FilterFilled />}
                onClick={() => setFilterVisible(!filterVisible)}
              />
            </Tooltip>
          </Badge>
          {userHasPermission(
            currentUser,
            Permission.INVOICE_RECIPIENT_CREATE,
          ) && (
            <Button
              type="primary"
              className="text-sm font-medium"
              icon={<PlusOutlined />}
              onClick={() => setCreateModalOpen(true)}>
              {t('views.InvoiceRecipients.newInvoiceRecipient')}
            </Button>
          )}
        </div>
      </div>

      <InvoiceRecipientFilter visible={filterVisible} />

      <CreateInvoiceRecipientModal
        visible={createModalOpen}
        onCreate={() => {
          setCreateModalOpen(false);
          fetchInvoiceRecipients();
        }}
        onCancel={() => setCreateModalOpen(false)}
      />

      <InvoiceRecipientResults
        invoiceRecipients={invoiceRecipients}
        paginationMeta={paginationMeta}
        loading={loadingInvoiceRecipients}
        getLink={
          userHasPermission(currentUser, Permission.INVOICE_RECIPIENT_UPDATE)
            ? (invoiceRecipient) => String(invoiceRecipient.id)
            : undefined
        }
        onPrev={
          paginationMeta &&
          (() =>
            setSearchParams(
              stringifyValues({ page: paginationMeta?.page - 1 }),
            ))
        }
        onNext={
          paginationMeta &&
          (() =>
            setSearchParams(
              stringifyValues({ page: paginationMeta?.page + 1 }),
            ))
        }
        onPageChange={
          paginationMeta &&
          ((page) => setSearchParams(stringifyValues({ page })))
        }
      />

      <Outlet
        context={{
          invoiceRecipients,
          onClose: () => {
            fetchInvoiceRecipients();

            // If we enter the site for the first time, navigate to a clean search state
            // Otherwise go back in history to get the previously set search parameters
            if (location.key === 'default') {
              navigate('.', { relative: 'path' });
            } else {
              history.back();
            }
          },
        }}
      />
    </>
  );
};

export default InvoiceRecipients;
