import { ExclamationCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CourseInstanceStatusBadge from '../../courseInstance/components/CourseInstanceStatusBadge';
import { formatCourseInstanceString } from '../../courseInstance/courseInstanceUtils';
import CourseInstance, {
  CourseInstanceStatus,
} from '../../courseInstance/types/CourseInstance';
import BookingOrder, { BookingOrderStatus } from '../types/BookingOrder';
import { getInfoText } from '../utils/bookingOrder';

import BookingOrderFromLimeBadge from './BookingOrderFromLimeBadge';
import BookingOrderInvoicedBadge from './BookingOrderInvoicedBadge';
import BookingOrderStatusBadge from './BookingOrderStatusBadge';
import BookingOrderFromSelfServiceBadge from './BookingOrderFromSelfServiceBadge';

type BookingOrderCardSize = 'large' | 'small';

type BookingOrderCardProps = {
  bookingOrder: BookingOrder;
  size?: 'large' | 'small';
  className?: string;
};

type CourseInstanceStatusBlipProps = {
  status: CourseInstanceStatus;
  className?: string;
};

const CourseInstanceStatusBlip: React.FC<CourseInstanceStatusBlipProps> = ({
  status,
  className,
}) => {
  const getStatusColor = () => {
    switch (status) {
      case CourseInstanceStatus.Confirmed:
        return 'bg-safeLifeSuccess';
      case CourseInstanceStatus.Preliminary:
        return 'bg-warningYellow';
      case CourseInstanceStatus.Ordered:
        return 'bg-safeLifeLight';
      case CourseInstanceStatus.Canceled:
        return 'bg-warningRed';
    }
  };

  return (
    <Popover
      placement="topLeft"
      arrow
      content={<CourseInstanceStatusBadge status={status} />}>
      <div
        className={`course-instance-status-blip ${className} ${getStatusColor()}`}
      />
    </Popover>
  );
};

const BookingOrderCardOuter: React.FC<{
  link: string;
  size: BookingOrderCardSize;
  children: React.ReactNode;
  className?: string;
}> = ({ children, link, size, className }) => (
  <Link to={link}>
    <div
      className={`bg-white text-black rounded-lg shadow-md p-4 ${
        size === 'large' ? 'mb-4' : 'mb-3'
      } ${className ?? ''}`}>
      {children}
    </div>
  </Link>
);

const BookingOrderCardTitle: React.FC<BookingOrderCardProps> = ({
  bookingOrder,
}) => {
  const { t } = useTranslation();

  const infoText = getInfoText(bookingOrder, t);

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-col overflow-hidden">
        <div className="flex flex-row items-center">
          {infoText && bookingOrder.status !== BookingOrderStatus.Canceled && (
            <Popover
              placement="topLeft"
              arrow
              content={infoText.map((info) => (
                <div key={info}>{info}</div>
              ))}>
              <ExclamationCircleTwoTone
                className="mr-1 text-base"
                twoToneColor={'orange'}
              />
            </Popover>
          )}
          <span className="text-base font-medium mr-2">
            {bookingOrder.clientCompany.name}
          </span>
          <BookingOrderStatusBadge
            status={bookingOrder.status}
            className="mr-2"
          />
          {bookingOrder.invoicedAt && (
            <BookingOrderInvoicedBadge className="mr-2" />
          )}
          {typeof bookingOrder.limeObjectId === 'number' && (
            <BookingOrderFromLimeBadge className="mr-2" />
          )}
          {bookingOrder.selfServiceOrder != null && (
            <BookingOrderFromSelfServiceBadge className="mr-2" />
          )}
        </div>
        <div className="flex flex-row" style={{ whiteSpace: 'nowrap' }}>
          <span className="text-gray-600 whitespace-nowrap">
            {bookingOrder.courseInstances?.length || 0}{' '}
            {formatCourseInstanceString(
              bookingOrder.courseInstances?.length,
              t,
            )}
          </span>
          {bookingOrder.responsibleBooker && (
            <>
              <span className="text-gray-600 mx-0.5">・</span>
              <span className="text-gray-600 whitespace-nowrap">
                {bookingOrder.responsibleBooker.name}
              </span>
            </>
          )}
          {(bookingOrder.comments?.length || false) && (
            <>
              <span className="text-gray-600 mx-0.5">・</span>
              <span className="text-gray-600 whitespace-nowrap">
                {t('components.BookingOrderCard.comments', {
                  count: bookingOrder.comments.length,
                })}
              </span>
            </>
          )}
        </div>
      </div>
      <Button className="mr-1 ml-2">{t('common.open')}</Button>
    </div>
  );
};

const Divider: React.FC = () => <div className="h-px bg-gray-400 my-3" />;

const CourseInstancesList: React.FC<{ courseInstances: CourseInstance[] }> = ({
  courseInstances,
}) => {
  const MAX_DISPLAYED_INITIAL_COURSE_INSTANCES = 3;

  const [displayedList, setDisplayedList] = useState<CourseInstance[]>(
    courseInstances.length > MAX_DISPLAYED_INITIAL_COURSE_INSTANCES
      ? courseInstances.slice(0, MAX_DISPLAYED_INITIAL_COURSE_INSTANCES - 1)
      : courseInstances,
  );
  const [canShowMore, setCanShowMore] = useState<boolean>(
    (courseInstances?.length || 0) > MAX_DISPLAYED_INITIAL_COURSE_INSTANCES,
  );

  const { t } = useTranslation();

  const showMore = (event: React.MouseEvent) => {
    event.preventDefault();
    setCanShowMore(false);
    setDisplayedList(courseInstances || []);
  };

  return (
    <div className="flex flex-col items-center">
      {displayedList.map((courseInstance) => (
        <div
          key={courseInstance.id}
          className="grid grid-cols-3 w-full px-1 mt-1">
          <div className="flex flex-row space-x-1 items-start">
            <div className="flex items-center">
              <CourseInstanceStatusBlip status={courseInstance.status} />
              <span>&nbsp;</span>
            </div>
            <span className="font-medium">{courseInstance.course.name}</span>
          </div>
          <div className="col-span-2 flex flex-row justify-between">
            <span className="flex flex-row text-gray-600">
              {courseInstance.startsAt &&
              courseInstance.endsAt &&
              courseInstance.status !== CourseInstanceStatus.Canceled ? (
                `${dayjs(courseInstance.startsAt).format(
                  'D MMMM YYYY, HH:mm',
                )} - ${dayjs(courseInstance.endsAt).format('HH:mm')}`
              ) : (
                <div className="flex items-center">
                  {courseInstance.status !== CourseInstanceStatus.Canceled && (
                    <ExclamationCircleTwoTone
                      twoToneColor={'orange'}
                      className="mr-1"
                    />
                  )}
                  {t('components.BookingOrderCard.missingTime')}
                </div>
              )}
            </span>
            <span className="flex flex-row text-gray-600">
              {courseInstance.instructor ? (
                courseInstance.instructor.name
              ) : (
                <div className="flex items-start">
                  <div className="items-center">
                    {courseInstance.status !==
                      CourseInstanceStatus.Canceled && (
                      <ExclamationCircleTwoTone
                        twoToneColor={'orange'}
                        className="mr-1"
                      />
                    )}
                    {t('components.BookingOrderCard.missingInstructor')}
                  </div>
                </div>
              )}
            </span>
          </div>
        </div>
      ))}
      {canShowMore && (
        <Button type="text" size="small" onClick={showMore} className="mt-1">
          {t('components.BookingOrderCard.showAll')}
          <PlusOutlined />
        </Button>
      )}
    </div>
  );
};

const BookingOrderCard: React.FC<BookingOrderCardProps> = ({
  bookingOrder,
  size = 'large',
  className,
}) => (
  <BookingOrderCardOuter
    link={`/bestallningar/${bookingOrder.id}`}
    size={size}
    className={className}>
    <BookingOrderCardTitle bookingOrder={bookingOrder} />
    {(bookingOrder.courseInstances?.length || 0) > 0 && size === 'large' && (
      <>
        <Divider />
        <CourseInstancesList courseInstances={bookingOrder.courseInstances!} />
      </>
    )}
  </BookingOrderCardOuter>
);

export default BookingOrderCard;
