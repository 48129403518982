import { ArrowUpOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BookingOrder from '../types/BookingOrder';

import BookingOrderComment from './BookingOrderComment';

type BookingOrderCommentModalProps = {
  setShowModal: (value: boolean) => void;
  showModal: boolean;
  comments?: BookingOrder['comments'];
  submitComment: (text: string) => Promise<void>;
  onDeleteComment: (id: number) => void;
  onUpdateComment: (id: number, text: string) => void;
  submittingComment: boolean;
};

const BookingOrderCommentModal: React.FC<BookingOrderCommentModalProps> = ({
  setShowModal,
  showModal,
  comments,
  submitComment,
  onDeleteComment,
  onUpdateComment,
  submittingComment,
}) => {
  const [newCommentText, setNewCommentText] = useState<string>('');
  const close = () => setShowModal(false);
  const submit = async () => {
    await submitComment(newCommentText);
    setNewCommentText('');
  };
  const { t } = useTranslation();

  return (
    <Modal
      title={t('components.BookingOrderCommentModal.comments', {
        commentCount: comments?.length || 0,
      })}
      open={showModal}
      onCancel={close}
      width={440}
      footer={null}
      maskClosable={false}
      style={{ left: '20%' }}>
      {comments?.map((comment) => (
        <BookingOrderComment
          key={comment.id}
          comment={comment}
          onDelete={onDeleteComment}
          onUpdate={onUpdateComment}
        />
      ))}
      <div className="flex flex-row">
        <Input.TextArea
          key="Comments field"
          autoSize={{ minRows: 1, maxRows: 4 }}
          placeholder={t('components.BookingOrderCommentModal.writeAComment')}
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.target.value)}
          style={{ resize: 'none' }}
        />
        <Button
          key="submit"
          onClick={submit}
          disabled={!newCommentText}
          loading={submittingComment}
          className="ml-2 flex justify-center items-center"
          style={{ height: 36, width: 'calc(36px + 0.5rem)' }}
          icon={<ArrowUpOutlined />}
        />
      </div>
    </Modal>
  );
};

export default BookingOrderCommentModal;
