import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Edit from '../../resources/img/edit.svg';
import Remove from '../../resources/img/remove.svg';
import Swap from '../../resources/img/swap.svg';
import ContactPerson from '../types/ContactPerson';
import FieldSelect from '../../components/FieldSelect';
import { getFullContactName } from '../utils/contactPerson';

import InformationCard from './InformationCard';
import EditContactPersonModal from './EditContactPersonModal';
import CreateContactPersonModal from './CreateContactPersonModal';

type PurchaserFieldProps = {
  bookingOrderId?: number;
  selectedPurchaser?: ContactPerson;
  setSelectedPurchaser?: (purchaser?: ContactPerson) => void;
  purchasers?: ContactPerson[];
  fetchPurchasers?: () => void;
  setDeletePurchaserOnSave?: (purchaser: number) => void;
  disableDelete?: boolean;
  loading?: boolean;
};

const PurchaserField: React.FC<PurchaserFieldProps> = ({
  bookingOrderId,
  selectedPurchaser,
  setSelectedPurchaser,
  purchasers,
  fetchPurchasers,
  setDeletePurchaserOnSave,
  disableDelete,
  loading,
}) => {
  const [showCreatePurchaserModal, setShowCreatePurchaserModal] =
    useState<boolean>(false);
  const [showEditPurchaserModal, setShowEditPurchaserModal] =
    useState<boolean>(false);
  const [fieldSelectDefaultOpen, setFieldSelectDefaultOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const actionPopoverElements =
    setSelectedPurchaser && fetchPurchasers
      ? [
          {
            rowElement: (
              <>
                <img src={Swap} className="pr-2" />
                {t('common.switch')}
              </>
            ),
            action: () => {
              setFieldSelectDefaultOpen(true);
              fetchPurchasers();
              setSelectedPurchaser(undefined);
            },
            hidden: !setSelectedPurchaser,
          },
          {
            rowElement: (
              <>
                <img src={Edit} className="pr-2" />
                {t('common.edit')}
              </>
            ),
            action: () => setShowEditPurchaserModal(true),
          },
          {
            rowElement: (
              <>
                <img src={Remove} className="pr-2" />
                {t('common.remove')}
              </>
            ),
            action: () => {
              setFieldSelectDefaultOpen(false);
              fetchPurchasers();
              setSelectedPurchaser(undefined);
            },
            hidden: !setSelectedPurchaser,
          },
        ]
      : undefined;

  return (
    <>
      {selectedPurchaser ? (
        <InformationCard
          title={t('components.PurchaserField.contactPerson')}
          actionPopoverElements={actionPopoverElements}>
          <div className="gap-y-1 informationCardText pb-2">
            <div>{getFullContactName(selectedPurchaser)}</div>
            <div>{selectedPurchaser.email}</div>
            <div>{selectedPurchaser.phoneNumber}</div>
          </div>
        </InformationCard>
      ) : (
        setSelectedPurchaser && (
          <FieldSelect
            className="w-64 ml-2"
            title={t('components.PurchaserField.contactPerson')}
            placeholderText={t('components.PurchaserField.pickContactPerson')}
            loading={loading}
            defaultOpen={fieldSelectDefaultOpen}
            elements={purchasers?.map((contactPerson) => ({
              primaryText: getFullContactName(contactPerson),
              secondaryText: contactPerson.email,
              id: contactPerson.id,
            }))}
            createButtonText={t(
              'components.PurchaserField.createNewContactPerson',
            )}
            onCreate={() => setShowCreatePurchaserModal(true)}
            onSelect={(selectedId) =>
              setSelectedPurchaser(
                purchasers?.find(
                  (contactPerson) => contactPerson.id === selectedId,
                ),
              )
            }
          />
        )
      )}
      {setSelectedPurchaser && (
        <CreateContactPersonModal
          title={t('components.PurchaserField.createNewContactPerson')}
          visible={showCreatePurchaserModal}
          onCreate={(purchaser) => {
            setSelectedPurchaser(purchaser);
            setShowCreatePurchaserModal(false);
          }}
          onCancel={() => setShowCreatePurchaserModal(false)}
        />
      )}
      {setSelectedPurchaser && selectedPurchaser && (
        <EditContactPersonModal
          title={t('components.PurchaserField.editContactPerson')}
          bookingOrderId={bookingOrderId}
          visible={showEditPurchaserModal}
          disableDelete={disableDelete}
          contactPerson={selectedPurchaser}
          onUpdate={(purchaser) => {
            setSelectedPurchaser(purchaser);
            setShowEditPurchaserModal(false);
          }}
          onDelete={() => {
            setDeletePurchaserOnSave?.(selectedPurchaser.id);
            fetchPurchasers?.();
            setSelectedPurchaser(undefined);
            setShowEditPurchaserModal(false);
          }}
          onCancel={() => setShowEditPurchaserModal(false)}
        />
      )}
    </>
  );
};

export default PurchaserField;
