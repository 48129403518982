import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Spin,
  message,
} from 'antd';
import Course from '../types/Course';
import CertificateSettingSection from './CertificateSettingSection';
import UserAPI from '../../user/UserAPI';
import User from '../../user/types/User';
import { useTranslation } from 'react-i18next';
import Language from '../../types/Language';
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import CertificateAPI from '../../certificate/CertificateAPI';
import {
  AttendeeSignatureType,
  CertificateDateFormat,
  CertificateLanguage,
  CertificateTemplate,
  SignatureType,
} from '../../certificate/types/Certificate';
import { ImageMedia } from '../../types/ImageMedia';

interface CreateOrEditCertificateTemplateModalProps {
  course: Course;
  isOpen: boolean;
  onClose: () => void;
  onTemplateUpdated: (data: CertificateTemplate) => void;
  certificateTemplate?: CertificateTemplate;
}

interface LanguageTextContentValues {
  title: string;
  description: string;
}

interface CertificateFormValues {
  validFor?: number;
  useValidFor: boolean;
  issuer?: number;
  useIssuer: boolean;
  issuerSignature: SignatureType;
  attendeeSignature: AttendeeSignatureType;
  dateFormat: CertificateDateFormat;
  instructorSignature: SignatureType;
  languages: number[];
  languageTextContent: LanguageTextContentValues[];
  stampImage1: number;
  stampImage2: number;
  footerText1?: string;
  footerText2?: string;
}

const CreateOrEditCertificateTemplateModal: React.FC<
  CreateOrEditCertificateTemplateModalProps
> = ({
  course,
  isOpen,
  onClose,
  onTemplateUpdated,
  certificateTemplate,
}: CreateOrEditCertificateTemplateModalProps) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [certificateForm] = Form.useForm();
  const validFor = Form.useWatch('validFor', certificateForm);
  const useValidFor = Form.useWatch('useValidFor', certificateForm);
  const issuer = Form.useWatch('issuer', certificateForm);
  const useIssuer = Form.useWatch('useIssuer', certificateForm);
  const issuerSignature = Form.useWatch('issuerSignature', certificateForm);
  const dateFormat = Form.useWatch('dateFormat', certificateForm);
  const instructorSignature = Form.useWatch(
    'instructorSignature',
    certificateForm,
  );
  const attendeeSignature = Form.useWatch('attendeeSignature', certificateForm);
  const languages = Form.useWatch('languages', certificateForm);
  const languageTextContent = Form.useWatch(
    'languageTextContent',
    certificateForm,
  );
  const stampImage1 = Form.useWatch('stampImage1', certificateForm);
  const stampImage2 = Form.useWatch('stampImage2', certificateForm);
  const footerText1 = Form.useWatch('footerText1', certificateForm);
  const footerText2 = Form.useWatch('footerText2', certificateForm);
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [availableLanguages, setAvailableLanguages] = useState<
    CertificateLanguage[]
  >([]);
  const [chosenLanguage, setChosenLanguage] = useState<number>();
  const [certificateImages, setCertificateImages] = useState<ImageMedia[]>();

  const initializeForm = useCallback(async () => {
    const languagePromise = CertificateAPI.getCertificatesLanguages().then(
      ({ data }) => {
        setAvailableLanguages(data);
      },
    );
    const userPromise = UserAPI.getUsers().then(({ data }) => setUsers(data));
    const certificateImagesPromise = CertificateAPI.getCertificateImages().then(
      ({ data }) => {
        setCertificateImages(data);
      },
    );
    await Promise.all([languagePromise, userPromise, certificateImagesPromise]);

    setLoaded(true);
  }, []);

  useEffect(() => {
    initializeForm();
  }, [initializeForm]);

  useEffect(() => {
    if (isOpen && !!certificateTemplate) {
      certificateForm.setFieldValue(
        'validFor',
        certificateTemplate.validForMonths,
      );
      certificateForm.setFieldValue(
        'useValidFor',
        certificateTemplate.validForMonths,
      );
      certificateForm.setFieldValue('issuer', certificateTemplate?.issuer?.id);
      certificateForm.setFieldValue('useIssuer', !!certificateTemplate?.issuer);
      certificateForm.setFieldValue(
        'dateFormat',
        certificateTemplate?.dateFormat,
      );
      certificateForm.setFieldValue(
        'instructorSignature',
        certificateTemplate?.instructorSignatureType,
      );
      certificateForm.setFieldValue(
        'attendeeSignature',
        certificateTemplate?.attendeeSignatureType,
      );
      certificateForm.setFieldValue(
        'issuerSignature',
        certificateTemplate?.issuerSignatureType,
      );
      certificateForm.setFieldValue(
        'languages',
        certificateTemplate.languageTextContents.map(
          (el) => el.certificateLanguage.id,
        ),
      );
      certificateForm.setFieldValue(
        'languageTextContent',
        certificateTemplate.languageTextContents.map(
          ({ title, description }) => ({ title, description }),
        ),
      );
      certificateForm.setFieldValue(
        'stampImage1',
        certificateTemplate.stampImage1?.id,
      );
      certificateForm.setFieldValue(
        'stampImage2',
        certificateTemplate.stampImage2?.id,
      );
      certificateForm.setFieldValue(
        'footerText1',
        certificateTemplate.footerText1,
      );
      certificateForm.setFieldValue(
        'footerText2',
        certificateTemplate.footerText2,
      );
    }
  }, [certificateForm, certificateTemplate, isOpen]);

  const signatureDisplayValue = (
    singatureType: SignatureType | AttendeeSignatureType,
  ) => {
    switch (singatureType) {
      case AttendeeSignatureType.NoSignature:
      case SignatureType.NoSignature:
        return t('components.CreateOrEditCertificateTemplateModal.noSignature');
      case AttendeeSignatureType.PhysicalSignature:
      case SignatureType.PhysicalSignature:
        return t(
          'components.CreateOrEditCertificateTemplateModal.physicalSignature',
        );
      case SignatureType.DigitalSignature:
        return t(
          'components.CreateOrEditCertificateTemplateModal.digitalSignature',
        );
      default:
        return t('common.notAvailable');
    }
  };

  const dateFormatDisplayValues = (dateFormatValue: CertificateDateFormat) => {
    switch (dateFormatValue) {
      case CertificateDateFormat.MONTH_DAY_YEAR:
        return 'MM-DD-YYYY';
      case CertificateDateFormat.YEAR_MONTH_DAY:
        return 'YYYY-MM-DD';
      case CertificateDateFormat.DAY_MONTH_YEAR:
        return 'DD-MM-YYYY';
      default:
        return t('common.notAvailable');
    }
  };

  const issuerDisplayValue = (
    <>
      {useIssuer ? (
        <>
          <div>{users.find((it) => it.id === issuer)?.name}</div>
          <div>{signatureDisplayValue(issuerSignature)}</div>
        </>
      ) : (
        t('components.CreateOrEditCertificateTemplateModal.notUser')
      )}
    </>
  );

  const languagesData: Language[] = languages?.map((languageId: number) =>
    availableLanguages.find((language) => language.id == languageId),
  );

  const chosenLanguageIndex = languages?.findIndex(
    (el: number) => el == chosenLanguage,
  );

  const chosenLanguageIndexWithFallBack =
    languageTextContent && languageTextContent.length > 0
      ? Math.max(chosenLanguageIndex, 0)
      : chosenLanguageIndex;

  const showValidationError = ({
    errorFields,
  }: ValidateErrorEntity<CertificateFormValues>) => {
    const errors = errorFields
      .map((field) => {
        const fieldName =
          field.name[0] == 'languageTextContent'
            ? `${languagesData[field.name[1] as number].name}-${field.name[2]}`
            : field.name.join(' ');

        return `${fieldName}: ${field.errors.join(' ')}`;
      })
      .join(', ');
    messageApi.open({
      type: 'error',
      content: t(
        'components.CreateOrEditCertificateTemplateModal.validationError',
        {
          errors,
        },
      ),
    });
  };

  const submitTemplate = (values: CertificateFormValues) => {
    const languageTextContents = values.languageTextContent.map((el, i) => ({
      ...el,
      languageId: languagesData[i].id,
    }));

    const issuerId = values.issuer ? values.issuer : null;
    const validForMonths = values.validFor ? values.validFor : null;
    const commonTemplateValues = {
      languageTextContents,
      dateFormat,
      instructorSignatureType: values.instructorSignature,
      attendeeSignatureType: values.attendeeSignature,
      issuerSignatureType: useIssuer
        ? values.issuerSignature
        : SignatureType.NoSignature,
      ...(useIssuer ? { issuerId } : { issuerId: null }),
      ...(useValidFor ? { validForMonths } : { validForMonths: null }),
      ...(stampImage1 ? { stampImage1 } : { stampImage1: null }),
      ...(stampImage2 ? { stampImage2 } : { stampImage2: null }),
      footerText1: values.footerText1,
      footerText2: values.footerText2,
    };

    (!!certificateTemplate
      ? CertificateAPI.updateCertificateTemplate(certificateTemplate.id, {
          ...commonTemplateValues,
        })
      : CertificateAPI.createCertificateTemplate({
          ...commonTemplateValues,
          courseId: course.id,
        })
    ).then(({ data }) => {
      onTemplateUpdated(data);
      resetAndClose();
    });
  };

  const resetAndClose = () => {
    certificateForm.resetFields();
    onClose();
  };

  return (
    <Modal
      maskClosable={false}
      key={`certificate-template-modal-${course.id}`}
      title={t('components.CreateOrEditCertificateTemplateModal.modalTitle')}
      open={isOpen}
      onCancel={resetAndClose}
      footer={[
        <Button key="back" onClick={resetAndClose}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={(_) => certificateForm.submit()}>
          {t('common.save')}
        </Button>,
      ]}>
      <div className="pb-3 border-solid border-0 border-b-2 border-gray-200 flex flex-row justify-between">
        <span className="text-base font-semibold">{course.name}</span>
        <Button type="primary" disabled>
          {t('components.CreateOrEditCertificateTemplateModal.preview')}
        </Button>
      </div>
      {loaded ? (
        <Form
          initialValues={{
            validFor: 12,
            useValidFor: true,
            issuer: undefined,
            useIssuer: false,
            dateFormat: CertificateDateFormat.YEAR_MONTH_DAY,
            attendeeSignature: AttendeeSignatureType.NoSignature,
            instructorSignature: SignatureType.NoSignature,
            issuerSignature: SignatureType.NoSignature,
            languages: [],
            languageTextContent: [],
            stampImage1: 0,
            stampImage2: 0,
            footerText1: undefined,
            footerText2: undefined,
          }}
          onFinish={submitTemplate}
          onFinishFailed={(e) => showValidationError(e)}
          form={certificateForm}
          labelCol={{ span: 6 }}>
          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.language',
            )}
            displayValue={
              <>
                <Space>
                  {languagesData?.map((language: Language, i) => (
                    <Button
                      key={language.id}
                      shape="round"
                      type={
                        i == chosenLanguageIndexWithFallBack
                          ? 'default'
                          : 'dashed'
                      }
                      onClick={() => setChosenLanguage(language.id)}>
                      {language.name}
                    </Button>
                  ))}
                </Space>
                {chosenLanguageIndexWithFallBack >= 0 && (
                  <div className="mt-4 p-3 bg-gray-200 rounded-md">
                    <p className="font-semibold mb-0">
                      {t(
                        'components.CreateOrEditCertificateTemplateModal.title',
                      )}
                    </p>
                    <p>
                      {
                        languageTextContent[chosenLanguageIndexWithFallBack]
                          ?.title
                      }
                    </p>
                    <p className="font-semibold mb-0">
                      {t(
                        'components.CreateOrEditCertificateTemplateModal.description',
                      )}
                    </p>
                    <p className="mb-0">
                      {
                        languageTextContent[chosenLanguageIndexWithFallBack]
                          ?.description
                      }
                    </p>
                  </div>
                )}
              </>
            }>
            <div className="w-full pr-4">
              <Form.Item
                name="languages"
                rules={[{ required: true, message: t('common.required') }]}>
                <Select mode="multiple" className="w-full">
                  {availableLanguages.map((language) => (
                    <Select.Option key={language.id} value={language.id}>
                      {language.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="ml-2">
                {languagesData?.map((el, i) => (
                  <div key={el.id}>
                    <p className="font-semibold ml-1">{el.name}</p>
                    <Form.Item
                      name={['languageTextContent', i, 'title']}
                      label={t(
                        'components.CreateOrEditCertificateTemplateModal.title',
                      )}
                      rules={[
                        { required: true, message: t('common.required') },
                      ]}>
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['languageTextContent', i, 'description']}
                      label={t(
                        'components.CreateOrEditCertificateTemplateModal.description',
                      )}
                      rules={[
                        { required: true, message: t('common.required') },
                      ]}>
                      <Input.TextArea />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </div>
          </CertificateSettingSection>

          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.validFor',
            )}
            displayValue={
              useValidFor
                ? t('components.CreateOrEditCertificateTemplateModal.months', {
                    count: validFor,
                  })
                : t(
                    'components.CreateOrEditCertificateTemplateModal.noExpirationDate',
                  )
            }>
            <Form.Item
              name="validFor"
              label={t(
                'components.CreateOrEditCertificateTemplateModal.validForlabel',
              )}
              labelCol={{ span: 10 }}>
              <InputNumber type="number" min={1} disabled={!useValidFor} />
            </Form.Item>
            <Form.Item
              name="useValidFor"
              valuePropName="checked"
              label={t(
                'components.CreateOrEditCertificateTemplateModal.useExpirationDate',
              )}
              labelCol={{ span: 10 }}>
              <Checkbox />
            </Form.Item>
          </CertificateSettingSection>

          <CertificateSettingSection
            title={t('components.CreateOrEditCertificateTemplateModal.issuer')}
            displayValue={issuerDisplayValue}>
            <Form.Item name="useIssuer" valuePropName="checked">
              <Checkbox>
                {t(
                  'components.CreateOrEditCertificateTemplateModal.useIssuerField',
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item name="issuer">
              <Select
                disabled={!useIssuer}
                placeholder={t(
                  'components.CreateOrEditCertificateTemplateModal.selectIssuer',
                )}
                allowClear>
                {users.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="issuerSignature">
              <Select disabled={!useIssuer}>
                <Select.Option value={SignatureType.NoSignature}>
                  {signatureDisplayValue(SignatureType.NoSignature)}
                </Select.Option>
                <Select.Option value={SignatureType.PhysicalSignature}>
                  {signatureDisplayValue(SignatureType.PhysicalSignature)}
                </Select.Option>
                <Select.Option value={SignatureType.DigitalSignature}>
                  {signatureDisplayValue(SignatureType.DigitalSignature)}
                </Select.Option>
              </Select>
            </Form.Item>
          </CertificateSettingSection>

          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.courseAttendee',
            )}
            displayValue={signatureDisplayValue(attendeeSignature)}>
            <Form.Item name="attendeeSignature">
              <Select>
                <Select.Option value={AttendeeSignatureType.NoSignature}>
                  {signatureDisplayValue(AttendeeSignatureType.NoSignature)}
                </Select.Option>
                <Select.Option value={AttendeeSignatureType.PhysicalSignature}>
                  {signatureDisplayValue(
                    AttendeeSignatureType.PhysicalSignature,
                  )}
                </Select.Option>
              </Select>
            </Form.Item>
          </CertificateSettingSection>

          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.instructor',
            )}
            displayValue={signatureDisplayValue(instructorSignature)}>
            <Form.Item name="instructorSignature">
              <Select>
                <Select.Option value={SignatureType.NoSignature}>
                  {signatureDisplayValue(SignatureType.NoSignature)}
                </Select.Option>
                <Select.Option value={SignatureType.PhysicalSignature}>
                  {signatureDisplayValue(SignatureType.PhysicalSignature)}
                </Select.Option>
                <Select.Option value={SignatureType.DigitalSignature}>
                  {signatureDisplayValue(SignatureType.DigitalSignature)}
                </Select.Option>
              </Select>
            </Form.Item>
          </CertificateSettingSection>

          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.dateFormat',
            )}
            displayValue={dateFormatDisplayValues(dateFormat)}>
            <Form.Item name="dateFormat">
              <Select>
                <Select.Option value={CertificateDateFormat.YEAR_MONTH_DAY}>
                  {dateFormatDisplayValues(
                    CertificateDateFormat.YEAR_MONTH_DAY,
                  )}
                </Select.Option>
                <Select.Option value={CertificateDateFormat.MONTH_DAY_YEAR}>
                  {dateFormatDisplayValues(
                    CertificateDateFormat.MONTH_DAY_YEAR,
                  )}
                </Select.Option>
                <Select.Option value={CertificateDateFormat.DAY_MONTH_YEAR}>
                  {dateFormatDisplayValues(
                    CertificateDateFormat.DAY_MONTH_YEAR,
                  )}
                </Select.Option>
              </Select>
            </Form.Item>
          </CertificateSettingSection>
          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.stampImage1',
            )}
            displayValue={
              certificateImages?.find((image) => image.id == stampImage1)
                ?.name ??
              t('components.CreateOrEditCertificateTemplateModal.doNotUse')
            }>
            <Form.Item name="stampImage1">
              <Select>
                <Select.Option value={0}>
                  {t(
                    'components.CreateOrEditCertificateTemplateModal.doNotUse',
                  )}
                </Select.Option>
                {certificateImages?.map((image) => (
                  <Select.Option key={image.id} value={image.id}>
                    {image.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </CertificateSettingSection>

          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.stampImage2',
            )}
            displayValue={
              certificateImages?.find((image) => image.id == stampImage2)
                ?.name ??
              t('components.CreateOrEditCertificateTemplateModal.doNotUse')
            }>
            <Form.Item name="stampImage2">
              <Select>
                <Select.Option value={0}>
                  {t(
                    'components.CreateOrEditCertificateTemplateModal.doNotUse',
                  )}
                </Select.Option>
                {certificateImages?.map((image) => (
                  <Select.Option key={image.id} value={image.id}>
                    {image.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </CertificateSettingSection>
          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.footerText1',
            )}
            displayValue={footerText1}>
            <Form.Item name="footerText1">
              <Input />
            </Form.Item>
          </CertificateSettingSection>
          <CertificateSettingSection
            title={t(
              'components.CreateOrEditCertificateTemplateModal.footerText2',
            )}
            displayValue={footerText2}>
            <Form.Item name="footerText2">
              <Input />
            </Form.Item>
          </CertificateSettingSection>
        </Form>
      ) : (
        <Spin size="large" className="flex justify-center items-center h-40" />
      )}
      {contextHolder}
    </Modal>
  );
};

export default CreateOrEditCertificateTemplateModal;
