import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Button,
  Skeleton,
  List,
  Select,
  Form,
  Empty,
  Input,
  Switch,
  InputNumber,
} from 'antd';
import { DeleteOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import User, { UserRole } from '../types/User';
import Course from '../../course/types/Course';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import Location from '../../types/Location';
import UserAPI from '../UserAPI';
import { getMarkets } from '../../market/MarketAPI';
import * as API from '../../api';
import CourseAPI from '../../course/CourseAPI';
import SubsidiaryCompanyAPI from '../../subsidiaryCompany/SubsidiaryCompanyAPI';
import { getUserRoleText, userHasPermission } from '../userUtils';
import { useUser } from '../userContext';
import { formatCourseInstanceString } from '../../courseInstance/courseInstanceUtils';
import Language from '../../types/Language';
import AddressSearchInput, {
  PlaceSuggestion,
} from '../../components/AddressSearchInput';
import DetailsRow from '../../components/DetailsRow';
import ConditionalTooltip from '../../components/ConditionalTooltip';
import NumberPicker from '../../components/NumberPicker';
import { Permission } from '../types/Permission';
import Market from '../../market/types/Market';
import { CourseInstanceLimitBoundary } from '../../courseInstance/types/CourseInstanceLimit';

type CoursesFormValues = {
  courseIds: number[];
  userRoles: UserRole[];
};

type UserRolesValues = {
  userRoles: UserRole[];
};

type LocationFormValues = {
  location: PlaceSuggestion;
};

type PreferredLanguageFormValues = {
  preferredLanguageId: number;
};

type UserFormValues = {
  email?: string;
  name?: string;
  phoneNumber?: string;
  maxTravelDistance?: number;
  defaultCourseInstanceLimit?: number;
  bookerNote?: string;
  marketId?: number;
  internal?: boolean;
};

type UserSubsidiariesFormValues = {
  subsidiaryCompanyIds: number[];
};

type UserInfoProps = {
  user?: User;
  loading: boolean;
  onUpdate?: () => void;
};

const UserInfo: React.FC<UserInfoProps> = ({ user, loading, onUpdate }) => {
  const [addressForm] = Form.useForm();
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [courses, setCourses] = useState<Course[]>();
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState<boolean>(false);
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);
  const [languages, setLanguages] = useState<Language[]>();
  const [loadingMarkets, setLoadingMarkets] = useState<boolean>(false);
  const [markets, setMarkets] = useState<Market[]>();
  const [locationChanged, setLocationChanged] = useState<boolean>(false);
  const [updatingLocations, setUpdatingLocations] = useState<boolean>(false);
  const [updatingFavorites, setUpdatingFavorites] = useState(false);
  const [currentUser, setCurrentUser] = useUser();

  const { t } = useTranslation();

  const fetchMarkets = useCallback(async () => {
    if (userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL)) {
      setLoadingMarkets(true);
      try {
        const { data } = await getMarkets();
        setMarkets(data);
      } finally {
        setLoadingMarkets(false);
      }
    }
  }, [currentUser]);

  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const { data } = await CourseAPI.getCourses({
        marketId: user?.market.id,
      });
      setCourses(data);
    } finally {
      setLoadingCourses(false);
    }
  }, [user?.market.id]);

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  const fetchLanguages = useCallback(async () => {
    setLoadingLanguages(true);
    try {
      const { data } = await API.getLanguages();
      setLanguages(data);
    } finally {
      setLoadingLanguages(false);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL)) {
      fetchSubsidiaryCompanies();
    }
    fetchCourses();
    fetchLanguages();
    fetchMarkets();
  }, [
    currentUser,
    fetchCourses,
    fetchLanguages,
    fetchMarkets,
    fetchSubsidiaryCompanies,
    user,
  ]);

  const addLocation = useCallback(
    async (values: LocationFormValues) => {
      setUpdatingLocations(true);
      try {
        await UserAPI.createUserLocation(user!.id, {
          address: values.location.address,
          placeId: values.location.placeId,
          geolocation: {
            lat: values.location.geolocation.lat,
            lng: values.location.geolocation.lng,
          },
        });
        onUpdate?.();
      } finally {
        setUpdatingLocations(false);
        setLocationChanged(false);
        addressForm.resetFields();
      }
    },
    [addressForm, onUpdate, user],
  );

  const deleteLocation = useCallback(
    async (locationId: Location['id']) => {
      setUpdatingLocations(true);
      try {
        await UserAPI.deleteUserLocation(user!.id, locationId);
        onUpdate?.();
      } finally {
        setUpdatingLocations(false);
      }
    },
    [onUpdate, user],
  );

  const updateCourses = useCallback(
    async (values: CoursesFormValues) => {
      await UserAPI.setUserCourses(
        user!.id,
        values.courseIds.concat(
          user?.courses
            .filter((course) => course.market.id !== user.market.id)
            .map(({ id }) => id) ?? [],
        ),
      );
      onUpdate?.();
    },
    [onUpdate, user],
  );

  const updateRoles = useCallback(
    async (values: UserRolesValues) => {
      await UserAPI.setUserRoles(user!.id, { roles: values.userRoles });
      onUpdate?.();
    },
    [onUpdate, user],
  );

  const updatePreferredLanguage = useCallback(
    async (values: PreferredLanguageFormValues) => {
      if (user) {
        await UserAPI.updatePreferredLanguage(user.id, {
          preferredLanguageId: values.preferredLanguageId,
        });
        if (
          currentUser &&
          currentUser.id === user.id &&
          values.preferredLanguageId !== currentUser.preferredLanguage?.id
        ) {
          // Reload page to update language on the current view
          window.location.reload();
        } else {
          onUpdate?.();
        }
      }
    },
    [currentUser, onUpdate, user],
  );

  const setFavoriteInstructors = useCallback(
    async (favoriteInstructorIds: number[]) => {
      if (currentUser && user && user.id) {
        setUpdatingFavorites(true);
        try {
          const { data } = await UserAPI.setUserFavoriteInstructor(
            currentUser.id,
            {
              instructorIds: favoriteInstructorIds,
            },
          );
          setCurrentUser(data);
        } finally {
          setUpdatingFavorites(false);
        }
      }
    },
    [currentUser, setCurrentUser, user],
  );

  const updateUser = useCallback(
    async (values: UserFormValues) => {
      if (!user) {
        return;
      }

      const updatingMarketOfCurrentUser =
        currentUser &&
        currentUser.id === user.id &&
        !!values.marketId &&
        values.marketId !== currentUser.market.id;

      const preferredSubsidiaryCompanyId = updatingMarketOfCurrentUser
        ? user.subsidiaryCompanies.find(
            (company) => company.market.id == values.marketId,
          )?.id
        : user.preferredSubsidiaryCompany?.id;

      await UserAPI.updateUser(user.id, {
        email: values.email ?? user.email,
        name: values.name ?? user.name,
        marketId: values.marketId ?? user.market.id,
        phoneNumber: values.phoneNumber ?? user.phoneNumber,
        internal: values.internal ?? user.internal,
        maxTravelDistance: values.maxTravelDistance ?? user.maxTravelDistance,
        defaultCourseInstanceLimit:
          values.defaultCourseInstanceLimit ?? user.defaultCourseInstanceLimit,
        preferredSubsidiaryCompanyId: preferredSubsidiaryCompanyId,
        preferredLanguageId: user.preferredLanguage?.id,
        bookerNote: values.bookerNote ?? user.bookerNote,
      });

      if (updatingMarketOfCurrentUser) {
        // Reload page to update subsidiary company picker in navbar
        window.location.reload();
      } else {
        onUpdate?.();
      }
    },
    [currentUser, onUpdate, user],
  );

  const updateDefaultCourseInstanceLimit = useCallback(
    async (defaultCourseInstanceLimit: number) => {
      await UserAPI.updateDefaultCourseInstanceLimit(user!.id, {
        defaultCourseInstanceLimit: defaultCourseInstanceLimit,
      });

      onUpdate?.();
    },
    [onUpdate, user],
  );

  const updateSubsidiaries = useCallback(
    async (values: UserSubsidiariesFormValues) => {
      // Re-add filtered away subsidiaries from other markets
      const crossMarketSubsidiaryCompanyIdList =
        values.subsidiaryCompanyIds.concat(
          user?.subsidiaryCompanies
            .filter((company) => company.market.id != user.market.id)
            .map((company) => company.id) ?? [],
        );

      await UserAPI.setUserSubsidiaries(
        user!.id,
        crossMarketSubsidiaryCompanyIdList,
      );
      onUpdate?.();
    },
    [onUpdate, user],
  );

  const onPlaceSuggestionChange = useCallback(
    (suggestion?: PlaceSuggestion | null) => {
      setLocationChanged(!!suggestion);
    },
    [],
  );

  const allowRestrictedEdits =
    userHasPermission(currentUser, Permission.USER_UPDATE_RESTRICTED) &&
    user?.roles.length === 1 &&
    user.roles.includes(UserRole.Instructor);

  const marketSubsidiaries = useMemo(
    () =>
      subsidiaryCompanies?.filter(
        (company) => company.market.id == user?.market.id,
      ) ?? [],
    [subsidiaryCompanies, user?.market.id],
  );

  const userMarketSubsidiaries = useMemo(
    () =>
      user?.subsidiaryCompanies.filter(
        (company) => company.market.id == user.market.id,
      ) ?? [],
    [user?.market.id, user?.subsidiaryCompanies],
  );

  const inMarketCourses = user?.courses.filter(
    (course) => course.market.id === user.market.id,
  );

  return (
    <Skeleton loading={loading && !user} paragraph={{ rows: 5 }} round active>
      <dl>
        <DetailsRow
          label={t('common.name')}
          displayValue={user?.name}
          editable={userHasPermission(
            currentUser,
            Permission.USER_UPDATE_GLOBAL,
          )}
          formContent={
            <Form.Item name="name" initialValue={user?.name}>
              <Input placeholder={t('common.name')} />
            </Form.Item>
          }
          onFinish={(values) => updateUser(values as UserFormValues)}
        />
        <DetailsRow
          label={t('common.email')}
          displayValue={user?.email}
          editable={userHasPermission(
            currentUser,
            Permission.USER_UPDATE_GLOBAL,
          )}
          formContent={
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('components.UserInfo.invalidEmail'),
                },
              ]}
              name="email"
              initialValue={user?.email}>
              <Input placeholder={t('common.email')} />
            </Form.Item>
          }
          onFinish={(values) => updateUser(values as UserFormValues)}
        />
        <DetailsRow
          label={t('common.phoneNumber')}
          displayValue={user?.phoneNumber}
          editable={userHasPermission(
            currentUser,
            Permission.USER_UPDATE_GLOBAL,
          )}
          formContent={
            <Form.Item name="phoneNumber" initialValue={user?.phoneNumber}>
              <Input placeholder={t('common.phoneNumber')} />
            </Form.Item>
          }
          onFinish={(values) => updateUser(values as UserFormValues)}
        />
        <DetailsRow
          label={t('common.roles')}
          displayValue={
            <>
              {user?.roles?.length ? (
                <div className="flex flex-wrap">
                  {user?.roles.map((role) => (
                    <span
                      key={role}
                      className="mr-2 mb-2 px-2 inline-flex rounded-full bg-gray-200">
                      {getUserRoleText(role, t)}
                    </span>
                  ))}
                </div>
              ) : (
                <span className="text-sm text-gray-600">
                  {userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL)
                    ? t('components.UserInfo.addRoles')
                    : ''}
                </span>
              )}
            </>
          }
          editable={userHasPermission(
            currentUser,
            Permission.USER_UPDATE_GLOBAL,
          )}
          formContent={
            <Form.Item
              name="userRoles"
              initialValue={user?.roles.map((role) => role)}>
              <Select
                showSearch
                mode="multiple"
                placeholder={t('components.UserInfo.selectRoles')}
                filterOption={(
                  input,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  option: any,
                ) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('components.UserInfo.noRolesFound')}
                  />
                }>
                {Object.values(UserRole).map((role) => (
                  <Select.Option key={role} value={role}>
                    {getUserRoleText(role, t)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          onFinish={(values) => updateRoles(values as UserRolesValues)}
        />
        <DetailsRow
          label={t('common.language')}
          displayValue={
            user?.preferredLanguage?.code
              ? t(`languages.${user.preferredLanguage.code}` as const)
              : t('components.UserInfo.noLanguageSelected')
          }
          editable={
            userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) ||
            (!!currentUser && !!user && currentUser.id === user.id)
          }
          formContent={
            <Form.Item
              name="preferredLanguageId"
              initialValue={user?.preferredLanguage?.id}>
              <Select
                showSearch
                placeholder={t('components.UserInfo.selectLanguage')}
                loading={loadingLanguages}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                filterOption={(input, option: any) =>
                  option!
                    .children!.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('components.UserInfo.noLanguagesFound')}
                  />
                }>
                {languages?.map((language) => (
                  <Select.Option key={language.id} value={language.id}>
                    {t(`languages.${language.code}`)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          onFinish={(values) =>
            updatePreferredLanguage(values as PreferredLanguageFormValues)
          }
        />

        <DetailsRow
          label={t('common.market')}
          displayValue={user?.market.name}
          editable={userHasPermission(
            currentUser,
            Permission.USER_UPDATE_GLOBAL,
          )}
          formContent={
            <Form.Item name="marketId" initialValue={user?.market.id}>
              <Select
                showSearch
                placeholder={t('common.market')}
                loading={loadingMarkets}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('components.UserInfo.noMarketsFound')}
                  />
                }>
                {markets?.map((market) => (
                  <Select.Option key={market.id} value={market.id}>
                    {market.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          onFinish={(values) => updateUser(values as UserFormValues)}
        />
        {user?.isInstructor && (
          <>
            <DetailsRow
              label={t('components.UserInfo.address')}
              loading={updatingLocations}
              displayValue={
                <div className="flex-1">
                  <List<Location>
                    itemLayout="horizontal"
                    split={false}
                    dataSource={user?.locations || undefined}
                    rowKey={(location) => String(location.id)}
                    renderItem={(location) => (
                      <List.Item
                        actions={[
                          <ConditionalTooltip
                            key="key"
                            displayTitle={t(
                              'components.UserInfo.onlyAdminCanRemoveAdress',
                            )}
                            component={
                              <Button
                                disabled={
                                  !userHasPermission(
                                    currentUser,
                                    Permission.USER_UPDATE_GLOBAL,
                                  )
                                }
                                key="delete"
                                type="text"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => deleteLocation(location.id)}>
                                {t('common.remove')}
                              </Button>
                            }
                            condition={
                              !userHasPermission(
                                currentUser,
                                Permission.USER_UPDATE_GLOBAL,
                              )
                            }
                          />,
                        ]}>
                        <List.Item.Meta
                          title={
                            <>
                              <EnvironmentOutlined className="mr-2" />
                              {location.address}
                            </>
                          }
                        />
                      </List.Item>
                    )}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_DEFAULT}
                          description={t('components.UserInfo.selectAddress')}
                        />
                      ),
                    }}
                  />
                  <ConditionalTooltip
                    component={
                      <Form
                        form={addressForm}
                        onFinish={(values) =>
                          addLocation(values as LocationFormValues)
                        }>
                        {user.locations.length < 1 && (
                          <Form.Item
                            name="location"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  'components.UserInfo.pleaseEnterAddress',
                                ),
                              },
                            ]}>
                            <AddressSearchInput
                              disabled={
                                !userHasPermission(
                                  currentUser,
                                  Permission.USER_UPDATE_GLOBAL,
                                )
                              }
                              placeholder={t('components.UserInfo.address')}
                              onChange={onPlaceSuggestionChange}
                            />
                          </Form.Item>
                        )}
                        {locationChanged && (
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              {t('components.UserInfo.addLocation')}
                            </Button>
                          </Form.Item>
                        )}
                      </Form>
                    }
                    condition={
                      !userHasPermission(
                        currentUser,
                        Permission.USER_UPDATE_GLOBAL,
                      )
                    }
                    displayTitle={t(
                      'components.UserInfo.onlyAdminCanEditAdress',
                    )}
                  />
                </div>
              }
            />
            <DetailsRow
              label={t('components.UserInfo.travelDistance') + ' (km)'}
              displayValue={
                user.maxTravelDistance ? `${user?.maxTravelDistance} km` : ''
              }
              editable={
                userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) ||
                allowRestrictedEdits
              }
              formContent={
                <Form.Item
                  name="maxTravelDistance"
                  initialValue={user?.maxTravelDistance}>
                  <InputNumber min={0} />
                </Form.Item>
              }
              onFinish={(values) => updateUser(values as UserFormValues)}
            />
            <DetailsRow
              label={t('common.courses')}
              displayValue={
                <>
                  {inMarketCourses?.length ? (
                    <div className="flex flex-wrap">
                      {inMarketCourses
                        .sort((a, b) => (a.name < b.name ? -1 : 0))
                        .map((course) => (
                          <span
                            key={course.id}
                            className="mr-2 mb-2 px-2 inline-flex rounded-full bg-gray-200">
                            {course.name}
                          </span>
                        ))}
                    </div>
                  ) : (
                    <span className="text-sm text-gray-600">
                      {userHasPermission(
                        currentUser,
                        Permission.USER_UPDATE_GLOBAL,
                      )
                        ? t('components.UserInfo.addCourses') + '...'
                        : ''}
                    </span>
                  )}
                </>
              }
              editable={
                userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) ||
                allowRestrictedEdits
              }
              formContent={
                <Form.Item
                  name="courseIds"
                  initialValue={inMarketCourses?.map((course) => course.id)}>
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder={t('components.UserInfo.selectCourses')}
                    loading={loadingCourses}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    filterOption={(input, option: any) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('components.UserInfo.noCoursesFound')}
                      />
                    }>
                    {courses?.map((course) => (
                      <Select.Option key={course.id} value={course.id}>
                        {course.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              }
              onFinish={(values) => updateCourses(values as CoursesFormValues)}
            />
            {user.isInstructor &&
              userHasPermission(currentUser, Permission.BOOKER_NOTE_READ) && (
                <DetailsRow
                  label={t('components.UserInfo.bookerNote')}
                  displayValue={
                    <Input.TextArea
                      value={user.bookerNote || undefined}
                      placeholder={t('views.UserDetails.noNote')}
                      autoSize={{ minRows: 1 }}
                      readOnly
                      bordered={false}
                      style={{ resize: 'none' }}
                    />
                  }
                  editable={
                    userHasPermission(
                      currentUser,
                      Permission.USER_UPDATE_GLOBAL,
                    ) || allowRestrictedEdits
                  }
                  formContent={
                    <Form.Item name="bookerNote" initialValue={user.bookerNote}>
                      <Input.TextArea
                        autoSize={{ minRows: 1 }}
                        placeholder={t('views.UserDetails.writeANote')}
                        style={{ resize: 'none' }}
                      />
                    </Form.Item>
                  }
                  onFinish={(values) => updateUser(values as UserFormValues)}
                />
              )}
            <DetailsRow
              label={t('components.UserInfo.maxCourseInstancesPerDay')}
              displayValue={t('components.UserInfo.maxCourseInstancesText', {
                courseInstanceCountString: `${
                  user.defaultCourseInstanceLimit
                } ${formatCourseInstanceString(
                  user.defaultCourseInstanceLimit,
                  t,
                )}`,
              })}
              editable={
                userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) ||
                currentUser?.id === user.id
              }
              formContent={
                <Form.Item
                  name="defaultCourseInstanceLimit"
                  initialValue={user.defaultCourseInstanceLimit}>
                  <NumberPicker
                    size="large"
                    min={CourseInstanceLimitBoundary.Min}
                    max={CourseInstanceLimitBoundary.Max}
                  />
                </Form.Item>
              }
              onFinish={(values) =>
                updateDefaultCourseInstanceLimit(
                  values.defaultCourseInstanceLimit,
                )
              }
            />
            {userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) && (
              <DetailsRow
                label={t('components.UserInfo.inhouseInstructor')}
                displayValue={
                  <Form.Item name="internal" valuePropName="checked">
                    <Switch
                      defaultChecked={user.internal}
                      onChange={(values) => updateUser({ internal: values })}
                    />
                  </Form.Item>
                }
              />
            )}
            {currentUser &&
              userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) && (
                <DetailsRow
                  label={t('components.UserInfo.favoriteInstructor')}
                  loading={updatingFavorites}
                  displayValue={
                    <Form.Item name="internal" valuePropName="checked">
                      <Switch
                        defaultChecked={
                          user.id !== null &&
                          currentUser.favoriteInstructorIds.includes(user.id)
                        }
                        onChange={(toggled) => {
                          if (user.id) {
                            const favoriteInstructorIds = toggled
                              ? currentUser.favoriteInstructorIds.concat([
                                  user.id,
                                ])
                              : currentUser.favoriteInstructorIds.filter(
                                  (id) => id !== user.id,
                                );

                            setFavoriteInstructors(favoriteInstructorIds);
                          }
                        }}
                      />
                    </Form.Item>
                  }
                />
              )}
          </>
        )}
        {!user?.isOnlyInstructor && (
          <DetailsRow
            label={t('common.companies')}
            displayValue={
              <>
                {userMarketSubsidiaries.length ? (
                  <div className="flex flex-wrap">
                    {userMarketSubsidiaries.map((subsidiaryCompany) => (
                      <span
                        key={subsidiaryCompany.id}
                        className="mr-2 mb-2 px-2 inline-flex rounded-full bg-gray-200">
                        {subsidiaryCompany.name}
                      </span>
                    ))}
                  </div>
                ) : (
                  <span className="text-sm text-gray-600">
                    {userHasPermission(
                      currentUser,
                      Permission.USER_UPDATE_GLOBAL,
                    )
                      ? t('components.UserInfo.addCompanies')
                      : ''}
                  </span>
                )}
              </>
            }
            editable={userHasPermission(
              currentUser,
              Permission.USER_UPDATE_GLOBAL,
            )}
            formContent={
              <Form.Item
                name="subsidiaryCompanyIds"
                initialValue={userMarketSubsidiaries.map(
                  (subsidiaryCompany) => subsidiaryCompany.id,
                )}>
                <Select
                  showSearch
                  mode="multiple"
                  placeholder={t('components.UserInfo.selectCompanies')}
                  loading={loadingSubsidiaryCompanies}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  filterOption={(input, option: any) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={t('components.UserInfo.noCompaniesFound')}
                    />
                  }>
                  {marketSubsidiaries.map((subsidiaryCompany) => (
                    <Select.Option
                      key={subsidiaryCompany.id}
                      value={subsidiaryCompany.id}>
                      {subsidiaryCompany.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
            onFinish={(values) =>
              updateSubsidiaries(values as UserSubsidiariesFormValues)
            }
          />
        )}
      </dl>
    </Skeleton>
  );
};

export default UserInfo;
