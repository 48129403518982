import User from '../user/types/User';
import client from '../api/client';
import Market from '../market/types/Market';

import Course from './types/Course';
import CourseResource from './types/CourseResource';
import { CertificateLanguage } from '../certificate/types/Certificate';

export type CreateCourseParams = {
  name: string;
  defaultPrice?: number;
  currencyId: number;
  vismaInventoryNumber: string | null;
  elsaCourseId?: string;
};

const createCourse = (course: CreateCourseParams) =>
  client.post<Course>('/courses', course);

export type GetCourseParams = {
  userIds?: User['id'][];
  marketId?: Market['id'];
};

const getCourses = (params?: GetCourseParams) =>
  client.get<Course[]>('/courses', {
    params,
  });

const getCourse = (id: Course['id']) => client.get<Course>(`/courses/${id}`);

export type UpdateCourseResourceParams = {
  id?: number;
  title: string;
  url: string;
  delete?: boolean;
};

export type UpdateCourseParams = {
  name: string;
  defaultPrice?: number;
  currencyId: number;
  courseResources?: UpdateCourseResourceParams[];
  elsaCourseId?: string;
};

const updateCourse = (id: Course['id'], params: UpdateCourseParams) =>
  client.patch<Course>(`/courses/${id}`, params);

const deleteCourse = (id: Course['id']) => client.delete(`/courses/${id}`);

const getCourseResources = (id: Course['id']) =>
  client.get<CourseResource[]>(`/courses/${id}/resources`);

const getCourseCertificateLanguages = (id: Course['id']) =>
  client.get<CertificateLanguage[]>(`/courses/${id}/certificate-languages`);

export default {
  createCourse,
  getCourse,
  getCourses,
  updateCourse,
  deleteCourse,
  getCourseResources,
  getCourseCertificateLanguages,
};
