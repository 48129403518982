import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, message, Modal, Tooltip } from 'antd';
import ClientCompany, { ElsaClientCompany } from '../types/ClientCompany';
import { QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import colors from '../../theme/colors';
import ClientCompanyAPI from '../api/ClientCompanyAPI';
import { isAxiosError } from 'axios';
import FieldSelect from '../../components/FieldSelect';
import CreateElsaClientCompanyModal from './CreateElsaClientCompanyModal';
import ElsaAPI from '../api/ElsaAPI';

type MapCompanyModalValues = {
  elsaClientCompanyId?: string;
};

type MapCompanyModalProps = {
  clientCompany: ClientCompany;
  close: () => void;
  onUpdate?: (clientCompany: ClientCompany) => void;
};

export const MapCompanyModal: FC<MapCompanyModalProps> = ({
  clientCompany,
  close,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const [elsaClientCompanies, setElsaClientCompanies] = useState<
    ElsaClientCompany[]
  >([]);
  const [loadingElsaClientCompanies, setLoadingElsaClientCompanies] =
    useState(false);
  const [
    showCreateElsaClientCompanyModal,
    setShowCreateElsaClientCompanyModal,
  ] = useState(false);
  const fetchElsaClientCompanies = useCallback(async () => {
    setLoadingElsaClientCompanies(true);
    try {
      const { data } = await ElsaAPI.getElsaClientCompanies(
        clientCompany.subsidiaryCompany.id,
      );
      setElsaClientCompanies(data);
    } catch (error) {
      if (isAxiosError(error)) {
        message.error(
          error.response?.data.message ?? t('errors.somethingWentWrong'),
        );
        close();
      }
    } finally {
      setLoadingElsaClientCompanies(false);
    }
  }, [close, clientCompany.subsidiaryCompany.id, t]);

  useEffect(() => {
    fetchElsaClientCompanies();
  }, [fetchElsaClientCompanies]);

  const onFinish = async (values: MapCompanyModalValues) => {
    setSubmitting(true);

    try {
      const { data } = await ClientCompanyAPI.updateClientCompany(
        clientCompany.id,
        {
          name: clientCompany.name,
          elsaClientCompanyId: values.elsaClientCompanyId,
        },
      );

      message.success(t('components.MapCompanyModal.onCreated'));
      form.resetFields();
      onUpdate?.(data);
      close();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        key="course-modal"
        title={t('components.MapCompanyModal.title')}
        open
        onCancel={close}
        footer={
          <div className="flex justify-end gap-2">
            <Button key="cancel" onClick={close}>
              {t('common.cancel')}
            </Button>
            <Button
              key="save"
              type="primary"
              loading={submitting}
              form="form"
              htmlType="submit">
              {t('components.MapCompanyModal.saveMapping')}
            </Button>
          </div>
        }
        maskClosable={false}>
        <Form
          id="form"
          form={form}
          onFinish={(values) => onFinish(values as MapCompanyModalValues)}
          initialValues={{
            elsaClientCompanyId: undefined,
          }}>
          <div className="mb-1 flex flex-row items-center gap-2">
            <span className="text-md">
              {t('components.MapCompanyModal.findInElsa')}
            </span>
            <Tooltip
              title={t('components.MapCompanyModal.info')}
              placement="right">
              <QuestionCircleOutlined />
            </Tooltip>
          </div>

          <FieldSelect
            formName="elsaClientCompanyId"
            formRules={[
              {
                required: true,
                message: t('common.required'),
              },
            ]}
            elements={elsaClientCompanies.map((ecc) => ({
              primaryText: ecc.name,
              id: ecc.id,
            }))}
            loadingOptions={loadingElsaClientCompanies}
            createButtonText={t('components.MapCompanyModal.createNewInElsa')}
            placeholderText={clientCompany.name}
            onCreate={() => setShowCreateElsaClientCompanyModal(true)}
          />

          <div className="mt-4 flex flex-row gap-4 items-center">
            <WarningOutlined
              style={{ fontSize: '24px', color: colors.warningYellow }}
            />
            <span>{t('components.MapCompanyModal.warning')}</span>
          </div>

          {showCreateElsaClientCompanyModal && (
            <CreateElsaClientCompanyModal
              clientCompany={clientCompany}
              onCancel={() => setShowCreateElsaClientCompanyModal(false)}
              onCreate={(ecc: ElsaClientCompany) => {
                fetchElsaClientCompanies().then(() =>
                  form.setFieldValue('elsaClientCompanyId', ecc.id),
                );
              }}
            />
          )}
        </Form>
      </Modal>
    </>
  );
};
