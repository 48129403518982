import React from 'react';
import dayjs from 'dayjs';
import {
  ClockCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { CourseInstanceStatus } from '../types/CourseInstance';

export const statusText: (t: TFunction) => {
  [key in CourseInstanceStatus]: string;
} = (t) => ({
  [CourseInstanceStatus.Canceled]: t(
    'components.CourseInstanceStatusBadge.canceled',
  ),
  [CourseInstanceStatus.Preliminary]: t(
    'components.CourseInstanceStatusBadge.preliminary',
  ),
  [CourseInstanceStatus.Confirmed]: t(
    'components.CourseInstanceStatusBadge.confirmed',
  ),
  [CourseInstanceStatus.Ordered]: t(
    'components.CourseInstanceStatusBadge.ordered',
  ),
});

const statusStyle: { [key in CourseInstanceStatus]: string } = {
  [CourseInstanceStatus.Canceled]: 'bg-red-100 text-red-800',
  [CourseInstanceStatus.Preliminary]: 'bg-yellow-100 text-yellow-800',
  [CourseInstanceStatus.Confirmed]: 'bg-green-100 text-green-800',
  [CourseInstanceStatus.Ordered]: 'bg-blue-100 text-blue-800',
};

const statusIcon: { [key in CourseInstanceStatus]: React.ReactNode } = {
  [CourseInstanceStatus.Canceled]: (
    <CloseOutlined className="text-red-800 pr-1" />
  ),
  [CourseInstanceStatus.Preliminary]: (
    <ClockCircleOutlined className="text-yellow-800 pr-1" />
  ),
  [CourseInstanceStatus.Confirmed]: (
    <CheckOutlined className="text-green-800 pr-1" />
  ),
  [CourseInstanceStatus.Ordered]: (
    <ClockCircleOutlined className="text-blue-800 pr-1" />
  ),
};

const CourseInstanceStatusBadge: React.FC<{
  status?: CourseInstanceStatus;
  endsAt?: dayjs.Dayjs;
}> = ({ status, endsAt }) => {
  const { t } = useTranslation();

  return (
    <>
      {status ? (
        <div
          className={`h-6 px-2 inline-flex text-xs font-semibold rounded-full uppercase ${statusStyle[status]}`}>
          {status === CourseInstanceStatus.Confirmed &&
          endsAt?.isBefore(dayjs()) ? (
            t('components.CourseInstanceStatusBadge.completed')
          ) : (
            <div className="flex items-center">
              {statusIcon[status]} {statusText(t)[status]}{' '}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default CourseInstanceStatusBadge;
