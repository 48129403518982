import { Blocker, History, Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';

const useBlocker = (blocker: Blocker, when: boolean) => {
  const navigator = useContext(UNSAFE_NavigationContext)
    .navigator as unknown as History;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((transition: Transition) => {
      const autoUnblockingTransition = {
        ...transition,
        retry: () => {
          unblock();
          transition.retry();
        },
      };

      blocker(autoUnblockingTransition);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

export const usePrompt = (message: string, when: boolean) => {
  const blocker: Blocker = useCallback(
    (transition: Transition) => {
      if (window.confirm(message)) {
        transition.retry();
      }
    },
    [message],
  );

  useBlocker(blocker, when);
};
