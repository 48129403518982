import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import React from 'react';

import colors from '../../theme/colors';
import { CourseInstanceStatus } from '../types/CourseInstance';

type CourseInstanceResultsProps = {
  courseInstanceStatus: CourseInstanceStatus;
  className?: string;
};

const CourseInstanceStatusIcon: React.FC<CourseInstanceResultsProps> = ({
  courseInstanceStatus,
  className,
}) => {
  return (
    <div className={`flex flex-col ${className ?? ''}`}>
      {courseInstanceStatus === CourseInstanceStatus.Ordered && (
        <ClockCircleTwoTone
          className="text-3xl"
          twoToneColor={colors.blue[500]}
        />
      )}
      {courseInstanceStatus === CourseInstanceStatus.Confirmed && (
        <CheckCircleTwoTone
          className="text-3xl"
          twoToneColor={colors.green[500]}
        />
      )}
      {courseInstanceStatus === CourseInstanceStatus.Canceled && (
        <CloseCircleTwoTone
          className="text-3xl"
          twoToneColor={colors.red[500]}
        />
      )}
      {courseInstanceStatus === CourseInstanceStatus.Preliminary && (
        <ClockCircleTwoTone
          className="text-3xl"
          twoToneColor={colors.orange[500]}
        />
      )}
    </div>
  );
};

export default CourseInstanceStatusIcon;
