import CourseInstanceLimit from '../courseInstance/types/CourseInstanceLimit';
import Course from '../course/types/Course';
import Location, { Geolocation } from '../types/Location';
import SubsidiaryCompany from '../subsidiaryCompany/types/SubsidiaryCompany';
import TimeSlot from '../user/types/TimeSlot';
import User, { InstructorMarkerData, UserRole } from '../user/types/User';
import InstructorCalendarDate from '../user/types/InstructorCalendarDate';
import client from '../api/client';
import { ImageMedia } from '../types/ImageMedia';

export type UserFilterParams = {
  userIds?: User['id'][];
  roles?: UserRole[];
  deleted?: boolean;
};

const getUsers = (params?: UserFilterParams) =>
  client.get<User[]>('/users', {
    params,
  });

const getUser = (id: User['id']) => client.get<User>(`/users/${id}`);

const getProfile = () => client.get<User>('/users/profile');

type CreateUserParams = {
  name: string;
  email: string;
  marketId: number;
  password?: string;
  phoneNumber?: string;
  generatePassword?: boolean;
  preferredSubsidiaryCompanyId?: number;
  preferredLanguageId?: number;
  bookerNote?: string | null;
  roles?: UserRole[];
};

const createUser = (user: CreateUserParams) =>
  client.post<{ user: User; jwt: string }>('/users/register', user);

type UpdateUserParams = {
  email: string;
  name: string;
  marketId: number;
  phoneNumber?: string | null;
  maxTravelDistance?: number | null;
  internal: boolean;
  defaultCourseInstanceLimit: number;
  preferredSubsidiaryCompanyId?: number;
  preferredLanguageId?: number;
  bookerNote?: string | null;
};

const updateUser = (id: User['id'], params: UpdateUserParams) =>
  client.patch<User>(`/users/${id}`, params);

const deleteUser = (id: User['id']) => client.delete(`/users/${id}`);

const restoreUser = (id: User['id']) => client.post(`/users/${id}/restore`);

const setUserCourses = (id: User['id'], courseIds: Course['id'][]) =>
  client.post<User>(`/users/${id}/courses`, {
    courseIds,
  });

const setUserFavouriteCourses = (id: User['id'], courseIds: Course['id'][]) =>
  client.post<User>(`/users/${id}/favourite-courses`, {
    courseIds,
  });

const setUserSubsidiaries = (
  id: User['id'],
  subsidiaryIds: SubsidiaryCompany['id'][],
) =>
  client.post<User>(`/users/${id}/subsidiaries`, {
    subsidiaryIds,
  });

const getUserTimeSlots = (id: User['id']) =>
  client.get<TimeSlot[]>(`/users/${id}/slots`);

const getUserTimeSlot = (userId: User['id'], timeSlotId: TimeSlot['id']) =>
  client.get<TimeSlot>(`/users/${userId}/slots/${timeSlotId}`);

type CreateTimeSlotParams = {
  comment?: string;
  startsAt: string;
  endsAt: string;
};

const createUserTimeSlot = (id: User['id'], params: CreateTimeSlotParams) =>
  client.post<TimeSlot>(`/users/${id}/slots`, params);

type UpdateTimeSlotParams = {
  courseInstanceLimit: number;
  startsAt: string;
  endsAt: string;
};

const updateUserTimeSlot = (
  userId: User['id'],
  timeSlotId: TimeSlot['id'],
  params: UpdateTimeSlotParams,
) => client.patch<TimeSlot>(`/users/${userId}/slots/${timeSlotId}`, params);

const deleteUserTimeSlot = (userId: User['id'], timeSlotId: TimeSlot['id']) =>
  client.delete(`/users/${userId}/slots/${timeSlotId}`);

type CreateLocationParams = {
  address: string;
  placeId: string;
  geolocation: Geolocation;
};

const createUserLocation = (userId: User['id'], params: CreateLocationParams) =>
  client.post<Location>(`/users/${userId}/locations`, params);

const deleteUserLocation = (userId: User['id'], locationId: Location['id']) =>
  client.delete(`/users/${userId}/locations/${locationId}`);

type SetUserRolesParams = {
  roles: UserRole[];
};

const setUserRoles = (userId: User['id'], params: SetUserRolesParams) =>
  client.post(`/users/${userId}/roles`, params);

type SetUserPreferredLanguageParams = {
  preferredLanguageId?: number;
};

const setUserPreferredLanguage = (
  userId: User['id'],
  params: SetUserPreferredLanguageParams,
) => client.patch(`/users/${userId}/preferred-language`, params);

type IcsToken = {
  icsToken: string;
};

const getUserCalendarLink = (userId: User['id']) =>
  client.get<IcsToken>(`/users/${userId}/calendar`);

//Dates in format of YYYY-MM-DD
export type GetCourseInstanceLimitsParams = {
  startDate: string;
  endDate: string;
};

const getUserCourseInstanceLimits = (
  userId: User['id'],
  courseInstanceLimitParams: GetCourseInstanceLimitsParams,
) =>
  client.get<CourseInstanceLimit[]>(`/users/${userId}/course-instance-limits`, {
    params: { ...courseInstanceLimitParams },
  });

const getUserCourseInstanceLimit = (
  userId: User['id'],
  courseInstanceLimitId: CourseInstanceLimit['id'],
) =>
  client.get<CourseInstanceLimit>(
    `/users/${userId}/course-instance-limits/${courseInstanceLimitId}`,
  );

type CreateCourseInstanceLimitParams = {
  date: string;
  endsAt: string;
};

const createUserCourseInstanceLimit = (
  id: User['id'],
  params: CreateCourseInstanceLimitParams,
) =>
  client.post<CourseInstanceLimit>(
    `/users/${id}/course-instance-limits`,
    params,
  );

type UpdateCourseInstanceLimitParams = {
  courseInstanceLimit: number;
};

const updateUserCourseInstanceLimit = (
  userId: User['id'],
  courseInstanceLimitId: CourseInstanceLimit['id'],
  params: UpdateCourseInstanceLimitParams,
) =>
  client.patch<CourseInstanceLimit>(
    `/users/${userId}/slots/${courseInstanceLimitId}`,
    params,
  );

const deleteUserCourseInstanceLimit = (
  userId: User['id'],
  courseInstanceLimitId: TimeSlot['id'],
) => client.delete(`/users/${userId}/slots/${courseInstanceLimitId}`);

export type GetInstructorCalendarParams = {
  startDate: string;
  endDate: string;
};

const getInstructorCalendar = (
  userId: User['id'],
  params: GetInstructorCalendarParams,
) =>
  client.get<InstructorCalendarDate[]>(`/users/${userId}/instructor-calendar`, {
    params: { ...params },
  });

type UpdateDefaultCourseInstanceLimitParams = {
  defaultCourseInstanceLimit: number;
};

const updateDefaultCourseInstanceLimit = (
  id: User['id'],
  params: UpdateDefaultCourseInstanceLimitParams,
) => client.patch<User>(`/users/${id}/default-course-instance-limit`, params);

type GetInstructorMarkerDataParams = {
  southWestLat: number;
  southWestLng: number;
  northEastLat: number;
  northEastLng: number;
};

const getInstructorMarkerData = (params?: GetInstructorMarkerDataParams) =>
  client.get<InstructorMarkerData[]>('/users/instructor-markers', {
    params,
  });

type UpdatePreferredLanguageParams = {
  preferredLanguageId: number | null;
};

const updatePreferredLanguage = (
  id: User['id'],
  params: UpdatePreferredLanguageParams,
) => client.patch<User>(`/users/${id}/preferred-language`, params);

const getUserFavoriteInstructors = (id: User['id']) =>
  client.get<User>(`/users/${id}/favorite-instructors`);

type SetUserFavoriteInstructorParams = {
  instructorIds: User['id'][];
};

const setUserFavoriteInstructor = (
  id: User['id'],
  params: SetUserFavoriteInstructorParams,
) => client.put<User>(`/users/${id}/favorite-instructors`, params);

const putUserCertificateSignature = (id: number, data: FormData) =>
  client.put<ImageMedia>(`/users/${id}/certificate-signature`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const deleteUserCertificateSignature = (id: number) =>
  client.delete(`/users/${id}/certificate-signature`);

export default {
  getUsers,
  getUser,
  getProfile,
  createUser,
  updateUser,
  deleteUser,
  restoreUser,
  setUserCourses,
  setUserFavouriteCourses,
  setUserSubsidiaries,
  getUserTimeSlots,
  getUserTimeSlot,
  createUserTimeSlot,
  updateUserTimeSlot,
  deleteUserTimeSlot,
  setUserRoles,
  createUserLocation,
  deleteUserLocation,
  setUserFavoriteInstructor,
  setUserPreferredLanguage,
  getUserCalendarLink,
  getUserCourseInstanceLimit,
  getUserCourseInstanceLimits,
  createUserCourseInstanceLimit,
  updateUserCourseInstanceLimit,
  deleteUserCourseInstanceLimit,
  getInstructorCalendar,
  updateDefaultCourseInstanceLimit,
  getInstructorMarkerData,
  updatePreferredLanguage,
  getUserFavoriteInstructors,
  putUserCertificateSignature,
  deleteUserCertificateSignature,
};
