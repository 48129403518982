import React from 'react';
import { Form, Input, Button, Spin, Layout } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { useTranslation } from 'react-i18next';

type CourseResourceEditorParams = {
  loading?: boolean;
  onRemove?: (field: FormListFieldData) => void;
};

const CourseResourceEditor: React.FC<CourseResourceEditorParams> = ({
  loading,
  onRemove,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {loading ? (
        <Layout.Content className="flex justify-center items-center py-5">
          <Spin size="large" />
        </Layout.Content>
      ) : (
        <Form.List name="courseResources">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div
                  key={field.key}
                  className="pb-4 mb-4 flex items-center border-gray-300 border-0 border-b-2 border-dashed">
                  <div className="flex flex-col w-full pr-4" key={field.key}>
                    <Form.Item
                      labelCol={{ span: 4 }}
                      label={t('components.CourseResourceEditor.title')}
                      name={[field.name, 'title']}
                      rules={[
                        {
                          required: true,
                          message: t(
                            'components.CourseResourceEditor.enterTitle',
                          ),
                        },
                      ]}
                      className="mb-2">
                      <Input className="flex flex-grow" />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 4 }}
                      label={t('components.CourseResourceEditor.link')}
                      name={[field.name, 'url']}
                      rules={[
                        {
                          required: true,
                          message: t('components.CourseResourceEditor.badLink'),
                          type: 'url',
                        },
                      ]}
                      className="mb-0">
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="bg-red-600 rounded-full h-10 w-10 flex justify-center items-center flex-shrink-0">
                    <DeleteOutlined
                      className="text-lg text-white"
                      onClick={() => {
                        onRemove?.(field);
                        remove(field.name);
                      }}
                    />
                  </div>
                </div>
              ))}
              <Form.Item className="mb-0">
                <Button
                  className="mt-3"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}>
                  {t('components.CourseResourceEditor.addCourseInformation')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
    </>
  );
};

export default CourseResourceEditor;
