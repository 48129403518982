import React, { useState } from 'react';
import { Button } from 'antd';
import { CloseSquareOutlined, FormOutlined } from '@ant-design/icons';
import { twMerge } from 'tailwind-merge';

interface CertificateSettingSectionProps {
  title: string;
  displayValue: React.ReactNode;
  children: React.ReactNode;
}

const CertificateSettingSection: React.FC<CertificateSettingSectionProps> = ({
  title,
  displayValue,
  children,
}: CertificateSettingSectionProps) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="py-4 flex justify-between items-center border-solid border-0 border-b-2 border-gray-200">
      <div className="grow pr-4">
        <div className="text-sm font-semibold mb-2">{title}</div>
        <div className={twMerge('w-full', editMode ? 'hidden' : '')}>
          {displayValue}
        </div>
        <div className={twMerge('w-full', editMode ? '' : 'hidden')}>
          {children}
        </div>
      </div>
      <Button
        icon={editMode ? <CloseSquareOutlined /> : <FormOutlined />}
        onClick={() => setEditMode(!editMode)}
        type="text"
      />
    </div>
  );
};

export default CertificateSettingSection;
