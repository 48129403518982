import {
  ArrowLeftOutlined,
  DollarCircleOutlined,
  EllipsisOutlined,
  FileTextFilled,
  MailFilled,
  MessageOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { Button, message, Modal, Popconfirm, Spin, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ContentCard from '../../components/ContentCard';
import CourseInstanceAPI from '../../courseInstance/CourseInstanceAPI';
import CourseInstanceCard from '../../courseInstance/components/CourseInstanceCard';
import EmailTemplateModal from '../../courseInstance/components/EmailTemplateModal';
import { DisplayedCourseInstance } from '../../courseInstance/types/CourseInstance';
import SearchModal from '../../search/component/SearchModal';
import UserAPI from '../../user/UserAPI';
import User, { UserRole } from '../../user/types/User';
import { useUser } from '../../user/userContext';
import { usePrompt } from '../../utils/usePrompt';
import BookingOrderAPI, {
  BookingOrderCreateCourseInstanceParams,
} from '../api/BookingOrderAPI';
import ClientCompanyAPI from '../api/ClientCompanyAPI';
import ContactPersonAPI from '../api/ContactPersonAPI';
import InvoiceRecipientAPI from '../api/InvoiceRecipientAPI';
import BookingOrderCommentModal from '../components/BookingOrderCommentModal';
import ClientCompanyField from '../components/ClientCompanyField';
import InvoicePreviewModal from '../components/InvoicePreviewModal';
import InvoiceRecipientField from '../components/InvoiceRecipientField';
import PurchaserField from '../components/PurchaserField';
import ResponsibleBookerField from '../components/ResponsibleBookerField';
import BookingOrder, { BookingOrderStatus } from '../types/BookingOrder';
import ClientCompany from '../types/ClientCompany';
import ContactPerson from '../types/ContactPerson';
import InvoiceRecipient from '../types/InvoiceRecipient';
import useDebounce from '../../utils/useDebounce';
import { AxiosError, isAxiosError } from 'axios';
import BookingOrderNotFound from './BookingOrderNotFound';
import { useFeatureToggles } from '../../context/featureToggles';
import { shouldShowClientCompanyElsaMappingInformation } from '../utils/bookingOrder';

type BookingOrderDetailsProps = {
  isNewOrder?: boolean;
};

const BookingOrderDetails: React.FC<BookingOrderDetailsProps> = ({
  isNewOrder = false,
}) => {
  const { bookingOrderId } = useParams();
  const id = useMemo(() => Number(bookingOrderId), [bookingOrderId]);
  const [currentUser] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingBookingOrder, setLoadingBookingOrder] =
    useState<boolean>(false);
  const [selectedClientCompany, setSelectedClientCompany] =
    useState<ClientCompany>();
  const [selectedContactPerson, setSelectedContactPerson] =
    useState<ContactPerson>();
  const [selectedInvoiceRecipient, setSelectedInvoiceRecipient] =
    useState<InvoiceRecipient>();
  const [selectedResponsibleBooker, setSelectedResponsibleBooker] =
    useState<User>();

  const [showCourseInstanceModal, setShowCourseInstanceModal] =
    useState<boolean>(false);
  const [showInvoicePreviewModal, setShowInvoicePreviewModal] =
    useState<boolean>(false);
  const [clientCompanies, setClientCompanies] = useState<ClientCompany[]>();
  const [loadingClientCompanies, setLoadingClientCompanies] = useState(false);
  const [contactPersons, setContactPersons] = useState<ContactPerson[]>();
  const [loadingContactPersons, setLoadingContactPersons] = useState(false);
  const [loadingBookers, setLoadingBookers] = useState(false);
  const [bookers, setBookers] = useState<User[]>();
  const [invoiceRecipients, setInvoiceRecipients] =
    useState<InvoiceRecipient[]>();
  const [loadingInvoiceRecipients, setLoadingInvoiceRecipients] =
    useState(false);
  const [bookingOrder, setBookingOrder] = useState<BookingOrder>();
  const [orderEdited, setOrderEdited] = useState<boolean>(false);
  const [displayedCourseInstances, setDisplayedCourseInstances] =
    useState<DisplayedCourseInstance[]>();
  const [submittingBookingOrder, setSubmittingBookingOrder] =
    useState<boolean>(false);
  const [submittingCourseInstance, setSubmittingCourseInstance] =
    useState<boolean>(false);
  const [syncingCourseInstanceToElsa, setSyncingCourseInstanceToElsa] =
    useState<boolean>(false);
  const [saveIsDisabled, setSaveIsDisabled] = useState<boolean>(false);
  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
  const [comments, setComments] = useState<BookingOrder['comments']>();
  const [bookingOrderStatus, setBookingOrderStatus] =
    useState<BookingOrderStatus>();
  const [submittingComment, setSubmittingComment] = useState<boolean>(false);
  const [emailModalCourseInstance, setEmailModalCourseInstance] =
    useState<DisplayedCourseInstance | null>(null);
  const [invoiceReference, setInvoiceReference] = useState<string>('');
  const [deletePurchaserOnSave, setDeletePurchaserOnSave] = useState<number>();
  const [deleteClientCompanyOnSave, setDeleteClientCompanyOnSave] =
    useState<number>();
  const [deleteInvoiceRecipientOnSave, setDeleteInvoiceRecipientOnSave] =
    useState<number>();
  const [bookingOrderNotFound, setBookingOrderNotFound] = useState(false);
  const { isElsaIntegrationEnabled } = useFeatureToggles();

  const bookingOrderSubsidiaryCompany = useMemo(() => {
    if (loadingBookingOrder) {
      return undefined;
    }
    return (
      bookingOrder?.subsidiaryCompany ?? currentUser?.preferredSubsidiaryCompany
    );
  }, [currentUser, loadingBookingOrder, bookingOrder]);

  const showElsaMappingInformation = useMemo(() => {
    return shouldShowClientCompanyElsaMappingInformation(
      currentUser,
      isElsaIntegrationEnabled,
      bookingOrder,
    );
  }, [isElsaIntegrationEnabled, bookingOrder, currentUser]);

  const bookingHasElsaMappedCourseInstance = useMemo(
    () => !!displayedCourseInstances?.some((ci) => !!ci.elsaTrainingSessionId),
    [displayedCourseInstances],
  );

  const { t } = useTranslation();
  usePrompt(
    t('views.BookingOrderDetails.unsavedChanges'),
    orderEdited && !(isNewOrder && bookingOrder),
  );

  const {
    debounce: invoiceRecipientDebounce,
    loading: loadingInvoiceRecipientDebounce,
  } = useDebounce(
    (value: string) => {
      if (!currentUser) return;

      return InvoiceRecipientAPI.getInvoiceRecipients({
        name: value || undefined,
        subsidiaryCompanyId: currentUser.preferredSubsidiaryCompany?.id,
      });
    },
    (res) => setInvoiceRecipients(res.data.data),
    {
      beforeDebounce: () => setInvoiceRecipients([]),
    },
  );

  const fetchClientCompanies = useCallback(async () => {
    if (!bookingOrderSubsidiaryCompany) {
      return;
    }
    setLoadingClientCompanies(true);
    try {
      const { data } = await ClientCompanyAPI.getClientCompanies({
        subsidiaryCompanyIds: [bookingOrderSubsidiaryCompany.id],
      });
      setClientCompanies(data);
    } finally {
      setLoadingClientCompanies(false);
    }
  }, [bookingOrderSubsidiaryCompany]);

  const fetchContactPersons = useCallback(async () => {
    setLoadingContactPersons(true);
    try {
      const { data } = await ContactPersonAPI.getContactPersons();
      setContactPersons(data);
    } finally {
      setLoadingContactPersons(false);
    }
  }, []);

  const fetchBookers = useCallback(async () => {
    setLoadingBookers(true);
    try {
      const { data } = await UserAPI.getUsers({
        roles: [UserRole.Admin, UserRole.Booker],
      });
      setBookers(data);
    } finally {
      setLoadingBookers(false);
    }
  }, []);

  const fetchBookingOrder = useCallback(async (id: number) => {
    setLoadingBookingOrder(true);
    try {
      const { data } = await BookingOrderAPI.getBookingOrder(id);
      setBookingOrder(data);
      setSelectedClientCompany(data.clientCompany);
      setSelectedContactPerson(data.contactPerson);
      setSelectedInvoiceRecipient(data.invoiceRecipient);
      setSelectedResponsibleBooker(data.responsibleBooker);

      setDisplayedCourseInstances(
        data.courseInstances?.sort(
          (a, b) => a.id - b.id,
        ) as DisplayedCourseInstance[],
      );
    } catch (err) {
      if ((err as AxiosError).response?.status !== 404) {
        throw err;
      }
      setBookingOrderNotFound(true);
    } finally {
      setLoadingBookingOrder(false);
      setOrderEdited(false);
    }
  }, []);

  const fetchInvoiceRecipients = useCallback(async () => {
    if (!currentUser) {
      return;
    }
    setLoadingInvoiceRecipients(true);
    try {
      const { data } = await InvoiceRecipientAPI.getInvoiceRecipients({
        name: undefined,
        subsidiaryCompanyId: currentUser.preferredSubsidiaryCompany?.id,
      });

      setInvoiceRecipients(data.data);
    } finally {
      setLoadingInvoiceRecipients(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (id) {
      fetchBookingOrder(id);
      // The current user is the default responsible booker for new booking orders
    } else if (!!currentUser) {
      setSelectedResponsibleBooker(currentUser);
    }
    fetchInvoiceRecipients();
    fetchContactPersons();
    fetchBookers();
  }, [
    currentUser,
    fetchBookers,
    fetchBookingOrder,
    fetchContactPersons,
    fetchInvoiceRecipients,
    id,
  ]);

  const getBookingOrderStatus = useCallback(async (bookingOrderId: number) => {
    setSubmittingBookingOrder(true);
    try {
      const { data } = await BookingOrderAPI.getBookingOrder(bookingOrderId);
      setBookingOrderStatus(data.status);
    } catch (err) {
      if ((err as AxiosError).response?.status !== 404) {
        throw err;
      }
      setBookingOrderNotFound(true);
    } finally {
      setSubmittingBookingOrder(false);
    }
  }, []);

  useEffect(() => {
    // Editing an already linked entity should not impact the order edited state
    if (
      selectedClientCompany?.id === bookingOrder?.clientCompany.id &&
      selectedContactPerson?.id === bookingOrder?.contactPerson?.id &&
      selectedInvoiceRecipient?.id === bookingOrder?.invoiceRecipient?.id &&
      selectedResponsibleBooker?.id === bookingOrder?.responsibleBooker?.id
    ) {
      return;
    }

    setOrderEdited(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedClientCompany,
    selectedContactPerson,
    selectedInvoiceRecipient,
    selectedResponsibleBooker,
    invoiceReference,
  ]);

  useEffect(() => {
    setSaveIsDisabled(
      !selectedContactPerson ||
        !selectedClientCompany ||
        !orderEdited ||
        submittingBookingOrder,
    );
  }, [
    selectedContactPerson,
    selectedClientCompany,
    orderEdited,
    submittingBookingOrder,
  ]);

  useEffect(() => {
    if (!bookingOrder) return;

    if (isNewOrder) navigate(`/bestallningar/${bookingOrder.id}`);

    setComments(bookingOrder.comments);
    setBookingOrderStatus(bookingOrder.status);
    setInvoiceReference(bookingOrder.invoiceReference || '');
  }, [bookingOrder, isNewOrder, navigate]);

  useEffect(() => {
    if (bookingOrder) {
      getBookingOrderStatus(bookingOrder.id);
    }
  }, [bookingOrder, displayedCourseInstances, getBookingOrderStatus]);

  useEffect(() => {
    fetchClientCompanies();
  }, [fetchClientCompanies, bookingOrderSubsidiaryCompany]);

  const updateBookingOrder = useCallback(
    async (
      clientCompanyId: number,
      subsidiaryCompanyId: number,
      contactPersonId?: number,
      invoiceRecipientId?: number,
      responsibleBookerId?: number,
      invoiceReference?: string,
    ) => {
      if (!bookingOrder) return;
      setSubmittingBookingOrder(true);
      try {
        if (
          deletePurchaserOnSave &&
          contactPersonId &&
          contactPersonId !== deletePurchaserOnSave
        )
          await ContactPersonAPI.deleteBookingOrderContactPerson(
            deletePurchaserOnSave,
            bookingOrder.id,
            contactPersonId,
          );
        setDeletePurchaserOnSave(undefined);
        if (
          deleteClientCompanyOnSave &&
          clientCompanyId !== deleteClientCompanyOnSave
        )
          await ClientCompanyAPI.deleteBookingOrderClientCompany(
            deleteClientCompanyOnSave,
            bookingOrder.id,
            clientCompanyId,
          );
        setDeleteClientCompanyOnSave(undefined);
        if (
          deleteInvoiceRecipientOnSave &&
          invoiceRecipientId !== deleteInvoiceRecipientOnSave
        )
          await InvoiceRecipientAPI.deleteBookingOrderInvoiceRecipient(
            deleteInvoiceRecipientOnSave,
            bookingOrder.id,
            invoiceRecipientId ?? null,
          );
        setDeleteInvoiceRecipientOnSave(undefined);
        const { data } = await BookingOrderAPI.updateBookingOrder(
          bookingOrder.id,
          {
            clientCompanyId: clientCompanyId ?? bookingOrder.clientCompany?.id,
            contactPersonId: contactPersonId ?? bookingOrder.contactPerson?.id,
            invoiceRecipientId: invoiceRecipientId,
            subsidiaryCompanyId:
              subsidiaryCompanyId ?? bookingOrder.subsidiaryCompany.id,
            responsibleBookerId: responsibleBookerId,
            invoiceReference: invoiceReference ?? bookingOrder.invoiceReference,
          },
        );
        setBookingOrder(data);
        setOrderEdited(false);
      } finally {
        setSubmittingBookingOrder(false);
      }
    },
    [
      bookingOrder,
      deleteClientCompanyOnSave,
      deleteInvoiceRecipientOnSave,
      deletePurchaserOnSave,
    ],
  );

  const updateInvoiceReference = useCallback(
    (newInvoiceReference: string) => {
      setInvoiceReference(newInvoiceReference);
      if (selectedClientCompany && bookingOrder) {
        updateBookingOrder(
          selectedClientCompany.id,
          bookingOrder.subsidiaryCompany.id,
          selectedContactPerson?.id,
          selectedInvoiceRecipient?.id,
          selectedResponsibleBooker?.id || undefined,
          newInvoiceReference,
        );
      }
    },
    [
      bookingOrder,
      selectedClientCompany,
      selectedContactPerson?.id,
      selectedInvoiceRecipient?.id,
      selectedResponsibleBooker?.id,
      updateBookingOrder,
    ],
  );

  const onInvoiceCreated = useCallback((bookingOrder: BookingOrder) => {
    setBookingOrder(bookingOrder);
    setSelectedInvoiceRecipient(bookingOrder.invoiceRecipient);
  }, []);

  const onCourseInstanceDelete = useCallback(
    async (
      index: number,
      deletedDisplayedCourseInstance: DisplayedCourseInstance,
    ) => {
      if (!!deletedDisplayedCourseInstance.id) {
        setSubmittingCourseInstance(false);
        try {
          await CourseInstanceAPI.deleteCourseInstance(
            deletedDisplayedCourseInstance.id,
          );
        } finally {
          setSubmittingCourseInstance(false);
        }
      }
      setDisplayedCourseInstances((prevState) => {
        if (!prevState) {
          return;
        }

        prevState?.splice(index, 1);
        return [...prevState];
      });
    },
    [],
  );

  const addDisplayedCourseInstance = useCallback(
    (displayedCourseInstance: DisplayedCourseInstance) => {
      setDisplayedCourseInstances((prevState) => {
        if (prevState == undefined) {
          return [displayedCourseInstance];
        }
        return [...prevState, displayedCourseInstance];
      });
    },
    [],
  );

  const createCourseInstance = useCallback(
    async (
      bookingOrderId: number,
      displayedCourseInstance: DisplayedCourseInstance,
    ) => {
      setSubmittingBookingOrder(true);
      try {
        const { data } = await CourseInstanceAPI.createCourseInstance({
          courseId: displayedCourseInstance.course.id,
          orderId: bookingOrderId,
          startsAt: displayedCourseInstance.startsAt,
          endsAt: displayedCourseInstance.endsAt,
          contactPersonId: displayedCourseInstance.contactPerson?.id,
          instructorId: displayedCourseInstance.instructor?.id,
          price: displayedCourseInstance.price,
          currencyId: displayedCourseInstance.currency.id,
          address: displayedCourseInstance.address,
          placeId: displayedCourseInstance.placeId,
          lat: displayedCourseInstance.lat,
          lng: displayedCourseInstance.lng,
          comment: displayedCourseInstance.comment,
          equipment: displayedCourseInstance.equipment,
          mileageQuantity: displayedCourseInstance.mileageQuantity,
          mileagePrice: displayedCourseInstance.mileagePrice,
          participantLimit: displayedCourseInstance.participantLimit,
          managerEmails: displayedCourseInstance.managerEmails,
        });
        addDisplayedCourseInstance(data);
      } catch (err) {
        if (isAxiosError(err) && err.response?.data.message) {
          message.error(err.response?.data.message);
        }
      } finally {
        setSubmittingBookingOrder(false);
      }
    },
    [addDisplayedCourseInstance],
  );

  const onCreateDisplayedCourseInstance = useCallback(
    async (displayedCourseInstance: DisplayedCourseInstance) => {
      if (bookingOrder?.id) {
        createCourseInstance(bookingOrder.id, displayedCourseInstance);
      } else {
        addDisplayedCourseInstance(displayedCourseInstance);
      }
      setShowCourseInstanceModal(false);
    },
    [addDisplayedCourseInstance, bookingOrder?.id, createCourseInstance],
  );

  const getCreateCourseInstanceParams = useCallback(
    (displayedCourseInstances?: DisplayedCourseInstance[]) => {
      return displayedCourseInstances
        ?.filter((displayedCourseInstance) => !displayedCourseInstance.id)
        .map(
          (displayedCourseInstance) =>
            ({
              courseId: displayedCourseInstance.course.id,
              startsAt: displayedCourseInstance.startsAt,
              endsAt: displayedCourseInstance.endsAt,
              contactPersonId: displayedCourseInstance.contactPerson?.id,
              instructorId: displayedCourseInstance.instructor?.id,
              price: displayedCourseInstance.price,
              currencyId: displayedCourseInstance.currency.id,
              address: displayedCourseInstance.address,
              placeId: displayedCourseInstance.placeId,
              lat: displayedCourseInstance.lat,
              lng: displayedCourseInstance.lng,
              comment: displayedCourseInstance.comment,
              equipment: displayedCourseInstance.equipment,
              mileageQuantity: displayedCourseInstance.mileageQuantity,
              mileagePrice: displayedCourseInstance.mileagePrice,
              participantLimit: displayedCourseInstance.participantLimit,
            }) as BookingOrderCreateCourseInstanceParams,
        );
    },
    [],
  );

  const createBookingOrder = useCallback(
    async (
      clientCompanyId: number,
      subsidiaryCompanyId: number,
      contactPersonId?: number,
      invoiceRecipientId?: number,
      responsibleBookerId?: number,
    ) => {
      setSubmittingBookingOrder(true);

      try {
        const createCourseInstanceParams = getCreateCourseInstanceParams(
          displayedCourseInstances,
        );

        const { data } = await BookingOrderAPI.createBookingOrder({
          clientCompanyId: clientCompanyId,
          contactPersonId: contactPersonId,
          invoiceRecipientId: invoiceRecipientId,
          subsidiaryCompanyId: subsidiaryCompanyId,
          responsibleBookerId: responsibleBookerId,
          createCourseInstanceParams: createCourseInstanceParams,
          invoiceReference: invoiceReference,
        });
        setBookingOrder(data);
        setDisplayedCourseInstances(data.courseInstances);
        setOrderEdited(false);
      } finally {
        setSubmittingBookingOrder(false);
      }
    },
    [displayedCourseInstances, getCreateCourseInstanceParams, invoiceReference],
  );

  const deleteBookingOrder = useCallback(async () => {
    if (!bookingOrder) return;
    setSubmittingBookingOrder(true);
    try {
      await BookingOrderAPI.deleteBookingOrder(bookingOrder.id);
    } finally {
      setSubmittingBookingOrder(false);
      setOrderEdited(false);
      navigate('/bestallningar');
    }
  }, [bookingOrder, navigate]);

  const updateDisplayedCourseInstances = useCallback(
    (index: number, displayedCourseInstance: DisplayedCourseInstance) => {
      setDisplayedCourseInstances((prevState) => {
        const newState = [...prevState!];
        newState[index] = displayedCourseInstance;
        return newState;
      });
    },
    [],
  );

  const updateCourseInstance = useCallback(
    async (index: number, displayedCourseInstance: DisplayedCourseInstance) => {
      if (bookingOrder) {
        if (!displayedCourseInstance.id) return;
        setSubmittingCourseInstance(true);
        try {
          const { data } = await CourseInstanceAPI.updateCourseInstance(
            displayedCourseInstance.id,
            {
              courseId: displayedCourseInstance.course.id,
              startsAt: displayedCourseInstance.startsAt,
              endsAt: displayedCourseInstance.endsAt,
              contactPersonId: displayedCourseInstance.contactPerson?.id,
              instructorId: displayedCourseInstance.instructor?.id,
              price: displayedCourseInstance.price,
              currencyId: displayedCourseInstance.currency.id,
              address: displayedCourseInstance.address,
              placeId: displayedCourseInstance.placeId,
              lat: displayedCourseInstance.lat,
              lng: displayedCourseInstance.lng,
              comment: displayedCourseInstance.comment,
              equipment: displayedCourseInstance.equipment,
              mileagePrice: displayedCourseInstance.mileagePrice,
              mileageQuantity: displayedCourseInstance.mileageQuantity,
              participantLimit: displayedCourseInstance.participantLimit,
              managerEmails: displayedCourseInstance.managerEmails,
              ...(displayedCourseInstance.canceled != undefined
                ? { canceled: displayedCourseInstance.canceled }
                : {}),
              ...(displayedCourseInstance.confirmed != undefined
                ? { confirmed: displayedCourseInstance.confirmed }
                : {}),
            },
          );
          updateDisplayedCourseInstances(index, data);
        } catch (err) {
          if (isAxiosError(err) && err.response?.data.message) {
            message.error(err.response?.data.message);
          }
        } finally {
          setSubmittingCourseInstance(false);
        }
      } else {
        updateDisplayedCourseInstances(index, displayedCourseInstance);
      }
    },
    [bookingOrder, updateDisplayedCourseInstances],
  );

  const submitBookingOrderComment = useCallback(
    async (text: string) => {
      if (bookingOrder && text) {
        setSubmittingComment(true);
        try {
          const { data } = await BookingOrderAPI.createBookingOrderComment(
            bookingOrder.id,
            text,
          );
          setComments([...(comments || []), data]);
        } finally {
          setSubmittingComment(false);
        }
      }
    },
    [bookingOrder, comments],
  );

  const onSyncCourseInstanceToElsa = useCallback(
    async (index: number, displayedCourseInstance: DisplayedCourseInstance) => {
      if (!displayedCourseInstance.id) return;
      setSyncingCourseInstanceToElsa(true);
      try {
        const { data } = await CourseInstanceAPI.sendCourseInstanceToElsa(
          displayedCourseInstance.id!,
        );
        updateDisplayedCourseInstances(index, data);
      } catch (err) {
        if (isAxiosError(err) && err.response?.data.message) {
          message.error(err.response?.data.message);
        }
      } finally {
        setSyncingCourseInstanceToElsa(false);
      }
    },
    [updateDisplayedCourseInstances],
  );

  const onClientCompanyMappedToElsa = useCallback(
    (clientCompany: ClientCompany) => {
      setSelectedClientCompany(clientCompany);
      setBookingOrder(
        (prevBookingOrder) =>
          prevBookingOrder && {
            ...prevBookingOrder,
            clientCompany: clientCompany,
          },
      );
    },
    [],
  );

  const onDeleteComment = useCallback(
    (commentId: number) => {
      BookingOrderAPI.deleteBookingOrderComment(commentId);
      setComments(comments?.filter((comment) => comment.id != commentId) || []);
    },
    [comments],
  );

  const onUpdateComment = useCallback(
    (commentId: number, text: string) => {
      BookingOrderAPI.updateBookingOrderComment(commentId, text);
      setComments(
        comments?.map((comment) => {
          if (comment.id == commentId) {
            comment.text = text;
          }
          return comment;
        }) || [],
      );
    },
    [comments],
  );

  const setIsInvoiced = useCallback(async () => {
    if (bookingOrder) {
      setSubmittingBookingOrder(true);
      try {
        const isInvoiced =
          bookingOrder.invoicedAt !== undefined &&
          bookingOrder.invoicedAt !== null;

        const { data } = await BookingOrderAPI.setIsInvoiced(bookingOrder.id, {
          isInvoiced: !isInvoiced,
        });

        setBookingOrder(data);
        setOrderEdited(false);
      } finally {
        setSubmittingBookingOrder(false);
      }
    }
  }, [bookingOrder]);

  const openInvoiceModal = useCallback(() => {
    const ModalContent: React.FC = () => (
      <div>
        <div>
          {bookingOrder?.invoicedAt
            ? t('views.BookingOrderDetails.unmarkAsInvoicedConfirmation')
            : t('views.BookingOrderDetails.markAsInvoicedConfirmation')}
        </div>
        {bookingOrder?.invoicedAt && bookingOrder?.vismaSalesOrderId && (
          <div className="mt-2">
            <span className="font-bold">{`${t('common.obs')}: `}</span>
            <span>
              {t('views.BookingOrderDetails.vismaOrderCreatedWarning', {
                vismaId: bookingOrder.vismaSalesOrderId,
              })}
            </span>
          </div>
        )}
      </div>
    );

    Modal.confirm({
      centered: true,
      title: bookingOrder?.invoicedAt
        ? t('views.BookingOrderDetails.unmarkAsInvoiced')
        : t('views.BookingOrderDetails.markAsInvoiced'),
      content: <ModalContent />,
      onOk: setIsInvoiced,
      okText: bookingOrder?.invoicedAt
        ? t('views.BookingOrderDetails.unmarkAsInvoicedOkText')
        : t('views.BookingOrderDetails.markAsInvoicedOkText'),
      closable: true,
      cancelText: t('common.cancel'),
    });
  }, [
    bookingOrder?.invoicedAt,
    bookingOrder?.vismaSalesOrderId,
    setIsInvoiced,
    t,
  ]);

  return (
    <>
      <EmailTemplateModal
        visible={!!emailModalCourseInstance}
        courseInstance={emailModalCourseInstance}
        bookingOrder={bookingOrder}
        onCancel={() => setEmailModalCourseInstance(null)}
      />
      <BookingOrderCommentModal
        setShowModal={setShowCommentModal}
        showModal={showCommentModal}
        comments={comments}
        submitComment={submitBookingOrderComment}
        onDeleteComment={onDeleteComment}
        onUpdateComment={onUpdateComment}
        submittingComment={submittingComment}
      />
      <div className="sm:flex sm:flex-row items-center pb-2">
        <div
          onClick={() => {
            const locationState = location.state as {
              prevPage?: string;
            };

            if (
              !locationState ||
              (locationState.prevPage &&
                locationState.prevPage === '/ny-bestallning')
            ) {
              navigate('/bestallningar');
            } else {
              navigate(-1);
            }
          }}
          className="hover:underline cursor-pointer text-blueGrayDark">
          <ArrowLeftOutlined style={{ fontSize: 11 }} />
          <span className="pl-1 pt-0.5 text-xs">{t('common.goBack')}</span>
        </div>
      </div>
      {!loadingBookingOrder && !bookingOrderNotFound && (
        <div className="flex flex-row justify-between">
          <h2>
            {bookingOrder
              ? t('views.BookingOrderDetails.editOrder')
              : t('views.BookingOrderDetails.createOrder')}
          </h2>
          <div className="flex flex-row items-center gap-3">
            {orderEdited && !submittingBookingOrder && (
              <div>{t('views.BookingOrderDetails.unsavedDraft')}</div>
            )}
            {submittingBookingOrder && <Spin />}
            <Tooltip
              title={
                orderEdited || !bookingOrder
                  ? t('views.BookingOrderDetails.saveToInvoice')
                  : bookingOrder.vismaSalesOrderId !== null
                    ? t('views.BookingOrderDetails.orderAlreadyCreated', {
                        vismaId: `${bookingOrder.vismaSalesOrderId}`,
                      })
                    : bookingOrderStatus !== BookingOrderStatus.Confirmed &&
                        bookingOrderStatus !== BookingOrderStatus.Canceled
                      ? t(
                          'views.BookingOrderDetails.onlyCorrectStatusCanBeInvoiced',
                        )
                      : ''
              }>
              <Button
                disabled={
                  bookingOrder?.vismaSalesOrderId !== null ||
                  (bookingOrderStatus !== BookingOrderStatus.Confirmed &&
                    bookingOrderStatus !== BookingOrderStatus.Canceled) ||
                  orderEdited
                }
                onClick={() => setShowInvoicePreviewModal(true)}>
                {t('views.BookingOrderDetails.invoice')}
              </Button>
            </Tooltip>

            {bookingOrder &&
              displayedCourseInstances &&
              showInvoicePreviewModal && (
                <InvoicePreviewModal
                  bookingOrder={bookingOrder}
                  selectedInvoiceRecipient={selectedInvoiceRecipient}
                  selectedContactPerson={selectedContactPerson}
                  displayedCourseInstances={displayedCourseInstances}
                  onCancel={() => setShowInvoicePreviewModal(false)}
                  onInvoiceCreated={onInvoiceCreated}
                />
              )}

            <Tooltip
              title={
                bookingOrder &&
                displayedCourseInstances &&
                displayedCourseInstances?.length > 0
                  ? t('views.BookingOrderDetails.cannotDelete')
                  : ''
              }>
              <Popconfirm
                key="delete"
                title={
                  <div className="flex">
                    <p> {t('views.BookingOrderDetails.confirmDelete')}</p>
                  </div>
                }
                okText={t('common.yes')}
                cancelText={t('common.no')}
                onConfirm={() => deleteBookingOrder()}
                disabled={
                  !bookingOrder ||
                  (displayedCourseInstances &&
                    displayedCourseInstances?.length > 0)
                }
                placement="bottom">
                <Button
                  disabled={
                    !bookingOrder ||
                    (displayedCourseInstances &&
                      displayedCourseInstances?.length > 0)
                  }>
                  {t('common.delete')}
                </Button>
              </Popconfirm>
            </Tooltip>

            <Tooltip
              title={
                selectedClientCompany && selectedContactPerson
                  ? ''
                  : t('views.BookingOrderDetails.pickCompanyAndPurchaser')
              }>
              <Button
                className={`${
                  saveIsDisabled
                    ? 'text-gray-500 bg-gray-300'
                    : 'text-white bg-safeLifeSuccess '
                }`}
                disabled={saveIsDisabled}
                onClick={() => {
                  if (
                    !selectedClientCompany ||
                    !selectedContactPerson ||
                    !currentUser?.preferredSubsidiaryCompany
                  )
                    return;
                  if (!bookingOrder) {
                    createBookingOrder(
                      selectedClientCompany.id,
                      currentUser.preferredSubsidiaryCompany.id,
                      selectedContactPerson?.id,
                      selectedInvoiceRecipient?.id,
                      selectedResponsibleBooker?.id || undefined,
                    );
                  } else {
                    updateBookingOrder(
                      selectedClientCompany.id,
                      bookingOrder.subsidiaryCompany.id,
                      selectedContactPerson?.id,
                      selectedInvoiceRecipient?.id,
                      selectedResponsibleBooker?.id || undefined,
                      invoiceReference,
                    );
                  }
                }}>
                {t('common.save')}
              </Button>
            </Tooltip>
          </div>
        </div>
      )}
      <ContentCard className="mt-1">
        {loadingBookingOrder ? (
          <Spin
            size="large"
            className="flex justify-center items-center h-40"
          />
        ) : !bookingOrderNotFound ? (
          <div>
            <div className="flex justify-between pb-4 border-solid border-gray-200 border-0 border-b-2">
              <div>
                <ClientCompanyField
                  bookingOrderId={bookingOrder?.id}
                  selectedClientCompany={selectedClientCompany}
                  selectedSubsidiaryCompany={bookingOrderSubsidiaryCompany}
                  clientCompanies={clientCompanies}
                  onClientCompanyMappedToElsa={onClientCompanyMappedToElsa}
                  setSelectedClientCompany={setSelectedClientCompany}
                  fetchClientCompanies={fetchClientCompanies}
                  bookingOrderStatus={bookingOrderStatus}
                  invoicedAt={bookingOrder?.invoicedAt}
                  limeObjectId={bookingOrder?.limeObjectId}
                  isSelfService={bookingOrder?.selfServiceOrder != null}
                  setDeleteClientCompanyOnSave={setDeleteClientCompanyOnSave}
                  disableDelete={!!deleteClientCompanyOnSave}
                  loading={loadingClientCompanies}
                  showElsaMappingInformation={showElsaMappingInformation}
                  bookingHasElsaMappedCourseInstance={
                    bookingHasElsaMappedCourseInstance
                  }
                />
              </div>
              {bookingOrder && (
                <div className="flex items-center gap-4">
                  {/* temporarily removed border-solid from className*/}
                  <div
                    className="flex flex-row items-center border-0 border-gray-200 border-r-2 pr-4 cursor-pointer border-solid"
                    onClick={() => setShowCommentModal(true)}>
                    <MessageOutlined className="blueButton pr-2" />
                    <div className="text-safeLifeMedium">
                      {t('views.BookingOrderDetails.comments', {
                        commentCount: comments?.length || 0,
                      })}
                    </div>
                  </div>
                  <Tooltip
                    title={
                      bookingOrder.invoicedAt
                        ? t('views.BookingOrderDetails.unmarkAsInvoiced')
                        : t('views.BookingOrderDetails.markAsInvoiced')
                    }>
                    <div className="relative inline-block">
                      <DollarCircleOutlined
                        className="blueButton"
                        onClick={openInvoiceModal}
                      />
                      {bookingOrder.invoicedAt && (
                        <RedoOutlined
                          style={{ fontSize: '14px' }}
                          className="absolute inline-block -mt-2"
                        />
                      )}
                    </div>
                  </Tooltip>

                  <FileTextFilled className="blueButton hidden" />
                  <MailFilled className="blueButton hidden" />
                  <EllipsisOutlined className="blueButton hidden" />
                </div>
              )}
            </div>
            <div className="my-4 sm:my-8 flex flex-col sm:flex-row gap-y-4">
              <div className="sm:basis-0 grow overflow-hidden">
                <PurchaserField
                  bookingOrderId={bookingOrder?.id}
                  purchasers={contactPersons}
                  selectedPurchaser={selectedContactPerson}
                  setSelectedPurchaser={setSelectedContactPerson}
                  fetchPurchasers={fetchContactPersons}
                  setDeletePurchaserOnSave={setDeletePurchaserOnSave}
                  disableDelete={!!deletePurchaserOnSave}
                  loading={loadingContactPersons}
                />
              </div>
              <div className="border-solid border-gray-200 border-0 border-r-2 h-32 mx-2 hidden sm:block" />
              <div className="sm:basis-0 grow overflow-hidden">
                <InvoiceRecipientField
                  invoiceRecipients={invoiceRecipients}
                  bookingOrderId={bookingOrder?.id}
                  selectedInvoiceRecipient={selectedInvoiceRecipient}
                  setSelectedInvoiceRecipient={setSelectedInvoiceRecipient}
                  fetchInvoiceRecipients={fetchInvoiceRecipients}
                  invoiceReference={invoiceReference}
                  disableDelete={!!deleteInvoiceRecipientOnSave}
                  setDeleteInvoiceRecipientOnSave={
                    setDeleteInvoiceRecipientOnSave
                  }
                  updateInvoiceReference={updateInvoiceReference}
                  loading={loadingInvoiceRecipients}
                  loadingOptions={loadingInvoiceRecipientDebounce}
                  onSearch={invoiceRecipientDebounce}
                />
              </div>
              <div className="border-solid border-gray-200 border-0 border-r-2 h-32 mx-2 hidden sm:block" />
              <div className="sm:basis-0 grow overflow-hidden">
                <ResponsibleBookerField
                  bookers={bookers}
                  selectedResponsibleBooker={selectedResponsibleBooker}
                  setSelectedResponsibleBooker={setSelectedResponsibleBooker}
                  loading={loadingBookers}
                />
              </div>
            </div>

            <div className="border-solid border-gray-200 border-0 border-b-2 pb-4 flex flex-row gap-2 h-10">
              <div className="text-xl font-semibold">
                {t('common.courseInstances')}
              </div>
              {submittingCourseInstance && <Spin />}
            </div>
            {!loadingBookingOrder && bookingOrderSubsidiaryCompany && (
              <>
                {displayedCourseInstances &&
                displayedCourseInstances?.length > 0 ? (
                  <div className="w-full flex flex-col pb-4">
                    {displayedCourseInstances.map(
                      (displayedCourseInstance, index) => (
                        <CourseInstanceCard
                          key={`displayedCourseInstance_${index}}`}
                          displayedCourseInstance={displayedCourseInstance}
                          bookingOrderEdited={orderEdited}
                          bookingOrderSubsidiary={bookingOrderSubsidiaryCompany}
                          syncingCourseInstanceToElsa={
                            syncingCourseInstanceToElsa
                          }
                          bookingOrderClientCompany={selectedClientCompany}
                          bookingOrderContactPerson={selectedContactPerson}
                          onCourseInstanceUpdate={(displayedCourseInstance) =>
                            updateCourseInstance(index, displayedCourseInstance)
                          }
                          onSyncCourseInstanceToElsa={(
                            displayedCourseInstance,
                          ) =>
                            onSyncCourseInstanceToElsa(
                              index,
                              displayedCourseInstance,
                            )
                          }
                          submittingCourseInstance={submittingCourseInstance}
                          onDelete={(displayedCourseInstance) =>
                            onCourseInstanceDelete(
                              index,
                              displayedCourseInstance,
                            )
                          }
                          openEmailModal={() => {
                            setEmailModalCourseInstance(
                              displayedCourseInstance,
                            );
                          }}
                        />
                      ),
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col items-center text-center">
                    <div className="text-lg font-semibold pt-12">
                      {t('views.BookingOrderDetails.noCourseInstances')}
                    </div>
                    <div className="text-gray-600 pt-4">
                      <div>
                        {t('views.BookingOrderDetails.createCourseInstances')}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="flex justify-center py-4">
              <Button
                className="text-white bg-safeLifeMedium"
                onClick={() => setShowCourseInstanceModal(true)}>
                {t('views.BookingOrderDetails.createNewCourseInstance')}
              </Button>
            </div>
          </div>
        ) : (
          <BookingOrderNotFound />
        )}
      </ContentCard>
      {bookingOrderSubsidiaryCompany && (
        <SearchModal
          visible={showCourseInstanceModal}
          clientCompany={selectedClientCompany}
          subsidiaryCompany={bookingOrderSubsidiaryCompany}
          contactPerson={selectedContactPerson}
          onCancel={() => setShowCourseInstanceModal(false)}
          createDisplayedCourseInstance={onCreateDisplayedCourseInstance}
        />
      )}
    </>
  );
};

export default BookingOrderDetails;
