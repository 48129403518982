import React from 'react';
import { Chart } from 'react-chartjs-2';

import { GraphData } from '../types/Graph';

type InsightCardGraphProps = {
  graphData?: GraphData;
  color?: string;
};

const InsightCardGraph: React.FC<InsightCardGraphProps> = ({
  graphData,
  color = '#2A5C96',
}) => {
  return (
    <div>
      <Chart
        type="line"
        height={64}
        data={{
          labels: graphData?.entries.map((entry) => entry.label),
          datasets: [
            {
              data: graphData?.entries.map((entry) => entry.value),
              borderColor: color,
              borderWidth: 3,
              pointRadius: 0,
              pointHoverRadius: 0,
              tension: 0.3,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          responsive: true,
          interaction: {},
          scales: {
            x: { display: false },
            y: {
              type: 'linear',
              display: false,
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
};

export default InsightCardGraph;
