import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BookingOrderAPI from '../../bookingOrders/api/BookingOrderAPI';
import WeekGraphCard from '../../insights/components/WeekGraphCard';
import DashboardBookingOrderList from '../components/DashboardBookingOrderList';
import DashboardBookingOrderTabs, {
  DashboardBookingOrderTab,
} from '../components/DashboardBookingOrderTabs';
import { useUser } from '../../user/userContext';
import { BookingOrderStatus } from '../../bookingOrders/types/BookingOrder';
import DateCount from '../../insights/types/DateCount';

dayjs.extend(minMax);

const Dashboard: React.FC = () => {
  const [currentUser] = useUser();
  const [graphData, setGraphData] = useState<DateCount[]>([]);
  const [graphDate, setGraphDate] = useState<dayjs.Dayjs>();
  const [loadingGraph, setLoadingGraph] = useState<boolean>(true);
  const [graphIsCurrentWeek, setGraphIsCurrentWeek] = useState<boolean>(true);

  const { t } = useTranslation();

  useEffect(() => {
    setGraphDate(dayjs());
  }, []);

  useEffect(() => {
    const handleGraphDateUpdate = async () => {
      if (!graphDate) {
        return;
      }

      const today = dayjs();
      setGraphIsCurrentWeek(graphDate.isSame(today, 'week'));

      setLoadingGraph(true);
      try {
        const toDate = dayjs.min(
          today,
          graphDate.endOf('week').subtract(2, 'days'),
        );

        if (!toDate) {
          return;
        }

        const { data } = await BookingOrderAPI.getBookingOrdersCreated(
          graphDate.startOf('week').format('YYYY-MM-DD'),
          toDate.format('YYYY-MM-DD'),
          currentUser?.preferredSubsidiaryCompany
            ? [currentUser?.preferredSubsidiaryCompany.id]
            : null,
        );
        setGraphData(data);
      } finally {
        setLoadingGraph(false);
      }
    };

    handleGraphDateUpdate();
  }, [graphDate, currentUser?.preferredSubsidiaryCompany]);

  const orderedTabs = useMemo<DashboardBookingOrderTab[]>(
    () => [
      {
        key: 'urgent',
        title: t('views.Dashboard.urgent'),
        content: (amountSetter) => (
          <DashboardBookingOrderList
            setTabBookingOrderAmount={amountSetter}
            bookingOrderLoader={(page, pageSize) =>
              BookingOrderAPI.getIncompleteBookingOrders(
                {
                  subsidiaryCompanyIds:
                    currentUser?.preferredSubsidiaryCompany && [
                      currentUser.preferredSubsidiaryCompany.id,
                    ],
                  from: dayjs().startOf('day').toISOString(),
                  to: dayjs().add(1, 'week').toISOString(),
                },
                {
                  pageSize,
                  page,
                },
              )
            }
            noBookingOrdersText={t('views.Dashboard.noUrgentOrders')}
          />
        ),
      },
      {
        key: 'new',
        title: t('views.Dashboard.new'),
        content: (amountSetter) => (
          <DashboardBookingOrderList
            setTabBookingOrderAmount={amountSetter}
            bookingOrderLoader={(page, pageSize) =>
              BookingOrderAPI.getBookingOrders(
                {
                  subsidiaryCompanyIds:
                    currentUser?.preferredSubsidiaryCompany && [
                      currentUser.preferredSubsidiaryCompany.id,
                    ],
                  responsibleBookerIds: [null],
                },
                {
                  pageSize,
                  page,
                },
              )
            }
            noBookingOrdersText={t('views.Dashboard.noNewOrders')}
          />
        ),
      },
      {
        key: 'incomplete',
        title: t('views.Dashboard.incomplete'),
        content: (amountSetter) => (
          <DashboardBookingOrderList
            setTabBookingOrderAmount={amountSetter}
            bookingOrderLoader={(page, pageSize) =>
              BookingOrderAPI.getIncompleteBookingOrders(
                {
                  subsidiaryCompanyIds:
                    currentUser?.preferredSubsidiaryCompany && [
                      currentUser.preferredSubsidiaryCompany.id,
                    ],
                },
                {
                  pageSize,
                  page,
                },
              )
            }
            noBookingOrdersText={t('views.Dashboard.noIncompleteOrders')}
          />
        ),
      },
    ],
    [currentUser?.preferredSubsidiaryCompany, t],
  );

  const readyForInvoicingTabs = useMemo<DashboardBookingOrderTab[]>(
    () => [
      {
        key: 'urgent',
        title: t('views.Dashboard.urgent'),
        content: (amountSetter) => (
          <DashboardBookingOrderList
            setTabBookingOrderAmount={amountSetter}
            bookingOrderLoader={(page, pageSize) =>
              BookingOrderAPI.getBookingOrders(
                {
                  subsidiaryCompanyIds:
                    currentUser?.preferredSubsidiaryCompany && [
                      currentUser.preferredSubsidiaryCompany.id,
                    ],
                  to: dayjs().startOf('day').toISOString(),
                  isInvoiced: false,
                  statuses: [BookingOrderStatus.Confirmed],
                  hasInvoiceRecipient: true,
                  courseInstancePriceGreaterThan: 0,
                },
                {
                  pageSize,
                  page,
                },
              )
            }
            noBookingOrdersText={t('views.Dashboard.noConfirmedOrders')}
          />
        ),
      },
      {
        key: 'completed',
        title: t('views.Dashboard.confirmed'),
        content: (amountSetter) => (
          <DashboardBookingOrderList
            setTabBookingOrderAmount={amountSetter}
            bookingOrderLoader={(page, pageSize) =>
              BookingOrderAPI.getBookingOrders(
                {
                  subsidiaryCompanyIds:
                    currentUser?.preferredSubsidiaryCompany && [
                      currentUser.preferredSubsidiaryCompany.id,
                    ],
                  isInvoiced: false,
                  statuses: [BookingOrderStatus.Confirmed],
                  hasInvoiceRecipient: true,
                  courseInstancePriceGreaterThan: 0,
                },
                {
                  pageSize,
                  page,
                },
              )
            }
            noBookingOrdersText={t('views.Dashboard.noConfirmedOrders')}
          />
        ),
      },
      {
        key: 'canceled',
        title: t('views.Dashboard.canceled'),
        content: (amountSetter) => (
          <DashboardBookingOrderList
            setTabBookingOrderAmount={amountSetter}
            bookingOrderLoader={(page, pageSize) =>
              BookingOrderAPI.getBookingOrders(
                {
                  subsidiaryCompanyIds:
                    currentUser?.preferredSubsidiaryCompany && [
                      currentUser.preferredSubsidiaryCompany.id,
                    ],
                  isInvoiced: false,
                  statuses: [BookingOrderStatus.Canceled],
                  hasInvoiceRecipient: true,
                  courseInstancePriceGreaterThan: 0,
                },
                {
                  pageSize,
                  page,
                },
              )
            }
            noBookingOrdersText={t('views.Dashboard.noCanceledOrders')}
          />
        ),
      },
    ],
    [currentUser?.preferredSubsidiaryCompany, t],
  );

  return (
    <div className="flex flex-row">
      {/* For the future when displaying available instructors: className="w-3/4 pr-4 flex flex-col" */}
      <div className="w-full flex flex-col">
        {graphDate && (
          <WeekGraphCard
            title={t('views.Dashboard.numberOfOrders')}
            subtitle={(nOrders) =>
              t('views.Dashboard.ordersCreated', { nOrders })
            }
            weekData={graphData.map((date) => date.count)}
            displayDate={graphDate}
            isCurrentWeek={graphIsCurrentWeek}
            onPrev={() => setGraphDate(graphDate.clone().subtract(1, 'week'))}
            onNext={() => setGraphDate(graphDate.clone().add(1, 'week'))}
            setWeek={setGraphDate}
            loading={loadingGraph}
          />
        )}
        <div className="mt-6">
          <DashboardBookingOrderTabs
            title={t('views.Dashboard.orders')}
            tabs={orderedTabs}
          />
        </div>

        <div className="mt-6">
          <DashboardBookingOrderTabs
            title={t('views.Dashboard.readyForInvoicing')}
            tabs={readyForInvoicingTabs}
          />
        </div>
      </div>
      <div className="w-1/4 pl-6 hidden">
        <div>
          <h2 className="mb-6">{t('views.Dashboard.availableInstructors')}</h2>
          <div
            className="rounded-lg bg-white"
            style={{ height: '30rem' }}></div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
