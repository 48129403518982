import React from 'react';
import { Card, Row } from 'antd';
import InsightsFilters from './InsightsFilters';
import { useTranslation } from 'react-i18next';

type InsightsHeaderProps = {
  toggleShowExtendedFilters: () => void;
  showExtendedFilters: boolean;
};

const InsightsHeader: React.FC<InsightsHeaderProps> = ({
  toggleShowExtendedFilters,
  showExtendedFilters,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="w-full">
      <Row className="text-black text-xl font-semibold">
        {t('components.InsightsHeader.title')}
      </Row>
      <Row className="text-grayMedium font-thin">
        {t('components.InsightsHeader.description')}
      </Row>

      <Row className="mt-4">
        <InsightsFilters
          toggleShowExtendedFilters={toggleShowExtendedFilters}
          showExtendedFilters={showExtendedFilters}
        />
      </Row>
    </Card>
  );
};

export default InsightsHeader;
