import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Radio,
  RadioChangeEvent,
  Select,
  SelectProps,
  Spin,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import CourseOption from '../../components/CourseOption';
import UserOption from '../../components/UserOption';
import CourseAPI from '../../course/CourseAPI';
import Course from '../../course/types/Course';
import UserAPI from '../../user/UserAPI';
import { Permission } from '../../user/types/Permission';
import User, { UserRole } from '../../user/types/User';
import { useUser } from '../../user/userContext';
import {
  sortInstructorsByFavorite,
  userHasPermission,
} from '../../user/userUtils';
import { getSearchParam, stringifyValues } from '../../utils/searchParams';
import {
  BookingOrderApiFilter,
  IncompleteBookingOrderApiFilter,
} from '../api/BookingOrderAPI';
import ClientCompanyAPI from '../api/ClientCompanyAPI';
import InvoiceRecipientAPI from '../api/InvoiceRecipientAPI';
import { BookingOrderStatus } from '../types/BookingOrder';
import ClientCompany from '../types/ClientCompany';
import InvoiceRecipient from '../types/InvoiceRecipient';
import useDebounce from '../../utils/useDebounce';

import BookingOrderStatusBadge from './BookingOrderStatusBadge';

const { RangePicker } = DatePicker;

enum InvoiceFilter {
  All = 'all',
  Invoiced = 'invoiced',
  NotInvoiced = 'notInvoiced',
}

type CustomFilterFormValues = {
  date?: [dayjs.Dayjs, dayjs.Dayjs];
  invoiceFilter: InvoiceFilter;
  statuses?: BookingOrderStatus[];
  courseIds?: number[];
  responsibleBookerIds?: (number | null)[];
  invoiceRecipientIds?: (number | null)[];
  instructorIds?: (number | null)[];
  clientCompanyIds?: number[];
};

export type FilterPickerValue = {
  bookingOrderFilter?: BookingOrderApiFilter;
  incompleteBookingOrderFilter?: IncompleteBookingOrderApiFilter;
};

export enum BookingOrderFilterCategory {
  All = 'all',
  Urgent = 'urgent',
  New = 'new',
  Incomplete = 'incomplete',
  Custom = 'custom',
}

function filterUserOption(input: string, option?: DefaultOptionType) {
  const entryName = option?.props.children.props.user
    ? option?.props.children.props.user.name
    : option?.props.children.props.noUserText;

  return entryName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

function selectProps(
  placeholder: string,
  notFoundDescription?: string,
  loading = false,
  showSearch = true,
  disabled = false,
): Pick<
  SelectProps,
  | 'mode'
  | 'showSearch'
  | 'allowClear'
  | 'loading'
  | 'placeholder'
  | 'notFoundContent'
  | 'disabled'
> {
  return {
    mode: 'multiple',
    showSearch,
    allowClear: true,
    loading,
    disabled,
    placeholder,
    notFoundContent: notFoundDescription ? (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={notFoundDescription}
      />
    ) : undefined,
  };
}

type BookingOrderFilterProps = {
  className?: string;
  setCurrentFilterCategoryName?: (filterCategoryName: string) => void;
  setFilter: (filterParams: FilterPickerValue) => void;
  filterSubsidiaryCompanyIds?: number[];
};

const BookingOrderFilterPicker: React.FC<BookingOrderFilterProps> = ({
  className,
  setCurrentFilterCategoryName,
  setFilter,
  filterSubsidiaryCompanyIds,
}) => {
  const [currentUser] = useUser();
  const location = useLocation();
  const [courses, setCourses] = useState<Course[]>([]);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [instructors, setInstructors] = useState<User[]>([]);
  const [loadingInstructors, setLoadingInstructors] = useState<boolean>(false);
  const [responsibleBookers, setResponsibleBookers] = useState<User[]>([]);
  const [loadingResponsibleBookers, setLoadingResponsibleBookers] =
    useState<boolean>(false);
  const [invoiceRecipients, setInvoiceRecipients] = useState<
    InvoiceRecipient[]
  >([]);
  const [loadingInvoiceRecipients, setLoadingInvoiceRecipients] =
    useState<boolean>(false);
  const [clientCompanies, setClientCompanies] = useState<ClientCompany[]>([]);
  const [loadingClientCompanies, setLoadingClientCompanies] =
    useState<boolean>(false);
  const [filterCategory, setFilterCategory] =
    useState<BookingOrderFilterCategory>();
  const [invoiceFilterValue, setInvoiceFilterValue] = useState<InvoiceFilter>();
  const [invoiceRecipientSearch, setInvoiceRecipientSearch] = useState('');
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    debounce: invoiceRecipientDebounce,
    loading: loadingInvoiceRecipientDebounce,
  } = useDebounce(
    (value: string) => {
      if (!currentUser) return;

      return InvoiceRecipientAPI.getInvoiceRecipients({
        name: value || undefined,
        subsidiaryCompanyId: currentUser.preferredSubsidiaryCompany?.id,
      });
    },
    (res) => setInvoiceRecipients(res.data.data),
    {
      beforeDebounce: () => setInvoiceRecipients([]),
    },
  );

  const firstUpdate = useRef(true);

  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const { data } = await CourseAPI.getCourses();
      setCourses(data);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  const fetchClientCompanies = useCallback(async () => {
    setLoadingClientCompanies(true);
    try {
      const { data } = await ClientCompanyAPI.getClientCompanies({
        subsidiaryCompanyIds: filterSubsidiaryCompanyIds,
      });
      setClientCompanies(data);
    } finally {
      setLoadingClientCompanies(false);
    }
  }, [filterSubsidiaryCompanyIds]);

  const fetchInstructors = useCallback(async () => {
    setLoadingInstructors(true);
    try {
      const { data } = await UserAPI.getUsers({
        roles: [UserRole.Instructor],
      });
      const sortedInstructors = sortInstructorsByFavorite(data, currentUser);
      setInstructors(sortedInstructors);
    } finally {
      setLoadingInstructors(false);
    }
  }, [currentUser]);

  const fetchResponsibleBookers = useCallback(async () => {
    setLoadingResponsibleBookers(true);
    try {
      const { data } = await UserAPI.getUsers({
        roles: [UserRole.Admin, UserRole.Booker],
      });
      setResponsibleBookers(data);
    } finally {
      setLoadingResponsibleBookers(false);
    }
  }, []);

  const fetchInvoiceRecipients = useCallback(async () => {
    setLoadingInvoiceRecipients(true);
    try {
      const { data } = await InvoiceRecipientAPI.getInvoiceRecipients({
        name: undefined,
        subsidiaryCompanyId: currentUser?.preferredSubsidiaryCompany?.id,
      });
      setInvoiceRecipients(data.data);
    } finally {
      setLoadingInvoiceRecipients(false);
    }
  }, [currentUser?.preferredSubsidiaryCompany?.id]);

  const getFilterNameFromFilterCategory = useCallback(
    (filterCategory: BookingOrderFilterCategory) => {
      switch (filterCategory) {
        case BookingOrderFilterCategory.All:
          return t('components.BookingOrderFilterPicker.all');
        case BookingOrderFilterCategory.Urgent:
          return t('components.BookingOrderFilterPicker.urgent');
        case BookingOrderFilterCategory.New:
          return t('components.BookingOrderFilterPicker.new');
        case BookingOrderFilterCategory.Incomplete:
          return t('components.BookingOrderFilterPicker.incomplete');
        case BookingOrderFilterCategory.Custom:
          return t('components.BookingOrderFilterPicker.custom');
      }
    },
    [t],
  );

  const getInvoiceFilterFromIsInvoiced = useCallback(
    (isInvoiced: boolean | undefined) => {
      switch (isInvoiced) {
        case true:
          return InvoiceFilter.Invoiced;
        case false:
          return InvoiceFilter.NotInvoiced;
        default:
          return InvoiceFilter.All;
      }
    },
    [],
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    invoiceRecipientDebounce(invoiceRecipientSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceRecipientSearch]);

  useEffect(() => {
    // Set the name for the default filter category
    setCurrentFilterCategoryName?.(
      getFilterNameFromFilterCategory(BookingOrderFilterCategory.All),
    );
  }, [getFilterNameFromFilterCategory, setCurrentFilterCategoryName]);

  useEffect(() => {
    if (!location.search) {
      setFilterCategory(BookingOrderFilterCategory.All);
      form.setFieldsValue({ invoiceFilter: InvoiceFilter.All });
      return;
    }

    const filterCategory =
      getSearchParam<BookingOrderFilterCategory>(
        searchParams,
        'category',
        'first',
      ) || BookingOrderFilterCategory.All;

    if (!Object.values(BookingOrderFilterCategory).includes(filterCategory)) {
      return setSearchParams({});
    }

    setFilterCategory(filterCategory);

    if (filterCategory !== BookingOrderFilterCategory.Custom) {
      form.setFieldsValue({ invoiceFilter: InvoiceFilter.All });
      return;
    }

    const from = getSearchParam(searchParams, 'from', 'moment');
    const to = getSearchParam(searchParams, 'to', 'moment');

    const bookingOrderFilter: BookingOrderApiFilter = {
      from: from?.toISOString(),
      to: to?.toISOString(),
      subsidiaryCompanyIds: filterSubsidiaryCompanyIds,
      courseIds: getSearchParam(searchParams, 'courseIds', 'list')?.map(Number),
      instructorIds: getSearchParam(searchParams, 'instructorIds', 'list')?.map(
        (value) => (value === 'null' ? null : Number(value)),
      ),
      responsibleBookerIds: getSearchParam(
        searchParams,
        'responsibleBookerIds',
        'list',
      )?.map((value) => (value === 'null' ? null : Number(value))),
      invoiceRecipientIds: getSearchParam(
        searchParams,
        'invoiceRecipientIds',
        'list',
      )?.map((value) => (value === 'null' ? null : Number(value))),
      isInvoiced: getSearchParam(searchParams, 'isInvoiced', 'bool'),
      statuses: getSearchParam<BookingOrderStatus>(
        searchParams,
        'statuses',
        'list',
      ),
      clientCompanyIds: getSearchParam(
        searchParams,
        'clientCompanyIds',
        'list',
      )?.map(Number),
    };

    form.setFieldsValue({
      ...bookingOrderFilter,
      date: from && to && [from, to],
      instructorIds: bookingOrderFilter.instructorIds?.map((id) =>
        id === null ? 0 : id,
      ),
      responsibleBookerIds: bookingOrderFilter.responsibleBookerIds?.map(
        (id) => (id === null ? 0 : id),
      ),
      invoiceRecipientIds: bookingOrderFilter.invoiceRecipientIds?.map((id) =>
        id === null ? 0 : id,
      ),
      invoiceFilter: getInvoiceFilterFromIsInvoiced(
        bookingOrderFilter.isInvoiced,
      ),
    });

    setFilter({ bookingOrderFilter });
  }, [
    setFilter,
    setSearchParams,
    getInvoiceFilterFromIsInvoiced,
    location.search,
    searchParams,
    filterSubsidiaryCompanyIds,
    form,
  ]);

  useEffect(() => {
    setCurrentFilterCategoryName?.(
      getFilterNameFromFilterCategory(
        filterCategory || BookingOrderFilterCategory.All,
      ),
    );

    if (filterCategory === BookingOrderFilterCategory.Custom) {
      fetchCourses();
      fetchInstructors();
      fetchResponsibleBookers();
      fetchInvoiceRecipients();
      if (userHasPermission(currentUser, Permission.CLIENT_COMPANY_READ)) {
        fetchClientCompanies();
      }
    }

    switch (filterCategory) {
      case BookingOrderFilterCategory.All: {
        setFilter({
          bookingOrderFilter: {
            subsidiaryCompanyIds: filterSubsidiaryCompanyIds,
          },
        });
        break;
      }
      case BookingOrderFilterCategory.New: {
        setFilter({
          bookingOrderFilter: {
            subsidiaryCompanyIds: filterSubsidiaryCompanyIds,
            responsibleBookerIds: [null],
          },
        });
        break;
      }
      case BookingOrderFilterCategory.Urgent: {
        setFilter({
          incompleteBookingOrderFilter: {
            subsidiaryCompanyIds: filterSubsidiaryCompanyIds,
            from: dayjs().startOf('day').toISOString(),
            to: dayjs().add(1, 'week').toISOString(),
          },
        });
        break;
      }
      case BookingOrderFilterCategory.Incomplete: {
        setFilter({
          incompleteBookingOrderFilter: {
            subsidiaryCompanyIds: filterSubsidiaryCompanyIds,
          },
        });
        break;
      }
    }
  }, [
    currentUser,
    fetchClientCompanies,
    fetchCourses,
    fetchInstructors,
    fetchInvoiceRecipients,
    fetchResponsibleBookers,
    filterCategory,
    filterSubsidiaryCompanyIds,
    getFilterNameFromFilterCategory,
    setCurrentFilterCategoryName,
    setFilter,
  ]);

  const onRadioChange = useCallback(
    (event: RadioChangeEvent) => {
      if (!currentUser || !filterSubsidiaryCompanyIds) {
        return;
      }
      if (event.target.value === BookingOrderFilterCategory.All) {
        return setSearchParams({});
      }
      setSearchParams(stringifyValues({ category: event.target.value }));
    },
    [currentUser, filterSubsidiaryCompanyIds, setSearchParams],
  );

  const onInvoiceFilterChange = useCallback((event: InvoiceFilter) => {
    setInvoiceFilterValue(event);
  }, []);

  const getIsInvoicedFromInvoiceFilter = useCallback(
    (invoiceFilter: InvoiceFilter) => {
      switch (invoiceFilter) {
        case InvoiceFilter.Invoiced:
          return true;
        case InvoiceFilter.NotInvoiced:
          return false;
        case InvoiceFilter.All:
        default:
          return undefined;
      }
    },
    [],
  );

  const onCustomFilterSubmit = useCallback(
    (values: CustomFilterFormValues) => {
      if (!currentUser || !filterSubsidiaryCompanyIds) {
        return;
      }

      const from = values.date?.[0].startOf('day').toISOString();
      const to = values.date?.[1].endOf('day').toISOString();

      const instructorIds = values.instructorIds?.includes(0)
        ? values.instructorIds.filter((id) => id !== 0).concat([null])
        : values.instructorIds;

      const responsibleBookerIds = values.responsibleBookerIds?.includes(0)
        ? values.responsibleBookerIds.filter((id) => id !== 0).concat([null])
        : values.responsibleBookerIds;

      const invoiceRecipientIds = values.invoiceRecipientIds?.includes(0)
        ? values.invoiceRecipientIds.filter((id) => id !== 0).concat([null])
        : values.invoiceRecipientIds;

      const isInvoiced = getIsInvoicedFromInvoiceFilter(values.invoiceFilter);

      setSearchParams(
        stringifyValues({
          category: BookingOrderFilterCategory.Custom,
          from,
          to,
          courseIds: values.courseIds,
          instructorIds,
          responsibleBookerIds,
          invoiceRecipientIds,
          isInvoiced,
          statuses: values.statuses,
          clientCompanyIds: values.clientCompanyIds,
        }),
      );
    },
    [
      currentUser,
      filterSubsidiaryCompanyIds,
      getIsInvoicedFromInvoiceFilter,
      setSearchParams,
    ],
  );

  return (
    <div className={`${className}`}>
      <Radio.Group
        value={filterCategory}
        onChange={onRadioChange}
        buttonStyle="solid"
        size="large"
        className="flex flex-col transparentRadioGroup">
        <Radio.Button value={BookingOrderFilterCategory.All}>
          {getFilterNameFromFilterCategory(BookingOrderFilterCategory.All)}
        </Radio.Button>
        <Radio.Button value={BookingOrderFilterCategory.New}>
          {getFilterNameFromFilterCategory(BookingOrderFilterCategory.New)}
        </Radio.Button>
        <Radio.Button value={BookingOrderFilterCategory.Urgent}>
          {getFilterNameFromFilterCategory(BookingOrderFilterCategory.Urgent)}
        </Radio.Button>
        <Radio.Button value={BookingOrderFilterCategory.Incomplete}>
          {getFilterNameFromFilterCategory(
            BookingOrderFilterCategory.Incomplete,
          )}
        </Radio.Button>
        <Radio.Button value={BookingOrderFilterCategory.Custom}>
          {getFilterNameFromFilterCategory(BookingOrderFilterCategory.Custom)}
        </Radio.Button>
      </Radio.Group>
      {filterCategory === BookingOrderFilterCategory.Custom && (
        <div className="bg-white rounded-md mt-3 p-3">
          <Form
            form={form}
            onFinish={onCustomFilterSubmit}
            layout="vertical"
            className="-mb-3">
            <Button htmlType="submit" type="primary" className="w-full mb-3">
              <SearchOutlined className="-mr-1 -ml-1" />
              {t('components.BookingOrderFilterPicker.filter')}
            </Button>
            <Form.Item name="date" label={t('common.date')}>
              <RangePicker />
            </Form.Item>
            <Form.Item name="clientCompanyIds" label={t('common.companies')}>
              <Select
                {...selectProps(
                  t('components.BookingOrderFilterPicker.selectCompanies'),
                  t('components.BookingOrderFilterPicker.noCompaniesFound'),
                  loadingClientCompanies,
                  true,
                  !userHasPermission(
                    currentUser,
                    Permission.CLIENT_COMPANY_READ,
                  ),
                )}
                filterOption={(input, option) =>
                  option?.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }>
                {clientCompanies?.map(
                  (clientCompany) =>
                    clientCompany.id && (
                      <Select.Option
                        key={clientCompany.id}
                        value={clientCompany.id}>
                        {clientCompany.name}
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
            <Form.Item name="instructorIds" label={t('common.instructors')}>
              <Select
                {...selectProps(
                  t('components.BookingOrderFilterPicker.selectInstructors'),
                  t('components.BookingOrderFilterPicker.noInstructorsFound'),
                  loadingInstructors,
                )}
                filterOption={filterUserOption}>
                <Select.Option key={0} value={0}>
                  <UserOption
                    user={undefined}
                    noUserText={t(
                      'components.BookingOrderFilterPicker.noInstructor',
                    )}
                  />
                </Select.Option>
                {instructors?.map(
                  (instructor) =>
                    instructor.id && (
                      <Select.Option key={instructor.id} value={instructor.id}>
                        <UserOption
                          user={instructor}
                          currentUser={currentUser}
                        />
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
            <Form.Item name="courseIds" label={t('common.courses')}>
              <Select
                {...selectProps(
                  t('components.BookingOrderFilterPicker.selectCourses'),
                  t('components.BookingOrderFilterPicker.noCoursesFound'),
                  loadingCourses,
                )}
                filterOption={(input, option) =>
                  option?.props.children.props.course.name
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }>
                {courses?.map((course) => (
                  <Select.Option key={course.id} value={course.id}>
                    <CourseOption user={currentUser} course={course} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="responsibleBookerIds"
              label={t('common.responsibleBooker')}>
              <Select
                {...selectProps(
                  t(
                    'components.BookingOrderFilterPicker.selectResponsibleBookers',
                  ),
                  t(
                    'components.BookingOrderFilterPicker.noResponsibleBookersFound',
                  ),
                  loadingResponsibleBookers,
                )}
                filterOption={filterUserOption}>
                <Select.Option key={0} value={0}>
                  <UserOption
                    user={undefined}
                    noUserText={t(
                      'components.BookingOrderFilterPicker.noResponsibleBooker',
                    )}
                  />
                </Select.Option>
                {responsibleBookers?.map(
                  (responsibleBooker) =>
                    responsibleBooker.id && (
                      <Select.Option
                        key={responsibleBooker.id}
                        value={responsibleBooker.id}>
                        <UserOption user={responsibleBooker} />
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="invoiceRecipientIds"
              label={t('common.invoiceRecipient')}>
              <Select
                {...selectProps(
                  t(
                    'components.BookingOrderFilterPicker.selectInvoiceRecipient',
                  ),
                  t(
                    'components.BookingOrderFilterPicker.noInvoiceRecipientsFound',
                  ),
                  loadingInvoiceRecipients || loadingInvoiceRecipientDebounce,
                )}
                onSearch={setInvoiceRecipientSearch}
                onBlur={() => setInvoiceRecipientSearch('')}
                onChange={() => setTimeout(() => setInvoiceRecipientSearch(''))} // Reset list after ant component has cached the selected answer
                filterOption={false}
                notFoundContent={
                  loadingInvoiceRecipientDebounce ? (
                    <Spin className="mx-auto p-2" />
                  ) : null
                }>
                {!loadingInvoiceRecipientDebounce && (
                  <Select.Option key={0} value={0}>
                    {t(
                      'components.BookingOrderFilterPicker.noInvoiceRecipient',
                    )}
                  </Select.Option>
                )}
                {invoiceRecipients?.map((invoiceRecipient) => (
                  <Select.Option
                    key={invoiceRecipient.id}
                    value={invoiceRecipient.id}>
                    {invoiceRecipient.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t('common.status')} name="statuses">
              <Select
                {...selectProps(
                  t('components.BookingOrderFilterPicker.selectStatuses'),
                  undefined,
                  false,
                  false,
                )}
                className="text-sm"
                size="large">
                {[
                  BookingOrderStatus.Confirmed,
                  BookingOrderStatus.Preliminary,
                  BookingOrderStatus.Ordered,
                  BookingOrderStatus.Canceled,
                ].map((status) => (
                  <Select.Option key={status} value={status}>
                    <BookingOrderStatusBadge status={status} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('components.BookingOrderFilterPicker.invoiceStatus')}
              name="invoiceFilter"
              valuePropName="checked">
              <Select
                value={
                  invoiceFilterValue ?? form.getFieldValue('invoiceFilter')
                }
                onChange={onInvoiceFilterChange}>
                <Select.Option value={InvoiceFilter.All}>
                  {t('components.BookingOrderFilterPicker.all')}
                </Select.Option>
                <Select.Option value={InvoiceFilter.Invoiced}>
                  {t('components.BookingOrderFilterPicker.invoiced')}
                </Select.Option>
                <Select.Option value={InvoiceFilter.NotInvoiced}>
                  {t('components.BookingOrderFilterPicker.notInvoiced')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default BookingOrderFilterPicker;
