import React, { useEffect, useRef, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { EventInput } from '@fullcalendar/core';
import dayjs from 'dayjs';

import CalendarHeader from '../../components/CalendarHeader';
import { CalendarSearchResult } from '../types/SearchResult';
import { getFullCalendarLocale } from '../../utils/language';
import { useLanguage } from '../../context/language';

import SearchResultBox from './SearchResultBox';

type SearchCalendarPropsParam = {
  setDateString?: (values: string) => void;
  fetchedEvents: CalendarSearchResult[];
  onClickDay: (date: Date | null) => void;
  loadingSearchResults?: boolean;
};

const SearchCalendar: React.FC<SearchCalendarPropsParam> = ({
  setDateString,
  fetchedEvents,
  onClickDay,
  loadingSearchResults,
}) => {
  const calendarRef = useRef<FullCalendar>(null);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [currentLanguage] = useLanguage();

  const opacityArray = ['0.1', '0.25', '0.35', '0.5', '0.7', '0.8', '1'];

  useEffect(() => {
    const _events: EventInput[] = fetchedEvents.map((event, index) => ({
      id: String(index),
      date: event.date,
      textColor: '#000000',
      backgroundColor: '#FFFFFF00',
      borderColor: '#FFFFFF00',
      extendedProps: {
        instructorCount: event.instructorCount,
        internalInstructorCount: event.internalInstructorCount,
        externalInstructorCount: event.externalInstructorCount,
        unassignedCourseInstanceCount: event.unassignedCourseInstanceCount,
      },
    }));
    setEvents(_events);
  }, [fetchedEvents]);

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      <CalendarHeader
        calendar={calendarRef}
        setDateString={setDateString}
        disablePastDates
        loading={loadingSearchResults}
      />
      <FullCalendar
        weekNumbers
        ref={calendarRef}
        dayCellClassNames={function (arg) {
          if (arg.isPast) {
            return ['bg-past'];
          }
          return ['hover:bg-calendar'];
        }}
        dayCellWillUnmount={() => setEvents([])}
        headerToolbar={false}
        events={events}
        selectMirror={true}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        eventClick={(info) => {
          if (dayjs(info.event.start) >= dayjs().startOf('day'))
            onClickDay?.(info.event.start);
        }}
        dateClick={(info) => {
          if (dayjs(info.date) >= dayjs().startOf('day'))
            onClickDay?.(info.date);
        }}
        contentHeight={900}
        dayHeaderContent={function (arg) {
          return capitalize(arg.text);
        }}
        firstDay={1}
        locale={getFullCalendarLocale(currentLanguage?.code)}
        eventClassNames={
          'hover:bg-calendar m-0 absolute w-full h-80 flex justify-self-center overflow-hidden'
        }
        eventContent={function (arg) {
          return (
            !arg.isPast && (
              <SearchResultBox
                opacity={opacityArray[+arg.event.title]}
                event={arg.event}
              />
            )
          );
        }}
      />
    </>
  );
};

export default SearchCalendar;
