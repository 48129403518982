import React from 'react';
import { useTranslation } from 'react-i18next';

import InvoiceRecipient from '../types/InvoiceRecipient';
import { buildAddressString } from '../../utils/address';

import InformationCard from './InformationCard';

type InvoiceRecipientPreviewProps = {
  invoiceRecipient?: InvoiceRecipient;
};

const InvoiceRecipientPreview: React.FC<InvoiceRecipientPreviewProps> = ({
  invoiceRecipient,
}) => {
  const { t } = useTranslation();
  return (
    <InformationCard title={t('common.invoiceRecipient')}>
      {invoiceRecipient ? (
        <div className="gap-y-1 informationCardText pb-2">
          <div className="font-medium">{invoiceRecipient.name}</div>
          <div>{invoiceRecipient.corporateId}</div>
          <div>
            {buildAddressString(
              invoiceRecipient.clientAddress1,
              invoiceRecipient.clientAddress2,
              invoiceRecipient.clientAddress3,
              invoiceRecipient.clientPostalCode,
              invoiceRecipient.clientCity,
            )}
          </div>
        </div>
      ) : (
        <div className="gap-y-1 informationCardText pb-2">
          <div>
            {t('components.InvoiceRecipientPreview.noInvoiceRecipient')}
          </div>
        </div>
      )}
    </InformationCard>
  );
};

export default InvoiceRecipientPreview;
