import React from 'react';
import { useTranslation } from 'react-i18next';

import User from '../../user/types/User';
import Gravatar from '../../user/components/Gravatar';

import InformationCard from './InformationCard';

type ResponsibleBookerPreviewProps = {
  responsibleBooker?: User;
};

const ResponsibleBookerPreview: React.FC<ResponsibleBookerPreviewProps> = ({
  responsibleBooker,
}) => {
  const { t } = useTranslation();

  return (
    <InformationCard title={t('common.responsibleBooker')}>
      {responsibleBooker ? (
        <div className="flex flex-row items-center">
          <Gravatar email={responsibleBooker.email} className="mr-2 mb-2" />
          <div className="gap-y-1 informationCardText pb-2">
            <div className="font-medium">{responsibleBooker.name}</div>
            <div>{responsibleBooker.email}</div>
            <div>{responsibleBooker.phoneNumber}</div>
          </div>
        </div>
      ) : (
        <div>{t('common.responsibleBooker')}</div>
      )}
    </InformationCard>
  );
};

export default ResponsibleBookerPreview;
