import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Spin, Tooltip } from 'antd';
import FullCalendar from '@fullcalendar/react';
import {
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
  LocaleSingularArg,
} from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { range } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useTranslation } from 'react-i18next';

import User from '../types/User';
import colors from '../../theme/colors';
import UserAPI from '../UserAPI';
import { useUser } from '../userContext';
import InstructorCalendarDate from '../types/InstructorCalendarDate';
import { CourseInstanceStatus } from '../../courseInstance/types/CourseInstance';
import { getFullCalendarLocale } from '../../utils/language';
import { useLanguage } from '../../context/language';
import { userHasPermission } from '../userUtils';
import { Permission } from '../types/Permission';

type UserCalendarProps = {
  user: User;
};

type SlotTimeSpan = {
  start: Dayjs;
  end: Dayjs;
};

type DisplayedWeek = {
  startDate: Dayjs;
  endDate: Dayjs;
};

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const UserCalendar: React.FC<UserCalendarProps> = ({ user }) => {
  const calendarRef = useRef<FullCalendar>(null);
  const navigate = useNavigate();
  const [instructorCalendar, setInstructorCalendar] = useState<
    InstructorCalendarDate[]
  >([]);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [fullCalendarLocale, setFullCalendarLocale] =
    useState<LocaleSingularArg>();
  const [events, setEvents] = useState<EventInput[]>();
  const [displayedWeek, setDisplayedWeek] = useState<DisplayedWeek>();
  const [currentUser] = useUser();
  const [currentLanguage] = useLanguage();
  const earliestHour = 7;
  const latestHour = 22;
  const { t } = useTranslation();

  const fetchInstructorCalendar = useCallback(
    async (startDate: Dayjs, endDate: Dayjs) => {
      setLoadingCalendar(true);
      try {
        const { data } = await UserAPI.getInstructorCalendar(user.id, {
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });
        setInstructorCalendar(data);
      } finally {
        setLoadingCalendar(false);
      }
    },
    [user.id],
  );

  const setCalendarEvents = useCallback(() => {
    const events: EventInput[] = instructorCalendar.flatMap((day) => {
      const courseInstancesOnDay = day.courseInstances.length;
      return day.timeSlots.map((timeSlot) => ({
        id: String(timeSlot.id),
        title: t('components.UserCalendar.courseInstanceDayLimit', {
          dayLimit: day.courseInstanceLimit,
        }),
        start: timeSlot.startsAt,
        end: timeSlot.endsAt,
        backgroundColor:
          day.courseInstanceLimit <= courseInstancesOnDay
            ? colors.primary[400]
            : colors.primary[800],
        borderColor: colors.primary[900],
        extendedProps: {
          type: 'timeSlot',
        },
      }));
    });

    const eventCourseInstances: EventInput[] = instructorCalendar
      .flatMap((it) => it.courseInstances)
      .map((courseInstance) => ({
        id: `courseInstance-${courseInstance.id}`,
        title: courseInstance.course?.name,
        start: courseInstance.startsAt,
        end: courseInstance.endsAt,
        backgroundColor:
          courseInstance.status === CourseInstanceStatus.Canceled
            ? colors.warningRed
            : courseInstance.status === CourseInstanceStatus.Preliminary
              ? colors.warningYellow
              : courseInstance.status === CourseInstanceStatus.Confirmed
                ? colors.safeLifeSuccess
                : colors.safeLifeLight,
        borderColor:
          courseInstance.status === CourseInstanceStatus.Canceled
            ? colors.red[800]
            : courseInstance.status === CourseInstanceStatus.Preliminary
              ? colors.yellow[800]
              : courseInstance.status === CourseInstanceStatus.Confirmed
                ? colors.green[800]
                : colors.blue[800],
        editable: false,
        extendedProps: { type: 'courseInstance' },
      }));

    setEvents([...events, ...eventCourseInstances]);
  }, [instructorCalendar, t]);

  useEffect(() => {
    if (displayedWeek)
      fetchInstructorCalendar(displayedWeek.startDate, displayedWeek.endDate);
  }, [displayedWeek, fetchInstructorCalendar]);

  useEffect(() => {
    setCalendarEvents();
  }, [instructorCalendar, setCalendarEvents]);

  useEffect(() => {
    setFullCalendarLocale(getFullCalendarLocale(currentLanguage?.code));
  }, [currentLanguage]);

  const customAllDay = useCallback((e: DateSelectArg) => {
    e.start.setHours(earliestHour);
    e.start.setMinutes(0);
    e.end.setFullYear(e.start.getFullYear());
    e.end.setMonth(e.start.getMonth());
    e.end.setDate(e.start.getDate());
    e.end.setHours(latestHour);
    e.end.setMinutes(0);
  }, []);

  const splitSlots = useCallback((e: DateSelectArg) => {
    const startDate = dayjs(e.start);
    const endDate = dayjs(e.end);

    const daysDiff =
      Math.abs(startDate.startOf('day').diff(endDate.startOf('day'), 'day')) +
      1;
    const newSlots: Array<SlotTimeSpan> = [];
    const dayOffsets = [...range(daysDiff)];
    dayOffsets.forEach((dayOffset) => {
      const currentDate = startDate.clone().add(dayOffset, 'days');
      const startTime = currentDate.isAfter(startDate)
        ? currentDate.clone().set('hour', earliestHour)
        : currentDate
            .clone()
            .set('hour', e.start.getHours())
            .set('minute', e.start.getMinutes());

      const endTime = currentDate.isBefore(endDate, 'day')
        ? currentDate.clone().set('hour', latestHour)
        : currentDate
            .clone()
            .set('hour', e.end.getHours())
            .set('minute', e.end.getMinutes());

      newSlots.push({
        start: startTime,
        end: endTime,
      });
    });

    return newSlots;
  }, []);

  const getTimeSlotsOnWeek = () => {
    return instructorCalendar.flatMap((day) => day.timeSlots);
  };

  const createSlot = useCallback(
    async (newSlot: SlotTimeSpan) => {
      try {
        const { data } = await UserAPI.createUserTimeSlot(user.id, {
          startsAt: newSlot.start.toISOString(),
          endsAt: newSlot.end.toISOString(),
        });

        setInstructorCalendar((prevState) => {
          const dayIndex = prevState.findIndex((day) =>
            dayjs(day.date).isSame(newSlot.start, 'day'),
          );
          if (dayIndex !== -1) {
            const _instructorCalendar = [...prevState];
            _instructorCalendar[dayIndex].timeSlots.push(data);
            return _instructorCalendar;
          } else {
            return [
              ...prevState,
              {
                id: prevState.length + 1,
                date: dayjs(data.startsAt).format('YYYY-MM-DD'),
                timeSlots: [data],
                courseInstances: [],
                courseInstanceLimit: user.defaultCourseInstanceLimit,
              },
            ];
          }
        });
      } catch {
        calendarRef.current?.getApi().unselect();
      }
    },
    [user.defaultCourseInstanceLimit, user.id],
  );

  const createSlots = useCallback(
    (e: DateSelectArg) => {
      if (e.allDay) {
        customAllDay(e);
      }

      const newSlots = splitSlots(e);

      newSlots.forEach((newSlot) => createSlot(newSlot));
    },
    [createSlot, customAllDay, splitSlots],
  );

  const updateSlot = useCallback(
    async (e: EventChangeArg) => {
      const updatedDayIndex = instructorCalendar.findIndex((day) =>
        dayjs(day.date).isSame(e.event.start, 'day'),
      );
      try {
        const { data } = await UserAPI.updateUserTimeSlot(
          user.id,
          Number(e.event.id),
          {
            courseInstanceLimit:
              instructorCalendar[updatedDayIndex].courseInstanceLimit ??
              user.defaultCourseInstanceLimit,
            startsAt: e.event.start!.toISOString(),
            endsAt: e.event.end!.toISOString(),
          },
        );

        const updatedSlotIndex = instructorCalendar[
          updatedDayIndex
        ].timeSlots.findIndex((slot) => slot.id === Number(e.event.id));

        setInstructorCalendar((prevState) => {
          const _instructorCalendar = [...prevState];
          _instructorCalendar[updatedDayIndex].timeSlots[updatedSlotIndex] =
            data;
          return _instructorCalendar;
        });
      } catch {
        // Force update events to the current state
        setEvents(events?.concat([]));
      }
    },
    [events, instructorCalendar, user.defaultCourseInstanceLimit, user.id],
  );

  const openSlotModal = useCallback(
    async (e: EventClickArg) => {
      if (
        e.event.extendedProps.type === 'timeSlot' &&
        userHasPermission(currentUser, Permission.USER_UPDATE)
      ) {
        navigate(`tidslucka/${e.event.id}`, { state: { fromCalendar: true } });
      } else if (e.event.extendedProps.type === 'courseInstance') {
        const id = e.event.id.split('-')[1];
        navigate(`kurstillfalle/${id}`, { state: { fromCalendar: true } });
      }
    },
    [currentUser, navigate],
  );

  const getCourseInstancesInTimeSpan = useCallback(
    (from: Dayjs, to: Dayjs) => {
      return instructorCalendar
        .flatMap((day) => day.courseInstances)
        .filter(
          (courseInstance) =>
            dayjs(courseInstance.startsAt).isSameOrBefore(to) &&
            dayjs(courseInstance.endsAt).isSameOrAfter(from),
        ).length;
    },
    [instructorCalendar],
  );

  return (
    <div>
      <div className="flex justify-center">
        <Spin
          spinning={loadingCalendar}
          size="large"
          className="absolute self-center mt-20 z-50"
        />
        <FullCalendar
          height={'auto'}
          ref={calendarRef}
          plugins={[timeGridPlugin, interactionPlugin]}
          locale={fullCalendarLocale}
          initialView="timeGridWeek"
          events={events}
          selectable={true}
          selectMirror={true}
          nowIndicator={true}
          eventStartEditable={true}
          eventResizableFromStart={true}
          eventDurationEditable={true}
          select={createSlots}
          eventChange={updateSlot}
          eventClick={openSlotModal}
          slotDuration={'00:30:00'}
          slotMinTime={`${earliestHour}:00:00`}
          slotMaxTime={`${latestHour}:00:00`}
          datesSet={() => {
            if (calendarRef.current) {
              setDisplayedWeek({
                startDate: dayjs(
                  calendarRef.current?.getApi().view.activeStart,
                ),
                endDate: dayjs(
                  calendarRef.current?.getApi().view.activeEnd,
                ).subtract(1, 'day'),
              });
            }
          }}
          eventContent={(a) => (
            <Tooltip
              overlayInnerStyle={{ maxWidth: '140px' }}
              overlayStyle={{ pointerEvents: 'none' }}
              title={
                <div>
                  <div>{a.timeText}</div>
                  <div>{a.event.title}</div>
                </div>
              }>
              <div className="fc-event-main-frame">
                <div className="fc-event-time">{a.timeText}</div>
                <div className="fc-event-title-container">
                  <div className="fc-event-title fc-event-sticky">
                    {a.event.title}
                  </div>
                </div>
              </div>
              <div className="fc-event-resizer fc-event-resizer-start" />
              <div className="fc-event-resizer fc-event-resizer-end" />
            </Tooltip>
          )}
        />

        <Outlet
          context={{
            user,
            timeSlots: getTimeSlotsOnWeek(),
            instructorCalendar,
            getCourseInstancesInTimeSpan,
            onClose: () =>
              displayedWeek &&
              fetchInstructorCalendar(
                displayedWeek.startDate,
                displayedWeek.endDate,
              ),

            onUpdate: () =>
              displayedWeek &&
              fetchInstructorCalendar(
                displayedWeek.startDate,
                displayedWeek.endDate,
              ),
            earliestHour: earliestHour,
            latestHour: latestHour,
          }}
        />
      </div>
      <>
        <div className="flex pl-2 pt-6">
          <div className="flex items-center">
            <div className="rounded-full h-4 w-4 bg-blue-800"></div>
            <span className="ml-2">
              {t('components.UserCalendar.timeSlot')}
            </span>
          </div>
          <div className="flex pl-4 items-center">
            <div className="rounded-full h-4 w-4 bg-blue-300"></div>
            <span className="ml-2">
              {t('components.UserCalendar.enterTimeSlot')}
            </span>
          </div>
          <div className="flex pl-4 items-center">
            <div className="rounded-full h-4 w-4 bg-safeLifeSuccess"></div>
            <span className="ml-2">
              {t('components.UserCalendar.confirmedCompleted')}
            </span>
          </div>
          <div className="flex pl-4 items-center">
            <div className="rounded-full h-4 w-4 bg-warningYellow"></div>
            <span className="ml-2">
              {t('components.UserCalendar.preliminary')}
            </span>
          </div>
          <div className="flex pl-4 items-center">
            <div className="rounded-full h-4 w-4 bg-warningRed"></div>
            <span className="ml-2">
              {t('components.UserCalendar.canceled')}
            </span>
          </div>
        </div>
      </>
    </div>
  );
};

export default UserCalendar;
