import InvoiceRecipient, {
  ViewInvoiceRecipient,
} from '../types/InvoiceRecipient';
import client from '../../api/client';
import BookingOrder from '../types/BookingOrder';
import ApiResponse from '../../types/ApiResponse';
import PaginationMeta from '../../types/PaginationMeta';
import PaginationParams from '../../types/PaginationParams';

type InvoiceRecipientApiFiler = {
  name?: string;
  subsidiaryCompanyId?: number;
};

export type InvoiceRecipientViewApiFiler = {
  name?: string;
  organizationNumber?: string;
  vismaCustomerId?: string;
  email?: string;
  clientCompanyIds?: number[];
  subsidiaryCompanyId?: number;
  sortBy?: 'TITLE' | 'BOOKING_ORDERS_COUNT';
  sortOrder?: 'ASC' | 'DESC';
};

type CreateInvoiceRecipientParams = {
  name: string;
  subsidiaryCompanyId: number;
  corporateId?: string;
  clientAddress1?: string;
  clientAddress2?: string;
  clientAddress3?: string;
  clientPostalCode?: string;
  clientCity?: string;
  invoiceAddress1?: string;
  invoiceAddress2?: string;
  invoiceAddress3?: string;
  invoicePostalCode?: string;
  invoiceCity?: string;
  invoiceEmail?: string;
  vismaCustomerId?: string;
};

type UpdateInvoiceRecipientParams = {
  name: string;
  subsidiaryCompanyId: number;
  corporateId?: string;
  clientAddress1?: string;
  clientAddress2?: string;
  clientAddress3?: string;
  clientPostalCode?: string;
  clientCity?: string;
  invoiceAddress1?: string;
  invoiceAddress2?: string;
  invoiceAddress3?: string;
  invoicePostalCode?: string;
  invoiceCity?: string;
  invoiceEmail?: string;
  vismaCustomerId?: string;
};

const getInvoiceRecipients = (
  params?: InvoiceRecipientApiFiler,
  paginationParams?: PaginationParams | null,
) =>
  client.get<ApiResponse<InvoiceRecipient[], PaginationMeta>>(
    `/invoice-recipient/`,
    {
      params: {
        ...params,
        ...paginationParams,
      },
    },
  );

const getViewInvoiceRecipients = (
  params?: InvoiceRecipientViewApiFiler,
  paginationParams?: PaginationParams | null,
) =>
  client.get<ApiResponse<ViewInvoiceRecipient[], PaginationMeta>>(
    `/invoice-recipient/view`,
    {
      params: {
        ...params,
        ...paginationParams,
      },
    },
  );

const getInvoiceRecipient = (id: InvoiceRecipient['id']) =>
  client.get<InvoiceRecipient>(`/invoice-recipient/${id}`);

const createInvoiceRecipient = (params: CreateInvoiceRecipientParams) =>
  client.post<InvoiceRecipient>('/invoice-recipient', params);

const updateInvoiceRecipient = (
  id: InvoiceRecipient['id'],
  params: UpdateInvoiceRecipientParams,
) => client.patch<InvoiceRecipient>(`/invoice-recipient/${id}`, params);

const getInvoiceRecipientVismaData = (
  subsidiaryCompanyId: number,
  vismaCustomerId: string,
) =>
  client.get<CreateInvoiceRecipientParams>(
    `/invoice-recipient/subsidiary-companies/${subsidiaryCompanyId}/visma-data/${vismaCustomerId}`,
  );

const deleteInvoiceRecipient = (id: InvoiceRecipient['id']) =>
  client.delete<InvoiceRecipient>(`/invoice-recipient/${id}`);

const deleteBookingOrderInvoiceRecipient = (
  invoiceRecipientId: InvoiceRecipient['id'],
  editedBookingOrderId: BookingOrder['id'] | null,
  replacementInvoiceRecipientId: InvoiceRecipient['id'] | null,
) =>
  client.delete<InvoiceRecipient>(
    `/booking-order/${editedBookingOrderId}/invoice-recipient/${invoiceRecipientId}/replacement/${replacementInvoiceRecipientId}`,
  );

const deleteBookingOrderInvoiceRecipientDryRun = (
  invoiceRecipientId: InvoiceRecipient['id'],
  editedBookingOrderId: BookingOrder['id'] | null,
) =>
  client.delete<InvoiceRecipient>(
    `/booking-order/${editedBookingOrderId}/invoice-recipient/${invoiceRecipientId}/dry-run`,
  );

export default {
  getInvoiceRecipients,
  getViewInvoiceRecipients,
  getInvoiceRecipient,
  createInvoiceRecipient,
  updateInvoiceRecipient,
  getInvoiceRecipientVismaData,
  deleteInvoiceRecipient,
  deleteBookingOrderInvoiceRecipient,
  deleteBookingOrderInvoiceRecipientDryRun,
};
