import React, { useState } from 'react';
import { message, Modal, Form, Input, Button } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import ClientCompany, { ElsaClientCompany } from '../types/ClientCompany';
import ElsaAPI from '../api/ElsaAPI';

type CreateElsaClientCompanyModalValues = {
  name: string;
};

type CreateElsaClientCompanyModalProps = {
  clientCompany: ClientCompany;
  onCreate?: (ecc: ElsaClientCompany) => void;
  onCancel: () => void;
};

const CreateElsaClientCompanyModal: React.FC<
  CreateElsaClientCompanyModalProps
> = ({ clientCompany, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();

  const onFinish = async (values: CreateElsaClientCompanyModalValues) => {
    setSubmitting(true);
    try {
      const { data } = await ElsaAPI.createElsaClientCompany({
        subsidiaryCompanyId: clientCompany.subsidiaryCompany.id,
        name: values.name,
      });

      message.success(t('components.CreateElsaClientCompanyModal.onSuccess'));
      form.resetFields();
      onCreate?.(data);
      onCancel();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={t('components.CreateElsaClientCompanyModal.createNewCompany')}
      open
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createElsaClientCompanyForm"
          htmlType="submit">
          {t('components.CreateElsaClientCompanyModal.create')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createElsaClientCompanyForm"
        form={form}
        layout="vertical"
        onFinish={(values) =>
          onFinish(values as CreateElsaClientCompanyModalValues)
        }
        initialValues={{
          name: clientCompany.name,
        }}>
        <Form.Item
          name="name"
          label={t('common.name')}
          rules={[
            {
              required: true,
              message: t('components.CreateElsaClientCompanyModal.enterName'),
            },
          ]}>
          <Input placeholder={t('common.name')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateElsaClientCompanyModal;
