import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useOutletContext, useNavigate } from 'react-router-dom';
import { message, Button, Modal, Spin, Alert } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import User from '../types/User';
import UserAPI from '../UserAPI';

type SyncCalendarModalMessage = {
  message: string;
  messageType: 'info' | 'warning' | 'error';
};

const SyncCalendarModal: React.FC = () => {
  const { user } = useOutletContext<{ user?: User }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [calendarLink, setCalendarLink] = useState<string>();
  const [modalMessage, setModalMessage] = useState<SyncCalendarModalMessage>();
  const [loadingCalendarLink, setLoadingCalendarLink] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const fetchCalendarLink = useCallback(async () => {
    if (!user) {
      return;
    }

    setLoadingCalendarLink(true);
    try {
      const response = await UserAPI.getUserCalendarLink(user.id);
      setCalendarLink(
        `${process.env.REACT_APP_API_BASE_URL}/fe/calendar-${response.data.icsToken}.ics`,
      );
      setModalMessage({
        message: t('components.SyncCalendarModal.useLinkToSync'),
        messageType: 'info',
      });
    } catch {
      setModalMessage({
        message: t('errors.somethingWentWrong'),
        messageType: 'error',
      });
    } finally {
      setLoadingCalendarLink(false);
    }
  }, [t, user]);

  const close = useCallback(() => {
    if (location.state?.fromCalendar) {
      navigate(-1);
    } else {
      navigate('..', { replace: true });
    }
  }, [location.state?.fromCalendar, navigate]);

  useEffect(() => {
    if (!user) {
      close();
    }
    fetchCalendarLink();
  }, [close, fetchCalendarLink, user]);

  const info = useCallback(() => {
    message.success(t('components.SyncCalendarModal.copiedToClipboard'));
  }, [t]);

  return (
    <Modal
      title={t('components.SyncCalendarModal.syncCalendar')}
      open
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          {t('common.close')}
        </Button>,
      ]}
      maskClosable={false}>
      {!loadingCalendarLink ? (
        <>
          {calendarLink && (
            <Button
              className="w-full truncate mb-2"
              onClick={() => {
                navigator.clipboard.writeText(calendarLink);
                info();
              }}>
              <CopyOutlined />
              {calendarLink}
            </Button>
          )}
          <Alert
            className="text-center"
            message={modalMessage?.message}
            type={modalMessage?.messageType}
          />
        </>
      ) : (
        <div className="flex justify-center items-center pt-2">
          <Spin />
        </div>
      )}
    </Modal>
  );
};

export default SyncCalendarModal;
