import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { TFunction } from 'i18next';

export const verifyImageUpload = (file: RcFile, t: TFunction) => {
  const isValidImage =
    file.type === 'image/jpeg' ||
    file.type === 'image/jpg' ||
    file.type === 'image/png';

  if (!isValidImage) {
    message.error(t('utils.upload.invalidImageType'));
  }
  return isValidImage;
};
