import React from 'react';
import { Link } from 'react-router-dom';

export interface ContentWrapperProps<RecordType> {
  record: RecordType;
  index: number;
  getRowLink?: (record: RecordType) => string;
  pageUrl?: string;
  render: (record: RecordType, index: number) => React.ReactNode;
  notLinked?: boolean;
  onClick?: (record: RecordType) => void;
}

const ContentWrapper = <RecordType,>({
  record,
  index,
  getRowLink,
  pageUrl,
  render,
  notLinked,
  onClick,
}: ContentWrapperProps<RecordType>) => {
  return (
    <>
      {getRowLink && !notLinked ? (
        <Link
          className="block pl-6 pr-1 py-3 text-gray-800"
          key={index}
          to={{
            pathname: getRowLink(record),
          }}
          state={{ prevPage: pageUrl }}>
          {render(record!, index)}
        </Link>
      ) : (
        <div
          className="block pl-6 pr-1 py-3 text-gray-800"
          key={index}
          onClick={() => onClick?.(record)}>
          {render(record!, index)}
        </div>
      )}
    </>
  );
};

export default ContentWrapper;
