import { Button, Form, Input, Modal, Popconfirm, Spin, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import CertificateAPI, {
  CertificateLanguageParams,
} from '../../certificate/CertificateAPI';
import { isAxiosError } from 'axios';
import { CertificateLanguage } from '../../certificate/types/Certificate';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

const CertificateLanguageModal: React.FC = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { certificateLanguageId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [currentUser] = useUser();
  const [deleting, setDeleting] = useState<boolean>(false);
  const { certificateLanguages, onChange } = useOutletContext<{
    certificateLanguages: CertificateLanguage[];
    onChange: () => void;
  }>();
  const certificateLanguage = certificateLanguages?.find(
    (e) => e.id === Number(certificateLanguageId),
  );

  const close = () => {
    navigate('..', { replace: true });
  };

  const onFinish = async (values: CertificateLanguageParams) => {
    setSubmitting(true);
    try {
      await CertificateAPI.updateCertificateLanguage(
        Number(certificateLanguageId),
        values,
      );
      onChange();
      close();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const deleteCertificateLanguage = async () => {
    setDeleting(true);
    try {
      await CertificateAPI.deleteCertificateLanguage(certificateLanguage!.id);
      onChange?.();
      close();
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal
      title={certificateLanguage?.name}
      open
      onCancel={close}
      onOk={form.submit}
      width="60%"
      footer={[
        <Button key="back" onClick={close}>
          {t('common.close')}
        </Button>,
        ...(userHasPermission(
          currentUser,
          Permission.CERTIFICATE_LANGUAGE_DELETE,
        )
          ? [
              <Popconfirm
                key="delete"
                title={
                  <div className="flex">
                    <p>
                      {t('components.CertificateLanguageModal.confirmDelete')}
                      &nbsp;
                    </p>
                    <em>{certificateLanguage?.name}</em>?
                  </div>
                }
                okText={t('common.yes')}
                cancelText={t('common.no')}
                onConfirm={deleteCertificateLanguage}
                placement="bottom">
                <Button type="primary" danger loading={deleting}>
                  {t('common.delete')}
                </Button>
              </Popconfirm>,
            ]
          : []),
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="updateCertificateLangugageForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      {certificateLanguage ? (
        <Form
          id="updateCertificateLangugageForm"
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values)}>
          <Form.Item
            label={t('components.CertificateLanguageModal.form.name')}
            name="name"
            initialValue={certificateLanguage?.name}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterName',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('components.CertificateLanguageModal.form.code')}
            name="code"
            initialValue={certificateLanguage?.code}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterCode',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.certificateLabel',
            )}
            name="certificateLabel"
            initialValue={certificateLanguage?.certificateLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.courseDateLabel',
            )}
            name="courseDateLabel"
            initialValue={certificateLanguage?.courseDateLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('components.CertificateLanguageModal.form.locationLabel')}
            name="locationLabel"
            initialValue={certificateLanguage?.locationLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('components.CertificateLanguageModal.form.validToLabel')}
            name="validToLabel"
            initialValue={certificateLanguage?.validToLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.instructorLabel',
            )}
            name="instructorLabel"
            initialValue={certificateLanguage?.instructorLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('components.CertificateLanguageModal.form.issuerLabel')}
            name="issuerLabel"
            initialValue={certificateLanguage?.issuerLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.authenticationLabel',
            )}
            name="authenticationLabel"
            initialValue={certificateLanguage?.authenticationLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.awardedByCompanyLabel',
            )}
            name="awardedByCompanyLabel"
            initialValue={certificateLanguage?.awardedByCompanyLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input
              placeholder={t(
                'components.CertificateLanguageModal.form.enterAwardedByCompanyLabel',
              )}
            />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.attendeeSignatureLabel',
            )}
            name="attendeeSignatureLabel"
            initialValue={certificateLanguage?.attendeeSignatureLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.instructorSignatureLabel',
            )}
            name="instructorSignatureLabel"
            initialValue={certificateLanguage?.instructorSignatureLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t(
              'components.CertificateLanguageModal.form.issuerSignatureLabel',
            )}
            name="issuerSignatureLabel"
            initialValue={certificateLanguage?.issuerSignatureLabel}
            rules={[
              {
                required: true,
                message: t(
                  'components.CertificateLanguageModal.form.enterTranslation',
                ),
              },
            ]}>
            <Input />
          </Form.Item>
        </Form>
      ) : (
        <Spin />
      )}
    </Modal>
  );
};

export default CertificateLanguageModal;
