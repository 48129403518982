import React from 'react';
import { Button, Modal, Tabs, Typography } from 'antd';
import { CopyFilled, CopyOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';

import BookingOrder from '../../bookingOrders/types/BookingOrder';
import { DisplayedCourseInstance } from '../types/CourseInstance';
import { makeContactString } from '../../bookingOrders/utils/contactPerson';
import { buildAddressString } from '../../utils/address';

type EmailTemplateProps = {
  bookingOrder?: BookingOrder;
  courseInstance?: DisplayedCourseInstance | null;
  visible: boolean;
  onCancel?: () => void;
};

dayjs.extend(localizedFormat);

const EmailTemplateModal: React.FC<EmailTemplateProps> = ({
  bookingOrder,
  courseInstance,
  visible,
  onCancel,
}) => {
  const { t } = useTranslation();

  const InstructorTemplate: React.FC<unknown> = () => {
    return (
      <div className="flex flex-col">
        <Typography.Paragraph
          className="self-end"
          copyable={{
            icon: [
              <CopyOutlined key="copy-icon" />,
              <CopyFilled key="copied-icon" />,
            ],
            tooltips: [
              t('components.CourseInstanceEmailTemplateModal.copy'),
              t('components.CourseInstanceEmailTemplateModal.copied'),
            ],
            onCopy: async () => {
              await navigator.clipboard.writeText(instructorText as string);
            },
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: (instructorText as string).replace(/\n/g, '<br/>'),
          }}
        />
        <a
          className="self-end"
          href={`mailto:${courseInstance?.instructor?.email}?subject=${t(
            'components.CourseInstanceEmailTemplateModal.bookingConfirmation',
          )} ${bookingOrder?.clientCompany.name}&body=${encodeURIComponent(
            instructorText as string,
          )}`}>
          <Button type="primary">
            {t('components.CourseInstanceEmailTemplateModal.sendEmail')}
          </Button>
        </a>
      </div>
    );
  };

  const ClientTemplate: React.FC<unknown> = () => {
    return (
      <div className="flex flex-col">
        <Typography.Paragraph
          className="self-end"
          copyable={{
            icon: [
              <CopyOutlined key="copy-icon" />,
              <CopyFilled key="copied-icon" />,
            ],
            tooltips: [
              t('components.CourseInstanceEmailTemplateModal.copy'),
              t('components.CourseInstanceEmailTemplateModal.copied'),
            ],
            onCopy: async () => {
              await navigator.clipboard.writeText(clientText as string);
            },
          }}
        />
        <p
          dangerouslySetInnerHTML={{
            __html: (clientText as string).replace(/\n/g, '<br/>'),
          }}
        />
        <a
          className="self-end"
          href={`mailto:${courseInstance?.contactPerson?.email}?subject=${t(
            'components.CourseInstanceEmailTemplateModal.bookingConfirmation',
          )}&body=${encodeURIComponent(clientText as string)}`}>
          <Button type="primary">
            {t('components.CourseInstanceEmailTemplateModal.sendEmail')}
          </Button>
        </a>
      </div>
    );
  };

  const instructorText =
    bookingOrder && courseInstance
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        t('emailTemplates.instructorEmailTemplate', {
          instructorName: courseInstance.instructor?.name.split(' ')[0],
          courseName: courseInstance.course.name,
          equipment: courseInstance.equipment
            ? t(
                'components.CourseInstanceEmailTemplateModal.conditionalFields.equipment',
                { equipment: courseInstance.equipment },
              )
            : '',
          date: dayjs(courseInstance.startsAt).format('LL'),
          startTime: dayjs(courseInstance.startsAt).format('HH:mm'),
          endTime: dayjs(courseInstance.endsAt).format('HH:mm'),
          clientCompanyName: bookingOrder.clientCompany.name,
          address: courseInstance.address,
          contactString:
            makeContactString(
              courseInstance.contactPerson?.firstName,
              courseInstance.contactPerson?.lastName,
              courseInstance.contactPerson?.phoneNumber,
            ) || '-',
          comment: courseInstance.comment || '-',
        })
      : '';

  const clientText =
    bookingOrder && courseInstance
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        t('emailTemplates.clientEmailTemplate', {
          contactName: courseInstance.contactPerson?.firstName,
          courseName: courseInstance.course.name,
          clientCompanyName: bookingOrder.clientCompany.name,
          date: dayjs(courseInstance.startsAt).format('LL'),
          startTime: dayjs(courseInstance.startsAt).format('HH:mm'),
          endTime: dayjs(courseInstance.endsAt).format('HH:mm'),
          contactString:
            makeContactString(
              courseInstance.contactPerson?.firstName,
              courseInstance.contactPerson?.lastName,
              courseInstance.contactPerson?.phoneNumber,
              courseInstance.contactPerson?.email,
            ) || '-',
          address: courseInstance.address,
          invoiceAddress:
            buildAddressString(
              bookingOrder.invoiceRecipient?.invoiceAddress1,
              bookingOrder.invoiceRecipient?.invoiceAddress2,
              bookingOrder.invoiceRecipient?.invoiceAddress3,
              bookingOrder.invoiceRecipient?.invoicePostalCode,
              bookingOrder.invoiceRecipient?.invoiceCity,
            ) || '-',
          email: bookingOrder.invoiceRecipient?.invoiceEmail
            ? t(
                'components.CourseInstanceEmailTemplateModal.conditionalFields.email',
                {
                  email: bookingOrder.invoiceRecipient?.invoiceEmail,
                },
              )
            : '',
          price: courseInstance.price
            ? `${courseInstance.price} ${courseInstance.currency.code}`
            : '-',
          corporateId: bookingOrder.invoiceRecipient?.corporateId || '-',
          invoiceReference: bookingOrder.invoiceReference || '-',
        })
      : '';

  const tabItems = [
    {
      key: 'instructor',
      label: t('common.instructor'),
      children: <InstructorTemplate />,
    },
    {
      key: 'client',
      label: t('components.CourseInstanceEmailTemplateModal.client'),
      children: <ClientTemplate />,
    },
  ];

  return (
    <Modal
      title={t('components.CourseInstanceEmailTemplateModal.emailTemplate')}
      open={visible}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}>
      <Tabs
        defaultActiveKey={courseInstance?.instructor ? 'instructor' : 'client'}
        items={tabItems}
      />
    </Modal>
  );
};

export default EmailTemplateModal;
