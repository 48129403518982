import React from 'react';
import { HeartFilled } from '@ant-design/icons';

import { useUser } from '../../user/userContext';
import Course from '../types/Course';
import { ColumnsType } from '../../types/ColumnTypes';
import Table from '../../components/Table';

type CourseResultsProps = {
  courses?: Course[];
  loading: boolean;
  getLink: (course: Course) => string;
};

const CourseResults: React.FC<CourseResultsProps> = ({
  courses,
  loading,
  getLink,
}) => {
  const [currentUser] = useUser();

  const CourseCell: React.FC<{ course: Course }> = ({ course }) => (
    <div className="flex justify-between">
      <span>{course.name}</span>
      <div>
        {currentUser?.favouriteCourses
          .map((favCourses) => (favCourses.id === course.id ? true : false))
          .includes(true) && (
          <HeartFilled
            className="pr-3"
            style={{
              fontSize: '18px',
              color: '#FF2121',
            }}
          />
        )}
      </div>
    </div>
  );

  const columns: ColumnsType<Course> = [
    {
      title: 'Titel',
      render: (course) => <CourseCell course={course} />,
    },
  ];

  return (
    <Table
      rowKey={(course) => course?.id}
      columns={columns}
      rows={courses}
      loading={loading}
      getRowLink={getLink}
    />
  );
};

export default CourseResults;
