import React from 'react';
import {
  ClockCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { BookingOrderStatus } from '../types/BookingOrder';

const BookingOrderStatusBadge: React.FC<{
  className?: string;
  status?: BookingOrderStatus;
}> = ({ className, status }) => {
  const { t } = useTranslation();

  const statusText: { [key in BookingOrderStatus]: string } = {
    [BookingOrderStatus.Ordered]: t(
      'components.BookingOrderStatusBadge.ordered',
    ),
    [BookingOrderStatus.Canceled]: t(
      'components.BookingOrderStatusBadge.canceled',
    ),
    [BookingOrderStatus.Preliminary]: t(
      'components.BookingOrderStatusBadge.preliminary',
    ),
    [BookingOrderStatus.Confirmed]: t(
      'components.BookingOrderStatusBadge.confirmed',
    ),
  };

  const statusStyle: { [key in BookingOrderStatus]: string } = {
    [BookingOrderStatus.Ordered]: 'bg-blue-100 text-blue-800',
    [BookingOrderStatus.Canceled]: 'bg-red-100 text-red-800',
    [BookingOrderStatus.Preliminary]: 'bg-yellow-100 text-yellow-800',
    [BookingOrderStatus.Confirmed]: 'bg-green-100 text-green-800',
  };

  const statusIcon: { [key in BookingOrderStatus]: React.ReactNode } = {
    [BookingOrderStatus.Ordered]: (
      <ClockCircleOutlined className="text-blue-800 pr-1" />
    ),
    [BookingOrderStatus.Canceled]: (
      <CloseOutlined className="text-red-800 pr-1" />
    ),
    [BookingOrderStatus.Preliminary]: (
      <ClockCircleOutlined className="text-yellow-800 pr-1" />
    ),
    [BookingOrderStatus.Confirmed]: (
      <CheckOutlined className="text-green-800 pr-1" />
    ),
  };

  return (
    <>
      {status ? (
        <div
          className={`h-6 px-2 inline-flex text-xs font-semibold rounded-full uppercase ${
            statusStyle[status]
          } ${className || ''}`}>
          <div className="flex items-center">
            {statusIcon[status]} {statusText[status]}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BookingOrderStatusBadge;
