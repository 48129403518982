import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';
import { Card, Row } from 'antd';
import {
  CourseInstanceStatus,
  CourseInstanceStatusData,
  CourseInstanceStatusYMode,
} from '../types/CourseInstanceStatus';
import { useInsights } from '../../context/insights';
import { twMerge } from 'tailwind-merge';

type CourseInstanceStatusDonutProps = {
  data?: CourseInstanceStatusData;
};

const CourseInstanceStatusDonut: React.FC<CourseInstanceStatusDonutProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const {
    market,
    statusYMode: yMode,
    selectedStatus: selectedCourseStatus,
    setSelectedStatus: setSelectedCourseStatus,
  } = useInsights();

  if (data === undefined) {
    return null;
  }

  const pieData = data.graphs
    .filter((g) => g.id !== CourseInstanceStatus.TOTAL)
    .map((g) => ({
      id: g.id,
      label: g.label,
      value: g.data.reduce((prev, curr) => prev + curr.y, 0),
      color: g.color,
    }));

  const total = data.graphs
    .find((g) => g.id === CourseInstanceStatus.TOTAL)!
    .data.reduce((prev, curr) => prev + curr.y, 0);

  const showRevenue = yMode === CourseInstanceStatusYMode.REVENUE;

  const formatter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: market?.defaultCurrency.code ?? 'SEK',
    maximumSignificantDigits: 3,
    notation: 'compact',
  });

  return (
    <>
      <div className="absolute top-[50%] left-[50%] transform -translate-x-1/2 flex flex-col items-center">
        <span className="text-xs font-medium">
          {yMode === CourseInstanceStatusYMode.NUMBER_OF_CLASSES
            ? t('views.InsightsV2.allCourses')
            : t('views.InsightsV2.totalRevenue')}
        </span>
        <span
          className={twMerge(
            'text-3xl font-semibold leading-10',
            showRevenue && 'text-2xl',
          )}>
          {showRevenue ? formatter.format(total) : total}
        </span>
      </div>

      <ResponsivePie
        data={pieData}
        margin={{ top: 20, right: 10, bottom: 20, left: 10 }}
        innerRadius={0.73}
        padAngle={4}
        cornerRadius={8}
        sortByValue={true}
        borderWidth={5}
        borderColor={{
          from: 'color',
        }}
        colors={pieData.map((d) => d.color)}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        tooltip={({ datum }) => (
          <Card className="shadow" bodyStyle={{ padding: '12px' }}>
            <Row className="text-sm items-center gap-2">
              <div
                className="h-3 w-3 rounded-full"
                style={{ backgroundColor: datum.color }}
              />
              <span>{datum.label}</span>
              <span className="ml-auto font-semibold">
                {showRevenue
                  ? formatter.format(Number.parseInt(datum.formattedValue))
                  : datum.formattedValue}
              </span>
            </Row>
          </Card>
        )}
        activeId={selectedCourseStatus}
        onActiveIdChange={(e) => {
          if (e) {
            setSelectedCourseStatus(e as CourseInstanceStatus);
          } else {
            setSelectedCourseStatus(null);
          }
        }}
      />
    </>
  );
};

export default CourseInstanceStatusDonut;
