import React, { useState } from 'react';
import { message, Modal, Form, Input, Button, Popconfirm, Tooltip } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import ContactPersonAPI from '../api/ContactPersonAPI';
import ContactPerson from '../types/ContactPerson';
import ConditionalParent from '../../components/ConditionalParent';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import { useUser } from '../../user/userContext';

type EditContactPersonModalProps = {
  bookingOrderId?: number;
  title?: string;
  contactPerson: ContactPerson;
  visible: boolean;
  disableDelete?: boolean;
  onUpdate?: (contactPerson?: ContactPerson) => void;
  onDelete?: () => void;
  onCancel?: () => void;
};

const EditContactPersonModal: React.FC<EditContactPersonModalProps> = ({
  bookingOrderId,
  title,
  contactPerson,
  visible,
  disableDelete,
  onUpdate,
  onDelete,
  onCancel,
}) => {
  const [currentUser] = useUser();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  const submit = () => {
    form.submit();
  };

  const onFinish = async (values: ContactPerson) => {
    setSubmitting(true);
    try {
      const { data } = await ContactPersonAPI.updateContactPerson(
        contactPerson.id,
        {
          firstName: values.firstName,
          lastName: values.lastName || undefined,
          email: values.email,
          phoneNumber: values.phoneNumber,
          note: values.note,
        },
      );
      onUpdate?.(data);
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const deleteContactPerson = async () => {
    if (disableDelete) return;
    setSubmitting(true);
    try {
      await ContactPersonAPI.deleteBookingOrderContactPersonDryRun(
        contactPerson.id,
        bookingOrderId ?? null,
      );
      onDelete?.();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const showDeleteButton = userHasPermission(
    currentUser,
    Permission.CONTACT_PERSON_DELETE,
  );

  return (
    <Modal
      title={title ?? t('components.EditContactPersonModal.editContactPerson')}
      open={visible}
      onCancel={onCancel}
      onOk={submit}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.cancel')}
        </Button>,
        ...(showDeleteButton
          ? [
              <ConditionalParent
                key="delete"
                condition={disableDelete}
                parent={(children) => (
                  <Tooltip
                    title={t(
                      'components.EditContactPersonModal.mustSaveBeforeAnotherDelete',
                    )}>
                    {children}
                  </Tooltip>
                )}>
                <Popconfirm
                  className="ml-2"
                  disabled={disableDelete}
                  title={
                    <div className="flex flex-col">
                      <p>
                        {t('components.EditContactPersonModal.confirmDelete')}
                        &nbsp;
                      </p>
                    </div>
                  }
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  onConfirm={deleteContactPerson}
                  placement="top">
                  <Button
                    type="primary"
                    danger
                    loading={submitting}
                    disabled={disableDelete}>
                    {t('common.delete')}
                  </Button>
                </Popconfirm>
              </ConditionalParent>,
            ]
          : []),
        <Button
          key="submit"
          className="ml-2"
          type="primary"
          loading={submitting}
          form="editContactPersonForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="editContactPersonForm"
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish(values as ContactPerson)}
        initialValues={{
          firstName: contactPerson.firstName,
          lastName: contactPerson.lastName,
          email: contactPerson.email,
          phoneNumber: contactPerson.phoneNumber,
          note: contactPerson.note,
        }}>
        <div className="flex gap-5 w-full">
          <Form.Item
            name="firstName"
            className="w-1/2"
            label={t('common.firstName')}
            rules={[
              {
                required: true,
                message: t('components.CreateContactPersonModal.fillOutName'),
              },
            ]}>
            <Input placeholder={t('common.firstName')} />
          </Form.Item>
          <Form.Item
            name="lastName"
            className="w-1/2"
            label={t('common.lastName')}>
            <Input placeholder={t('common.lastName')} />
          </Form.Item>
        </div>
        <Form.Item
          name="email"
          label={t('common.email')}
          rules={[
            {
              required: true,
              type: 'email',
              message: t('components.EditContactPersonModal.invalidEmail'),
            },
          ]}>
          <Input placeholder={t('common.email')} />
        </Form.Item>
        <Form.Item name="phoneNumber" label={t('common.phoneNumber')}>
          <Input placeholder={t('common.phoneNumber')} />
        </Form.Item>
        <Form.Item name="note" label={t('common.note')}>
          <Input placeholder={t('common.note')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditContactPersonModal;
