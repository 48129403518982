import React, { useMemo, useState } from 'react';
import {
  useLocation,
  useOutletContext,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { message, Modal, Form, Button, TimePicker, Popconfirm } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import TimeSlot from '../types/TimeSlot';
import User from '../types/User';
import UserAPI from '../UserAPI';
import * as DateUtils from '../../utils/date';
import { formatCourseInstanceString } from '../../courseInstance/courseInstanceUtils';
import NumberPicker from '../../components/NumberPicker';
import InstructorCalendarDate from '../types/InstructorCalendarDate';
import { CourseInstanceLimitBoundary } from '../../courseInstance/types/CourseInstanceLimit';

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

type TimeSlotModalContext = {
  user: User;
  timeSlots: TimeSlot[];
  instructorCalendar: InstructorCalendarDate[];
  getCourseInstancesInTimeSpan: (from: Dayjs, to: Dayjs) => number;
  courseInstanceLimit: number;
  onClose?: () => void;
  onUpdate?: () => void;
};

const TimeSlotModal: React.FC = () => {
  const { timeSlotId } = useParams();
  const id = useMemo(() => Number(timeSlotId), [timeSlotId]);
  const {
    user,
    timeSlots,
    instructorCalendar,
    getCourseInstancesInTimeSpan,
    onClose,
    onUpdate,
  } = useOutletContext<TimeSlotModalContext>();
  const timeSlot = timeSlots?.find((slot) => slot.id === id);

  const courseInstanceLimit =
    instructorCalendar?.find((day) =>
      dayjs(day.date).isSame(timeSlot?.startsAt, 'day'),
    )?.courseInstanceLimit ?? user?.defaultCourseInstanceLimit;
  const nCourseInstancesOnTimeSlot = getCourseInstancesInTimeSpan?.(
    dayjs(timeSlot?.startsAt),
    dayjs(timeSlot?.endsAt),
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const { t } = useTranslation();

  if (!timeSlot) return null;

  const close = () => {
    if (location.state?.fromCalendar) {
      navigate(-1);
    } else {
      navigate('..', { replace: true });
    }
  };

  const submit = () => {
    form.submit();
  };

  type FormValues = {
    time: [dayjs.Dayjs, dayjs.Dayjs];
    courseInstanceLimit: number;
  };

  const onFinish = async (values: FormValues) => {
    setSubmitting(true);
    try {
      await UserAPI.updateUserTimeSlot(user.id, timeSlot.id, {
        courseInstanceLimit: values.courseInstanceLimit,
        startsAt: DateUtils.mergeDateTime(
          dayjs(timeSlot.startsAt),
          values.time[0],
        ).toISOString(),
        endsAt: DateUtils.mergeDateTime(
          dayjs(timeSlot.endsAt),
          values.time[1],
        ).toISOString(),
      });
      onClose?.();
      close();
    } catch {
      message.error(t('errors.somethingWentWrong'));
    } finally {
      setSubmitting(false);
    }
  };

  const deleteTimeSlot = async (timeSlotId: number) => {
    setDeleting(true);
    try {
      await UserAPI.deleteUserTimeSlot(user.id, timeSlotId);
      onUpdate?.();
    } finally {
      setDeleting(false);
      close();
      onClose?.();
    }
  };

  return (
    <>
      <Modal
        title={capitalize(dayjs(timeSlot.startsAt).format('dddd D MMMM'))}
        open
        onCancel={close}
        onOk={submit}
        footer={
          <div className="flex justify-end">
            {nCourseInstancesOnTimeSlot > 0 ? (
              <Popconfirm
                title={
                  <div>
                    <div>
                      {t('components.TimeSlotModal.instancesWithinSlot', {
                        courseInstances: `${nCourseInstancesOnTimeSlot} ${formatCourseInstanceString(
                          nCourseInstancesOnTimeSlot,
                          t,
                        )}`,
                      })}
                    </div>
                    <div>
                      {t('components.TimeSlotModal.instancesWontBeAltered')}
                    </div>
                    <div className="pt-3">
                      {t('components.TimeSlotModal.stillWishToRemove')}
                    </div>
                  </div>
                }
                okText={t('common.yes')}
                cancelText={t('common.no')}
                onConfirm={() => deleteTimeSlot(timeSlot.id)}>
                <Button key="delete" type="primary" danger loading={deleting}>
                  {t('common.remove')}
                </Button>
              </Popconfirm>
            ) : (
              <Button
                key="delete"
                type="primary"
                danger
                loading={deleting}
                onClick={() => deleteTimeSlot(timeSlot.id)}>
                {t('common.remove')}
              </Button>
            )}
            <Button key="back" onClick={close}>
              {t('common.close')}
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={submitting}
              form="timeSlotForm"
              htmlType="submit">
              {t('common.save')}
            </Button>
          </div>
        }
        maskClosable={false}>
        <Form
          id="timeSlotForm"
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={(values) => onFinish(values as FormValues)}
          layout="vertical">
          <Form.Item
            label={t('common.time') + ':'}
            name="time"
            initialValue={[dayjs(timeSlot.startsAt), dayjs(timeSlot.endsAt)]}
            rules={[
              {
                required: true,
                message: t('components.TimeSlotModal.selectTime'),
              },
            ]}>
            <TimePicker.RangePicker
              changeOnBlur={true}
              minuteStep={15}
              format="HH:mm"
            />
          </Form.Item>
          <Form.Item
            label={t('components.TimeSlotModal.maxInstancesThisDay') + ':'}
            className="course-instance-limit-form-item"
            name="courseInstanceLimit"
            initialValue={courseInstanceLimit}>
            <NumberPicker
              size="large"
              min={CourseInstanceLimitBoundary.Min}
              max={CourseInstanceLimitBoundary.Max}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TimeSlotModal;
