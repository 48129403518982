import Course from '../../course/types/Course';
import Language from '../../types/Language';
import Location, { Geolocation } from '../../types/Location';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import Market from '../../market/types/Market';

import { Permission } from './Permission';
import { ImageMedia } from '../../types/ImageMedia';

export default interface User {
  id: number | null;
  roles: UserRole[];
  courses: Course[];
  locations: Location[];
  subsidiaryCompanies: SubsidiaryCompany[];
  favouriteCourses: Course[];
  favoriteInstructorIds: number[];
  email: string;
  name: string;
  phoneNumber?: string | null;
  internal: boolean;
  isAdmin: boolean;
  isInstructor: boolean;
  isOnlyInstructor: boolean;
  isSeller: boolean;
  maxTravelDistance: number;
  defaultCourseInstanceLimit: number;
  preferredSubsidiaryCompany?: SubsidiaryCompany;
  preferredLanguage?: Language;
  permissions?: Permission[];
  bookerNote?: string | null;
  market: Market;
  certificateSignature?: ImageMedia;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface MinimalUser {
  id: number | null;
  email: string;
  name: string;
  phoneNumber?: string | null;
  internal: boolean;
  isInstructor: boolean;
  isAdmin: boolean;
  isSeller: boolean;
  maxTravelDistance: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface SearchResultUser {
  id: number;
  location: Geolocation;
  email: string;
  name: string;
  maxTravelDistance: number;
  internal: boolean;
}

export interface InstructorMarkerData {
  id: number;
  userId: number | null;
  location: Geolocation;
  email: string;
  name: string;
  phoneNumber?: string | null;
  internal: boolean;
}

export interface CardDataUser {
  id: User['id'];
  email: User['email'];
  name: User['name'];
  internal: User['internal'];
}

export enum UserRole {
  Admin = 'admin',
  Instructor = 'instructor',
  Seller = 'seller',
  Booker = 'booker',
}
