import React, { useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  Form,
  Modal,
  Button,
  Popconfirm,
  Row,
  Col,
  Input,
  message,
  Upload,
  UploadProps,
} from 'antd';
import CertificateAPI from '../../certificate/CertificateAPI';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../user/userContext';
import { isAxiosError } from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { ImageMedia } from '../../types/ImageMedia';
import { verifyImageUpload } from '../../utils/upload';

const CertificateImageModal: React.FC = () => {
  const [deleting, setDeleting] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const { t } = useTranslation();
  const [currentUser] = useUser();
  const navigate = useNavigate();

  const { certificateImages, onChange } = useOutletContext<{
    certificateImages: ImageMedia[];
    onChange: () => void;
  }>();
  const [form] = Form.useForm();

  const { certificateImageId } = useParams();

  const certificateImage = certificateImages?.find(
    (e) => e.id === Number(certificateImageId),
  );

  const deleteCertificateImage = async () => {
    setDeleting(true);
    try {
      await CertificateAPI.deleteCertificateImage(certificateImage!.id);
      onChange?.();
      close();
    } finally {
      setDeleting(false);
    }
  };

  const close = () => {
    navigate('..', { replace: true });
  };

  const onFinish = async ({ name }: { name: string }) => {
    setSubmitting(true);
    try {
      const fmData = new FormData();
      fmData.append('name', name);
      if (!!file) {
        fmData.append('image', file);
        const extension = file?.name.split('.').pop();
        if (!!extension) {
          fmData.append('extension', extension);
        }
      }
      await CertificateAPI.updateCertificateImage(certificateImage!.id, fmData);
      onChange();
      close();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      setFile(undefined);
    },
    beforeUpload: (file) => {
      if (!verifyImageUpload(file, t)) {
        return Upload.LIST_IGNORE;
      }

      setFile(file);
      return false;
    },
    maxCount: 1,
    listType: 'picture',
  };

  return (
    <Modal
      title={certificateImage?.name}
      open
      onCancel={close}
      onOk={form.submit}
      width="60%"
      footer={[
        <Button key="back" onClick={close}>
          {t('common.close')}
        </Button>,
        ...(userHasPermission(currentUser, Permission.CERTIFICATE_IMAGE_DELETE)
          ? [
              <Popconfirm
                key="delete"
                title={
                  <div className="flex">
                    <p>
                      {t('components.CertificateImageModal.confirmDelete')}
                      &nbsp;
                    </p>
                    <em>{certificateImage?.name}</em>?
                  </div>
                }
                okText={t('common.yes')}
                cancelText={t('common.no')}
                onConfirm={deleteCertificateImage}
                placement="bottom">
                <Button type="primary" danger loading={deleting}>
                  {t('common.delete')}
                </Button>
              </Popconfirm>,
            ]
          : []),
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="updateCertificateImageForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          {certificateImage && (
            <img
              src={certificateImage.url}
              className="w-full border-dashed border-gray-500"
            />
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form
            id="updateCertificateImageForm"
            layout="vertical"
            form={form}
            onFinish={(values) => onFinish(values)}>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label={t('components.CertificateImageModal.form.name')}
                  name="name"
                  initialValue={certificateImage?.name}
                  rules={[
                    {
                      required: true,
                      message: t(
                        'components.CertificateImageModal.form.enterName',
                      ),
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>
                    {t('common.selectFile')}
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default CertificateImageModal;
