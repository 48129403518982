import Currency from '../types/Currency';

import client from './client';

export const getCurrencies = (code?: string) =>
  client.get<Currency[]>(`/currencies`, {
    params: {
      code,
    },
  });

export const getCurrency = (id: Currency['id']) =>
  client.get<Currency>(`/currencies/${id}`);
