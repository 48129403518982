import { HeartFilled } from '@ant-design/icons';
import React from 'react';

import Course from '../course/types/Course';
import User from '../user/types/User';

type CourseOptionProps = {
  user: User | null;
  course?: Course;
  size?: 'small' | 'large';
};

const CourseOption: React.FC<CourseOptionProps> = ({ user, course, size }) => (
  <>
    <div>
      {user?.favouriteCourses
        .map((favCourses) => (favCourses.id === course?.id ? true : false))
        .includes(true) ? (
        <div className="flex justify-between items-center">
          <span>{course?.name}</span>
          <HeartFilled
            style={{
              fontSize: `${
                size === 'large' ? '20px' : size === 'small' ? '18px' : '18px'
              }`,
              paddingBottom: `${
                size === 'large' ? '3px' : size === 'small' ? '0' : '0'
              }`,
              color: '#FF2121',
            }}
            className="ml-1"
          />
        </div>
      ) : (
        course?.name
      )}
    </div>
  </>
);

export default CourseOption;
