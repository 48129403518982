import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  CourseInstanceAttendee,
  CourseInstanceAttendeeStatus,
  DisplayedCourseInstance,
} from '../types/CourseInstance';
import Passed from '../../resources/img/passed.svg';
import Failed from '../../resources/img/failed.svg';
import Participated from '../../resources/img/participated.svg';

import CourseInstanceAttendanceListModal from './CourseInstanceAttendeesListModal';
import DownloadCertificateDropdown from './DownloadCertificateDropdown';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

type CourseInstanceAttendeeFooterProps = {
  courseInstance: DisplayedCourseInstance;
  attendeesQuery: UseQueryResult<
    AxiosResponse<CourseInstanceAttendee[]> | undefined
  >;
};

const CourseInstanceAttendeeFooter: React.FC<
  CourseInstanceAttendeeFooterProps
> = ({ courseInstance, attendeesQuery }) => {
  const [showAttendeesListModal, setShowAttendeesListModal] = useState(false);

  const { t } = useTranslation();

  const attendees = attendeesQuery.data?.data;

  const attendeesStatusAccumulatorEmpty = {
    participated: 0,
    passed: 0,
    total: 0,
  };

  const attendeesStatusAccumulation =
    attendees?.reduce((acc, attendee) => {
      switch (attendee.status) {
        case CourseInstanceAttendeeStatus.Passed:
          return {
            participated: acc.participated + 1,
            passed: acc.passed + 1,
            total: acc.total + 1,
          };

        case CourseInstanceAttendeeStatus.Failed:
          return {
            ...acc,
            participated: acc.participated + 1,
            total: acc.total + 1,
          };

        case CourseInstanceAttendeeStatus.DidNotParticipate:
        default:
          return {
            ...acc,
            total: acc.total + 1,
          };
      }
    }, attendeesStatusAccumulatorEmpty) ?? attendeesStatusAccumulatorEmpty;

  const hasEnded = dayjs().isAfter(dayjs(courseInstance.endsAt));

  const renderAttendeeSummary = () => {
    if (!attendees) {
      return null;
    }

    return hasEnded ? (
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-2">
          <img src={Participated} />
          {t('components.CourseInstanceCard.participatedAttendees', {
            attendeeCount: attendeesStatusAccumulation.participated,
            totalCount: attendeesStatusAccumulation.total,
          })}
        </div>
        <div className="flex flex-row items-center gap-2">
          <img src={Passed} />
          {t('components.CourseInstanceCard.participatedAndPassedAttendees', {
            count: attendeesStatusAccumulation.passed,
          })}
        </div>
        <div className="flex flex-row items-center gap-2">
          <img src={Failed} />
          {t('components.CourseInstanceCard.participatedAndFailedAttendees', {
            count:
              attendeesStatusAccumulation.participated -
              attendeesStatusAccumulation.passed,
          })}
        </div>
      </div>
    ) : (
      <div>
        {t('components.CourseInstanceCard.attendees', {
          count: attendees.length,
        })}
      </div>
    );
  };

  return (
    <>
      <div className="border-solid border-t border-b-0 border-gray-300 mx-6" />
      <div className="w-100 py-4 px-6 flex justify-between items-end">
        <div className="flex gap-4">
          {!attendeesQuery.isError ? (
            <div className="flex flex-col">
              <p className="text-gray-900 font-semibold mb-2">
                {t('components.CourseInstanceCard.attendeesTitle')}
              </p>
              <div className="flex flex-row items-center">
                {attendeesQuery.isFetching ? <Spin /> : renderAttendeeSummary()}
              </div>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-4">
              <Button
                className="rounded h-10 font-medium"
                onClick={() => attendeesQuery.refetch()}>
                {t('common.tryAgain')}
              </Button>
              <span>
                {t('components.CourseInstanceCard.couldNotFetchAttendees')}
              </span>
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <Button
            disabled={!courseInstance.id || !attendeesQuery.isSuccess}
            className="border-0 bg-blue-50 shadow-none rounded h-10 font-medium"
            onClick={() => setShowAttendeesListModal(true)}>
            {t('components.CourseInstanceCard.attendeeList')}
          </Button>
          <DownloadCertificateDropdown
            selectedAttendees={
              attendees?.filter(
                (it) => it.status === CourseInstanceAttendeeStatus.Passed,
              ) || []
            }
            courseInstance={courseInstance}
          />
        </div>
      </div>
      {courseInstance.id && (
        <CourseInstanceAttendanceListModal
          open={showAttendeesListModal}
          onClose={() => setShowAttendeesListModal(false)}
          id={courseInstance.id!}
          courseInstance={courseInstance}
          attendeesQuery={attendeesQuery}
        />
      )}
    </>
  );
};

export default CourseInstanceAttendeeFooter;
