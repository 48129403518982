import React from 'react';

type ContentCardProps = {
  children: React.ReactNode;
  className?: string;
};

const ContentCard: React.FC<ContentCardProps> = ({ children, className }) => (
  <div
    className={`bg-white rounded-md shadow-md overflow-hidden my-5 px-4 sm:px-6 py-5 ${className}`}>
    {children}
  </div>
);

export default ContentCard;
