import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import Paragraph from 'antd/lib/typography/Paragraph';
import { FilterFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

type CourseInstanceViewInstructionsProps = {
  visible: boolean;
  onCancel?: () => void;
};

const CourseInstanceViewInstructions: React.FC<
  CourseInstanceViewInstructionsProps
> = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('components.CourseInstanceViewInstructions.instructions')}
      open={visible}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}>
      <h3>{t('components.CourseInstanceViewInstructions.filter')}</h3>
      <Paragraph>
        {t('components.CourseInstanceViewInstructions.filterInstructions.1')}{' '}
        <Button
          type="primary"
          shape="circle"
          size="small"
          icon={
            <FilterFilled
              style={{
                fontSize: `${'14px'}`,
              }}
            />
          }
        />{' '}
        {t('components.CourseInstanceViewInstructions.filterInstructions.2')}
      </Paragraph>
      <h3> {t('components.CourseInstanceViewInstructions.calendar')}</h3>
      <Paragraph>
        {t('components.CourseInstanceViewInstructions.calendarInstructions.1')}{' '}
        {t('components.CourseInstanceViewInstructions.calendarInstructions.2')}
      </Paragraph>
      <h3>{t('components.CourseInstanceViewInstructions.listView')}</h3>
      <Paragraph>
        {t('components.CourseInstanceViewInstructions.listViewInstructions.1')}{' '}
        {t('components.CourseInstanceViewInstructions.listViewInstructions.2')}{' '}
        {t('components.CourseInstanceViewInstructions.listViewInstructions.3')}{' '}
        {t('components.CourseInstanceViewInstructions.listViewInstructions.4')}
      </Paragraph>
    </Modal>
  );
};

export default CourseInstanceViewInstructions;
