import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationSv from './sv/translation.json';
import translationEn from './en/translation.json';
import clientEmailTemplateTextSv from './sv/clientEmailTemplate.txt';
import instructorEmailTemplateTextSv from './sv/instructorEmailTemplate.txt';
import clientEmailTemplateTextEn from './en/clientEmailTemplate.txt';
import instructorEmailTemplateTextEn from './en/instructorEmailTemplate.txt';

export const resources = {
  sv: {
    translation: {
      ...translationSv,
      emailTemplates: {
        clientEmailTemplate: clientEmailTemplateTextSv,
        instructorEmailTemplate: instructorEmailTemplateTextSv,
      },
    },
  },
  en: {
    translation: {
      ...translationEn,
      emailTemplates: {
        clientEmailTemplate: clientEmailTemplateTextEn,
        instructorEmailTemplate: instructorEmailTemplateTextEn,
      },
    },
  },
} as const;

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['navigator'] },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
