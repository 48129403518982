import React, { useMemo } from 'react';
import { CarFilled, ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  DistanceCategory,
  formatDistance,
  getDistanceCategory,
} from '../../utils/distance';
import Mileage from '../types/Mileage';
import { formatTravelTime } from '../../utils/date';

type DistanceBadgeProps = {
  distance: number;
  mileage?: Mileage;
  maxTravelDistance: number;
};

const DistanceBadge: React.FC<DistanceBadgeProps> = ({
  distance,
  mileage,
  maxTravelDistance,
}) => {
  const { t } = useTranslation();

  const badgeColorClassNames = useMemo(() => {
    const usedDistanceInMeters = mileage?.distanceInMeters ?? distance;
    switch (
      getDistanceCategory(usedDistanceInMeters, maxTravelDistance * 1000)
    ) {
      case DistanceCategory.WithinLimit:
        return 'bg-green-100 text-green-800';
      case DistanceCategory.SlightlyOverLimit:
        return 'bg-yellow-100 text-yellow-800';
      case DistanceCategory.ExceedsLimit:
        return 'bg-red-100 text-red-800';
    }
  }, [distance, maxTravelDistance, mileage?.distanceInMeters]);

  return (
    <div className="flex">
      {mileage && mileage.distanceInMeters !== 0 ? (
        <div
          className={`flex flex-col px-2 text-xs font-semibold rounded-full shrink ${badgeColorClassNames}`}>
          <div>
            <CarFilled className="pr-1" />
            {formatDistance(mileage.distanceInMeters, t)}
          </div>
          <div>
            <ClockCircleOutlined className="pr-1" />
            {formatTravelTime(mileage.durationInSeconds)}
          </div>
        </div>
      ) : (
        <div
          className={`px-2 text-xs font-semibold rounded-full ${badgeColorClassNames}`}>
          {formatDistance(distance, t)}
        </div>
      )}
    </div>
  );
};

export default DistanceBadge;
