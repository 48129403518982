import {
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Spin,
  Tooltip,
  message,
} from 'antd';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';

import ConditionalParent from '../../components/ConditionalParent';
import { Permission } from '../../user/types/Permission';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import InvoiceRecipientAPI from '../api/InvoiceRecipientAPI';
import InvoiceRecipient from '../types/InvoiceRecipient';
import BookingOrder from '../types/BookingOrder';
import BookingOrderAPI from '../api/BookingOrderAPI';

import BookingOrderCard from './BookingOrderCard';

type EditInvoiceRecipientModalProps = {
  bookingOrderId?: number;
  invoiceRecipient?: InvoiceRecipient;
  visible: boolean;
  setInvoiceRecipient?: (
    InvoiceRecipient: InvoiceRecipient | undefined,
  ) => void;
  onDelete?: () => void;
  disableDelete?: boolean;
  onCancel?: () => void;
  invoiceReference?: string;
  updateInvoiceReference?: (newInvoiceReference: string) => void;
  setShowEditInvoiceRecipientModal: (value: boolean) => void;
  showConnectedBookingOrders?: boolean;
};

const EditInvoiceRecipientModal: React.FC<EditInvoiceRecipientModalProps> = ({
  bookingOrderId,
  invoiceRecipient: invoiceRecipientProp,
  visible,
  setInvoiceRecipient,
  onDelete,
  disableDelete,
  onCancel,
  invoiceReference,
  updateInvoiceReference,
  setShowEditInvoiceRecipientModal,
  showConnectedBookingOrders = false,
}) => {
  const { invoiceRecipients, onClose } =
    useOutletContext<{
      invoiceRecipients?: InvoiceRecipient[];
      onClose?: () => void;
    }>() ?? {};
  const { invoiceRecipientId } = useParams();
  const [currentUser] = useUser();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [searchedVismaCustomerId, setSearchedVismaCustomerId] =
    useState<string>();
  const [connectedBookingOrders, setConnectedBookingOrders] = useState<
    BookingOrder[]
  >([]);
  const [loadingBookingOrders, setLoadingBookingOrders] = useState(
    showConnectedBookingOrders,
  );

  const { t } = useTranslation();

  const invoiceRecipient =
    invoiceRecipientProp ??
    invoiceRecipients?.find(({ id }) => id === Number(invoiceRecipientId));

  useEffect(() => {
    if (!currentUser || !showConnectedBookingOrders || !invoiceRecipient)
      return;

    setLoadingBookingOrders(true);
    BookingOrderAPI.getBookingOrders({
      invoiceRecipientIds: [invoiceRecipient.id],
    })
      .then(({ data }) => setConnectedBookingOrders(data.data))
      .finally(() => setLoadingBookingOrders(false));
  }, [showConnectedBookingOrders, currentUser, invoiceRecipient]);

  const submit = () => {
    form.submit();
  };

  useEffect(() => {
    if (!invoiceRecipient) return;
    form.setFieldsValue({
      name: invoiceRecipient.name,
      subsidiaryCompanyId: invoiceRecipient.subsidiaryCompany.id,
      corporateId: invoiceRecipient.corporateId,
      clientAddress1: invoiceRecipient.clientAddress1,
      clientAddress2: invoiceRecipient.clientAddress2,
      clientAddress3: invoiceRecipient.clientAddress3,
      clientPostalCode: invoiceRecipient.clientPostalCode,
      clientCity: invoiceRecipient.clientCity,
      invoiceAddress1: invoiceRecipient.invoiceAddress1,
      invoiceAddress2: invoiceRecipient.invoiceAddress2,
      invoiceAddress3: invoiceRecipient.invoiceAddress3,
      invoicePostalCode: invoiceRecipient.invoicePostalCode,
      invoiceCity: invoiceRecipient.invoiceCity,
      invoiceEmail: invoiceRecipient.invoiceEmail,
      vismaCustomerId: invoiceRecipient.vismaCustomerId,
      invoiceReference: invoiceReference,
    });
    setSearchedVismaCustomerId(invoiceRecipient.vismaCustomerId);
  }, [form, invoiceRecipient, invoiceReference]);

  interface FormValues extends InvoiceRecipient {
    invoiceReference: string;
  }

  const onFinish = async (values: FormValues) => {
    if (!invoiceRecipient) return;
    if (!currentUser || !currentUser.preferredSubsidiaryCompany) {
      return;
    }
    setSubmitting(true);
    try {
      const { data } = await InvoiceRecipientAPI.updateInvoiceRecipient(
        invoiceRecipient.id,
        {
          name: values.name,
          subsidiaryCompanyId: currentUser.preferredSubsidiaryCompany.id,
          corporateId: values.corporateId,
          clientAddress1: values.clientAddress1,
          clientAddress2: values.clientAddress2,
          clientAddress3: values.clientAddress3,
          clientPostalCode: values.clientPostalCode,
          clientCity: values.clientCity,
          invoiceAddress1: values.invoiceAddress1,
          invoiceAddress2: values.invoiceAddress2,
          invoiceAddress3: values.invoiceAddress3,
          invoicePostalCode: values.invoicePostalCode,
          invoiceCity: values.invoiceCity,
          invoiceEmail: values.invoiceEmail,
          vismaCustomerId: searchedVismaCustomerId,
        },
      );
      setInvoiceRecipient?.(data);
      updateInvoiceReference?.(values.invoiceReference);
      setShowEditInvoiceRecipientModal(false);
      onClose?.();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const deleteInvoiceRecipient = async () => {
    if (!invoiceRecipient) return;
    setSubmitting(true);
    try {
      await InvoiceRecipientAPI.deleteBookingOrderInvoiceRecipientDryRun(
        invoiceRecipient.id,
        bookingOrderId ?? null,
      );
      onDelete?.();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const fetchFromVisma = async (vismaCustomerId: string) => {
    if (!invoiceRecipient) return;
    if (!currentUser || !currentUser.preferredSubsidiaryCompany) {
      return;
    }
    setSubmitting(true);
    try {
      const { data } = await InvoiceRecipientAPI.getInvoiceRecipientVismaData(
        invoiceRecipient.subsidiaryCompany.id ?? null,
        vismaCustomerId,
      );

      form.setFieldsValue({
        corporateId: data.corporateId,
        name: data.name,
        vismaCustomerId: data.vismaCustomerId,
        clientAddress1: data.clientAddress1,
        clientAddress2: data.clientAddress2,
        clientAddress3: data.clientAddress3,
        clientPostalCode: data.clientPostalCode,
        clientCity: data.clientCity,
        invoiceAddress1: data.invoiceAddress1,
        invoiceAddress2: data.invoiceAddress2,
        invoiceAddress3: data.invoiceAddress3,
        invoiceCity: data.invoiceCity,
        invoicePostalCode: data.invoicePostalCode,
        invoiceEmail: data.invoiceEmail,
      });
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const autofillInvoiceAddressFields = () => {
    form.setFieldsValue({
      invoiceAddress1: form.getFieldValue('clientAddress1'),
      invoiceAddress2: form.getFieldValue('clientAddress2'),
      invoiceAddress3: form.getFieldValue('clientAddress3'),
      invoicePostalCode: form.getFieldValue('clientPostalCode'),
      invoiceCity: form.getFieldValue('clientCity'),
    });
  };

  const AutofillButton: React.FC = () => {
    return (
      <Button onClick={autofillInvoiceAddressFields}>
        {t('components.EditInvoiceRecipientModal.useCustomerAddress')}
      </Button>
    );
  };

  const showDeleteButton = userHasPermission(
    currentUser,
    Permission.INVOICE_RECIPIENT_DELETE,
  );

  return invoiceRecipient ? (
    <Modal
      title={t('components.EditInvoiceRecipientModal.editInvoiceRecipient')}
      width={1000}
      open={visible}
      onCancel={() => {
        onCancel?.();
        onClose?.();
      }}
      onOk={submit}
      zIndex={0}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onCancel?.();
            onClose?.();
          }}>
          {t('common.close')}
        </Button>,
        ...(showDeleteButton && onDelete
          ? [
              <ConditionalParent
                key="delete"
                condition={disableDelete}
                parent={(children) => (
                  <Tooltip
                    title={t(
                      'components.EditInvoiceRecipientModal.mustSaveBeforeAnotherDelete',
                    )}
                    placement="top">
                    {children}
                  </Tooltip>
                )}>
                <Popconfirm
                  className="ml-2"
                  disabled={disableDelete}
                  title={
                    <div className="flex">
                      <p>
                        {t(
                          'components.EditInvoiceRecipientModal.confirmDelete',
                        )}
                        &nbsp;
                      </p>
                    </div>
                  }
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  onConfirm={deleteInvoiceRecipient}>
                  <Button
                    type="primary"
                    danger
                    loading={submitting}
                    disabled={disableDelete}>
                    {t('common.delete')}
                  </Button>
                </Popconfirm>
              </ConditionalParent>,
            ]
          : []),
        <Button
          key="submit"
          className="ml-2"
          type="primary"
          loading={submitting}
          form="editInvoiceRecipientForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="editInvoiceRecipientForm"
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish(values as FormValues)}>
        <div className="md:grid grid-cols-2 gap-x-8 border-solid border-gray-200 border-0 border-b-2">
          <Form.Item
            name="name"
            label={t('components.EditInvoiceRecipientModal.companyName')}
            rules={[
              {
                required: true,
                message: t(
                  'components.EditInvoiceRecipientModal.enterCompanyName',
                ),
              },
            ]}>
            <Input
              placeholder={t(
                'components.EditInvoiceRecipientModal.companyName',
              )}
            />
          </Form.Item>
          <Form.Item
            name="corporateId"
            label={t('common.corporateId')}
            rules={[
              {
                pattern: /(^[0-9]{10}$|^[0-9]{6}-[0-9]{4}$)/,
                message: t(
                  'components.EditInvoiceRecipientModal.writeValidCorporateId',
                ),
              },
            ]}>
            <Input placeholder={t('common.corporateId')} />
          </Form.Item>
          {!!invoiceRecipient.vismaCustomerId ? (
            <Form.Item
              name="vismaCustomerId"
              label={t(
                'components.EditInvoiceRecipientModal.vismaCustomerNumber',
              )}>
              <Input
                disabled
                placeholder={t(
                  'components.EditInvoiceRecipientModal.vismaCustomerNumber',
                )}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label={t(
                  'components.EditInvoiceRecipientModal.vismaCustomerNumber',
                )}>
                <div className="flex gap-2">
                  <Input
                    onChange={(e) => setSearchedVismaCustomerId(e.target.value)}
                    placeholder={t(
                      'components.EditInvoiceRecipientModal.vismaCustomerNumber',
                    )}
                  />
                  <Tooltip
                    placement="top"
                    title={
                      !searchedVismaCustomerId &&
                      t(
                        'components.EditInvoiceRecipientModal.enterVismaCustomerNumber',
                      )
                    }>
                    {userHasPermission(
                      currentUser,
                      Permission.VISMA_CUSTOMER_READ,
                    ) && (
                      <Button
                        disabled={submitting || !searchedVismaCustomerId}
                        onClick={() =>
                          searchedVismaCustomerId &&
                          fetchFromVisma(searchedVismaCustomerId)
                        }>
                        {t(
                          'components.EditInvoiceRecipientModal.fetchFromVisma',
                        )}
                      </Button>
                    )}
                  </Tooltip>
                </div>
              </Form.Item>
            </>
          )}
          {
            // If invoiceReference is an empty string then this item shall render
            invoiceReference !== undefined && (
              <Form.Item
                name="invoiceReference"
                label={t('common.invoiceReference')}>
                <Input placeholder={t('common.invoiceReference')} />
              </Form.Item>
            )
          }
        </div>
        <div className="grid grid-cols-2 pt-4">
          <div>
            <div className="text-lg font-semibold pb-2">
              {t('components.EditInvoiceRecipientModal.customerAddress')}
            </div>
            <Form.Item
              className="w-4/5 mb-4"
              name="clientAddress1"
              label={t('common.address')}>
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.rowOne')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="clientAddress2">
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.rowTwo')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="clientAddress3">
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.rowThree')}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="clientPostalCode"
              label={t('components.EditInvoiceRecipientModal.postalCode')}
              rules={[
                {
                  pattern: /(^[0-9]{5}$|^[0-9]{3}(\s|-)[0-9]{2}$)/,
                  message: t(
                    'components.EditInvoiceRecipientModal.writeValidPostalCode',
                  ),
                },
              ]}>
              <Input
                placeholder={t(
                  'components.EditInvoiceRecipientModal.postalCode',
                )}
                maxLength={6}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="clientCity"
              label={t('components.EditInvoiceRecipientModal.city')}>
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.city')}
              />
            </Form.Item>
          </div>
          <div>
            <div className="pb-2 flex justify-between w-4/5">
              <span className="text-lg font-semibold">
                {t('components.EditInvoiceRecipientModal.billingAddress')}
              </span>
              <AutofillButton />
            </div>
            <Form.Item
              className="w-4/5 mb-4"
              name="invoiceAddress1"
              label={t('common.address')}>
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.rowOne')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="invoiceAddress2">
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.rowTwo')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="invoiceAddress3">
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.rowThree')}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="invoicePostalCode"
              label={t('components.EditInvoiceRecipientModal.postalCode')}
              rules={[
                {
                  pattern: /(^[0-9]{5}$|^[0-9]{3}(\s|-)[0-9]{2}$)/,
                  message: t(
                    'components.EditInvoiceRecipientModal.writeValidPostalCode',
                  ),
                },
              ]}>
              <Input
                placeholder={t(
                  'components.EditInvoiceRecipientModal.postalCode',
                )}
                maxLength={6}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="invoiceCity"
              label={t('components.EditInvoiceRecipientModal.city')}>
              <Input
                placeholder={t('components.EditInvoiceRecipientModal.city')}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="invoiceEmail"
              label={t('common.email')}
              rules={[
                {
                  type: 'email',
                  message: t(
                    'components.EditInvoiceRecipientModal.writeValidEmail',
                  ),
                },
              ]}>
              <Input placeholder={t('common.email')} />
            </Form.Item>
          </div>
        </div>
      </Form>
      {showConnectedBookingOrders ? (
        <div className="w-full mt-2 pt-4 border-0 border-t border-gray-400 border-solid flex flex-col gap-2">
          <h2>
            {t('components.EditInvoiceRecipientModal.connectedBookingOrders')}
          </h2>
          {loadingBookingOrders ? (
            <Spin className="self-center" />
          ) : connectedBookingOrders.length ? (
            <>
              {connectedBookingOrders.map((bookingOrder) => (
                <BookingOrderCard
                  key={bookingOrder.id}
                  bookingOrder={bookingOrder}
                  size="small"
                  className="border border-gray-300 border-solid"
                />
              ))}
            </>
          ) : (
            <Empty
              className="self-center"
              image={Empty.PRESENTED_IMAGE_DEFAULT}
              description={<span>{t('views.BookingOrders.noOrders')}</span>}
            />
          )}
        </div>
      ) : null}
    </Modal>
  ) : null;
};

export default EditInvoiceRecipientModal;
