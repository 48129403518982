import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Skeleton, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import * as API from '../../api';
import CourseInstanceAPI from '../CourseInstanceAPI';
import ContentCard from '../../components/ContentCard';
import DetailsRow from '../../components/DetailsRow';
import { useUser } from '../../user/userContext';
import { InstructorCourseInstance } from '../types/CourseInstance';
import AddressDetailsRow from '../../components/AddressDetailsRow';
import CourseInstanceHeader from '../components/CourseInstanceHeader';
import Currency from '../../types/Currency';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import { getFullContactName } from '../../bookingOrders/utils/contactPerson';

const CourseInstanceDetails: React.FC = () => {
  const { courseInstanceId } = useParams();
  const id = useMemo(() => Number(courseInstanceId), [courseInstanceId]);
  const [courseInstance, setCourseInstance] =
    useState<InstructorCourseInstance>();
  const [loadingCourseInstance, setLoadingCourseInstance] =
    useState<boolean>(false);

  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [loadingDefaultCurrency, setLoadingDefaultCurrency] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser] = useUser();
  const { t } = useTranslation();

  const fetchCourseInstance = useCallback(async () => {
    setLoadingCourseInstance(true);
    try {
      const { data } = await CourseInstanceAPI.getInstructorCourseInstance(id);
      setCourseInstance(data);
    } finally {
      setLoadingCourseInstance(false);
    }
  }, [id]);

  const fetchDefaultCurrency = useCallback(async () => {
    setLoadingDefaultCurrency(true);
    try {
      const { data } = await API.getCurrencies(
        currentUser!.market.defaultCurrency.code,
      );
      setDefaultCurrency(data[0]);
    } finally {
      setLoadingDefaultCurrency(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (userHasPermission(currentUser, Permission.CURRENCY_READ)) {
      fetchDefaultCurrency();
    }
    fetchCourseInstance();
  }, [currentUser, fetchCourseInstance, fetchDefaultCurrency]);

  return (
    <>
      <div className="sm:flex sm:flex-row items-center pb-2">
        <div
          onClick={() => {
            const locationState = location.state as {
              prevPage?: string;
            };

            if (!locationState) {
              navigate('/kurstillfallen');
            } else {
              navigate(-1);
            }
          }}
          className="hover:underline cursor-pointer text-blueGrayDark">
          <ArrowLeftOutlined style={{ fontSize: 11 }} />
          <span className="pl-1 pt-0.5 text-xs">{t('common.goBack')}</span>
        </div>
      </div>
      <ContentCard>
        <Skeleton
          loading={loadingCourseInstance || loadingDefaultCurrency}
          paragraph={{ rows: 5 }}
          round
          active>
          <dl>
            <CourseInstanceHeader
              displayedCourseInstance={
                courseInstance &&
                defaultCurrency && {
                  ...courseInstance,
                  price: 0,
                  currency: defaultCurrency,
                }
              }
              clientName={courseInstance?.clientName}
            />

            <DetailsRow
              label={t('views.CourseInstanceDetails.responsibleCompany')}
              displayValue={
                <>
                  {courseInstance?.subsidiaryCompanyName && (
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {courseInstance?.subsidiaryCompanyName}
                    </span>
                  )}
                </>
              }
            />
            <DetailsRow
              label={t('views.CourseInstanceDetails.note')}
              displayValue={
                <>
                  {courseInstance?.comment && (
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {courseInstance?.comment}
                    </span>
                  )}
                </>
              }
            />

            <DetailsRow
              label={t('views.CourseInstanceDetails.contactPerson')}
              displayValue={
                <div className="flex flex-col">
                  <span>
                    {courseInstance?.contactPerson &&
                      getFullContactName(courseInstance?.contactPerson)}
                  </span>
                  {userHasPermission(
                    currentUser,
                    Permission.COURSE_INSTANCE_READ_FULL,
                  ) && <span>{courseInstance?.contactPerson?.email}</span>}
                  <span>{courseInstance?.contactPerson?.phoneNumber}</span>
                </div>
              }
            />
            <AddressDetailsRow
              label={t('common.location')}
              displayValue={courseInstance?.address}
            />
            <DetailsRow
              label={t('views.CourseInstanceDetails.equipment')}
              displayValue={
                <>
                  {courseInstance?.equipment && (
                    <span>{courseInstance?.equipment}</span>
                  )}
                </>
              }
            />
            {courseInstance?.createdBy && (
              <DetailsRow
                label={t('common.createdBy')}
                displayValue={
                  currentUser?.isInstructor ? (
                    <div>
                      <div>{courseInstance.createdBy.name}</div>
                      <div>{courseInstance.createdBy.email}</div>
                      <div>{courseInstance.createdBy.phoneNumber}</div>
                    </div>
                  ) : (
                    courseInstance.createdBy.name
                  )
                }
              />
            )}
            <DetailsRow
              label={t('common.created')}
              displayValue={
                <Tooltip
                  placement="top"
                  title={dayjs(courseInstance?.createdAt).format(
                    'DD/MM-YYYY HH:mm',
                  )}>
                  {dayjs(courseInstance?.createdAt).fromNow()}
                </Tooltip>
              }
            />
            <DetailsRow
              label={t('views.CourseInstanceDetails.mostRecentlyEdited')}
              displayValue={
                <Tooltip
                  placement="top"
                  title={dayjs(courseInstance?.updatedAt).format(
                    'DD/MM-YYYY HH:mm',
                  )}>
                  {dayjs(courseInstance?.updatedAt).fromNow()}
                </Tooltip>
              }
            />
          </dl>
        </Skeleton>
      </ContentCard>
    </>
  );
};

export default CourseInstanceDetails;
