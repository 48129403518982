import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeartFilled } from '@ant-design/icons';

import User from '../types/User';
import { getUserRoleText } from '../userUtils';
import { ColumnsType } from '../../types/ColumnTypes';
import { useUser } from '../userContext';
import Table from '../../components/Table';

import Gravatar from './Gravatar';

type UserResultsType = {
  users?: User[];
  loading: boolean;
  getLink: (user: User) => string;
};

const UserResults: React.FC<UserResultsType> = ({
  users,
  loading,
  getLink,
}) => {
  const [currentUser] = useUser();
  const { t } = useTranslation();

  const UserCell: React.FC<{ user: User }> = ({ user }) => (
    <div className="flex items-center">
      <Gravatar
        email={user.email}
        internal={user.internal}
        isInstructor={user.isInstructor}
      />
      <div className="ml-4">
        <div className="text-sm font-medium">{user.name}</div>
        <div className="text-sm text-gray-500">{user.email}</div>
      </div>
    </div>
  );

  const RoleCell: React.FC<{ user: User }> = ({ user }) => (
    <div className="flex items-center justify-between">
      <div>
        {user.roles.map((role, index) => (
          <span
            key={index}
            className="px-2 inline-flex text-xs font-semibold rounded-full bg-gray-100 text-gray-800 uppercase mx-1">
            {getUserRoleText(role, t)}
          </span>
        ))}
      </div>
      <span>
        {user.id && currentUser?.favoriteInstructorIds.includes(user.id) && (
          <HeartFilled
            className="pr-3"
            style={{
              fontSize: '18px',
              color: '#FF2121',
            }}
          />
        )}
      </span>
    </div>
  );

  const columns: ColumnsType<User> = [
    {
      title: t('common.name'),
      render: (user) => <UserCell user={user} />,
    },
    {
      title: t('common.role'),
      render: (user) => <RoleCell user={user} />,
    },
  ];

  return (
    <Table
      rowKey={(user) => user.id!}
      columns={columns}
      rows={users}
      loading={loading}
      getRowLink={getLink}
    />
  );
};

export default UserResults;
