import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import Table from '../../components/Table';
import { ColumnsType } from '../../types/ColumnTypes';
import { useTranslation } from 'react-i18next';
import CertificateAPI from '../../certificate/CertificateAPI';
import { Outlet } from 'react-router-dom';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import { PlusOutlined } from '@ant-design/icons';
import { useUser } from '../../user/userContext';
import CreateCertificateImageModal from '../../certificateLanguage/components/CreateCertificateImageModal';
import { ImageMedia } from '../../types/ImageMedia';

const CertificateImage: React.FC = () => {
  const [images, setImages] = useState<ImageMedia[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { t } = useTranslation();
  const [currentUser] = useUser();

  const fetchImages = useCallback(() => {
    CertificateAPI.getCertificateImages().then(({ data }) => {
      setImages(data);
    });
  }, []);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const columns: ColumnsType<ImageMedia> = useMemo(
    () => [
      {
        width: '50%',
        title: t('views.CertificateImages.columns.name'),
        render: (imageMedia) => (
          <div className="overflow-hidden text-ellipsis">{imageMedia.name}</div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <div className="flex justify-between">
        <h2>{t('views.CertificateImages.title')}</h2>
        {userHasPermission(
          currentUser,
          Permission.CERTIFICATE_IMAGE_CREATE,
        ) && (
          <>
            <Button
              type="primary"
              className="text-sm font-medium"
              icon={<PlusOutlined />}
              onClick={() => setShowCreateModal(true)}>
              {t('views.CertificateImages.newImage')}
            </Button>
            {showCreateModal && (
              <CreateCertificateImageModal
                visible={showCreateModal}
                onCancel={() => setShowCreateModal(false)}
                onCreate={(image) => setImages([...images, image])}
              />
            )}
          </>
        )}
      </div>

      <Table
        rowKey={(image) => image.id}
        columns={columns}
        rows={images}
        loading={!!images.length}
        getRowLink={(image) => image.id.toString()}
      />

      <Outlet
        context={{
          onChange: fetchImages,
          certificateImages: images,
        }}
      />
    </>
  );
};

export default CertificateImage;
