import React, { useState, useContext } from 'react';

import Language from '../types/Language';

const LanguageContext = React.createContext<
  [Language | undefined, (language: Language | undefined) => void]
>([undefined, () => {}]);

export const useLanguage = () => useContext(LanguageContext);

type LanguageContextProviderProps = {
  children: React.ReactNode;
  value?: Language | undefined;
};

const LanguageContextProvider: React.FC<LanguageContextProviderProps> = ({
  children,
  value,
}) => {
  const [language, setLanguage] = useState<Language | undefined>(value);

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
