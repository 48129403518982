import React from 'react';

import EditField from '../../resources/img/editField.svg';
import ActionPopoverButton, {
  ActionPopoverElement,
} from '../../components/ActionPopoverButton';

type InformationCardProps = {
  title?: string;
  children?: React.ReactChild;
  className?: string;
  actionPopoverElements?: ActionPopoverElement[];
};

const editFieldIcon = (
  <div
    className="flex justify-center items-center action-button-background 
  cursor-pointer place-self-start mr-2 -mt-1 h-8 w-8 rounded-lg hover:bg-safeLifeMedium">
    <img className="action-button-icon" src={EditField} />
  </div>
);

const InformationCard: React.FC<InformationCardProps> = ({
  title,
  children,
  className,
  actionPopoverElements,
}) => {
  return (
    <div className={`${className ?? ''} flex flex-col flex-grow min-w-1/4`}>
      {title && (
        <div className="text-blueGrayDark font-medium pl-2">{title}</div>
      )}
      <div
        className={`information-container ${
          actionPopoverElements ? 'hover:bg-blueGrayLight pt-2' : ''
        } flex flex-row w-full pl-2 rounded-lg`}>
        <div className="overflow-hidden grow shrink">{children}</div>
        {actionPopoverElements && (
          <ActionPopoverButton
            className="justify-center h-8 w-8"
            icon={editFieldIcon}
            actionPopoverElements={actionPopoverElements}
          />
        )}
      </div>
    </div>
  );
};

export default InformationCard;
