import React, { FC } from 'react';
import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeData,
} from '../types/CourseInstanceLeadTime';
import {
  CourseInstanceStatus,
  CourseInstanceStatusData,
} from '../types/CourseInstanceStatus';
import { Row } from 'antd';
import { twMerge } from 'tailwind-merge';

type CourseInstanceLegendProps = {
  data?: CourseInstanceStatusData | CourseInstanceLeadTimeData;
  isSelected: (id: CourseInstanceStatus | CourseInstanceLeadTime) => boolean;
  isNotSelected: (id: CourseInstanceStatus | CourseInstanceLeadTime) => boolean;
  onMouseEnter: (id: CourseInstanceStatus | CourseInstanceLeadTime) => void;
  onMouseLeave: () => void;
};

export const CourseInstanceLegend: FC<CourseInstanceLegendProps> = ({
  data,
  isSelected,
  isNotSelected,
  onMouseEnter,
  onMouseLeave,
}) => {
  if (data === undefined) {
    return null;
  }

  return (
    <Row className="gap-8 ml-5">
      {data.graphs.map((g) => (
        <Row
          key={g.id}
          className={twMerge(
            'gap-2 items-center rounded-full px-2 py-0.5 text-sm text-grayMedium hover:bg-gray-100 cursor-pointer',
            isSelected(g.id) && 'text-grayDark bg-gray-100',
            isNotSelected(g.id) && 'text-grayLight',
          )}
          onMouseEnter={() => onMouseEnter(g.id)}
          onMouseLeave={onMouseLeave}>
          <div
            className="w-3 h-3 rounded-full"
            style={{
              backgroundColor:
                g.id === CourseInstanceStatus.TOTAL ? `${g.color}44` : g.color,
            }}
          />
          <span>{g.label}</span>
        </Row>
      ))}
    </Row>
  );
};
