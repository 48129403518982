import React, { useMemo } from 'react';
import {
  useLocation,
  Link,
  useParams,
  useOutletContext,
  useNavigate,
} from 'react-router-dom';
import { Button, Modal } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import * as DateUtils from '../../utils/date';
import DetailsRow from '../../components/DetailsRow';
import { useUser } from '../userContext';
import InstructorCalendarDate from '../types/InstructorCalendarDate';
import { userHasPermission } from '../userUtils';
import { Permission } from '../types/Permission';

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

type CourseInstancePreviewModalContext = {
  instructorCalendar: InstructorCalendarDate[];
};

const CourseInstancePreviewModal: React.FC = () => {
  const { courseInstanceId } = useParams();
  const id = useMemo(() => Number(courseInstanceId), [courseInstanceId]);
  const { instructorCalendar } =
    useOutletContext<CourseInstancePreviewModalContext>();
  const courseInstance = instructorCalendar
    ?.flatMap((day) => day.courseInstances)
    .find((courseInstance) => courseInstance.id === id);

  const [currentUser] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  if (!courseInstance) return null;

  const close = () => {
    if (location.state?.fromCalendar) {
      navigate(-1);
    } else {
      navigate('..', {
        replace: true,
      });
    }
  };

  return (
    <Modal
      title={capitalize(dayjs(courseInstance.startsAt).format('dddd D MMMM'))}
      open
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          {t('common.cancel')}
        </Button>,
        <Button key="information" type="primary">
          <Link
            to={
              userHasPermission(currentUser, Permission.BOOKING_ORDER_READ_LIST)
                ? `/bestallningar/${courseInstance.orderId}`
                : `/kurstillfallen/${courseInstance.id}`
            }
            state={{ prevPage: location.pathname }}>
            {t('components.CourseInstancePreviewModal.moreInformation')}
          </Link>
        </Button>,
      ]}
      maskClosable={false}>
      <DetailsRow
        label={t('components.CourseInstancePreviewModal.client')}
        displayValue={''}
      />
      <DetailsRow
        label={t('common.course')}
        displayValue={courseInstance.course?.name}
      />
      <DetailsRow
        label={t('common.time')}
        displayValue={
          <div className="flex flex-col">
            <span>{DateUtils.formatDate(courseInstance.startsAt)}</span>
            <span>
              {DateUtils.formatTime(courseInstance.startsAt)} -{' '}
              {DateUtils.formatTime(courseInstance.endsAt)}
            </span>
          </div>
        }
      />
      <DetailsRow
        label={t('common.location')}
        displayValue={courseInstance.address}
      />
    </Modal>
  );
};

export default CourseInstancePreviewModal;
