import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Form, Input, Button, Select, Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import * as API from '../../api';
import UserAPI from '../UserAPI';
import { getMarkets } from '../../market/MarketAPI';
import SubsidiaryCompanyAPI from '../../subsidiaryCompany/SubsidiaryCompanyAPI';
import User, { UserRole } from '../types/User';
import { getUserRoleText } from '../userUtils';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import { useUser } from '../userContext';
import Language from '../../types/Language';
import Market from '../../market/types/Market';

type UserFormValues = {
  name: string;
  email: string;
  phoneNumber?: string;
  role: UserRole;
  password: string;
  verifyPassword: string;
  subsidiaryCompanyId?: number;
  preferredLanguageId?: number;
  marketId: number;
};

type CreateUserModalProps = {
  visible: boolean;
  onCreateUser?: (user: User) => void;
  onCancel?: () => void;
};

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  visible,
  onCreateUser,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [subsidiaryVisible, setSubsidiaryVisible] = useState<boolean>(false);
  const [subsidiaryRequired, setSubsidiaryRequired] = useState<boolean>(false);
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState<boolean>(false);
  const [subsidiaryCompanies, setSubsidiaryCompanies] = useState<
    SubsidiaryCompany[]
  >([]);
  const [filteredSubsidiaryCompanies, setFilteredSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);
  const [languages, setLanguages] = useState<Language[]>();
  const [loadingMarkets, setLoadingMarkets] = useState<boolean>(false);
  const [markets, setMarkets] = useState<Market[]>();
  const [currentUser] = useUser();

  const { t } = useTranslation();

  const filterSubsidiaryCompaniesData = useCallback(
    (subsidiaryCompany: SubsidiaryCompany[], marketId: number) => {
      setFilteredSubsidiaryCompanies(
        subsidiaryCompany.filter((company) => {
          return company.market.id == marketId;
        }),
      );
    },
    [],
  );

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies({
        allMarkets: true,
      });
      setSubsidiaryCompanies(data);
      filterSubsidiaryCompaniesData(data, currentUser!.market.id);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, [currentUser, filterSubsidiaryCompaniesData]);

  const fetchMarkets = useCallback(async () => {
    setLoadingMarkets(true);
    try {
      const { data } = await getMarkets();
      setMarkets(data);
    } finally {
      setLoadingMarkets(false);
    }
  }, []);

  const fetchLanguages = useCallback(async () => {
    setLoadingLanguages(true);
    try {
      const { data } = await API.getLanguages();
      setLanguages(data);
    } finally {
      setLoadingLanguages(false);
    }
  }, []);

  useEffect(() => {
    fetchLanguages();
    fetchMarkets();
    fetchSubsidiaryCompanies();
  }, [fetchLanguages, fetchMarkets, fetchSubsidiaryCompanies]);

  const onMarketChange = useCallback(
    (marketId: number) => {
      form.setFieldValue('subsidiaryCompanyId', null);
      filterSubsidiaryCompaniesData(subsidiaryCompanies, marketId);
    },
    [filterSubsidiaryCompaniesData, form, subsidiaryCompanies],
  );

  const onFinish = useCallback(
    async (values: UserFormValues) => {
      if (!currentUser) {
        return;
      }

      setSubmitting(true);
      try {
        const { data } = await UserAPI.createUser({
          name: values.name,
          email: values.email,
          marketId: values.marketId,
          phoneNumber: values.phoneNumber,
          generatePassword: true,
          preferredSubsidiaryCompanyId: values.subsidiaryCompanyId,
          preferredLanguageId: values.preferredLanguageId,
          roles: [values.role],
        });
        if (values.subsidiaryCompanyId && values.role === UserRole.Seller) {
          await UserAPI.setUserSubsidiaries(data.user.id, [
            values.subsidiaryCompanyId,
          ]);
        }
        onCreateUser?.(data.user);
        form.resetFields();
      } finally {
        setSubmitting(false);
      }
    },
    [currentUser, form, onCreateUser],
  );

  const setSubsidiarySelectVisible = useCallback((value: UserRole) => {
    if (value == UserRole.Instructor) {
      setSubsidiaryVisible(false);
      setSubsidiaryRequired(false);
    } else {
      setSubsidiaryVisible(true);
      setSubsidiaryRequired(value != UserRole.Admin);
    }
  }, []);

  return (
    <Modal
      title={t('components.CreateUserModal.newUser')}
      open={visible}
      onCancel={onCancel}
      onOk={form.submit}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createUserForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createUserForm"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={(values) => onFinish(values as UserFormValues)}>
        <Form.Item
          label={t('common.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('components.CreateUserModal.enterName'),
            },
          ]}>
          <Input placeholder={t('common.name')} />
        </Form.Item>
        <Form.Item
          label={t('common.email')}
          name="email"
          rules={[
            {
              required: true,
              message: t('components.CreateUserModal.enterEmail'),
            },
          ]}>
          <Input type="email" placeholder={t('common.email')} />
        </Form.Item>
        <Form.Item
          label={t('common.phoneNumber')}
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: t('components.CreateUserModal.enterPhoneNumber'),
            },
          ]}>
          <Input type="tel" placeholder={t('common.phoneNumber')} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label={t('common.market')}
          name="marketId"
          initialValue={currentUser?.market.id}>
          <Select
            showSearch
            placeholder={t('common.market')}
            loading={loadingMarkets}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterOption={(input, option: any) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('components.UserInfo.noMarketsFound')}
              />
            }
            onChange={onMarketChange}>
            {markets?.map((market) => (
              <Select.Option key={market.id} value={market.id}>
                {market.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('common.role')}
          name="role"
          initialValue={UserRole.Instructor}>
          <Select
            onChange={(value) => setSubsidiarySelectVisible(value as UserRole)}>
            {Object.values(UserRole).map((role) => (
              <Select.Option key={role} value={role}>
                {getUserRoleText(role, t)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('common.language')}
          name="preferredLanguageId"
          initialValue={currentUser?.preferredLanguage?.id}>
          <Select
            showSearch
            placeholder={t('components.UserInfo.selectLanguage')}
            loading={loadingLanguages}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterOption={(input, option: any) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('components.UserInfo.noLanguagesFound')}
              />
            }>
            {languages?.map((language) => (
              <Select.Option key={language.id} value={language.id}>
                {t(`languages.${language.code}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {subsidiaryVisible && (
          <Form.Item
            label={t('common.company')}
            name="subsidiaryCompanyId"
            rules={[
              {
                required: subsidiaryRequired,
                message: t(
                  'components.CreateUserModal.selectSubsidiaryCompany',
                ),
              },
            ]}>
            <Select
              placeholder={t('common.company')}
              className="w-full"
              loading={loadingSubsidiaryCompanies}>
              {filteredSubsidiaryCompanies.map((sc) => (
                <Select.Option value={sc.id} key={sc.id}>
                  {sc.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
