export default interface CourseInstanceLimit {
  id: number;
  userId: number;
  date: string;
  limit: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export enum CourseInstanceLimitBoundary {
  Min = 1,
  Max = 10,
}
