import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Dayjs } from 'dayjs';
import { Chart } from 'react-chartjs-2';

import ConditionalParent from '../../components/ConditionalParent';
import colors from '../../theme/colors';

const generateStartValue = (dataset: number[]): number => {
  const [mondayValue, tuesdayValue] = dataset;
  if (tuesdayValue !== undefined) {
    const diff = tuesdayValue - mondayValue;
    const value =
      mondayValue - (diff > 0 ? Math.floor(diff / 2) : Math.ceil(diff / 2));
    return value < 0 ? 0 : value;
  }
  if (mondayValue !== undefined) return Math.ceil(mondayValue / 2);
  return 0;
};

const generateEndValue = (dataset: number[]) => {
  if (dataset.length < 5) return;
  const [, , , thursdayValue, fridayValue] = dataset;
  const diff = thursdayValue - fridayValue;
  const value =
    (fridayValue - (diff > 0 ? Math.floor(diff) : Math.ceil(diff))) / 2;
  return value < 0 ? 0 : value;
};

export type Tooltip = [string, string];

type GraphProps = {
  dataset: number[];
  startValue?: number | null;
  endValue?: number;
  color?: string;
  pointBorderColor?: string;
  grace?: number;
  onNext?: () => void;
  onPrev?: () => void;
  isCurrentWeek?: boolean;
  setWeek?: (moment: Dayjs) => void;
  internalHiddenOverflow?: boolean;
  overflowSize?: number;
  loading?: boolean;
  labels?: string[];
  tooltipableIndexes?: boolean[];
  tooltipGenerator?: (index: number) => Tooltip;
  minGraphY?: number;
  maxGraphY?: number;
  chartHeight?: number;
  padding?: { left?: number; right?: number; top?: number; bottom?: number };
};

const Graph: React.FC<GraphProps> = ({
  dataset,
  startValue = generateStartValue(dataset.slice(0)),
  endValue,
  color = '#f9bc60',
  pointBorderColor = '#fcdeb0',
  grace = 0.2,
  onNext,
  onPrev,
  isCurrentWeek,
  internalHiddenOverflow = false,
  overflowSize = 14,
  loading,
  labels,
  tooltipableIndexes,
  tooltipGenerator,
  minGraphY,
  maxGraphY,
  chartHeight = 75,
  padding,
}) => {
  const [displayData, setDisplayData] = useState<number[]>([]);
  const [tooltip, setTooltip] = useState<Tooltip>(['', '']);
  const [tooltipTitle, tooltipContent] = tooltip;
  const [graphMinY, setGraphMinY] = useState<number>(0);
  const [graphMaxY, setGraphMaxY] = useState<number>(0);
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>();
  const [lastIndex, setLastIndex] = useState<number>();
  const [lastOpacity, setLastOpacity] = useState<number>();

  useEffect(() => {
    const displayValues =
      startValue === null ? dataset : [startValue, ...dataset];

    const displayEndValue = endValue ?? generateEndValue(dataset);
    if (!isCurrentWeek && displayEndValue !== undefined)
      displayValues.push(displayEndValue);

    setDisplayData(displayValues);

    const minDataValue = Math.min(...displayValues);
    const maxDataValue = Math.max(...displayValues);
    const offset = Math.round((maxDataValue - minDataValue) * grace);
    setGraphMinY(minDataValue - (offset > 0 ? offset : 1));
    setGraphMaxY(
      maxDataValue +
        (offset > 0 ? offset : minDataValue === maxDataValue ? 2 : 1),
    );
  }, [dataset, startValue, endValue, isCurrentWeek, grace]);

  return (
    <div
      className="relative flex flex-row justify-center"
      style={{ margin: `0 -${overflowSize}px` }}>
      {tooltipGenerator && (
        <div
          className="rounded-lg p-2 absolute pointer-events-none text-white"
          style={{
            backgroundColor: colors.safeLifeDarker,
            transform: 'translate(-50%, -130%)',
            opacity: 0,
            zIndex: 10,
            transition: 'all .1s ease',
            ...tooltipStyle,
          }}>
          <span className="font-medium" style={{ whiteSpace: 'nowrap' }}>
            {tooltipTitle}
          </span>
          <br />
          <span>{tooltipContent}</span>
        </div>
      )}
      {(!!onNext || !!onPrev) && (
        <>
          <div className="absolute mb-8 mx-8 self-center left-0">
            <Button
              shape="circle"
              size="large"
              icon={<LeftOutlined />}
              disabled={!onPrev || loading}
              onClick={onPrev}
            />
          </div>
          <div className="absolute mb-8 mx-8 self-center right-0">
            <Button
              shape="circle"
              size="large"
              icon={<RightOutlined />}
              disabled={!onNext || isCurrentWeek || loading}
              onClick={onNext}
            />
          </div>
        </>
      )}
      <ConditionalParent
        condition={internalHiddenOverflow}
        parent={(children) => (
          <div
            className="w-full overflow-x-hidden flex justify-center"
            style={{ margin: `0px ${overflowSize}px` }}>
            <div
              style={{
                margin: `0px -${overflowSize}px`,
                width: `calc(100% + ${overflowSize * 2}px)`,
              }}>
              {children}
            </div>
          </div>
        )}>
        <Chart
          type="line"
          height={`${chartHeight}%`}
          data={{
            labels,
            datasets: [
              {
                data: displayData,
                borderColor: color,
                borderWidth: 5,
                pointStyle: 'circle',
                pointRadius: 0,
                pointHoverRadius: 10,
                pointHoverBackgroundColor: color,
                pointHoverBorderColor: pointBorderColor,
                pointBorderWidth: 4,
                pointHoverBorderWidth: 4,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                external: ({ tooltip, chart }) => {
                  if (!tooltip?.dataPoints?.length) return;
                  if (!tooltipGenerator) return;

                  const index: number = tooltip.dataPoints[0].parsed.x;
                  const opacity: number =
                    tooltipableIndexes?.[index] && tooltip.opacity ? 1 : 0;
                  if (index === lastIndex && opacity === lastOpacity) return;

                  setLastIndex(index);
                  setLastOpacity(opacity);

                  if (!opacity) {
                    return setTooltipStyle({
                      opacity,
                      left: tooltipStyle?.left,
                      top: tooltipStyle?.top,
                    });
                  }

                  setTooltipStyle({
                    opacity,
                    left: `${chart.canvas.offsetLeft + tooltip.caretX}px`,
                    top: `${chart.canvas.offsetTop + tooltip.caretY}px`,
                  });
                  setTooltip(tooltipGenerator(index));
                },
              },
            },
            elements: {
              line: {
                tension: 0.3,
              },
            },
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            layout: { padding },
            scales: {
              x: {
                grid: {
                  color: 'rgba(0,0,0,0)',
                },
                ticks: {
                  font: { weight: 500 },
                },
                offset: !!padding,
              },
              y: {
                type: 'linear',
                display: false,
                min: minGraphY || graphMinY,
                max: maxGraphY || graphMaxY,
              },
            },
          }}
        />
      </ConditionalParent>
    </div>
  );
};

export default Graph;
