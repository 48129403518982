import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Empty,
  message,
  Row,
  Col,
  InputNumber,
  Switch,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { getCurrencies, getLanguages } from '../../api';
import Currency from '../../types/Currency';
import Language from '../../types/Language';
import Market from '../types/Market';
import MarketAPI, { CreateMarketParams } from '../MarketAPI';
import acceptedTimezones from '../../utils/acceptedTimezones.json';
import { useUser } from '../../user/userContext';

type CreateMarketModalProps = {
  visible: boolean;
  onCreateMarket: (market: Market) => void;
  onCancel: () => void;
};

const CreateMarketModal: React.FC<CreateMarketModalProps> = ({
  visible,
  onCreateMarket,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loadingCurrencies, setLoadingCurrencies] = useState<boolean>(true);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [currentUser] = useUser();

  const { t } = useTranslation();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    getCurrencies()
      .then(({ data }) => setCurrencies(data))
      .finally(() => setLoadingCurrencies(false));

    getLanguages()
      .then(({ data }) => setLanguages(data))
      .finally(() => setLoadingLanguages(false));
  }, []);

  const onFinish = async (values: CreateMarketParams) => {
    setSubmitting(true);
    try {
      const { data: newMarket } = await MarketAPI.createMarket({
        name: values.name,
        code: values.code,
        timeZoneId: values.timeZoneId ? values.timeZoneId : undefined,
        defaultCurrencyId: values.defaultCurrencyId,
        preferredLanguageId: values.preferredLanguageId,
        mapCenterLatitude: values.mapCenterLatitude
          ? values.mapCenterLatitude
          : undefined,
        mapCenterLongitude: values.mapCenterLongitude
          ? values.mapCenterLongitude
          : undefined,
        allowInstructorSelfAssign: values.allowInstructorSelfAssign,
      });
      onCreateMarket(newMarket);
      resetAndClose();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const resetAndClose = () => {
    form.resetFields();
    onCancel();
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      title={t('components.CreateMarketModal.newMarket')}
      open={visible}
      onCancel={resetAndClose}
      onOk={submit}
      footer={[
        <Button key="back" onClick={resetAndClose}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createMarketForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createMarketForm"
        layout="vertical"
        form={form}
        onFinish={(values) => onFinish(values)}>
        <Form.Item
          label={t('components.MarketModal.form.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('components.MarketModal.form.enterName'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.MarketModal.form.code')}
          name="code"
          rules={[
            {
              required: true,
              message: t('components.MarketModal.form.enterCode'),
            },
            {
              len: 3,
              message: t('components.MarketModal.form.enterCode'),
            },
          ]}>
          <Input
            placeholder={t('components.MarketModal.form.codePlaceholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('components.MarketModal.form.currency')}
          name="defaultCurrencyId"
          rules={[
            {
              required: true,
              message: t('components.MarketModal.form.selectCurrency'),
            },
          ]}>
          <Select
            showSearch
            placeholder={t('components.MarketModal.form.selectCurrency')}
            loading={loadingCurrencies}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterOption={(input, option: any) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('components.MarketModal.form.noCurrencyFound')}
              />
            }>
            {currencies?.map((currency) => (
              <Select.Option key={currency.id} value={currency.id}>
                {currency.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('common.language')}
          name="preferredLanguageId"
          initialValue={currentUser?.preferredLanguage?.id}
          rules={[
            {
              required: true,
              message: t('components.MarketModal.form.selectLanguage'),
            },
          ]}>
          <Select
            showSearch
            placeholder={t('components.MarketModal.form.selectLanguage')}
            loading={loadingLanguages}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterOption={(input, option: any) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('components.MarketModal.form.noLanguagesFound')}
              />
            }>
            {languages?.map((language) => (
              <Select.Option key={language.id} value={language.id}>
                {t(`languages.${language.code}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('components.MarketModal.form.timeZoneId')}
          name="timeZoneId"
          rules={[
            {
              required: true,
              message: t('components.MarketModal.form.selectTimezone'),
            },
          ]}>
          <Select
            showSearch
            placeholder={t('components.MarketModal.form.timeZoneIdExample')}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            filterOption={(input, option: any) =>
              option?.children
                ?.toLowerCase()
                .replace(/[^a-z0-9\-\+]/g, '')
                .indexOf(input.toLowerCase().replace(/[^a-z0-9\-\+]/g, '')) >= 0
            }
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('components.MarketModal.form.noCurrencyFound')}
              />
            }>
            {acceptedTimezones.map((timezone) => (
              <Select.Option key={timezone} value={timezone}>
                {timezone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('components.MarketModal.form.allowInstructorSelfAssign')}
          name="allowInstructorSelfAssign"
          initialValue={true}
          valuePropName="checked">
          <Switch defaultChecked={true} />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={t('components.MarketModal.form.mapCenterLatitude')}
              name="mapCenterLatitude">
              <InputNumber
                style={{ width: '100%' }}
                placeholder={t(
                  'components.MarketModal.form.mapCenterLatitudePlaceholder',
                )}
                max={90}
                min={-90}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('components.MarketModal.form.mapCenterLongitude')}
              name="mapCenterLongitude">
              <InputNumber
                style={{ width: '100%' }}
                placeholder={t(
                  'components.MarketModal.form.mapCenterLongitudePlaceholder',
                )}
                max={180}
                min={-180}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateMarketModal;
