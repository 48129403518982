import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Form, Select, Spin, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import CourseInstanceAPI from '../CourseInstanceAPI';
import UserAPI from '../../user/UserAPI';
import Course from '../../course/types/Course';
import User, { MinimalUser, UserRole } from '../../user/types/User';
import { useUser } from '../../user/userContext';
import { DisplayedCourseInstance } from '../types/CourseInstance';
import * as DateUtils from '../../utils/date';
import {
  sortInstructorsByFavorite,
  userHasPermission,
} from '../../user/userUtils';
import CourseAPI from '../../course/CourseAPI';
import CourseOption from '../../components/CourseOption';
import UserOption from '../../components/UserOption';
import { Permission } from '../../user/types/Permission';

import { HeaderValues } from './CourseInstanceHeader';
import CourseInstanceChangesModal from './CourseInstanceChangesModal';
import { CourseInstanceUpdateValues } from './EditCourseInstance';
import { useFeatureToggles } from '../../context/featureToggles';

export type CourseInstanceHeaderFormValues = {
  instructor?: MinimalUser | null;
  course?: Course;
  date?: dayjs.Dayjs;
  time?: [dayjs.Dayjs, dayjs.Dayjs];
};

type CourseInstanceHeaderFormProps = {
  displayedCourseInstance?: DisplayedCourseInstance;
  showModal?: boolean;
  setError?: (values: boolean) => void;
  setEditing?: (values: boolean) => void;
  setShowModal?: (values: boolean) => void;
  checkRequiredFields?: (values: HeaderValues) => boolean;
  onFinish?: (
    values: CourseInstanceUpdateValues,
    setUpdating?: (updating: boolean) => void,
  ) => Promise<void>;
  editableValues?: HeaderValues;
  setEditableValues?: (values: HeaderValues) => void;
  selectedInstructorName?: string;
  setSelectedInstructorName: (value?: string) => void;
};

const CourseInstanceHeaderForm: React.FC<CourseInstanceHeaderFormProps> = ({
  displayedCourseInstance,
  onFinish,
  setError,
  setEditing,
  setShowModal,
  checkRequiredFields,
  showModal,
  editableValues,
  setEditableValues,
  setSelectedInstructorName,
}) => {
  const [currentUser] = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [instructors, setInstructors] = useState<User[]>();
  const [loadingInstructors, setLoadingInstructors] = useState<boolean>(false);
  const [courses, setCourses] = useState<Course[]>();
  const [showError, setShowError] = useState<boolean>(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [requireDateTime, setRequireDateTime] = useState<boolean>(false);

  const { t } = useTranslation();
  const { isElsaIntegrationEnabled } = useFeatureToggles();

  const fetchCourses = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await CourseAPI.getCourses();
      if (
        displayedCourseInstance &&
        !data
          .map((course) => course.id)
          .includes(displayedCourseInstance.course.id)
      ) {
        setCourses([...data, displayedCourseInstance.course]);
      } else {
        setCourses(data);
      }
    } finally {
      setLoading(false);
    }
  }, [displayedCourseInstance]);

  const fetchInstructors = useCallback(async () => {
    setLoadingInstructors(true);
    try {
      const { data } = await UserAPI.getUsers({ roles: [UserRole.Instructor] });
      const sortedInstructors = sortInstructorsByFavorite(data, currentUser);
      setInstructors(sortedInstructors);
    } finally {
      setLoadingInstructors(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (userHasPermission(currentUser, Permission.USER_READ_LIST)) {
      fetchInstructors();
    }
    fetchCourses();
    setSelectedInstructorName(displayedCourseInstance?.instructor?.name);
  }, [
    currentUser,
    displayedCourseInstance?.instructor?.name,
    fetchCourses,
    fetchInstructors,
    setSelectedInstructorName,
  ]);
  const validation = useCallback(
    async (values: HeaderValues) => {
      if (!displayedCourseInstance) {
        return;
      }
      setLoading(true);
      try {
        if (checkRequiredFields?.(values)) {
          const courseInstanceParameters = {
            courseId: values.courseId ?? displayedCourseInstance.course.id,
            currencyId: displayedCourseInstance.currency.id,
            orderId: displayedCourseInstance.orderId,
            contactPersonId: displayedCourseInstance.contactPerson?.id,
            instructorId:
              values.instructorId ?? displayedCourseInstance.instructor?.id,
            endsAt: values.time
              ? DateUtils.mergeDateTime(
                  dayjs(values.date),
                  values.time[1],
                ).toISOString()
              : displayedCourseInstance.endsAt,
            startsAt: values.time
              ? DateUtils.mergeDateTime(
                  dayjs(values.date),
                  values.time[0],
                ).toISOString()
              : displayedCourseInstance.startsAt,
            lat: displayedCourseInstance.lat,
            lng: displayedCourseInstance.lng,
            mileagePrice: displayedCourseInstance.mileagePrice,
          };
          if (displayedCourseInstance.id) {
            const { data } = await CourseInstanceAPI.validateCourseInstance(
              courseInstanceParameters,
              displayedCourseInstance.id,
            );
            setMessages(data);
          } else {
            const { data } =
              await CourseInstanceAPI.validateCourseInstanceParameters(
                courseInstanceParameters,
              );
            setMessages(data);
          }
          setShowError(false);
          setError?.(false);
        }
      } catch (error) {
        if (isAxiosError(error) && error.response) {
          setMessages([error.response.data.message]);
          if (error.response.status === 409 || error.response.status === 404) {
            setShowError(true);
            setError?.(true);
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [checkRequiredFields, displayedCourseInstance, setError],
  );

  useEffect(() => {
    if (editableValues?.instructorId) {
      validation(editableValues as HeaderValues);
    } else {
      setMessages([]);
      setShowError(false);
      setError?.(false);
    }

    setRequireDateTime(() => {
      // If a date or time is selected, both date and time must be assigned
      if (!!editableValues?.date !== !!editableValues?.time) {
        return true;
      }

      // Require both date and time if instructor is assigned
      if (
        !!editableValues?.instructorId &&
        (!editableValues?.date || !editableValues?.time)
      ) {
        return true;
      }

      return false;
    });
  }, [editableValues, setError, validation]);

  const cannotChangeCourse =
    isElsaIntegrationEnabled &&
    !!displayedCourseInstance?.elsaTrainingSessionId;

  return (
    <div>
      <CourseInstanceChangesModal
        course={courses?.find(
          (course) => course.id === editableValues?.courseId,
        )}
        instructor={instructors?.find(
          (instructor) => instructor.id === editableValues?.instructorId,
        )}
        setEditing={setEditing}
        editableValues={editableValues}
        showModal={showModal}
        setShowModal={setShowModal}
        onFinish={onFinish}
        messages={messages}
      />
      {loading ? (
        <div className="h-24 flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <Form
          fields={[
            { name: ['time'], value: editableValues?.time },
            { name: ['date'], value: editableValues?.date },
            {
              name: ['instructorId'],
              value: editableValues?.instructorId,
            },
            {
              name: ['courseId'],
              value: editableValues?.courseId,
            },
          ]}
          className="sm:grid sm:grid-cols-3 sm:pt-5 gap-5"
          onValuesChange={(_, values) => {
            setEditableValues?.(values as HeaderValues);
          }}>
          <div className="col-span-1 md:w-11/12 px-4">
            <div className="text-base font-bold pb-1">{t('common.course')}</div>
            <div className="text-sm pb-1">
              {cannotChangeCourse
                ? t('components.CourseInstanceHeaderForm.cannotChangeCourse')
                : t('components.CourseInstanceHeaderForm.pickCourse')}
            </div>
            <Form.Item
              name="courseId"
              rules={[
                {
                  required: true,
                  message: t('components.CourseInstanceHeaderForm.pickCourse'),
                },
              ]}>
              <Select
                showSearch
                allowClear
                loading={loading}
                filterOption={(input, option) =>
                  option?.props.children.props.course.name
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={cannotChangeCourse}>
                {courses?.map((course) => (
                  <Select.Option key={course.id} value={course.id}>
                    <CourseOption user={currentUser} course={course} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-1 md:w-9/12 mb-5">
            <div className="text-base font-bold pb-1">{t('common.time')}</div>
            <div className="text-sm pb-1">{t('common.date')}</div>
            <Form.Item
              name="date"
              className="m-0 hideRequiredWarning"
              rules={[{ required: requireDateTime }]}>
              <DatePicker
                size="middle"
                format="YYYY-MM-DD"
                className="w-full mb-2"
              />
            </Form.Item>
            <Form.Item
              name="time"
              rules={[{ required: requireDateTime }]}
              className="mb-0 hideRequiredWarning">
              <TimePicker.RangePicker
                changeOnBlur={true}
                minuteStep={5}
                format="HH:mm"
                className="w-full"
              />
            </Form.Item>

            <div className="text-sm pl-1 text-safeLifeWarning h-4">
              {requireDateTime &&
                t('components.CourseInstanceHeaderForm.enterDateAndTime')}
            </div>
          </div>
          <div className="col-span-1 md:w-11/12 md:grid-rows-2 m-0">
            <div className="text-base font-bold pb-1">
              {t('common.instructor')}
            </div>
            <div className="text-sm pb-1">
              {t('components.CourseInstanceHeaderForm.pickInstructor')}
            </div>
            <Form.Item
              name="instructorId"
              {...(showError && {
                validateStatus: 'error',
                help: messages,
                hasFeedback: true,
              })}>
              <Select
                showSearch
                allowClear
                disabled={
                  !userHasPermission(currentUser, Permission.USER_READ_LIST)
                }
                loading={loadingInstructors}
                filterOption={(input, option) =>
                  option?.props.children.props.user.name
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={t(
                  'components.CourseInstanceHeaderForm.instructorMissing',
                )}
                onChange={(instructorId) => {
                  setSelectedInstructorName(
                    instructors?.find(
                      (instructor) => instructor.id === instructorId,
                    )?.name,
                  );
                }}>
                {instructors?.map(
                  (instructor) =>
                    instructor.id && (
                      <Select.Option key={instructor.id} value={instructor.id}>
                        <UserOption
                          user={instructor}
                          currentUser={currentUser}
                        />
                      </Select.Option>
                    ),
                )}
              </Select>
            </Form.Item>
          </div>
        </Form>
      )}
    </div>
  );
};

export default CourseInstanceHeaderForm;
