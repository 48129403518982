import { ArrowsAltOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { useLanguage } from '../../context/language';
import { GraphData, GraphResource } from '../types/Graph';

import InsightCardGraph from './InsightCardGraph';
import StatisticsModal from './StatisticsModal';

type StatisticsCardProps = {
  title?: string;
  modalTitle?: string;
  unit?: string;
  tooltipContentGenerator?: (count: number) => string;
  resource?: GraphResource;
  graphData?: GraphData;
  subsidiaryCompanyId?: number;
};

const getDisplayedTextFontSize = (textLength: number) => {
  if (textLength > 21) {
    return 'text-base';
  } else if (textLength > 18) {
    return 'text-lg';
  } else if (textLength > 16) {
    return 'text-xl';
  } else if (textLength > 12) {
    return 'text-2xl';
  } else {
    return 'text-3xl';
  }
};

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  title,
  modalTitle = title,
  unit,
  tooltipContentGenerator,
  resource,
  graphData,
  subsidiaryCompanyId,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [language] = useLanguage();

  const total =
    graphData?.entries
      .map((entry) => entry.value)
      .reduce((previous, current) => previous + current, 0) ?? 0;

  const displayedText = [total?.toLocaleString(language?.code), unit].join(' ');

  const displayedTextFontSize = getDisplayedTextFontSize(displayedText.length);

  return (
    <>
      <div
        className="insights-statistics-graph-card"
        onClick={() => setShowModal(true)}>
        <div className="flex flex-row justify-between mb-1">
          <div className="flex flex-row items-center">
            <span className="text-small text-grayMedium">{title}</span>
          </div>
          <ArrowsAltOutlined className="insights-statistics-graph-card-expand text-lg text-gray-400 transition-colors duration-150 ease-in" />
        </div>
        <div className="relative" style={{ flexGrow: 1 }}>
          <div className="flex flex-row w-48 justify-between">
            <span className={`${displayedTextFontSize} leading-none`}>
              {displayedText}
            </span>
          </div>
          <div className="absolute w-full" style={{ bottom: 0 }}>
            <InsightCardGraph graphData={graphData} />
          </div>
        </div>
      </div>
      <StatisticsModal
        title={modalTitle}
        visible={showModal}
        setVisible={setShowModal}
        resource={resource}
        unit={unit}
        tooltipContentGenerator={tooltipContentGenerator}
        subsidiaryCompanyId={subsidiaryCompanyId}
      />
    </>
  );
};

export default StatisticsCard;
