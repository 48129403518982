import React, { useMemo, useState } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import {
  message,
  Modal,
  Form,
  Input,
  Button,
  Tabs,
  TabsProps,
  Row,
  Col,
  Upload,
  UploadProps,
} from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import SubsidiaryCompany from '../types/SubsidiaryCompany';
import SubsidiaryCompanyAPI from '../SubsidiaryCompanyAPI';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import {
  LockOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { verifyImageUpload } from '../../utils/upload';
import colors from '../../theme/colors';
import { UUID_PATTERN } from '../../utils/validation';
import { useFeatureToggles } from '../../context/featureToggles';

enum TabKey {
  Info = 'info',
  CertificateLogo = 'certificate_logo',
}

const SubsidiaryCompanyModal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.Info);
  const [deletingLogo, setDeletingLogo] = useState<boolean>(false);
  const [currentUser] = useUser();
  const [logoFile, setLogoFile] = useState<File>();

  const { subsidiaryCompanyId } = useParams();
  const id = useMemo(() => Number(subsidiaryCompanyId), [subsidiaryCompanyId]);
  const { subsidiaryCompanies, onClose } = useOutletContext<{
    subsidiaryCompanies?: SubsidiaryCompany[];
    onClose?: () => void;
  }>();
  const subsidiaryCompany = subsidiaryCompanies?.find((b) => b.id === id);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [certificateLogoForm] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [APIKeyValueChanged, setAPIKeyValueChanged] = useState<boolean>(false);

  const { t } = useTranslation();
  const { isElsaIntegrationEnabled } = useFeatureToggles();

  if (!subsidiaryCompany) return null;

  const close = () => {
    navigate('..', { replace: true });
  };

  const submit = () => {
    form.submit();
  };

  const onFinish = async (values: SubsidiaryCompany) => {
    setSubmitting(true);
    try {
      if (
        !subsidiaryCompany.elsaSubsidiaryCompanyId &&
        !!values.elsaSubsidiaryCompanyId &&
        !confirm(t('common.elsaMappingLastWarning'))
      ) {
        setSubmitting(false);
        return;
      }

      await SubsidiaryCompanyAPI.updateSubsidiaryCompany(subsidiaryCompany.id, {
        name: values.name,
        email: values.email ?? undefined,
        limeAPIKey:
          values.limeAPIKey !== null &&
          values.limeAPIKey !== undefined &&
          APIKeyValueChanged
            ? values.limeAPIKey
            : undefined,
        limeServerURL: values.limeServerURL ?? undefined,
        elsaSubsidiaryCompanyId: values.elsaSubsidiaryCompanyId ?? undefined,
      });
      onClose?.();
      close();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      setLogoFile(undefined);
    },
    beforeUpload: (file) => {
      if (!verifyImageUpload(file, t)) {
        return Upload.LIST_IGNORE;
      }

      setLogoFile(file);
      return false;
    },
    maxCount: 1,
    listType: 'picture',
  };

  const onCertificateLogoFinish = async () => {
    setSubmitting(true);
    try {
      const fmData = new FormData();
      if (!!logoFile) {
        fmData.append('image', logoFile);
        const extension = logoFile?.name.split('.').pop();
        if (!!extension) {
          fmData.append('extension', extension);
        }
      } else {
        return;
      }
      await SubsidiaryCompanyAPI.putCertificateLogo(
        subsidiaryCompany.id,
        fmData,
      );
      onClose?.();
      close();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: TabKey.Info,
      label: t('components.SubsidiaryCompanyModal.informationTab'),
      children: (
        <Form
          id="subsidiaryCompanyForm"
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values as SubsidiaryCompany)}>
          <Form.Item
            label={t('common.name')}
            name="name"
            initialValue={subsidiaryCompany.name}
            rules={[
              {
                required: true,
                message: t('components.SubsidiaryCompanyModal.enterName'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('common.email')}
            name="email"
            initialValue={subsidiaryCompany.email}
            rules={[
              {
                type: 'email',
                message: t('components.SubsidiaryCompanyModal.invalidEmail'),
              },
            ]}>
            <Input placeholder={t('common.email')} />
          </Form.Item>
          {userHasPermission(
            currentUser,
            Permission.SUBSIDIARY_COMPANY_LIME_UPDATE,
          ) && (
            <>
              <Form.Item
                label={t('components.SubsidiaryCompanyModal.limeServerUrl')}
                name="limeServerURL"
                initialValue={subsidiaryCompany.limeServerURL}
                rules={[
                  {
                    type: 'url',
                    message: t('components.SubsidiaryCompanyModal.invalidUrl'),
                  },
                ]}>
                <Input
                  placeholder={t(
                    'components.SubsidiaryCompanyModal.limeServerUrl',
                  )}
                />
              </Form.Item>
              <Form.Item
                label={t('components.SubsidiaryCompanyModal.limeApiKey')}
                name="limeAPIKey"
                initialValue={subsidiaryCompany.limeAPIKey}>
                <Input
                  placeholder={t(
                    'components.SubsidiaryCompanyModal.limeApiKey',
                  )}
                  onChange={() => setAPIKeyValueChanged(true)}
                />
              </Form.Item>
              {isElsaIntegrationEnabled && (
                <>
                  <Form.Item
                    label={t('common.elsaMapping')}
                    name="elsaSubsidiaryCompanyId"
                    initialValue={subsidiaryCompany.elsaSubsidiaryCompanyId}
                    rules={[
                      {
                        pattern: UUID_PATTERN,
                        message: t('common.mustBeValidUUID'),
                      },
                    ]}>
                    <Input
                      placeholder={t('common.enterElsaUUID')}
                      disabled={!!subsidiaryCompany.elsaSubsidiaryCompanyId}
                      {...(!!subsidiaryCompany.elsaSubsidiaryCompanyId && {
                        suffix: <LockOutlined />,
                      })}
                    />
                  </Form.Item>

                  {!subsidiaryCompany.elsaSubsidiaryCompanyId && (
                    <div className="flex flex-row gap-4 items-center">
                      <WarningOutlined
                        style={{
                          fontSize: '24px',
                          color: colors.warningYellow,
                        }}
                      />
                      <span>{t('common.elsaMappingWarning')}</span>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Form>
      ),
    },
    {
      key: TabKey.CertificateLogo,
      label: t('components.SubsidiaryCompanyModal.certificateLogoTab'),
      children: (
        <Row gutter={[16, 16]}>
          {subsidiaryCompany.certificateLogo && (
            <Col xs={24} md={12}>
              <img
                src={subsidiaryCompany.certificateLogo.url}
                className="w-full border-dashed border-gray-500"
              />
            </Col>
          )}
          <Col xs={24} md={12}>
            <Form
              id="putCertificateLogoForm"
              layout="vertical"
              form={certificateLogoForm}
              onFinish={onCertificateLogoFinish}>
              <Row>
                <Col xs={24}>
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>
                      {t('common.selectFile')}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ),
    },
  ];

  const onSave = () => {
    switch (activeTab) {
      case TabKey.Info:
        form.submit();
        break;
      case TabKey.CertificateLogo:
        certificateLogoForm.submit();
        break;
      default:
        message.error(t('errors.somethingWentWrong'));
    }
  };

  const onDeleteLogo = async () => {
    setDeletingLogo(true);
    try {
      await SubsidiaryCompanyAPI.deleteCertificateLogo(subsidiaryCompany.id);
      onClose?.();
      close();
    } catch {
      message.error(t('errors.somethingWentWrong'));
    } finally {
      setDeletingLogo(false);
    }
  };

  return (
    <Modal
      title={subsidiaryCompany.name}
      open
      onCancel={close}
      onOk={submit}
      width="60%"
      footer={[
        <Button key="back" onClick={close}>
          {t('common.close')}
        </Button>,
        ...(!!subsidiaryCompany.certificateLogo &&
        activeTab == TabKey.CertificateLogo
          ? [
              <Button
                key="delete"
                type="primary"
                danger
                onClick={onDeleteLogo}
                loading={deletingLogo}>
                {t('components.SubsidiaryCompanyModal.deleteCertificateLogo')}
              </Button>,
            ]
          : []),
        <Button
          key="saveButton"
          type="primary"
          loading={submitting}
          onClick={onSave}>
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={(activeKey) => setActiveTab(activeKey as TabKey)}
      />
    </Modal>
  );
};

export default SubsidiaryCompanyModal;
