import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import {
  message,
  Modal,
  Form,
  Input,
  Button,
  Spin,
  Empty,
  Select,
  InputNumber,
  Row,
  Col,
  Switch,
} from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Market from '../types/Market';
import MarketAPI, { UpdateMarketParams } from '../MarketAPI';
import Currency from '../../types/Currency';
import Language from '../../types/Language';
import { getCurrencies, getLanguages } from '../../api';
import acceptedTimezones from '../../utils/acceptedTimezones.json';

dayjs.extend(utc);
dayjs.extend(timezone);

const MarketModal: React.FC = () => {
  const { marketId } = useParams();
  const id = useMemo(() => Number(marketId), [marketId]);
  const { markets, onChange } = useOutletContext<{
    markets?: Market[];
    onChange: () => void;
  }>();
  const market = markets?.find((b) => b.id === id);

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [loadingCurrencies, setLoadingCurrencies] = useState<boolean>(true);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);

  const { t } = useTranslation();

  useEffect(() => {
    getCurrencies()
      .then(({ data }) => setCurrencies(data))
      .finally(() => setLoadingCurrencies(false));

    getLanguages()
      .then(({ data }) => setLanguages(data))
      .finally(() => setLoadingLanguages(false));
  }, []);

  const close = useCallback(() => {
    navigate('..', { replace: true });
  }, [navigate]);

  const onFinish = useCallback(
    async (values: UpdateMarketParams) => {
      setSubmitting(true);
      try {
        await MarketAPI.updateMarket(id, {
          name: values.name,
          code: values.code,
          timeZoneId: values.timeZoneId ? values.timeZoneId : undefined,
          defaultCurrencyId: values.defaultCurrencyId,
          preferredLanguageId: values.preferredLanguageId,
          mapCenterLatitude: values.mapCenterLatitude
            ? values.mapCenterLatitude
            : undefined,
          mapCenterLongitude: values.mapCenterLongitude
            ? values.mapCenterLongitude
            : undefined,
          allowInstructorSelfAssign: values.allowInstructorSelfAssign,
        });
        onChange();
        close();
      } catch (error) {
        if (isAxiosError(error) && !error.response?.data.message) {
          message.error(t('errors.somethingWentWrong'));
        }
      } finally {
        setSubmitting(false);
      }
    },
    [close, id, onChange, t],
  );

  return (
    <Modal
      title={market?.name}
      open
      onCancel={close}
      onOk={form.submit}
      width="60%"
      footer={[
        <Button key="back" onClick={close}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="updateMarketForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      {market ? (
        <Form
          id="updateMarketForm"
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values)}>
          <Form.Item
            label={t('components.MarketModal.form.name')}
            name="name"
            initialValue={market?.name}
            rules={[
              {
                required: true,
                message: t('components.MarketModal.form.enterName'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('components.MarketModal.form.code')}
            name="code"
            initialValue={market?.code}
            rules={[
              {
                required: true,
                message: t('components.MarketModal.form.enterCode'),
              },
              {
                len: 3,
                message: t('components.MarketModal.form.enterCode'),
              },
            ]}>
            <Input
              placeholder={t('components.MarketModal.form.codePlaceholder')}
            />
          </Form.Item>
          <Form.Item
            label={t('components.MarketModal.form.currency')}
            name="defaultCurrencyId"
            initialValue={market.defaultCurrency.id}
            rules={[
              {
                required: true,
                message: t('components.MarketModal.form.selectCurrency'),
              },
            ]}>
            <Select
              showSearch
              placeholder={t('components.MarketModal.form.selectCurrency')}
              loading={loadingCurrencies}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              filterOption={(input, option: any) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('components.MarketModal.form.noCurrencyFound')}
                />
              }>
              {currencies?.map((currency) => (
                <Select.Option key={currency.id} value={currency.id}>
                  {currency.code}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('common.language')}
            name="preferredLanguageId"
            initialValue={market?.preferredLanguage?.id}
            rules={[
              {
                required: true,
                message: t('components.MarketModal.form.selectLanguage'),
              },
            ]}>
            <Select
              showSearch
              placeholder={t('components.MarketModal.form.selectLanguage')}
              loading={loadingLanguages}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              filterOption={(input, option: any) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t(
                    'components.MarketModal.form.noLanguagesFound',
                  )}
                />
              }>
              {languages?.map((language) => (
                <Select.Option key={language.id} value={language.id}>
                  {t(`languages.${language.code}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('components.MarketModal.form.timeZoneId')}
            initialValue={market.timeZoneId}
            name="timeZoneId"
            rules={[
              {
                required: true,
                message: t('components.MarketModal.form.selectTimezone'),
              },
            ]}>
            <Select
              showSearch
              placeholder={t('components.MarketModal.form.timeZoneIdExample')}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              filterOption={(input, option: any) =>
                option?.children
                  ?.toLowerCase()
                  .replace(/[^a-z0-9\-\+]/g, '')
                  .indexOf(input.toLowerCase().replace(/[^a-z0-9\-\+]/g, '')) >=
                0
              }
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('components.MarketModal.form.noCurrencyFound')}
                />
              }>
              {acceptedTimezones.map((timezone) => (
                <Select.Option key={timezone} value={timezone}>
                  {timezone}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('components.MarketModal.form.allowInstructorSelfAssign')}
            name="allowInstructorSelfAssign"
            initialValue={market?.allowInstructorSelfAssign}
            valuePropName="checked">
            <Switch
              defaultChecked={market?.allowInstructorSelfAssign ?? true}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t('components.MarketModal.form.mapCenterLatitude')}
                name="mapCenterLatitude"
                initialValue={market?.mapCenterLatitude}>
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={t(
                    'components.MarketModal.form.mapCenterLatitudePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('components.MarketModal.form.mapCenterLongitude')}
                name="mapCenterLongitude"
                initialValue={market?.mapCenterLongitude}>
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder={t(
                    'components.MarketModal.form.mapCenterLongitudePlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Spin />
      )}
    </Modal>
  );
};

export default MarketModal;
