import React, { useState } from 'react';
import { message, Modal, Form, Input, Button } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import SubsidiaryCompanyAPI from '../SubsidiaryCompanyAPI';
import SubsidiaryCompany from '../types/SubsidiaryCompany';
import { WarningOutlined } from '@ant-design/icons';
import colors from '../../theme/colors';
import { UUID_PATTERN } from '../../utils/validation';
import { useFeatureToggles } from '../../context/featureToggles';

type CreateSubsidiaryCompanyModalProps = {
  visible: boolean;
  onCreateSubsidiaryCompany?: (subsidiaryCompany: SubsidiaryCompany) => void;
  onCancel?: () => void;
};

const CreateSubsidiaryCompanyModal: React.FC<
  CreateSubsidiaryCompanyModalProps
> = ({ visible, onCreateSubsidiaryCompany, onCancel }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isElsaIntegrationEnabled } = useFeatureToggles();

  const submit = () => {
    form.submit();
  };

  const onFinish = async (values: SubsidiaryCompany) => {
    setSubmitting(true);
    try {
      const { data: subsidiaryCompany } =
        await SubsidiaryCompanyAPI.createSubsidiaryCompany({
          name: values.name,
          email: values.email ?? undefined,
          limeAPIKey: values.limeAPIKey ?? undefined,
          limeServerURL: values.limeServerURL ?? undefined,
          elsaSubsidiaryCompanyId: values.elsaSubsidiaryCompanyId ?? undefined,
        });
      onCreateSubsidiaryCompany?.(subsidiaryCompany);
      form.resetFields();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={t('components.CreateSubsidiaryCompanyModal.newSubsidiaryCompany')}
      open={visible}
      onCancel={onCancel}
      onOk={submit}
      width="60%"
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createSubsidiaryCompanyForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createSubsidiaryCompanyForm"
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish(values as SubsidiaryCompany)}>
        <Form.Item
          label={t('common.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('components.CreateSubsidiaryCompanyModal.fillOutName'),
            },
          ]}>
          <Input placeholder={t('common.name')} />
        </Form.Item>
        <Form.Item
          label={t('common.email')}
          name="email"
          rules={[
            {
              type: 'email',
              message: t(
                'components.CreateSubsidiaryCompanyModal.invalidEmail',
              ),
            },
          ]}>
          <Input placeholder={t('common.email')} />
        </Form.Item>
        <Form.Item
          label={t('components.CreateSubsidiaryCompanyModal.limeServerUrl')}
          name="limeServerURL"
          rules={[
            {
              type: 'url',
              message: t('components.CreateSubsidiaryCompanyModal.invalidUrl'),
            },
          ]}>
          <Input
            placeholder={t(
              'components.CreateSubsidiaryCompanyModal.limeServerUrl',
            )}
          />
        </Form.Item>
        <Form.Item
          label={t('components.CreateSubsidiaryCompanyModal.limeApiKey')}
          name="limeAPIKey">
          <Input
            placeholder={t(
              'components.CreateSubsidiaryCompanyModal.limeApiKey',
            )}
          />
        </Form.Item>
        {isElsaIntegrationEnabled && (
          <>
            <Form.Item
              label={t('common.elsaMapping')}
              name="elsaSubsidiaryCompanyId"
              rules={[
                {
                  pattern: UUID_PATTERN,
                  message: t('common.mustBeValidUUID'),
                },
              ]}>
              <Input placeholder={t('common.enterElsaUUID')} />
            </Form.Item>

            <div className="mt-4 flex flex-row gap-4 items-center">
              <WarningOutlined
                style={{ fontSize: '24px', color: colors.warningYellow }}
              />
              <span>{t('common.elsaMappingWarning')}</span>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateSubsidiaryCompanyModal;
