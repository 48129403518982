import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Form, Select, DatePicker, Button, Checkbox, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import { CourseInstanceStatus } from '../types/CourseInstance';
import User, { UserRole } from '../../user/types/User';
import Course from '../../course/types/Course';
import ClientCompanyAPI from '../../bookingOrders/api/ClientCompanyAPI';
import UserAPI from '../../user/UserAPI';
import { useUser } from '../../user/userContext';
import ClientCompany from '../../bookingOrders/types/ClientCompany';
import {
  sortInstructorsByFavorite,
  userHasPermission,
} from '../../user/userUtils';
import CourseAPI from '../../course/CourseAPI';
import SubsidiaryCompanyAPI from '../../subsidiaryCompany/SubsidiaryCompanyAPI';
import ContentCard from '../../components/ContentCard';
import UserOption from '../../components/UserOption';
import ConditionalTooltip from '../../components/ConditionalTooltip';
import CourseOption from '../../components/CourseOption';
import { CourseInstanceApiFilter } from '../CourseInstanceAPI';
import { getSearchParam, stringifyValues } from '../../utils/searchParams';
import { Permission } from '../../user/types/Permission';

import CourseInstanceStatusBadge from './CourseInstanceStatusBadge';
import ClientCompanySelectOption from '../../clientCompany/ClientCompanySelectOption';
import isEqual from 'lodash/isEqual';

type FilterFormValues = {
  userIds?: User['id'][];
  courseIds?: Course['id'][];
  subsidiaryCompanyIds?: SubsidiaryCompany['id'][];
  clientCompanyIds?: ClientCompany['id'][];
  creatorIds?: User['id'][];
  startsAt?: dayjs.Dayjs;
  endsAt?: dayjs.Dayjs;
  status?: CourseInstanceStatus[];
  completed?: boolean;
};

type CourseInstanceFilterProps = {
  visible: boolean;
  onChange: (filter: CourseInstanceApiFilter, pristine: boolean) => void;
};

const CourseInstanceFilter: React.FC<CourseInstanceFilterProps> = ({
  visible,
  onChange,
}) => {
  const [form] = Form.useForm();
  const selectedClientCompanyIds = Form.useWatch('subsidiaryCompanyIds', form);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [instructors, setInstructors] = useState<User[]>();
  const [loadingInstructors, setLoadingInstructors] = useState<boolean>(false);
  const [courses, setCourses] = useState<Course[]>();
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [clientCompanies, setClientCompanies] = useState<ClientCompany[]>();
  const [loadingClientCompanies, setLoadingClientCompanies] =
    useState<boolean>(false);
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState<boolean>(false);

  const [currentUser] = useUser();
  const { t } = useTranslation();

  const [creators, setCreators] = useState<User[]>();
  const [loadingCreators, setLoadingCreators] = useState<boolean>(false);

  const fetchInstructors = useCallback(async () => {
    setLoadingInstructors(true);
    try {
      const { data } = await UserAPI.getUsers({ roles: [UserRole.Instructor] });
      const sortedInstructors = sortInstructorsByFavorite(data, currentUser);
      setInstructors(sortedInstructors);
    } finally {
      setLoadingInstructors(false);
    }
  }, [currentUser]);

  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const { data } = await CourseAPI.getCourses();
      setCourses(data);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  const fetchClientCompanies = useCallback(
    async (subsidiaryCompanyIds?: SubsidiaryCompany['id'][]) => {
      setLoadingClientCompanies(true);
      try {
        const { data } = await ClientCompanyAPI.getClientCompanies(
          subsidiaryCompanyIds ? { subsidiaryCompanyIds } : undefined,
        );
        setClientCompanies(data);
      } finally {
        setLoadingClientCompanies(false);
      }
    },
    [],
  );

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  const fetchCreators = useCallback(async () => {
    setLoadingCreators(true);
    try {
      const { data } = await UserAPI.getUsers({ roles: [UserRole.Admin] });
      setCreators(data);
    } finally {
      setLoadingCreators(false);
    }
  }, []);

  const defaultFilter = useMemo<FilterFormValues>(
    () => ({
      startsAt: dayjs().startOf('day'),
      subsidiaryCompanyIds: undefined,
      completed: false,
      courseIds: undefined,
      creatorIds: undefined,
      clientCompanyIds: undefined,
      userIds: undefined,
      endsAt: undefined,
      status: [
        CourseInstanceStatus.Confirmed,
        CourseInstanceStatus.Ordered,
        CourseInstanceStatus.Preliminary,
      ],
    }),
    [],
  );

  const isPristine = useCallback(
    () => isEqual(form.getFieldsValue(), defaultFilter),
    [defaultFilter, form],
  );

  const filter = useCallback(
    (values: FilterFormValues) => {
      onChange?.(
        {
          instructorIds: values.userIds,
          courseIds: values.courseIds,
          creatorIds: values.creatorIds,
          startsAt: values.startsAt?.toISOString(),
          endsAt: values.endsAt?.toISOString(),
          status: values.status,
          subsidiaryCompanyIds: values.subsidiaryCompanyIds,
          clientCompanyIds: values.clientCompanyIds,
          completed: values.completed,
        },
        isPristine(),
      );
    },
    [isPristine, onChange],
  );

  const applyFilterValues = useCallback(
    (values: FilterFormValues) => {
      setSearchParams(
        stringifyValues({
          ...values,
          date: undefined,
          startsAt: values.startsAt?.startOf('day').toISOString(),
          endsAt: values.endsAt?.endOf('day').toISOString(),
        }),
      );
      filter(values);
    },
    [filter, setSearchParams],
  );

  /*
   * Debounce the call to history.psh to prevent sending a request
   * for every keystroke.
   */
  const debounceOnValuesChange = useMemo(
    () => debounce(applyFilterValues, 400),
    [applyFilterValues],
  );

  const initializeFormFromSearchParams = useCallback(() => {
    if (location.search) {
      const formValues: FilterFormValues = {
        userIds: getSearchParam(searchParams, 'userIds', 'list')?.map(Number),
        courseIds: getSearchParam(searchParams, 'courseIds', 'list')?.map(
          Number,
        ),
        creatorIds: getSearchParam(searchParams, 'creatorIds', 'list')?.map(
          Number,
        ),
        clientCompanyIds: getSearchParam(
          searchParams,
          'clientCompanyIds',
          'list',
        )?.map(Number),
        subsidiaryCompanyIds: getSearchParam(
          searchParams,
          'subsidiaryCompanyIds',
          'list',
        )?.map(Number),
        startsAt: getSearchParam(searchParams, 'startsAt', 'moment'),
        endsAt: getSearchParam(searchParams, 'endsAt', 'moment'),
        status: getSearchParam<CourseInstanceStatus>(
          searchParams,
          'status',
          'list',
        ),
        completed:
          getSearchParam(searchParams, 'completed', 'first') === 'true',
      };

      form.setFieldsValue(formValues);
    }
  }, [form, location.search, searchParams]);

  useEffect(() => {
    if (visible) {
      if (userHasPermission(currentUser, Permission.USER_READ_LIST)) {
        fetchInstructors();
        fetchCreators();
      }

      if (
        userHasPermission(currentUser, Permission.SUBSIDIARY_COMPANY_READ_LIST)
      ) {
        fetchSubsidiaryCompanies();
      }

      if (userHasPermission(currentUser, Permission.COURSE_READ_LIST)) {
        fetchCourses();
      }
    }
  }, [
    currentUser,
    fetchCourses,
    fetchCreators,
    fetchInstructors,
    fetchSubsidiaryCompanies,
    visible,
  ]);

  useEffect(() => {
    initializeFormFromSearchParams();
    applyFilterValues(form.getFieldsValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (userHasPermission(currentUser, Permission.CLIENT_COMPANY_READ)) {
      fetchClientCompanies(selectedClientCompanyIds);
    }
  }, [currentUser, fetchClientCompanies, selectedClientCompanyIds, visible]);

  const isEmpty = useCallback(
    () => Object.values(form.getFieldsValue()).filter(Boolean).length <= 0,
    [form],
  );

  const onValuesChange = useCallback(
    (values: FilterFormValues) => {
      debounceOnValuesChange(values);
    },
    [debounceOnValuesChange],
  );

  const clearFilter = useCallback(() => {
    const formValues = form.getFieldsValue();
    let emptyFilter = Object.keys(formValues).reduce(
      (acc, key) => ({ ...acc, [key]: undefined }),
      {},
    );
    if (
      !userHasPermission(currentUser, Permission.COURSE_INSTANCE_READ_GLOBAL)
    ) {
      emptyFilter = {
        startsAt: undefined,
        status: [
          CourseInstanceStatus.Confirmed,
          CourseInstanceStatus.Preliminary,
          CourseInstanceStatus.Ordered,
        ],
      };
    }
    form.setFieldsValue(emptyFilter);
    onValuesChange({});
    if (JSON.stringify(defaultFilter) === JSON.stringify(formValues)) {
      filter(emptyFilter);
    }
  }, [currentUser, defaultFilter, filter, form, onValuesChange]);

  return (
    <ContentCard className={`flex flex-col ${!visible ? 'hidden' : ''}`}>
      <Button
        type="text"
        icon={<CloseOutlined />}
        disabled={isEmpty()}
        className="self-end mb-2"
        onClick={() => clearFilter()}>
        {t('common.clear')}
      </Button>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={defaultFilter}
        onValuesChange={(_, values) =>
          onValuesChange(values as FilterFormValues)
        }>
        <div className="md:grid gap-x-4 grid-rows-5 grid-cols-2 grid-flow-col">
          <ConditionalTooltip
            component={
              <Form.Item
                label={t('components.CourseInstanceFilter.instructors')}
                name="userIds">
                <Select
                  mode="multiple"
                  allowClear
                  disabled={
                    !userHasPermission(currentUser, Permission.USER_READ_LIST)
                  }
                  loading={loadingInstructors}
                  filterOption={(input, option) =>
                    option?.props.children.props.user?.name
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  <Select.Option key={0} value={0}>
                    <UserOption
                      user={undefined}
                      noUserText={t(
                        'components.CourseInstanceFilter.noInstructor',
                      )}
                    />
                  </Select.Option>
                  {instructors?.map(
                    (instructor) =>
                      instructor.id && (
                        <Select.Option
                          key={instructor.id}
                          value={instructor.id}>
                          <UserOption
                            user={instructor}
                            currentUser={currentUser}
                          />
                        </Select.Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            }
            condition={
              !userHasPermission(currentUser, Permission.USER_READ_LIST)
            }
            displayTitle={t('components.CourseInstanceFilter.cannotBeUsed')}
          />
          <ConditionalTooltip
            component={
              <Form.Item label={t('common.createdBy')} name="creatorIds">
                <Select
                  mode="multiple"
                  allowClear
                  disabled={
                    !userHasPermission(currentUser, Permission.USER_READ_LIST)
                  }
                  loading={loadingCreators}
                  filterOption={(input, option) =>
                    option?.props.children.props.user.name
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {creators?.map((creator) => (
                    <Select.Option key={creator.id!} value={creator.id!}>
                      <UserOption user={creator} />
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
            condition={
              !userHasPermission(currentUser, Permission.USER_READ_LIST)
            }
            displayTitle={t('components.CourseInstanceFilter.cannotBeUsed')}
          />
          <Form.Item
            label={t('components.CourseInstanceFilter.from')}
            name="startsAt">
            <DatePicker
              disabled={form.getFieldValue('completed')}
              size="large"
              format="YYYY-MM-DD"
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            label={t('components.CourseInstanceFilter.to')}
            name="endsAt">
            <DatePicker
              disabled={form.getFieldValue('completed')}
              size="large"
              format="YYYY-MM-DD"
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            label={t('components.CourseInstanceFilter.clients')}
            name="clientCompanyIds">
            <Select
              mode="multiple"
              allowClear
              loading={loadingClientCompanies}
              optionFilterProp="filterValue"
              disabled={
                !userHasPermission(currentUser, Permission.CLIENT_COMPANY_READ)
              }>
              {clientCompanies?.map((clientCompany) => (
                <Select.Option
                  key={clientCompany.id}
                  value={clientCompany.id}
                  filterValue={clientCompany.name}>
                  <ClientCompanySelectOption clientCompany={clientCompany} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('common.courses')} name="courseIds">
            <Select
              mode="multiple"
              allowClear
              loading={loadingCourses}
              filterOption={(input, option) =>
                option?.props.children.props.course.name
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {courses?.map((course) => (
                <Select.Option key={course.id} value={course.id}>
                  <CourseOption user={currentUser} course={course} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <ConditionalTooltip
            component={
              <Form.Item
                label={t('components.CourseInstanceFilter.status')}
                name="status">
                <Select
                  allowClear
                  disabled={
                    form.getFieldValue('completed') ||
                    !userHasPermission(
                      currentUser,
                      Permission.COURSE_INSTANCE_READ_GLOBAL,
                    )
                  }
                  mode="multiple">
                  {[
                    CourseInstanceStatus.Confirmed,
                    CourseInstanceStatus.Preliminary,
                    CourseInstanceStatus.Ordered,
                    CourseInstanceStatus.Canceled,
                  ].map((status) => (
                    <Select.Option key={status} value={status}>
                      <CourseInstanceStatusBadge status={status} />
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
            displayTitle={t('components.CourseInstanceFilter.cannotBeUsed')}
            condition={
              !userHasPermission(
                currentUser,
                Permission.COURSE_INSTANCE_READ_GLOBAL,
              )
            }
            key="formStatus"
          />
          <ConditionalTooltip
            component={
              <Form.Item
                label={`${t(
                  'components.CourseInstanceFilter.responsibleCompany',
                )}:`}
                name="subsidiaryCompanyIds">
                <Select
                  disabled={
                    !userHasPermission(
                      currentUser,
                      Permission.SUBSIDIARY_COMPANY_READ_LIST,
                    ) || loadingSubsidiaryCompanies
                  }
                  mode="multiple"
                  filterOption={(input, option) =>
                    (option?.title
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) ?? 0) >= 0
                  }>
                  {subsidiaryCompanies?.map((subsidiaryCompany) => (
                    <Select.Option
                      key={subsidiaryCompany.id}
                      value={subsidiaryCompany.id}
                      title={subsidiaryCompany.name}>
                      {subsidiaryCompany.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
            displayTitle={t('components.CourseInstanceFilter.cannotBeUsed')}
            condition={
              !userHasPermission(
                currentUser,
                Permission.SUBSIDIARY_COMPANY_READ_LIST,
              )
            }
          />
          <Form.Item
            label={t('components.CourseInstanceFilter.completed')}
            name="completed"
            valuePropName="checked">
            {!userHasPermission(
              currentUser,
              Permission.COURSE_INSTANCE_READ_GLOBAL,
            ) ? (
              <Tooltip
                title={t('components.CourseInstanceFilter.cannotBeUsed')}>
                <Checkbox
                  disabled={
                    !userHasPermission(
                      currentUser,
                      Permission.COURSE_INSTANCE_READ_GLOBAL,
                    )
                  }
                />
              </Tooltip>
            ) : (
              <Checkbox
                disabled={
                  !userHasPermission(
                    currentUser,
                    Permission.COURSE_INSTANCE_READ_GLOBAL,
                  )
                }
              />
            )}
          </Form.Item>
        </div>
      </Form>
    </ContentCard>
  );
};
export default CourseInstanceFilter;
