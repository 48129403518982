import React, { useCallback, useEffect, useState } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  HeatmapLayer,
} from '@react-google-maps/api';
import { Spin } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Geolocation } from '../../types/Location';
import CourseInstanceAPI from '../../courseInstance/CourseInstanceAPI';

type CourseInstanceHeatMapProps = {
  initialCenter: Geolocation;
  initialZoom?: number;
  className?: string;
  fromDate: Dayjs;
  toDate: Dayjs;
};

const CourseInstanceHeatMap: React.FC<CourseInstanceHeatMapProps> = ({
  initialCenter,
  initialZoom,
  className = '',
  fromDate,
  toDate,
}) => {
  const { t } = useTranslation();
  const apiKey = process.env.REACT_APP_MAPS_API_KEY ?? '';
  const defaultZoom = 5;

  const [heatMapData, setHeatMapData] = useState<google.maps.MVCArray>();
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>();
  const [zoom] = useState(initialZoom ?? defaultZoom);

  const fetchHeatMapData = useCallback(async () => {
    const bounds = map?.getBounds();
    if (!bounds) {
      return;
    }

    setLoading(true);
    try {
      const params = {
        southWestLat: bounds.getSouthWest().lat(),
        southWestLng: bounds.getSouthWest().lng(),
        northEastLat: bounds.getNorthEast().lat(),
        northEastLng: bounds.getNorthEast().lng(),
        from: fromDate.startOf('day').toISOString(),
        to: toDate.endOf('day').toISOString(),
      };
      const { data } = await CourseInstanceAPI.getCourseInstanceHeatMap(params);
      setHeatMapData(
        new google.maps.MVCArray(
          data.map(
            (heatMapData) =>
              new google.maps.LatLng(heatMapData.lat, heatMapData.lng),
          ),
        ),
      );
    } finally {
      setLoading(false);
    }
  }, [fromDate, map, toDate]);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    fetchHeatMapData();
  }, [fetchHeatMapData]);

  const onIdle = useCallback(() => {
    fetchHeatMapData();
  }, [fetchHeatMapData]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  return (
    <div className="grid grid-cols-1 h-full border-2 border-offwhiteDark border-solid rounded-lg">
      <div
        className="flex col-start-1 row-start-1 pt-5 px-6 w-full justify-between bg-gradient-to-b pointer-events-none from-heatMapGradient z-10 rounded-lg"
        style={{ minHeight: 500 }}>
        <div className="text-lg font-medium">
          {t('components.CourseInstanceHeatMap.bookedCourseInstances')}
        </div>
        <Spin spinning={loading} className="ml-3" />
      </div>
      <div className={`z-0 col-start-1 row-start-1 ${className}`}>
        {isLoaded ? (
          <GoogleMap
            mapContainerClassName="w-full h-full rounded-lg"
            clickableIcons={false}
            options={{
              styles: [
                {
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'off' }],
                },
              ],
              mapId: '26a46e9c4fbf9e24',
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
            }}
            onUnmount={onUnmount}
            onLoad={onLoad}
            onIdle={onIdle}
            center={initialCenter}
            zoom={zoom}>
            {heatMapData && <HeatmapLayer data={heatMapData} />}
          </GoogleMap>
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );
};

export default CourseInstanceHeatMap;
