import { PlusCircleFilled } from '@ant-design/icons';
import { Form, Select, Spin, Tooltip } from 'antd';
import { Rule } from 'antd/lib/form';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const { Option } = Select;

type FieldSelectOption = {
  primaryText: string;
  secondaryText?: string;
  id: number | string;
};

type FieldSelectProps = {
  className?: string;
  title?: string;
  placeholderText?: string;
  createButtonText?: string;
  onCreate?: () => void;
  onSelect?: (id: number) => void;
  onSearch?: (query: string) => void;
  elements?: FieldSelectOption[];
  loading?: boolean;
  loadingOptions?: boolean;
  disabled?: boolean;
  disabledText?: string;
  initialValue?: number | string[];
  formName?: string;
  formLabel?: string;
  formRules?: Rule[];
  defaultOpen?: boolean;
  mode?: 'multiple' | 'tags';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FieldSelect: React.FC<FieldSelectProps> = ({
  title,
  placeholderText,
  createButtonText,
  onCreate,
  onSelect,
  onSearch,
  elements,
  className,
  loading,
  loadingOptions,
  disabled,
  disabledText,
  initialValue,
  formName,
  formLabel,
  formRules,
  defaultOpen,
  mode,
}) => {
  const primaryTextIncludesInput = (
    input: string,
    option?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    const primaryText = option?.title; // Use title of element
    return typeof primaryText === 'string'
      ? textIncludesInput(input, primaryText)
      : false;
  };

  const secondaryTextIncludesInput = (
    input: string,
    option?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => {
    const secondaryText =
      option?.props?.children?.props?.children[1]?.props?.children; // Find seconday text of element
    return typeof secondaryText === 'string'
      ? textIncludesInput(input, secondaryText)
      : false;
  };

  const textIncludesInput = (input: string, text: string) =>
    text.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const showSecondaryText =
    elements && elements.length > 0 && elements[0].secondaryText;

  return (
    <div className={className}>
      <div className="pb-1 font-medium text-blueGrayDark">{title}</div>
      <Tooltip
        placement="bottom"
        title={<div>{disabledText}</div>}
        trigger={disabled && disabledText ? ['hover'] : []}>
        <Form.Item
          name={formName}
          className="m-0"
          initialValue={initialValue}
          label={formLabel}
          rules={formRules}>
          <Select
            value={initialValue}
            loading={loading}
            showSearch
            defaultOpen={defaultOpen}
            className={twMerge('flex', showSecondaryText && 'h-16')}
            placeholder={placeholderText}
            allowClear
            disabled={disabled}
            onSearch={onSearch}
            mode={mode}
            filterOption={
              !onSearch
                ? (input, option) =>
                    primaryTextIncludesInput(input, option) ||
                    secondaryTextIncludesInput(input, option)
                : () => true
            }
            onSelect={(id) => {
              if (id && onSelect) {
                onSelect(id.valueOf() as number);
              }
            }}
            notFoundContent={
              loadingOptions ? (
                <div className="flex justify-center items-center w-full my-3">
                  <Spin />
                </div>
              ) : undefined
            }
            dropdownRender={(menu) => (
              <div>
                {onCreate && (
                  <div
                    className="flex w-full items-center px-5 py-2 border-solid border-gray-200 border-0 border-b-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => onCreate?.()}>
                    <PlusCircleFilled className="mr-2 text-safeLifeLight" />
                    {createButtonText}
                  </div>
                )}
                {menu}
              </div>
            )}>
            {elements &&
              elements.map((element) => (
                <Option
                  key={element.id}
                  value={element.id}
                  title={element.primaryText}>
                  <div>
                    <div>{element.primaryText}</div>
                    <div className="text-xs text-gray-600 -mt-1">
                      {element.secondaryText}
                    </div>
                  </div>
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Tooltip>
    </div>
  );
};

export default FieldSelect;
