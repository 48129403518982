export const formatRevenue = (
  value?: number,
  currencyCode?: string,
): string => {
  const formatter = Intl.NumberFormat('en-US', {
    ...(currencyCode && {
      style: 'currency',
      currency: currencyCode,
    }),
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return value ? formatter.format(value) : '';
};
