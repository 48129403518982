import { debounce } from 'lodash';
import { useRef, useState } from 'react';

type UseDebounceProps<V> = {
  beforeDebounce?: (value: V) => void;
  debounceTime?: number;
};

export default function useDebounce<T, V>(
  promiseGenerator: (value: V) => Promise<T> | void,
  onResolved: (resolution: T) => void,
  { beforeDebounce, debounceTime = 1000 }: UseDebounceProps<V> = {},
): { debounce: (value: V) => void; loading: boolean } {
  const [loading, setLoading] = useState(false);
  const debounceRef = useRef(0);

  return {
    debounce: (value) => {
      debounceRef.current += 1;
      const debounceId = debounceRef.current;
      setLoading(true);

      beforeDebounce?.(value);

      debounce((value: V) => {
        promiseGenerator(value)
          ?.then((res) => {
            if (debounceRef.current !== debounceId) return;
            onResolved(res);
            setLoading(false);
          })
          .catch(() => {});
      }, debounceTime)(value);
    },
    loading,
  };
}
