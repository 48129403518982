import User from '../user/types/User';
import client from '../api/client';

export type LoginParams = {
  email: string;
  password: string;
};

export type ResetPasswordParams = {
  token: string;
  password: string;
};

const login = (params: LoginParams) =>
  client.post<{ user: User; jwt: string }>('/users/login', params);

type ResetPasswordRequestParams = {
  email: string;
};

const resetPasswordRequest = (params: ResetPasswordRequestParams) =>
  client.post('/users/fe/reset-password-request', params);

const resetPassword = (params: ResetPasswordParams) =>
  client.post('/users/reset-password', params);

export default {
  login,
  resetPasswordRequest,
  resetPassword,
};
