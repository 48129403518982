export enum IntervalOption {
  Yearly = 'yearly',
  Quaterly = 'quaterly',
  Monthly = 'monthly',
  Weekly = 'weekly',
}

export enum BucketSizeOption {
  Monthly = 30,
  Weekly = 7,
  Daily = 1,
}
