import User from '../../user/types/User';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import CourseInstance from '../../courseInstance/types/CourseInstance';

import ContactPerson from './ContactPerson';
import BookingOrderComment from './BookingOrderComment';
import ClientCompany from './ClientCompany';
import InvoiceRecipient from './InvoiceRecipient';
import SelfServiceOrder from './SelfServiceOrder';

export default interface BookingOrder {
  id: number;
  clientCompany: ClientCompany;
  subsidiaryCompany: SubsidiaryCompany;
  invoiceRecipient?: InvoiceRecipient;
  invoiceReference?: string;
  comments?: BookingOrderComment[];
  contactPerson?: ContactPerson;
  courseInstances?: CourseInstance[];
  createdBy?: User;
  responsibleBooker?: User;
  status?: BookingOrderStatus;
  limeObjectId?: number | null;
  vismaSalesOrderId?: string | null;
  isInvoiced?: boolean;
  selfServiceOrder?: SelfServiceOrder;
  invoicedAt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export enum BookingOrderStatus {
  Canceled = 'canceled',
  Preliminary = 'preliminary',
  Confirmed = 'confirmed',
  Ordered = 'ordered',
}
