import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import SubsidiaryCompany from '../types/SubsidiaryCompany';
import SubsidiaryCompanyAPI from '../SubsidiaryCompanyAPI';
import CreateSubsidiaryCompanyModal from '../components/CreateSubsidiaryCompanyModal';
import SubsidiaryCompanyResults from '../components/SubsidiaryCompanyResults';
import { useUser } from '../../user/userContext';
import { userHasAnyPermission, userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

const SubsidiaryCompanies: React.FC = () => {
  const [currentUser] = useUser();
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState<boolean>(false);
  const [showSubsidiaryCompanyModal, setShowSubsidiaryCompanyModal] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  useEffect(() => {
    fetchSubsidiaryCompanies();
  }, [fetchSubsidiaryCompanies]);

  const getLink = useCallback((subsidiaryCompany: SubsidiaryCompany) => {
    return `${subsidiaryCompany.id}`;
  }, []);

  const canOpenSubsidiaryCompanyDetails = userHasAnyPermission(currentUser, [
    Permission.SUBSIDIARY_COMPANY_UPDATE,
    Permission.SUBSIDIARY_COMPANY_DELETE,
  ]);

  return (
    <>
      <div className="flex justify-between">
        <h2>{t('views.SubsidiaryCompanies.subsidiaryCompanies')}</h2>
        {userHasPermission(
          currentUser,
          Permission.SUBSIDIARY_COMPANY_CREATE,
        ) && (
          <Button
            type="primary"
            className="text-sm font-medium"
            icon={<PlusOutlined />}
            onClick={() => setShowSubsidiaryCompanyModal(true)}>
            {t('views.SubsidiaryCompanies.newSubsidiaryCompany')}
          </Button>
        )}
      </div>

      <SubsidiaryCompanyResults
        subsidiaryCompanies={subsidiaryCompanies}
        loading={loadingSubsidiaryCompanies}
        getLink={getLink}
        canOpenSubsidiaryCompanyDetails={canOpenSubsidiaryCompanyDetails}
      />

      {userHasPermission(currentUser, Permission.SUBSIDIARY_COMPANY_CREATE) && (
        <CreateSubsidiaryCompanyModal
          visible={showSubsidiaryCompanyModal}
          onCreateSubsidiaryCompany={() => {
            setShowSubsidiaryCompanyModal(false);
            window.location.reload();
          }}
          onCancel={() => setShowSubsidiaryCompanyModal(false)}
        />
      )}

      {canOpenSubsidiaryCompanyDetails && (
        <Outlet
          context={{ onClose: fetchSubsidiaryCompanies, subsidiaryCompanies }}
        />
      )}
    </>
  );
};

export default SubsidiaryCompanies;
