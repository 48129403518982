import React, { useCallback, useEffect, useState } from 'react';
import { Empty, Modal, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import UserAPI from '../../user/UserAPI';
import CourseInstanceAPI from '../CourseInstanceAPI';
import {
  CourseInstanceCardData,
  CourseInstanceStatus,
} from '../types/CourseInstance';
import * as DateUtils from '../../utils/date';
import User from '../../user/types/User';
import Gravatar from '../../user/components/Gravatar';

import CourseInstanceStatusIcon from './CourseInstanceStatusIcon';
import { statusText } from './CourseInstanceStatusBadge';

type SwapInstructorModalProps = {
  firstCourseInstance: CourseInstanceCardData;
  setShowModal?: (value: boolean) => void;
  onSubmit?: () => void;
};

type SwapInstructorCardProps = {
  courseInstance: CourseInstanceCardData;
  setSelectedCourseInstanceId?: (courseInstanceId: number) => void;
  selected?: boolean;
};

const SwapInstructorCard: React.FC<SwapInstructorCardProps> = ({
  courseInstance,
  setSelectedCourseInstanceId,
  selected,
}) => {
  const { t } = useTranslation();

  const getSelectionClassNames = () => {
    if (setSelectedCourseInstanceId) {
      if (selected) {
        return 'bg-blue-100 border-blue-300';
      } else {
        return 'hover:bg-gray-100';
      }
    } else {
      return 'bg-gray-200';
    }
  };

  return (
    <div
      onClick={() => setSelectedCourseInstanceId?.(courseInstance.id)}
      className={`grid overflow-hidden justify-items-start place-items-center py-3 my-3 
      pr-4 border-2 gap-4 border-solid border-gray-200 rounded-lg shadow-md text-ellipsis-multiline instructor-swap-card 
      ${getSelectionClassNames()}
     `}>
      <Tooltip
        className="justify-self-center pl-2"
        placement="topLeft"
        title={statusText(t)[courseInstance.status]}>
        <CourseInstanceStatusIcon
          courseInstanceStatus={courseInstance.status}
        />
      </Tooltip>
      <div>
        <div className="text-sm">
          {DateUtils.formatDate(courseInstance.startsAt)}
          <br />
          {DateUtils.formatTime(courseInstance.startsAt)} -{' '}
          {DateUtils.formatTime(courseInstance.endsAt)}
        </div>
        <div className="text-sm text-gray-500">{courseInstance.address}</div>
      </div>
      <Tooltip placement="topLeft" title={courseInstance?.clientName}>
        <div className="text-ellipsis-multiline break-all">
          {courseInstance?.clientName}
        </div>
      </Tooltip>
      <Tooltip
        placement="topLeft"
        title={
          <>
            <div>
              {courseInstance?.instructor?.name ||
                t('components.SwapInstructorModal.missingInstructor')}
            </div>
            <div>
              {courseInstance?.instructor?.email ||
                `c/o ${courseInstance?.createdByName}`}
            </div>
          </>
        }>
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <Gravatar
              email={courseInstance?.instructor?.email}
              internal={courseInstance.instructor?.internal}
              isInstructor={true}
            />
          </div>
          <div className="ml-4 overflow-hidden">
            <div className="text-sm font-medium">
              {courseInstance?.instructor?.name ||
                t('components.SwapInstructorModal.missingInstructor')}
            </div>
            <div className="text-sm text-gray-500 text-ellipsis">
              {courseInstance?.instructor?.email ||
                `c/o ${courseInstance?.createdByName}`}
            </div>
          </div>
        </div>
      </Tooltip>
      <Tooltip placement="topLeft" title={courseInstance?.course?.name}>
        <div className="text-ellipsis-multiline">
          {courseInstance?.course?.name}
        </div>
      </Tooltip>
      <Tooltip
        placement="topLeft"
        title={courseInstance?.subsidiaryCompanyName}>
        <div className="text-ellipsis-multiline">
          {courseInstance?.subsidiaryCompanyName}
        </div>
      </Tooltip>
    </div>
  );
};

const SwapInstructorModal: React.FC<SwapInstructorModalProps> = ({
  firstCourseInstance,
  setShowModal,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [courseInstances, setCourseInstances] =
    useState<CourseInstanceCardData[]>();
  const [instructor, setInstructor] = useState<User>();
  const [selectedCourseInstanceId, setSelectedCourseInstanceId] =
    useState<number>();
  const [loadingInstructor, setloadingInstructor] = useState<boolean>(false);
  const [loadingCourseInstances, setLoadingCourseInstances] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const fetchInstructor = useCallback(async () => {
    if (!firstCourseInstance?.instructor) {
      return;
    }

    setloadingInstructor(true);
    try {
      const { data } = await UserAPI.getUser(firstCourseInstance.instructor.id);
      setInstructor(data);
    } finally {
      setloadingInstructor(false);
    }
  }, [firstCourseInstance.instructor]);

  const fetchSwappableCourseInstances = useCallback(async () => {
    if (!firstCourseInstance?.course || !instructor) {
      return;
    }

    setLoadingCourseInstances(true);
    try {
      const { data } = await CourseInstanceAPI.getCourseInstances({
        courseIds: instructor.courses.map((course) => course.id),
        // Only fetch course instances with an instructor and a date
        status: [
          CourseInstanceStatus.Confirmed,
          CourseInstanceStatus.Preliminary,
        ],
        startsAt: dayjs(firstCourseInstance.startsAt)
          .startOf('day')
          .toISOString(),
        endsAt: dayjs(firstCourseInstance.startsAt).endOf('day').toISOString(),
      });
      const filteredCourseInstances = data.data.filter(
        (courseInstance) =>
          courseInstance.id !== firstCourseInstance.id &&
          courseInstance.instructor?.id !== firstCourseInstance.instructor?.id,
      );
      setCourseInstances(filteredCourseInstances);
    } finally {
      setLoadingCourseInstances(false);
    }
  }, [
    firstCourseInstance?.course,
    firstCourseInstance.id,
    firstCourseInstance.instructor?.id,
    firstCourseInstance.startsAt,
    instructor,
  ]);

  useEffect(() => {
    fetchInstructor();
  }, [fetchInstructor]);

  useEffect(() => {
    if (!instructor) {
      return;
    }
    fetchSwappableCourseInstances();
  }, [fetchSwappableCourseInstances, instructor]);

  const swapInstructors = useCallback(async () => {
    if (!selectedCourseInstanceId) {
      return;
    }
    setSubmitting(true);
    try {
      await CourseInstanceAPI.swapInstructors(firstCourseInstance.id, {
        secondCourseInstanceId: selectedCourseInstanceId,
      });
      onSubmit?.();
      setShowModal?.(false);
    } finally {
      setSubmitting(false);
    }
  }, [
    firstCourseInstance.id,
    onSubmit,
    selectedCourseInstanceId,
    setShowModal,
  ]);

  return (
    <Modal
      title={t(
        'components.SwapInstructorModal.swapInstructorsBetweenCourseInstances',
      )}
      open
      width={'80%'}
      onOk={() => swapInstructors()}
      okButtonProps={{
        disabled:
          !selectedCourseInstanceId ||
          loadingInstructor ||
          loadingCourseInstances ||
          submitting,
        loading: submitting,
      }}
      cancelButtonProps={{
        disabled: submitting,
      }}
      onCancel={() => setShowModal?.(false)}
      maskClosable={false}>
      <SwapInstructorCard
        key={firstCourseInstance.id}
        courseInstance={firstCourseInstance}
      />
      <h3 className="border-solid border-0 border-b-2 border-gray-200 mx-3 py-2 my-1">
        {t('components.SwapInstructorModal.selectCourseInstance')}
      </h3>
      <div className="flex flex-col -mb-4">
        {loadingInstructor || loadingCourseInstances ? (
          <Spin size="large" className="my-12 self-center" />
        ) : (
          <div>
            {(courseInstances?.length === 0 && !loadingInstructor) ||
            loadingCourseInstances ? (
              <Empty
                className="py-8"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
                description={
                  <span>
                    {t('components.SwapInstructorModal.noCourseInstancesFound')}
                  </span>
                }
              />
            ) : (
              <>
                {courseInstances?.map((courseInstance) => (
                  <SwapInstructorCard
                    key={courseInstance.id}
                    courseInstance={courseInstance}
                    setSelectedCourseInstanceId={setSelectedCourseInstanceId}
                    selected={courseInstance.id === selectedCourseInstanceId}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SwapInstructorModal;
