import React, { useCallback, useState } from 'react';
import { message, Modal, Form, Input, Button } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import ClientCompanyAPI from '../api/ClientCompanyAPI';
import ClientCompany from '../types/ClientCompany';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';

type CreateClientCompanyModalProps = {
  visible: boolean;
  subsidiaryCompany: SubsidiaryCompany;
  onCreate?: (clientCompany: ClientCompany) => void;
  onCancel?: () => void;
};

const CreateClientCompanyModal: React.FC<CreateClientCompanyModalProps> = ({
  visible,
  subsidiaryCompany,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();

  const onFinish = useCallback(
    async (values: ClientCompany) => {
      setSubmitting(true);
      try {
        const { data: clientCompany } =
          await ClientCompanyAPI.createClientCompany({
            name: values.name,
            subsidiaryCompanyId: subsidiaryCompany.id,
          });
        form.resetFields();
        onCreate?.(clientCompany);
      } catch (error) {
        if (isAxiosError(error) && !error.response?.data.message) {
          message.error(t('errors.somethingWentWrong'));
        }
      } finally {
        setSubmitting(false);
      }
    },
    [subsidiaryCompany.id, form, onCreate, t],
  );

  return (
    <Modal
      title={t('components.CreateClientCompanyModal.createNewCompany')}
      open={visible}
      onCancel={onCancel}
      onOk={form.submit}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createClientCompanyForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createClientCompanyForm"
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish(values as ClientCompany)}>
        <Form.Item
          name="name"
          label={t('common.name')}
          rules={[
            {
              required: true,
              message: t('components.CreateClientCompanyModal.enterName'),
            },
          ]}>
          <Input placeholder={t('common.name')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateClientCompanyModal;
