import React, { FC, PropsWithChildren } from 'react';
import { Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type CourseInstanceDistributionProps = {
  isStart: boolean;
  isEnd: boolean;
  previous: () => void;
  next: () => void;
} & PropsWithChildren;

export const CourseInstanceDistribution: FC<
  CourseInstanceDistributionProps
> = ({ isStart, isEnd, previous, next, children }) => {
  const { t } = useTranslation();

  return (
    <div className="relative w-full h-full border border-solid border-grayLight rounded-2xl p-4 px-6">
      <Row className="gap-4">
        <span className="text-base font-semibold mr-auto">
          {t('views.InsightsV2.distributionTitle')}
        </span>

        {!(isStart && isEnd) && (
          <>
            <LeftOutlined
              onClick={previous}
              className={twMerge(
                'text-lg text-safeLifeMedium cursor-pointer',
                isStart && 'text-grayLight',
              )}
            />

            <RightOutlined
              onClick={next}
              className={twMerge(
                'text-lg text-safeLifeMedium cursor-pointer',
                isEnd && 'text-grayLight',
              )}
            />
          </>
        )}
      </Row>

      {children}
    </div>
  );
};
