import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import { Button, DatePicker, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type CalendarHeaderPropsParam = {
  calendar?: React.RefObject<FullCalendar>;
  setDateString?: (values: string) => void;
  disablePastDates?: boolean;
  loading?: boolean;
};

const CalendarHeader: React.FC<CalendarHeaderPropsParam> = ({
  calendar,
  setDateString,
  disablePastDates,
  loading,
}) => {
  const [date, setNewDate] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(disablePastDates ?? false);
  const { t } = useTranslation();

  const shouldDisableButton = useCallback(() => {
    if (disablePastDates) {
      setDisabled(dayjs(date).subtract(1, 'm').diff(dayjs()) < 0);
    } else {
      setDisabled(false);
    }
  }, [date, disablePastDates]);

  useEffect(() => {
    if (date) {
      shouldDisableButton();
      setDateString?.(date);
    }
  }, [date, setDateString, shouldDisableButton]);

  const onChange = useCallback(
    (e: dayjs.Dayjs | null) => {
      if (e !== null) {
        calendar?.current?.getApi().gotoDate(e.toDate());
        setNewDate(e.toISOString());
      }
    },
    [calendar],
  );

  const next = useCallback(() => {
    calendar?.current?.getApi().next();
    setNewDate(calendar!.current!.getApi().getDate().toISOString());
  }, [calendar]);

  const prev = useCallback(() => {
    calendar?.current?.getApi().prev();
    setNewDate(calendar!.current!.getApi().getDate().toISOString());
  }, [calendar]);

  const disabledDate = useCallback(
    (current: dayjs.Dayjs) => {
      if (disablePastDates) {
        return current && current < dayjs().endOf('day');
      } else {
        return false;
      }
    },
    [disablePastDates],
  );

  return (
    <div className="sm:flex sm:flex-row just items-center mb-5 gap-4">
      <div className="sm:flex sm:flex-col md:w-45">
        <p className="m-0">{t('common.month')}</p>
        <DatePicker.MonthPicker
          value={dayjs(date)}
          onChange={(e) => onChange(e)}
          disabledDate={disabledDate}
          format={'MMMM YYYY'}
          className="md:w-auto px-3"
        />
      </div>
      <div className="sm:flex sm:flex-row self-end">
        <Button onClick={prev} disabled={disabled}>
          <LeftOutlined />
        </Button>
        <Button onClick={next}>
          <RightOutlined />
        </Button>
      </div>
      {loading && <Spin size="large" className="self-end" />}
    </div>
  );
};

export default CalendarHeader;
