import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CourseInstanceAPI from '../CourseInstanceAPI';

const CourseInstanceRedirect: React.FC = () => {
  const { courseInstanceId } = useParams();
  const id = useMemo(() => Number(courseInstanceId), [courseInstanceId]);
  const navigate = useNavigate();

  useEffect(() => {
    CourseInstanceAPI.getCourseInstance(id).then(({ data }) =>
      navigate(`/bestallningar/${data.orderId}`),
    );
  }, [id, navigate]);

  return null;
};

export default CourseInstanceRedirect;
