import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

import { PlaceSuggestion } from '../components/AddressSearchInput';

export const getSuggestionByPlaceId = async (
  placeId: string,
): Promise<PlaceSuggestion> => {
  const [result] = await geocodeByPlaceId(placeId);
  const latLng = await getLatLng(result);

  return {
    address: result.formatted_address,
    geolocation: latLng,
    placeId: result.place_id,
  };
};

export const getSuggestionByAddress = async (
  address: string,
): Promise<PlaceSuggestion> => {
  const [result] = await geocodeByAddress(address);
  const latLng = await getLatLng(result);

  return {
    address: result.formatted_address,
    geolocation: latLng,
    placeId: result.place_id,
  };
};

export const buildAddressString = (
  addressRow1?: string,
  addressRow2?: string,
  addressRow3?: string,
  postalCode?: string,
  city?: string,
) =>
  [addressRow1, addressRow2, addressRow3, postalCode, city]
    .filter((x) => x !== undefined && x !== null)
    .join(', ');
