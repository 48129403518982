import { TFunction } from 'i18next';

export enum DistanceCategory {
  WithinLimit,
  SlightlyOverLimit,
  ExceedsLimit,
}

export const formatDistance = (distance: number | null, t: TFunction) => {
  if (distance == null) {
    return t('utils.distance.unknown');
  }
  if (distance < 1000) {
    return `${Math.round(distance)} m`;
  }
  return `${(distance / 1000).toFixed(1)} km`;
};

export const getDistanceCategory = (
  distanceInMeters: number,
  maxTravelDistanceInMeters: number,
) => {
  const tenKmInMeters = 10000;

  if (distanceInMeters <= maxTravelDistanceInMeters) {
    return DistanceCategory.WithinLimit;
  } else if (distanceInMeters <= maxTravelDistanceInMeters + tenKmInMeters) {
    return DistanceCategory.SlightlyOverLimit;
  } else {
    return DistanceCategory.ExceedsLimit;
  }
};
