import { Tooltip } from 'antd';
import React from 'react';

interface ConditionalTooltipProps {
  component: React.ReactNode;
  condition: boolean;
  displayTitle: string;
}
/**
 * Display a component with tooltip if condition is satisfied
 */
const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
  component,
  condition,
  displayTitle,
}) => {
  return condition ? (
    <Tooltip title={displayTitle}>{component}</Tooltip>
  ) : (
    <>{component}</>
  );
};

export default ConditionalTooltip;
