import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useUser } from '../../user/userContext';
import { Permission } from '../../user/types/Permission';
import { userHasAnyPermission, userHasPermission } from '../../user/userUtils';
import { getMarkets } from '../MarketAPI';
import Market from '../types/Market';
import { ColumnsType } from '../../types/ColumnTypes';
import Table from '../../components/Table';
import CreateMarketModal from '../components/CreateMarketModal';

const Markets: React.FC = () => {
  const [currentUser] = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [showCreateMarketModal, setShowCreateMarketModal] =
    useState<boolean>(false);
  const { t } = useTranslation();

  const fetchMarkets = useCallback(() => {
    setLoading(true);
    getMarkets()
      .then(({ data }) => setMarkets(data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchMarkets();
  }, [fetchMarkets]);

  const columns: ColumnsType<Market> = useMemo(
    () => [
      {
        width: '50%',
        title: t('views.Markets.columns.name'),
        render: (market) => (
          <div className="overflow-hidden text-ellipsis">{market.name}</div>
        ),
      },
      {
        width: '50%',
        title: t('views.Markets.columns.code'),
        render: (market) => (
          <div className="overflow-hidden text-ellipsis">{market.code}</div>
        ),
      },
    ],
    [t],
  );

  const canOpenMarketDetails = userHasAnyPermission(currentUser, [
    Permission.MARKET_UPDATE,
    Permission.MARKET_DELETE,
  ]);

  return (
    <>
      <div className="flex justify-between">
        <h2>{t('views.Markets.markets')}</h2>
        {userHasPermission(currentUser, Permission.MARKET_CREATE) && (
          <>
            <Button
              type="primary"
              className="text-sm font-medium"
              icon={<PlusOutlined />}
              onClick={() => setShowCreateMarketModal(true)}>
              {t('views.Markets.newMarket')}
            </Button>
            <CreateMarketModal
              visible={showCreateMarketModal}
              onCancel={() => setShowCreateMarketModal(false)}
              onCreateMarket={(market) => setMarkets(markets.concat(market))}
            />
          </>
        )}
      </div>
      {loading ? (
        <Spin />
      ) : (
        <Table
          rowKey={(market) => market.id}
          columns={columns}
          rows={markets}
          loading={loading}
          getRowLink={(market) => `${market.id}`}
        />
      )}

      {canOpenMarketDetails && (
        <Outlet
          context={{
            onChange: fetchMarkets,
            markets,
          }}
        />
      )}
    </>
  );
};

export default Markets;
