import React from 'react';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import { ColumnType } from '../types/ColumnTypes';

import ContentWrapper from './ContentWrapper';

export declare type GetRowKey<RecordType> = (
  record: RecordType,
  index: number,
) => Key;

export declare type Key = React.Key;

interface TableRowProps<RecordType> {
  record: RecordType;
  index: number;
  columns: ColumnType<RecordType>[];
  rowKey: GetRowKey<RecordType>;
  responsive?: boolean;
  getRowLink?: (record: RecordType) => string;
  pageUrl?: string;
  rowColorFunction?: (record: RecordType) => string;
}

export interface TableProps<RecordType> {
  rows?: RecordType[] | null | undefined;
  columns: ColumnType<RecordType>[];
  rowKey: GetRowKey<RecordType>;
  responsive?: boolean;
  loading?: boolean;
  groups?: Array<{ title: string; rows: RecordType[] }>;
  getRowLink?: (record: RecordType) => string;
  customHeader?: React.ReactNode;
  customTopRow?: React.ReactNode;
  customLastRow?: React.ReactNode;
  rowColorFunction?: (record: RecordType) => string;
}

const TableRow = <RecordType,>({
  record,
  index,
  columns,
  rowKey,
  responsive,
  getRowLink,
  pageUrl,
  rowColorFunction,
}: TableRowProps<RecordType>) => {
  const getRowColor = (record: RecordType) => {
    if (rowColorFunction) {
      return rowColorFunction(record);
    }
  };

  return (
    <tr
      className={`${getRowColor(
        record,
      )} relative filter:brightness-50 hover:bg-gray-100 hover-shadow ease-in-out transition-all ease-in-duration-200 border-solid border-gray-100 border-0 border-b-2`}
      key={rowKey?.(record, index) || index}>
      {columns?.map(({ render, width, notLinked, onClick }, index) => (
        <td
          key={index}
          className="border-b border-gray-200 overflow-hidden"
          style={{
            width,
            maxWidth: responsive ? 0 : 'auto',
          }}>
          <ContentWrapper
            record={record}
            index={index}
            getRowLink={getRowLink}
            pageUrl={pageUrl}
            render={render}
            notLinked={notLinked}
            onClick={onClick}
          />
        </td>
      ))}
    </tr>
  );
};

const Table = <RecordType,>({
  rows,
  columns,
  rowKey,
  responsive,
  getRowLink,
  groups,
  customHeader,
  customTopRow,
  customLastRow,
  rowColorFunction,
}: TableProps<RecordType>) => {
  const location = useLocation();

  const _groups: Array<{
    title: string | null;
    rows: RecordType[];
  }> = groups ?? [{ title: null, rows: rows as RecordType[] }];

  return (
    <div className="flex flex-col">
      <div className="py-2 px-2 -mx-2">
        <div className="align-middle inline-block w-full shadow-md rounded-md border-b border-gray-200">
          <table className="w-full table-fixed">
            {customHeader == null ? (
              <thead>
                <tr>
                  {columns?.map(({ title, width }, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 border-b border-gray-200 bg-primary-800 text-left text-xs leading-4 font-medium text-primary-100 uppercase tracking-wider overflow-hidden"
                      style={{
                        width,
                        maxWidth: responsive ? 0 : 'auto',
                      }}>
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
            ) : (
              <>{customHeader}</>
            )}
            <tbody className="bg-white">
              {customTopRow && <>{customTopRow}</>}

              {_groups ? (
                _groups.map((group) => (
                  <React.Fragment key={group.title}>
                    {group.title && (
                      <tr>
                        <td className="px-6 pt-4" colSpan={columns.length}>
                          <div className="pb-2 text-gray-800 text-base border-solid border-gray-200 border-0 border-b-2">
                            {group.title}
                          </div>
                        </td>
                      </tr>
                    )}
                    {group.rows?.map((record, index) => (
                      <TableRow
                        key={rowKey?.(record, index) || index}
                        record={record}
                        index={index}
                        columns={columns}
                        rowKey={rowKey}
                        responsive={responsive}
                        getRowLink={getRowLink}
                        pageUrl={location.pathname}
                        rowColorFunction={rowColorFunction}
                      />
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length}>
                    <div className="h-16 flex items-center justify-center">
                      <Spin />
                    </div>
                  </td>
                </tr>
              )}

              {customLastRow && <>{customLastRow}</>}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
