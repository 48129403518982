import Course from '../course/types/Course';
import User from '../user/types/User';
import PaginationMeta from '../types/PaginationMeta';
import PaginationParams from '../types/PaginationParams';
import ApiResponse from '../types/ApiResponse';
import client from '../api/client';

import SearchResult, { CalendarSearchResult } from './types/SearchResult';

export type SearchParams = {
  courseId: Course['id'];
  userIds?: User['id'][];
  lat: number;
  lng: number;
  minLength?: number;
  from?: string;
  to?: string;
  month?: string;
  internal?: boolean;
  maxDistance?: boolean;
  useCourseInstanceLimit?: boolean;
  fetchMileage?: boolean;
  placeId?: string;
};

const search = (
  params: SearchParams,
  paginationParams?: PaginationParams | null,
) =>
  client.get<ApiResponse<SearchResult[], PaginationMeta>>('/search', {
    params: { ...params, ...paginationParams },
  });

const calendarSearch = (params: SearchParams) =>
  client.get<CalendarSearchResult[]>('/search/calendar', {
    params: params,
  });

export default {
  search,
  calendarSearch,
};
