import FileDownload from 'js-file-download';

import client from '../api/client';
import {
  AttendeeSignatureType,
  CertificateDateFormat,
  CertificateLanguage,
  CertificateTemplate,
  SignatureType,
} from './types/Certificate';
import { ImageMedia } from '../types/ImageMedia';

const getCertificateForAttendee = (
  fileName: string,
  attendeeId: number,
  languageId: number,
) =>
  client
    .get(`/certificates/attendees/${attendeeId}/languages/${languageId}`, {
      responseType: 'blob',
    })
    .then((response) => FileDownload(response.data, `${fileName}.pdf`));

const getCertificateForElsaParticipant = (
  fileName: string,
  elsaParticipantId: string,
  courseInstanceId: number,
  languageId: number,
) =>
  client
    .get(
      `/certificates/elsa-participants/${elsaParticipantId}/languages/${languageId}`,
      {
        responseType: 'blob',
        params: { courseInstanceId },
      },
    )
    .then((response) => FileDownload(response.data, `${fileName}.pdf`));

const getCertificateZip = (
  fileName: string,
  courseId: number,
  courseInstanceId: number,
  languageId: number,
  attendeeIds?: number[],
  attendeeElsaIds?: string[],
) =>
  client
    .get(`/certificates/courses/${courseId}/languages/${languageId}`, {
      responseType: 'blob',
      params: { courseInstanceId, attendeeIds, attendeeElsaIds },
    })
    .then((response) => FileDownload(response.data, `${fileName}.zip`));

const getCertificatesLanguages = (name?: string, code?: string) =>
  client.get<CertificateLanguage[]>(`/certificates/languages/`, {
    params: {
      name,
      code,
    },
  });

export type CertificateLanguageParams = Omit<CertificateLanguage, 'id'>;

const createCertificateLanguage = (data: CertificateLanguageParams) =>
  client.post<CertificateLanguage>('/certificates/languages', data);

const updateCertificateLanguage = (
  id: number,
  data: CertificateLanguageParams,
) => client.patch<CertificateLanguage>(`/certificates/languages/${id}`, data);

const deleteCertificateLanguage = (id: number) =>
  client.delete(`/certificates/languages/${id}`);

const getCertificateImages = (name?: string, code?: string) =>
  client.get<ImageMedia[]>(`/certificates/images/`, {
    params: {
      name,
      code,
    },
  });

const createCertificateImage = (data: FormData) =>
  client.post<ImageMedia>(`/certificates/images`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const updateCertificateImage = (id: number, data: FormData) =>
  client.patch<ImageMedia>(`/certificates/images/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const deleteCertificateImage = (id: number) =>
  client.delete(`/certificates/images/${id}`);

interface CreateLanguageTextContent {
  title: string;
  description: string;
  languageId: number;
}

type CreateCertificateTemplateParams = {
  courseId: number;
  languageTextContents: CreateLanguageTextContent[];
  instructorSignatureType: SignatureType;
  attendeeSignatureType: AttendeeSignatureType;
  issuerSignatureType: SignatureType;
  issuerId: number | null;
  validForMonths: number | null;
  dateFormat: CertificateDateFormat;
};

type UpdateCertificateTemplateParams = {
  instructorSignatureType: SignatureType;
  attendeeSignatureType: AttendeeSignatureType;
  issuerSignatureType: SignatureType;
  issuerId: number | null;
  validForMonths: number | null;
  dateFormat: CertificateDateFormat;
  languageTextContents: CreateLanguageTextContent[];
};

const createCertificateTemplate = (
  templateParams: CreateCertificateTemplateParams,
) =>
  client.post<CertificateTemplate>(`/certificates/templates/`, templateParams);

const updateCertificateTemplate = (
  id: number,
  templateParams: UpdateCertificateTemplateParams,
) =>
  client.patch<CertificateTemplate>(
    `/certificates/templates/${id}`,
    templateParams,
  );

const getCourseCertificateTemplate = (courseId: number) =>
  client.get<CertificateTemplate>(
    `/certificates/templates/courses/${courseId}`,
  );

const deleteCertificateTemplate = (id: number) =>
  client.delete(`/certificates/templates/${id}`);

export default {
  getCertificateForAttendee,
  getCertificateForElsaParticipant,
  getCertificateZip,
  getCertificatesLanguages,
  createCertificateLanguage,
  updateCertificateLanguage,
  deleteCertificateLanguage,
  createCertificateTemplate,
  getCourseCertificateTemplate,
  deleteCertificateTemplate,
  updateCertificateTemplate,
  createCertificateImage,
  updateCertificateImage,
  getCertificateImages,
  deleteCertificateImage,
};
