import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const BookingOrderInvoicedBadge: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`h-6 px-2 inline-flex text-xs font-semibold rounded-full uppercase bg-blue-100 text-blue-800 ${
        className || ''
      }`}>
      <div className="flex items-center">
        <FileOutlined className="text-blue-800 pr-1" />
        {t('components.BookingOrderInvoicedBadge.invoiced')}
      </div>
    </div>
  );
};

export default BookingOrderInvoicedBadge;
