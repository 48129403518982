import React from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

type NumberPickerProps = {
  value?: number;
  min?: number;
  max?: number;
  size?: 'large';
  onChange?: (value: number) => void;
};

const NumberPicker: React.FC<NumberPickerProps> = ({
  value = 0,
  size,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  onChange,
}) => (
  <div className="flex items-center py-0.5">
    <Button
      disabled={value <= min}
      className="mr-2"
      shape="circle"
      size={size === 'large' ? 'middle' : 'small'}
      icon={<MinusOutlined />}
      onClick={() => onChange?.(Math.max(value - 1, min))}
    />
    <span
      className={`${
        size === 'large' ? 'w-8 text-lg' : 'w-4 text-base'
      } text-center`}>
      {value}
    </span>
    <Button
      disabled={value >= max}
      className="ml-2"
      shape="circle"
      size={size === 'large' ? 'middle' : 'small'}
      icon={<PlusOutlined />}
      onClick={() => onChange?.(Math.min(value + 1, max))}
    />
  </div>
);

export default NumberPicker;
