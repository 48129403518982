import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Spin, Pagination, Collapse, Switch, Tooltip } from 'antd';
import dayjs from 'dayjs';
import Empty from 'antd/es/empty';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import SearchResult from '../types/SearchResult';
import SearchAPI, { SearchParams } from '../SearchAPI';
import * as DateUtils from '../../utils/date';
import { ColumnsType } from '../../types/ColumnTypes';
import PaginationParams from '../../types/PaginationParams';
import { DisplayedCourseInstance } from '../../courseInstance/types/CourseInstance';
import Gravatar from '../../user/components/Gravatar';
import { HeaderValues } from '../../courseInstance/components/CourseInstanceHeader';
import EditCourseInstanceModal from '../../courseInstance/components/EditCourseInstanceModal';
import ContentWrapper from '../../components/ContentWrapper';

import DistanceBadge from './DistanceBadge';

const { Panel } = Collapse;

type InstructorSuggestionsProps = {
  displayedCourseInstance?: DisplayedCourseInstance;
  editableValues: HeaderValues;
  checkRequiredFields?: (values: HeaderValues) => boolean;
  onFormSubmit?: (values: HeaderValues) => void;
  setSelectedInstructorName: (value?: string) => void;
};

const InstructorCell: React.FC<{ result: SearchResult }> = ({ result }) => (
  <Tooltip
    placement="topLeft"
    title={
      <>
        <div>{result?.user.name}</div>
        <div>{result?.user.email}</div>
      </>
    }>
    <div className="flex items-center ">
      <div className="flex-shrink-0 h-10 w-10">
        <Gravatar
          email={result?.user?.email}
          internal={result?.user?.internal}
          isInstructor={true}
        />
      </div>
      <div className="ml-4 overflow-hidden">
        <div className="text-sm font-medium">{result?.user.name}</div>
        <div className="text-sm text-gray-500 text-ellipsis">
          {result?.user.email}
        </div>
      </div>
    </div>
  </Tooltip>
);

const DateCell: React.FC<{
  result: SearchResult;
  startsAt?: string;
  t: TFunction<'translation', undefined>;
}> = ({ result, startsAt, t }) => (
  <>
    {DateUtils.formatDate(startsAt) === DateUtils.formatDate(result.start) ? (
      <div className="text-sm text-green-800">
        {t('components.InstructorSuggestions.sameDay')}
      </div>
    ) : (
      <div className="text-sm text-yellow-800">
        {t('components.InstructorSuggestions.changedDay')}
      </div>
    )}

    <div className="text-sm">{DateUtils.formatDate(result.start)}</div>
  </>
);

const TimeCell: React.FC<{ result: SearchResult }> = ({ result }) => (
  <>
    <div className="text-sm">
      {DateUtils.formatTime(result.start)} - {DateUtils.formatTime(result.end)}
    </div>
  </>
);

const DistanceCell: React.FC<{ result: SearchResult }> = ({ result }) => (
  <DistanceBadge
    distance={result.distance}
    mileage={result.mileage}
    maxTravelDistance={result.user.maxTravelDistance}
  />
);

const ButtonCell: React.FC<{
  result: SearchResult;
  onClick: (toggle: boolean) => void;
  t: TFunction<'translation', undefined>;
}> = ({ result, onClick, t }) => (
  <div className="flex justify-end">
    <Button className="text-xs font-medium" onClick={() => onClick(true)}>
      {t('components.InstructorSuggestions.choose')}
    </Button>
  </div>
);

const InstructorSuggestions: React.FC<InstructorSuggestionsProps> = ({
  displayedCourseInstance: courseInstance,
  editableValues,
  checkRequiredFields,
  onFormSubmit,
  setSelectedInstructorName,
}) => {
  const [fetchedInstructors, setFetchedInstructors] =
    useState<SearchResult[]>();
  const [displayedPage, setDisplayedPage] = useState<number>(1);
  const [chosenSuggestion, setChosenSuggestion] = useState<SearchResult>();
  const [loadingInstructors, setLoadingInstructors] = useState<boolean>(false);
  const [displayEditingModal, setDisplayEditingModal] =
    useState<boolean>(false);
  const [maxDistance, setMaxDistance] = useState<boolean>(false);
  const [internal, setInternal] = useState<boolean>(false);
  const { t } = useTranslation();

  const pageSize = 3;
  const numberOfPages = 3;

  const searchForInstructors = useCallback(async () => {
    const searchParams: SearchParams = {
      courseId: editableValues.courseId!,
      placeId: courseInstance!.placeId!,
      lat: courseInstance!.lat!,
      lng: courseInstance!.lng!,
      from: editableValues.date
        ? dayjs(editableValues.date).startOf('day').toISOString()
        : dayjs().toISOString(),
      to: editableValues.date
        ? dayjs(editableValues.date).add(7, 'days').endOf('day').toISOString()
        : dayjs().add(7, 'days').toISOString(),
      internal: internal,
      maxDistance: maxDistance,
      fetchMileage: true,
      useCourseInstanceLimit: true,
    };

    const paginationParams: PaginationParams = {
      page: 1,
      pageSize: pageSize * numberOfPages,
    };

    setLoadingInstructors(true);
    try {
      const { data: res } = await SearchAPI.search(
        searchParams,
        paginationParams,
      );
      setFetchedInstructors(res.data);
    } finally {
      setLoadingInstructors(false);
    }
  }, [
    courseInstance,
    editableValues.courseId,
    editableValues.date,
    internal,
    maxDistance,
  ]);

  useEffect(() => {
    setDisplayedPage(1);
    if (checkRequiredFields?.(editableValues)) {
      searchForInstructors();
    }
  }, [checkRequiredFields, editableValues, searchForInstructors]);

  const onChangePage = useCallback((page: number) => {
    setDisplayedPage(page);
  }, []);

  const columns: ColumnsType<SearchResult> = useMemo(
    () => [
      {
        width: '35%',
        title: (
          <span>
            {t('components.InstructorSuggestions.suggestedInstructors')}
          </span>
        ),
        render: (result) => <InstructorCell result={result} />,
      },
      {
        width: '20%',
        render: (result) => (
          <DateCell result={result} startsAt={courseInstance!.startsAt} t={t} />
        ),
      },
      {
        render: (result) => <TimeCell result={result} />,
      },
      {
        render: (result) => <DistanceCell result={result} />,
      },
      {
        render: (result) => (
          <ButtonCell
            result={result}
            onClick={() => {
              setChosenSuggestion(result);
              setDisplayEditingModal(true);
            }}
            t={t}
          />
        ),
      },
    ],
    [courseInstance, t],
  );

  const getDisplayedInstructors = useCallback((): SearchResult[] => {
    const firstInstructorIndex = (displayedPage - 1) * pageSize;
    const lastInstructorIndex = displayedPage * pageSize;
    return (
      fetchedInstructors?.slice(firstInstructorIndex, lastInstructorIndex) || []
    );
  }, [displayedPage, fetchedInstructors]);

  const InstructorPage = useCallback(() => {
    return (
      <>
        {fetchedInstructors?.length !== 0 ? (
          <>
            {getDisplayedInstructors().map((result) => (
              <React.Fragment key={result.id}>
                <tr
                  className={`hover:bg-gray-100 hover-shadow ease-in-out transition-all ease-in-duration-200 border-solid border-gray-100 border-0 border-b-2`}>
                  {columns?.map(({ render, width }, index) => (
                    <td
                      key={index}
                      className="border-b border-gray-200 overflow-hidden"
                      style={{
                        width,
                        maxWidth: 0,
                      }}>
                      <ContentWrapper
                        record={result}
                        index={index}
                        render={render}
                      />
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={columns.length}>
              <Empty
                className="py-2"
                image={Empty.PRESENTED_IMAGE_DEFAULT}
                description={
                  <span>
                    {t('components.InstructorSuggestions.noSuggestions')}
                  </span>
                }
              />
            </td>
          </tr>
        )}
      </>
    );
  }, [columns, fetchedInstructors?.length, getDisplayedInstructors, t]);

  return (
    <div className="flex flex-col">
      <div className="py-2 px-2 -mx-2">
        <div className="align-middle inline-block min-w-full shadow-md rounded-md border-b border-gray-200 overflow-hidden">
          <Collapse expandIconPosition="start" defaultActiveKey="1">
            <Panel
              header={t(
                'components.InstructorSuggestions.suggestedInstructors',
              )}
              key="1"
              style={{
                backgroundColor: '#EAF3FA',
              }}>
              <table className="min-w-full table-fixed text-md font-medium">
                <tbody
                  className="bg-white"
                  style={{ display: 'table', width: '100%' }}>
                  {!loadingInstructors ? (
                    <InstructorPage />
                  ) : (
                    <tr>
                      <td colSpan={columns.length}>
                        <div className="h-16 flex items-center justify-center">
                          <Spin />
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="flex flex-row justify-between justify-center mt-3 -mb-2">
                        <div className="flex flex-row flex-1">
                          <div className="ml-3 mr-5">
                            <Switch onChange={setInternal} size="small" />
                            <div className="text-xs">
                              {t(
                                'components.InstructorSuggestions.inhouseOnly',
                              )}
                            </div>
                          </div>
                          <div>
                            <Switch onChange={setMaxDistance} size="small" />
                            <div className="text-xs">
                              {t(
                                'components.InstructorSuggestions.maxDistance',
                              )}
                            </div>
                          </div>
                        </div>
                        <Pagination
                          className="flex flex-1 justify-center align-center"
                          defaultPageSize={3}
                          total={fetchedInstructors?.length}
                          current={displayedPage}
                          onChange={onChangePage}
                        />
                        <div className="flex flex-1" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </Collapse>
        </div>
      </div>
      {chosenSuggestion && (
        <EditCourseInstanceModal
          onFormSubmit={onFormSubmit}
          visible={displayEditingModal}
          suggestion={chosenSuggestion!}
          onClose={() => setDisplayEditingModal(false)}
          setSelectedInstructorName={setSelectedInstructorName}
        />
      )}
    </div>
  );
};

export default InstructorSuggestions;
