import React, { useState } from 'react';
import { Button, Modal, Spin, Alert, Tooltip } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';

import BookingOrder from '../types/BookingOrder';
import {
  CourseInstanceStatus,
  DisplayedCourseInstance,
} from '../../courseInstance/types/CourseInstance';
import BookingOrderAPI from '../api/BookingOrderAPI';
import InvoiceRecipient from '../types/InvoiceRecipient';
import { buildAddressString } from '../../utils/address';
import CourseInstanceStatusBadge from '../../courseInstance/components/CourseInstanceStatusBadge';
import ContactPerson from '../types/ContactPerson';

type InvoicePreviewModalProps = {
  bookingOrder: BookingOrder;
  selectedInvoiceRecipient?: InvoiceRecipient;
  selectedContactPerson?: ContactPerson;
  displayedCourseInstances: DisplayedCourseInstance[];
  onCancel: () => void;
  onInvoiceCreated: (bookingOrder: BookingOrder) => void;
};

type TroubleshootingModalProps = {
  onClose: () => void;
  visible: boolean;
};

const TroubleshootingModal: React.FC<TroubleshootingModalProps> = ({
  onClose,
  visible,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="pt-8"
      open={visible}
      title={t('components.InvoicePreviewModal.troubleshooting')}
      onCancel={onClose}
      footer={<Button onClick={onClose}>{t('common.close')}</Button>}>
      <div className="mb-2">
        {t('components.InvoicePreviewModal.troubleshootingSteps')}
      </div>
      <ul>
        <li>{t('components.InvoicePreviewModal.vismaCustomerExists')}</li>
        <li>{t('components.InvoicePreviewModal.vismaArticleExists')} </li>
      </ul>
    </Modal>
  );
};

const InvoicePreviewModal: React.FC<InvoicePreviewModalProps> = ({
  bookingOrder,
  selectedInvoiceRecipient,
  selectedContactPerson,
  displayedCourseInstances,
  onCancel,
  onInvoiceCreated,
}) => {
  const [submittingInvoice, setSubmittingInvoice] = useState<boolean>();
  const [success, setSuccess] = useState<boolean>();
  const [backendErrorMessage, setBackendErrorMessage] = useState<string>();
  const [vismaSalesOrderId, setVismaSalesOrderId] = useState<string>();
  const [showTroubleshootingModal, setShowTroubleshootingModal] =
    useState<boolean>(false);

  const courseInstancesWithPrice = displayedCourseInstances?.filter(
    (courseInstance) => courseInstance.price && courseInstance.price > 0,
  );

  const { t } = useTranslation();

  const createVismaInvoice = async () => {
    if (!bookingOrder) return;
    setSubmittingInvoice(true);
    setBackendErrorMessage(undefined);
    try {
      const newBookingOrder = await BookingOrderAPI.createBookingOrderInVisma(
        bookingOrder.id,
      );

      onInvoiceCreated(newBookingOrder.data);
      setVismaSalesOrderId(newBookingOrder.data.vismaSalesOrderId ?? '');
      setSuccess(true);
    } catch (err) {
      if (isAxiosError(err)) {
        setBackendErrorMessage(err.response?.data.message);
      } else {
        throw err;
      }
    } finally {
      setSubmittingInvoice(false);
    }
  };

  const tryToCancel = () => {
    if (!submittingInvoice) {
      onCancel();
    }
  };

  const getTotalPrice = (
    displayedCourseInstances: DisplayedCourseInstance[],
  ) => {
    return displayedCourseInstances.reduce((partialSum, courseInstance) => {
      const mileage =
        !!courseInstance.mileagePrice && !!courseInstance.mileageQuantity
          ? courseInstance.mileageQuantity * courseInstance.mileagePrice
          : 0;
      return partialSum + courseInstance.price + mileage;
    }, 0);
  };

  const getErrorMessages = (): string[] => {
    const errorMessages: string[] = [];

    if (!selectedInvoiceRecipient) {
      errorMessages.push(
        t('components.InvoicePreviewModal.orderMissingInvoiceRecipient'),
      );
    }
    if (!bookingOrder.subsidiaryCompany.vismaTenantId) {
      errorMessages.push(t('components.InvoicePreviewModal.missingTenantId'));
    }
    if (!courseInstancesWithPrice || courseInstancesWithPrice.length === 0) {
      errorMessages.push(
        t('components.InvoicePreviewModal.orderMissingCourseInstances'),
      );
    }
    if (
      courseInstancesWithPrice
        ?.map((courseInstance) => courseInstance.course.vismaInventoryNumber)
        .includes(null)
    ) {
      errorMessages.push(
        t('components.InvoicePreviewModal.missingInventoryNumber'),
      );
    }

    if (selectedContactPerson && !selectedContactPerson.lastName) {
      errorMessages.push(
        t('components.InvoicePreviewModal.purchaserMissingLastName'),
      );
    }

    return errorMessages;
  };

  const Waiting: React.FC = () => (
    <div className="mr-3 flex justify-center items-center">
      <div>
        <div className="flex justify-center">
          {t('components.InvoicePreviewModal.creatingVismaSalesOrder')}
        </div>
        <div className="flex justify-center">
          {t('components.InvoicePreviewModal.thisMayTakeAWhile')}
        </div>
      </div>
      <Spin className="ml-3" />
    </div>
  );

  return (
    <Modal
      title={
        <div className="flex items-center gap-2">
          {t('components.InvoicePreviewModal.invoiceThroughVisma')}{' '}
          <Tooltip
            placement="top"
            title={t('components.InvoicePreviewModal.troubleshooting')}>
            <QuestionCircleOutlined
              className="text-gray-500 text-lg"
              onClick={() => setShowTroubleshootingModal(true)}
            />
          </Tooltip>
        </div>
      }
      open
      onCancel={tryToCancel}
      footer={[
        <div key="footerElements" className="flex justify-end items-center">
          {submittingInvoice && <Waiting />}
          <div>
            <Button onClick={tryToCancel} disabled={submittingInvoice}>
              {success ? t('common.ok') : t('common.cancel')}
            </Button>
            <Button
              onClick={createVismaInvoice}
              disabled={
                getErrorMessages().length > 0 || submittingInvoice || success
              }>
              {t('components.InvoicePreviewModal.invoice')}
            </Button>
          </div>
        </div>,
      ]}
      maskClosable={false}>
      {getErrorMessages().map((err) => (
        <Alert key={err} type="error" message={err} showIcon className="mb-4" />
      ))}
      {backendErrorMessage && (
        <Alert
          type="error"
          message={backendErrorMessage}
          showIcon
          className="mb-4"
        />
      )}

      {success && (
        <Alert
          type="success"
          className="mb-2"
          message={
            <>
              {t('components.InvoicePreviewModal.orderCreatedWithNumber')}{' '}
              <strong>{vismaSalesOrderId}</strong>
            </>
          }
        />
      )}

      <div className="pb-1 font-medium text-blueGrayDark">
        {t('common.invoiceRecipient')}
      </div>
      {selectedInvoiceRecipient ? (
        <div className="gap-y-1 pt-1">
          <div className="flex justify-between">
            <div>{`${t('components.InvoicePreviewModal.clientName')}:`}</div>
            <div>{selectedInvoiceRecipient.name || '-'}</div>
          </div>
          <div className="flex justify-between">
            <div>{`${t('common.email')}:`}</div>
            <div>{selectedInvoiceRecipient.invoiceEmail || '-'}</div>
          </div>
          <div className="flex justify-between">
            <div>{`${t('common.corporateId')}:`}</div>
            <div>{selectedInvoiceRecipient.corporateId || '-'}</div>
          </div>
          <div className="flex justify-between">
            <div>{`${t(
              'components.InvoicePreviewModal.invoiceAddress',
            )}:`}</div>
            <div>
              {buildAddressString(
                selectedInvoiceRecipient.invoiceAddress1,
                selectedInvoiceRecipient.invoiceAddress2,
                selectedInvoiceRecipient.invoiceAddress3,
                selectedInvoiceRecipient.invoicePostalCode,
                selectedInvoiceRecipient.invoiceCity,
              ) || '-'}
            </div>
          </div>
          <div className="flex justify-between">
            <div>{`${t('components.InvoicePreviewModal.clientAddress')}:`}</div>
            <div>
              {buildAddressString(
                selectedInvoiceRecipient.clientAddress1,
                selectedInvoiceRecipient.clientAddress2,
                selectedInvoiceRecipient.clientAddress3,
                selectedInvoiceRecipient.clientPostalCode,
                selectedInvoiceRecipient.clientCity,
              ) || '-'}
            </div>
          </div>
          <div className="flex justify-between">
            <div>{`${t(
              'components.InvoicePreviewModal.invoiceReference',
            )}:`}</div>
            <div>{bookingOrder.invoiceReference || '-'}</div>
          </div>
          <div className="flex justify-between">
            <div>{`${t(
              'components.InvoicePreviewModal.vismaCustomerNumber',
            )}:`}</div>
            <div className="font-semibold">
              {selectedInvoiceRecipient.vismaCustomerId ??
                t('components.InvoicePreviewModal.willBeFetched')}
            </div>
          </div>
        </div>
      ) : (
        <div className="gap-y-1">
          {t('components.InvoicePreviewModal.nothingSelected')}
        </div>
      )}

      {courseInstancesWithPrice && courseInstancesWithPrice.length > 0 && (
        <div className="gap-y-1 pt-3">
          <table className="w-full">
            <thead>
              <tr>
                <th
                  className="text-left font-medium text-blueGrayDark"
                  scope="column">
                  {t('common.course')}
                </th>
                <th
                  className="text-left font-medium text-blueGrayDark"
                  scope="column">
                  {t('components.InvoicePreviewModal.vismaInventoryNumber')}
                </th>
                <th
                  className="text-right font-medium text-blueGrayDark"
                  scope="column">
                  {t('common.price')}
                </th>
              </tr>
            </thead>
            <tbody>
              {courseInstancesWithPrice.map((courseInstance) => (
                <>
                  <tr key={courseInstance.id} className="border-t-1">
                    <td className="text-left">
                      {courseInstance.course.name + ' '}
                      {courseInstance.status ===
                        CourseInstanceStatus.Canceled && (
                        <CourseInstanceStatusBadge
                          status={CourseInstanceStatus.Canceled}
                        />
                      )}
                    </td>
                    <td className="text-left">
                      {courseInstance.course.vismaInventoryNumber
                        ? courseInstance.course.vismaInventoryNumber
                        : '-'}
                    </td>
                    <td className="text-right">
                      {(courseInstance.price ??
                        courseInstance.course.defaultPrice ??
                        0) + ' '}
                      {courseInstance.currency.code}
                    </td>
                  </tr>
                  {!!courseInstance.mileagePrice &&
                    !!courseInstance.mileageQuantity && (
                      <tr>
                        <td className="text-left">- {t('common.mileage')}</td>
                        <td />
                        <td className="text-right">
                          {courseInstance.mileageQuantity} x{' '}
                          {courseInstance.mileagePrice}{' '}
                          {courseInstance.currency.code}
                        </td>
                      </tr>
                    )}
                </>
              ))}
              <tr
                key="total"
                className="border-spacing-y-2 border-solid border-0 border-t-2">
                <td className="text-left" colSpan={2}>
                  <span className="font-semibold">
                    {t('components.InvoicePreviewModal.total')}
                  </span>
                </td>
                <td className="text-right">
                  {getTotalPrice(courseInstancesWithPrice)}{' '}
                  {courseInstancesWithPrice[0]?.currency.code}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <TroubleshootingModal
        visible={showTroubleshootingModal}
        onClose={() => setShowTroubleshootingModal(false)}
      />
    </Modal>
  );
};

export default InvoicePreviewModal;
