import { Select, Row, Col } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DownArrow from '../../resources/img/downArrow.svg';
import SubsidiaryCompanyAPI from '../../subsidiaryCompany/SubsidiaryCompanyAPI';
import CapacityGraphCard from '../components/CapacityGraphCard';
import StatisticsCard from '../components/StatisticsCard';
import CourseInstanceHeatMap from '../components/CourseInstanceHeatMap';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import InstructorStatisticsCard from '../components/InstructorStatisticsCard';
import InsightsAPI, { ExistingInstructorsCount } from '../InsightsAPI';
import {
  CapacityData,
  GraphData,
  GraphResource,
  TimeUnit,
} from '../types/Graph';
import CourseInstanceFlow from '../components/CourseInstanceFlow';
import CourseChartCard from '../components/CourseChartCard';
import { useUser } from '../../user/userContext';

const DAYS = 30;

const Insights: React.FC = () => {
  const { t } = useTranslation();
  const [currentUser] = useUser();
  const [graphData, setGraphData] = useState<CapacityData[]>([]);
  const [loadingGraphData, setLoadingGraphData] = useState<boolean>(true);
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState<boolean>(false);
  const [existingInstructorsCount, setExistingInstructorsCount] =
    useState<ExistingInstructorsCount>();
  const [loadingInstructorsCount, setLoadingInstructorsCount] =
    useState<boolean>(false);
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();
  const [selectedSubsidiaryCompany, setSelectedSubsidiaryCompany] =
    useState<SubsidiaryCompany>();
  const [courseInstanceData, setCourseInstanceData] = useState<GraphData>();
  const [createdBookingOrdersData, setCreatedBookingOrdersData] =
    useState<GraphData>();
  const [revenueData, setRevenueData] = useState<GraphData>();
  const [fromDate] = useState<Dayjs>(dayjs().subtract(DAYS - 1, 'day'));
  const [toDate] = useState<Dayjs>(dayjs());

  const fetchInstructorsCount = useCallback(async () => {
    setLoadingInstructorsCount(true);
    try {
      const { data } = await InsightsAPI.getExistingInstructorsCount();
      setExistingInstructorsCount(data);
    } finally {
      setLoadingInstructorsCount(false);
    }
  }, []);

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  const fetchGraphData = useCallback(
    (resource: GraphResource, setData: (graphData: GraphData) => void) => {
      InsightsAPI.getGraphData(
        resource,
        TimeUnit.DAY,
        fromDate.format('YYYY-MM-DD'),
        undefined,
        selectedSubsidiaryCompany ? selectedSubsidiaryCompany.id : undefined,
      ).then(({ data }) => setData(data));
    },
    [fromDate, selectedSubsidiaryCompany],
  );

  useEffect(() => {
    fetchInstructorsCount();
  }, [fetchInstructorsCount]);

  useEffect(() => {
    fetchSubsidiaryCompanies();
  }, [fetchSubsidiaryCompanies]);

  useEffect(() => {
    fetchGraphData(GraphResource.COURSE_INSTANCES, setCourseInstanceData);
    fetchGraphData(
      GraphResource.CREATED_BOOKING_ORDERS,
      setCreatedBookingOrdersData,
    );
    fetchGraphData(GraphResource.REVENUE, setRevenueData);
  }, [fetchGraphData]);

  useEffect(() => {
    const handleGraphDataUpdate = async () => {
      setLoadingGraphData(true);
      try {
        const { data: capacityGraphData } =
          await InsightsAPI.getCapacityGraphData(fromDate.format('YYYY-MM-DD'));

        const capacityGraphPercentageData = capacityGraphData.map(
          ({
            label,
            value: { totalCourseInstances, totalCourseInstanceLimit },
          }) => {
            const percentage = Math.floor(
              (totalCourseInstances / totalCourseInstanceLimit) * 100,
            );
            return {
              date: label,
              percentage:
                !isNaN(percentage) && isFinite(percentage)
                  ? percentage
                  : totalCourseInstances
                    ? 100
                    : 0,
              comment:
                totalCourseInstances && !totalCourseInstanceLimit
                  ? ` (${totalCourseInstances}/0)`
                  : '',
            };
          },
        );

        setGraphData(capacityGraphPercentageData);
      } finally {
        setLoadingGraphData(false);
      }
    };

    handleGraphDataUpdate();
  }, [selectedSubsidiaryCompany, fromDate]);

  const createdOrdersTooltipContentGenerator = useCallback(
    (count: number) => t('views.Insights.createdOrder', { count }),
    [t],
  );

  const courseInstancesTooltipContentGenerator = useCallback(
    (count: number) =>
      `${count} ${t('utils.courseInstance.courseInstance', { count })}`,
    [t],
  );

  // Show default map center if only one of lat&lng is set.
  const initialCenter =
    currentUser?.market.mapCenterLatitude &&
    currentUser?.market.mapCenterLongitude
      ? {
          lat: currentUser.market.mapCenterLatitude,
          lng: currentUser.market.mapCenterLongitude,
        }
      : {
          lat: 62.3833318,
          lng: 16.2824905,
        };

  return (
    <>
      <div className="h-64 w-full bg-safeLifeMedium absolute left-0 top-0 z-[-10]" />
      <div className="flex flex-row items-end justify-between mt-2 mb-6">
        <div className="flex flex-row items-center">
          <div className="text-white text-xl mb-1 font-medium">
            {t('components.Navbar.insights')}
          </div>
          <Select
            defaultValue={-1}
            className="preferred-subsidiary-selector font-small text-right rounded-md text-small text-white fill-white"
            popupClassName="min-w-64"
            bordered={false}
            loading={loadingSubsidiaryCompanies}
            disabled={loadingSubsidiaryCompanies}
            popupMatchSelectWidth={false}
            onSelect={(value: number) =>
              setSelectedSubsidiaryCompany(
                subsidiaryCompanies?.find((company) => company.id === value),
              )
            }
            suffixIcon={<img src={DownArrow} />}>
            <Select.Option key={-1} value={-1}>
              {t('views.Insights.allCompanies')}
            </Select.Option>
            {subsidiaryCompanies?.map((subsidiaryCompany, key) => {
              return (
                <Select.Option key={key} value={subsidiaryCompany.id}>
                  {subsidiaryCompany.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <span className="text-white">{t('views.Insights.last30Days')}</span>
      </div>
      <Row gutter={[24, 24]} className="pb-12">
        <Col xs={12} md={6}>
          <StatisticsCard
            title={t('views.Insights.createdOrders')}
            resource={GraphResource.CREATED_BOOKING_ORDERS}
            unit={t('views.Insights.createdOrders').toLowerCase()}
            tooltipContentGenerator={createdOrdersTooltipContentGenerator}
            graphData={createdBookingOrdersData}
            subsidiaryCompanyId={selectedSubsidiaryCompany?.id}
          />
        </Col>
        <Col xs={12} md={6}>
          <StatisticsCard
            title={t('common.courseInstances')}
            resource={GraphResource.COURSE_INSTANCES}
            unit={t('common.courseInstances').toLowerCase()}
            tooltipContentGenerator={courseInstancesTooltipContentGenerator}
            graphData={courseInstanceData}
            subsidiaryCompanyId={selectedSubsidiaryCompany?.id}
          />
        </Col>

        <Col xs={12} md={6}>
          <InstructorStatisticsCard
            title={t('common.instructors')}
            existingInstructorsCount={existingInstructorsCount}
            loading={loadingInstructorsCount}
          />
        </Col>

        <Col xs={12} md={6}>
          <StatisticsCard
            title={t('views.Insights.revenue')}
            resource={GraphResource.REVENUE}
            unit={currentUser!.market.defaultCurrency.code}
            graphData={revenueData}
            subsidiaryCompanyId={selectedSubsidiaryCompany?.id}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={16}>
          <CapacityGraphCard
            today={dayjs()}
            title={t('views.Insights.occupancy')}
            daysShown={DAYS}
            dataset={graphData}
            loading={loadingGraphData}
          />
          <CourseInstanceFlow fromDate={fromDate} toDate={toDate} />
        </Col>
        <Col xs={24} lg={8}>
          <CourseInstanceHeatMap
            fromDate={fromDate}
            toDate={toDate}
            initialCenter={initialCenter}
          />
        </Col>
        <Col xs={24}>
          <CourseChartCard
            fromDate={fromDate}
            toDate={toDate}
            subsidiaryCompany={selectedSubsidiaryCompany}
          />
        </Col>
      </Row>
    </>
  );
};

export default Insights;
