import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Form, Button, Select, Switch } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import User, { UserRole } from '../types/User';
import UserAPI, { UserFilterParams } from '../UserAPI';
import { getUserRoleText } from '../userUtils';
import ContentCard from '../../components/ContentCard';
import UserOption from '../../components/UserOption';
import { stringifyValues } from '../../utils/searchParams';

type FilterFormValues = {
  userIds?: User['id'][];
  roles?: UserRole[];
  deleted?: boolean;
};

type UserFilterProps = {
  visible: boolean;
  onChange: (filter: UserFilterParams, pristine: boolean) => void;
};

const UserFilter: React.FC<UserFilterProps> = ({ visible, onChange }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);

  const { t } = useTranslation();

  const fetchUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const { data } = await UserAPI.getUsers();
      setUsers(data);
    } finally {
      setLoadingUsers(false);
    }
  }, []);

  const isPristine = useCallback(() => {
    const formValues = form.getFieldsValue();
    return (
      JSON.stringify({
        ...formValues,
      }) ===
      JSON.stringify({
        ...{},
      })
    );
  }, [form]);

  const filter = useCallback(
    (values: FilterFormValues) => {
      onChange?.(
        {
          userIds: values.userIds,
          roles: values.roles,
          deleted: values.deleted,
        },
        isPristine(),
      );
    },
    [isPristine, onChange],
  );

  useEffect(() => {
    if (visible) {
      fetchUsers();
    }
  }, [fetchUsers, visible]);

  useEffect(() => {
    if (location.search) {
      const formValues: FilterFormValues = {
        userIds: searchParams.getAll('userIds').map(Number),
        roles: searchParams.getAll('roles') as UserRole[],
        deleted: searchParams.get('deleted') === 'true',
      };
      form.setFieldsValue(formValues);
    } else {
      form.resetFields();
    }
    filter(form.getFieldsValue());
  }, [filter, form, location.search, searchParams]);

  const isEmpty = useCallback(
    () => Object.values(form.getFieldsValue()).filter(Boolean).length <= 0,
    [form],
  );

  const onValuesChange = useCallback(
    (values: FilterFormValues) => {
      setSearchParams(stringifyValues(values));
    },
    [setSearchParams],
  );

  const clearFilter = useCallback(() => {
    const formValues = form.getFieldsValue();
    const emptyFilter = Object.keys(formValues).reduce(
      (acc, key) => ({ ...acc, [key]: undefined }),
      {},
    );
    form.setFieldsValue(emptyFilter);
    onValuesChange({});
    filter(emptyFilter);
  }, [filter, form, onValuesChange]);

  return (
    <ContentCard className={`flex flex-col ${!visible ? 'hidden' : ''}`}>
      <Button
        type="text"
        icon={<CloseOutlined />}
        disabled={isEmpty()}
        className="self-end mb-2"
        onClick={clearFilter}>
        {t('common.clear')}
      </Button>
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onValuesChange={(_, values) =>
          onValuesChange(values as FilterFormValues)
        }>
        <div className="md:grid col-gap-4 grid-rows-2 grid-cols-2 grid-flow-col">
          <Form.Item label={t('common.users')} name="userIds">
            <Select
              mode="multiple"
              allowClear
              loading={loadingUsers}
              filterOption={(input, option) =>
                option?.props.children.props.user.name
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {users?.map(
                (user) =>
                  user.id && (
                    <Select.Option key={user.id} value={user.id}>
                      <UserOption user={user} />
                    </Select.Option>
                  ),
              )}
            </Select>
          </Form.Item>
          <Form.Item label={t('common.roles')} name="roles">
            <Select mode="multiple">
              {Object.values(UserRole).map((role) => (
                <Select.Option key={role} value={role}>
                  {getUserRoleText(role, t)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('components.UserFilter.removed')}
            name="deleted"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
      </Form>
    </ContentCard>
  );
};

export default UserFilter;
