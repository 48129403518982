import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  unstable_HistoryRouter as Router,
  HistoryRouterProps,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

import ApiErrorHandler from './components/ApiErrorHandler';
import TokenContextProvider from './auth/token';
import UserContextProvider from './user/userContext';
import './styles/index.css';
import App from './App';
import LanguageContextProvider from './context/language';
import { FeatureToggleProvider } from './context/featureToggles';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const container = document.getElementById('app');
const root = createRoot(container!);

root.render(
  <TokenContextProvider>
    <FeatureToggleProvider>
      <UserContextProvider value={null}>
        <LanguageContextProvider>
          <Router
            history={
              createBrowserHistory({
                window,
                // TODO: Fix this unsafe typecast
              }) as unknown as HistoryRouterProps['history']
            }>
            <ApiErrorHandler>
              <App />
            </ApiErrorHandler>
          </Router>
        </LanguageContextProvider>
      </UserContextProvider>
    </FeatureToggleProvider>
  </TokenContextProvider>,
);
