import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventApi } from '@fullcalendar/core';

import { formatCourseInstanceString } from '../courseInstanceUtils';

type CourseInstanceBoxParam = {
  event: EventApi;
  opacity: string;
};

const CourseInstanceBox: React.FC<CourseInstanceBoxParam> = ({
  event,
  opacity,
}) => {
  const { t } = useTranslation();

  const dynamicBox = () => {
    return (
      <div
        className={`w-4 h-4 ${
          +event.title >= 7 ? 'bg-safeLifeMedium' : 'bg-safeLifeLight'
        } border-2 rounded-sm mx-0 col-span-1 max-w-0`}
        {...(+event.title < 7 && {
          style: { opacity: opacity },
        })}
      />
    );
  };

  const title = () => {
    return `${event.title} ${formatCourseInstanceString(+event.title, t)}`;
  };

  return (
    <div className="grid grid-row-2 grid-col-4 ml-1 gap-x-2 gap-y-4 md:w-full items-center">
      {dynamicBox()}
      <div className="flex flex-col col-start-2 col-span-4 m-0 text-sm">
        {title()}
      </div>
    </div>
  );
};

export const CourseInstanceBoxComp = React.memo(CourseInstanceBox);
