import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Remove from '../../resources/img/remove.svg';
import Swap from '../../resources/img/swap.svg';
import User from '../../user/types/User';
import Gravatar from '../../user/components/Gravatar';
import FieldSelect from '../../components/FieldSelect';

import InformationCard from './InformationCard';

type ResponsibleBookerFieldProps = {
  selectedResponsibleBooker?: User;
  setSelectedResponsibleBooker: (responsibleBooker?: User) => void;
  bookers?: User[];
  loading?: boolean;
};

const ResponsibleBookerField: React.FC<ResponsibleBookerFieldProps> = ({
  selectedResponsibleBooker,
  setSelectedResponsibleBooker,
  bookers,
  loading,
}) => {
  const [fieldSelectDefaultOpen, setFieldSelectDefaultOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const actionPopoverElements = [
    {
      rowElement: (
        <>
          <img src={Swap} className="pr-2" />
          {t('common.switch')}
        </>
      ),
      action: () => {
        setFieldSelectDefaultOpen(true);
        setSelectedResponsibleBooker(undefined);
      },
      hidden: !setSelectedResponsibleBooker,
    },
    {
      rowElement: (
        <>
          <img src={Remove} className="pr-2" />
          {t('common.remove')}
        </>
      ),
      action: () => {
        setFieldSelectDefaultOpen(false);
        setSelectedResponsibleBooker(undefined);
      },
      hidden: !setSelectedResponsibleBooker,
    },
  ];

  return selectedResponsibleBooker ? (
    <InformationCard
      title={t('common.responsibleBooker')}
      actionPopoverElements={actionPopoverElements}>
      <div className="flex flex-row items-center">
        <Gravatar
          email={selectedResponsibleBooker.email}
          className="mr-2 mb-2"
        />
        <div className="gap-y-1 informationCardText pb-2">
          <div className="font-medium">{selectedResponsibleBooker.name}</div>
          <div>{selectedResponsibleBooker.email}</div>
          <div>{selectedResponsibleBooker.phoneNumber}</div>
        </div>
      </div>
    </InformationCard>
  ) : (
    <FieldSelect
      className="w-64 ml-2"
      title={t('common.responsibleBooker')}
      placeholderText={t('common.responsibleBooker')}
      loading={loading}
      defaultOpen={fieldSelectDefaultOpen}
      elements={bookers?.map((booker) => ({
        primaryText: booker.name,
        id: booker.id!,
      }))}
      onSelect={(selectedId) =>
        setSelectedResponsibleBooker(
          bookers?.find((booker) => booker.id === selectedId),
        )
      }
    />
  );
};

export default ResponsibleBookerField;
