import React, { useCallback, useMemo } from 'react';
import { Button, Empty, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import SearchResult from '../types/SearchResult';
import * as DateUtils from '../../utils/date';
import { ColumnsType } from '../../types/ColumnTypes';
import Gravatar from '../../user/components/Gravatar';
import Table from '../../components/Table';

import DistanceBadge from './DistanceBadge';

type SearchResultsProps = {
  results?: Array<{
    title: string;
    rows: SearchResult[];
  }>;
  loading: boolean;
  onBook: (result: SearchResult) => void;
  onBookWithoutInstructor?: (toggle: boolean) => void;
};

const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  loading,
  onBook,
  onBookWithoutInstructor,
}) => {
  const { t } = useTranslation();

  const InstructorCell: React.FC<{ result: SearchResult }> = useCallback(
    ({ result }) => (
      <div className="flex items-center">
        <Gravatar
          email={result?.user?.email}
          internal={result?.user?.internal}
          isInstructor={true}
        />
        <div className="ml-4">
          <div className="text-sm font-medium">{result.user.name}</div>
          <div className="text-sm text-gray-500">{result.user.email}</div>
        </div>
      </div>
    ),
    [],
  );

  const TimePlaceCell: React.FC<{ result: SearchResult }> = useCallback(
    ({ result }) => (
      <div className="text-sm">
        {DateUtils.formatTime(result.start)} {t('components.SearchResults.to')}{' '}
        {DateUtils.formatTime(result.end)}
      </div>
    ),
    [t],
  );

  const DistanceCell: React.FC<{ result: SearchResult }> = useCallback(
    ({ result }) => (
      <DistanceBadge
        distance={result.distance}
        mileage={result.mileage}
        maxTravelDistance={result.user.maxTravelDistance}
      />
    ),
    [],
  );

  const CourseInstanceLimitCell: React.FC<{ result: SearchResult }> =
    useCallback(
      ({ result }) => (
        <div className="ml-6">
          {result.courseInstancesOnDayCount}/{result.courseInstanceLimitOnDay}
        </div>
      ),
      [],
    );

  const SelectionCell: React.FC<{ result: SearchResult }> = useCallback(
    ({ result }) => (
      <div className="flex justify-end pr-5">
        <Button className="text-xs font-medium" onClick={() => onBook(result)}>
          {t('common.book')}
        </Button>
      </div>
    ),
    [onBook, t],
  );

  const columns: ColumnsType<SearchResult> = useMemo(
    () => [
      {
        width: '45%',
        title: t('common.instructor'),
        render: (result) => <InstructorCell result={result} />,
      },
      {
        width: '18%',
        title: t('components.SearchResults.available'),
        render: (result) => <TimePlaceCell result={result} />,
      },
      {
        width: '12%',
        title: t('components.SearchResults.distance'),
        render: (result) => <DistanceCell result={result} />,
      },
      {
        width: '15%',
        title: t('common.courseInstances'),
        render: (result) => <CourseInstanceLimitCell result={result} />,
      },
      {
        width: '10%',
        title: '',
        render: (result) => <SelectionCell result={result} />,
      },
    ],
    [
      CourseInstanceLimitCell,
      DistanceCell,
      InstructorCell,
      SelectionCell,
      TimePlaceCell,
      t,
    ],
  );

  const noInstructorRow = useCallback(() => {
    return (
      <>
        <tr>
          <td className="px-6 pt-4" colSpan={columns.length}>
            <div className="pb-2 text-gray-800 text-base border-solid border-gray-200 border-0 border-b-2">
              {t(
                'components.SearchResults.createCourseInstanceWithoutInstructor',
              )}
            </div>
          </td>
        </tr>
        <tr
          className={`hover:bg-gray-100 hover-shadow ease-in-out transition-all ease-in-duration-200 border-solid border-gray-100 border-0 border-b-2`}>
          <td className="pl-6 py-3" colSpan={4}>
            <Tooltip
              placement="topLeft"
              title={<div>{t('components.SearchResults.noInstructor')}</div>}>
              <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10">
                  <Gravatar email={null} />
                </div>
                <div className="ml-4 overflow-hidden">
                  <div className="text-sm font-medium">
                    {t('components.SearchResults.noInstructor')}
                  </div>
                </div>
              </div>
            </Tooltip>
          </td>
          <td className="px-6 py-4" colSpan={1}>
            <div className="flex justify-end">
              <Button
                className="text-xs font-medium"
                onClick={() => onBookWithoutInstructor!(true)}>
                {t('common.book')}
              </Button>
            </div>
          </td>
        </tr>
      </>
    );
  }, [columns.length, onBookWithoutInstructor, t]);

  return (
    <div className="flex flex-col">
      <Table
        rowKey={(result, index) => index}
        columns={columns}
        loading={loading}
        groups={results}
        customTopRow={noInstructorRow()}
      />
      {results?.length === 0 && !loading && (
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description={<span>{t('components.SearchResults.noResults')}</span>}
        />
      )}
      {loading && <Spin size="large" className="mt-4 self-center" />}
    </div>
  );
};

export default SearchResults;
