import React from 'react';
import { useTranslation } from 'react-i18next';

const BookingOrderNotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-start gap-2">
      <span className="text-lg font-semibold">
        {t('views.BookingOrderNotFound.title')}
      </span>
      <span>{t('views.BookingOrderNotFound.description')}</span>
    </div>
  );
};

export default BookingOrderNotFound;
