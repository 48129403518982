import React, { useState } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import {
  UserSwitchOutlined,
  DeleteOutlined,
  UndoOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  RetweetOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  CourseInstanceCardData,
  CourseInstanceStatus,
} from '../types/CourseInstance';
import { ColumnsType } from '../../types/ColumnTypes';
import * as DateUtils from '../../utils/date';
import { useUser } from '../../user/userContext';
import CourseInstanceAPI from '../CourseInstanceAPI';
import Gravatar from '../../user/components/Gravatar';
import Table from '../../components/Table';
import ActionPopoverButton, {
  ActionPopoverElement,
} from '../../components/ActionPopoverButton';
import { userHasAnyPermission, userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import User from '../../user/types/User';

import { statusText } from './CourseInstanceStatusBadge';
import QuickActionEditModal, {
  EditableFormValues,
  FormType,
} from './QuickActionEditModal';
import CourseInstanceStatusIcon from './CourseInstanceStatusIcon';

type QuickActionPropsParam = {
  currentUser: User | null;
  courseInstance: CourseInstanceCardData;
  setCourseInstanceForUpdate: (value: CourseInstanceCardData) => void;
  setFormType: (value: FormType) => void;
  setShowModal: (value: boolean) => void;
};

export const QuickActionCell: React.FC<QuickActionPropsParam> = ({
  currentUser,
  courseInstance,
  setCourseInstanceForUpdate,
  setFormType,
  setShowModal,
}) => {
  const { t } = useTranslation();
  const onClickedAction = (action: FormType) => {
    setCourseInstanceForUpdate?.(courseInstance);
    setShowModal?.(true);
    setFormType(action);
  };

  const getActionPopoverElements = () => {
    let elements: ActionPopoverElement[] = [];
    if (userHasPermission(currentUser, Permission.COURSE_INSTANCE_UPDATE)) {
      elements = elements.concat(updateActionPopoverElements);
    }
    if (userHasPermission(currentUser, Permission.COURSE_INSTANCE_DELETE)) {
      elements = elements.concat(deleteActionPopoverElement);
    }
    return elements;
  };

  const updateActionPopoverElements: ActionPopoverElement[] = [
    {
      rowElement: (
        <div>
          <UserSwitchOutlined className="pr-2" />
          {t('components.CourseInstanceResults.selectInstructor')}
        </div>
      ),
      action: () => {
        onClickedAction(FormType.SelectInstructorForm);
      },
    },
    {
      rowElement: (
        <div>
          <RetweetOutlined className="pr-2" />
          {t('components.CourseInstanceResults.selectCourse')}
        </div>
      ),
      action: () => {
        onClickedAction(FormType.SelectCourseForm);
      },
    },
    {
      rowElement: (
        <div>
          {courseInstance.status === CourseInstanceStatus.Confirmed ? (
            <div>
              <UndoOutlined className="pr-2" />
              {t('components.CourseInstanceResults.unconfirm')}
            </div>
          ) : (
            <div>
              <CheckOutlined className="pr-2" />
              {t('common.confirm')}
            </div>
          )}
        </div>
      ),
      action: () => {
        onClickedAction(FormType.ConfirmForm);
      },
      hidden:
        !courseInstance.id ||
        !courseInstance.startsAt ||
        !courseInstance.endsAt ||
        !courseInstance.instructor,
    },
    {
      rowElement: (
        <div>
          <SwapOutlined className="pr-2" />
          {t('components.CourseInstanceResults.swapInstructor')}
        </div>
      ),
      action: () => {
        onClickedAction(FormType.SwapInstructorForm);
      },
      hidden: !courseInstance.instructor,
    },
    {
      rowElement: (
        <div>
          {courseInstance.status === CourseInstanceStatus.Canceled ? (
            <>
              <UndoOutlined className="pr-2" />
              {t('components.CourseInstanceResults.undoCancellation')}
            </>
          ) : (
            <>
              <CloseCircleOutlined className="pr-2" />
              {t('components.CourseInstanceResults.cancel')}
            </>
          )}
        </div>
      ),
      action: () => {
        onClickedAction(FormType.CancelForm);
      },
    },
  ];

  const deleteActionPopoverElement: ActionPopoverElement = {
    rowElement: (
      <div>
        <DeleteOutlined className="pr-2" />
        {t('common.delete')}
      </div>
    ),
    action: () => onClickedAction(FormType.DeleteForm),
  };

  return (
    <ActionPopoverButton actionPopoverElements={getActionPopoverElements()} />
  );
};

type CourseInstanceResultsProps = {
  courseInstances?: Array<{ title: string; rows: CourseInstanceCardData[] }>;
  loading: boolean;
  getLink: (courseInstance: CourseInstanceCardData) => string;
  updateCourseInstance: (
    id: number,
    courseInstance: CourseInstanceCardData,
  ) => void;
  removeCourseInstanceFromList: (id: number) => void;
  updating?: boolean;
  onFilterChange?: () => void;
};

const CourseInstanceResults: React.FC<CourseInstanceResultsProps> = ({
  courseInstances,
  loading,
  getLink,
  updateCourseInstance,
  removeCourseInstanceFromList,
  updating,
  onFilterChange,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [courseInstanceForUpdate, setCourseInstanceForUpdate] =
    useState<CourseInstanceCardData>();
  const [formType, setFormType] = useState<FormType>();
  const [updatingInstructor, setUpdatingInstructor] = useState(false);
  const [updatingCourse, setUpdatingCourse] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [deletingCourseInstance, setDeletingCourseInstance] = useState(false);

  const [currentUser] = useUser();
  const { t } = useTranslation();

  const onConfirm = (values: EditableFormValues) => {
    if (
      values.instructor !== undefined &&
      values.instructor !== courseInstanceForUpdate?.instructor
    ) {
      changeInstructor(values.instructor?.id ?? null);
    } else if (
      values.course &&
      values.course?.name !== courseInstanceForUpdate?.course?.name
    ) {
      changeCourse(values.course.id);
    }
  };

  const toggleCourseInstanceConfirmed = () => {
    if (
      courseInstanceForUpdate &&
      courseInstanceForUpdate.status !== CourseInstanceStatus.Confirmed
    ) {
      setConfirmedCourseInstance(true);
    } else {
      setConfirmedCourseInstance(false);
    }
  };

  const toggleCourseInstanceCanceled = () => {
    if (
      courseInstanceForUpdate &&
      courseInstanceForUpdate.status !== CourseInstanceStatus.Canceled
    ) {
      setCanceledCourseInstance(true);
    } else {
      setCanceledCourseInstance(false);
    }
  };

  const changeInstructor = async (instructorId: number | null) => {
    if (courseInstanceForUpdate) {
      try {
        setUpdatingInstructor(true);
        const { data } = await CourseInstanceAPI.changeCourseInstanceInstructor(
          courseInstanceForUpdate?.id,
          { instructorId },
        );
        updateCourseInstance(courseInstanceForUpdate.id, data);
      } finally {
        setUpdatingInstructor(false);
        setShowModal(false);
      }
    }
  };

  const changeCourse = async (courseId: number) => {
    if (courseInstanceForUpdate) {
      try {
        setUpdatingCourse(true);
        const { data } = await CourseInstanceAPI.changeCourseInstanceCourse(
          courseInstanceForUpdate?.id,
          {
            courseId,
          },
        );
        updateCourseInstance(courseInstanceForUpdate.id, data);
      } finally {
        setUpdatingCourse(false);
        setShowModal(false);
      }
    }
  };

  const setConfirmedCourseInstance = async (confirmed: boolean) => {
    if (courseInstanceForUpdate) {
      try {
        setUpdatingStatus(true);
        const { data } = await CourseInstanceAPI.setConfirmed(
          courseInstanceForUpdate?.id,
          {
            confirmed,
          },
        );
        updateCourseInstance(courseInstanceForUpdate.id, data);
      } finally {
        setUpdatingStatus(false);
        setShowModal(false);
      }
    }
  };

  const setCanceledCourseInstance = async (canceled: boolean) => {
    if (courseInstanceForUpdate) {
      try {
        setUpdatingStatus(true);
        const { data } = await CourseInstanceAPI.setCanceled(
          courseInstanceForUpdate?.id,
          {
            canceled,
          },
        );
        updateCourseInstance(courseInstanceForUpdate.id, data);
      } finally {
        setUpdatingStatus(false);
        setShowModal(false);
      }
    }
  };

  const deleteCourseInstance = async () => {
    if (courseInstanceForUpdate) {
      try {
        setDeletingCourseInstance(true);
        await CourseInstanceAPI.deleteCourseInstance(
          courseInstanceForUpdate.id,
        );
        removeCourseInstanceFromList(courseInstanceForUpdate.id);
      } finally {
        setDeletingCourseInstance(false);
        setShowModal(false);
      }
    }
  };

  const InstructorCell: React.FC<{
    courseInstance: CourseInstanceCardData;
  }> = ({ courseInstance }) => (
    <Tooltip
      placement="topLeft"
      title={
        <>
          <div>
            {courseInstance?.instructor?.name ||
              t('components.CourseInstanceResults.instructorMissing')}
          </div>
          <div>
            {courseInstance?.instructor?.email ||
              `c/o ${courseInstance?.createdByName}`}
          </div>
        </>
      }>
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <Gravatar
            email={courseInstance?.instructor?.email}
            internal={courseInstance.instructor?.internal}
            isInstructor={true}
          />
        </div>
        <div className="ml-4 overflow-hidden">
          <div className="text-sm font-medium">
            {courseInstance?.instructor?.name ||
              t('components.CourseInstanceResults.instructorMissing')}
          </div>
          <div className="text-sm text-gray-500 text-ellipsis">
            {courseInstance?.instructor?.email ||
              `c/o ${courseInstance?.createdByName}`}
          </div>
        </div>
      </div>
    </Tooltip>
  );

  const TimePlaceCell: React.FC<{ courseInstance: CourseInstanceCardData }> = ({
    courseInstance,
  }) => (
    <>
      <div className="text-sm">
        {DateUtils.formatDate(courseInstance.startsAt)}
        <br />
        {DateUtils.formatTime(courseInstance.startsAt)} -{' '}
        {DateUtils.formatTime(courseInstance.endsAt)}
      </div>
      <div className="text-sm text-gray-500">{courseInstance.address}</div>
    </>
  );

  const StatusCell: React.FC<{ courseInstance: CourseInstanceCardData }> = ({
    courseInstance,
  }) => {
    const { t } = useTranslation();
    return (
      <div className="ml-3">
        <Tooltip
          placement="topLeft"
          title={statusText(t)[courseInstance.status]}>
          <CourseInstanceStatusIcon
            courseInstanceStatus={courseInstance.status}
          />
        </Tooltip>
      </div>
    );
  };

  const ClientCell: React.FC<{ courseInstance: CourseInstanceCardData }> = ({
    courseInstance,
  }) => (
    <Tooltip placement="topLeft" title={courseInstance?.clientName}>
      <div className="text-ellipsis-multiline ">
        {courseInstance?.clientName}
      </div>
    </Tooltip>
  );

  const CourseCell: React.FC<{ courseInstance: CourseInstanceCardData }> = ({
    courseInstance,
  }) => (
    <Tooltip placement="topLeft" title={courseInstance?.course?.name}>
      <div className="text-ellipsis-multiline">
        {courseInstance?.course?.name}
      </div>
    </Tooltip>
  );

  const SubsidiaryCompanyCell: React.FC<{
    courseInstance: CourseInstanceCardData;
  }> = ({ courseInstance }) => (
    <Tooltip placement="topLeft" title={courseInstance?.subsidiaryCompanyName}>
      <div className="text-ellipsis-multiline">
        {courseInstance?.subsidiaryCompanyName}
      </div>
    </Tooltip>
  );

  const columns: ColumnsType<CourseInstanceCardData> = [
    {
      title: t('components.CourseInstanceResults.status'),
      width: '8%',
      render: (courseInstance) => (
        <StatusCell courseInstance={courseInstance} />
      ),
    },
    {
      title: t('components.CourseInstanceResults.timeAndPlace'),
      width: '20%',
      render: (courseInstance) => (
        <TimePlaceCell courseInstance={courseInstance} />
      ),
    },
    {
      title: t('components.CourseInstanceResults.client'),
      render: (courseInstance) => (
        <ClientCell courseInstance={courseInstance} />
      ),
    },
    {
      title: t('common.instructor'),
      width: '25%',
      render: (courseInstance) => (
        <InstructorCell courseInstance={courseInstance} />
      ),
    },
    {
      title: t('common.course'),
      render: (courseInstance) => (
        <CourseCell courseInstance={courseInstance} />
      ),
    },
    {
      title: t('components.CourseInstanceResults.responsibleCompany'),
      width: '12%',
      render: (courseInstance) => (
        <SubsidiaryCompanyCell courseInstance={courseInstance} />
      ),
    },
    {
      title: '',
      width: '7%',
      notLinked: true,
      render: (courseInstance) =>
        userHasAnyPermission(currentUser, [
          Permission.COURSE_INSTANCE_UPDATE,
          Permission.COURSE_INSTANCE_DELETE,
        ]) && (
          <QuickActionCell
            currentUser={currentUser}
            setCourseInstanceForUpdate={setCourseInstanceForUpdate}
            courseInstance={courseInstance}
            setFormType={setFormType}
            setShowModal={setShowModal}
          />
        ),
    },
  ];

  return (
    <div className="flex flex-col">
      <Table
        responsive
        columns={columns}
        rowKey={(courseInstance) => courseInstance?.id}
        loading={loading || updating}
        getRowLink={getLink}
        groups={courseInstances}
      />
      {courseInstances?.length === 0 && !loading && (
        <Empty
          image={Empty.PRESENTED_IMAGE_DEFAULT}
          description={
            <span>
              {t('components.CourseInstanceResults.noCourseInstances')}
            </span>
          }
        />
      )}
      {(loading || updating) && (
        <Spin size="large" className="mt-4 self-center" />
      )}
      {courseInstanceForUpdate && showModal && (
        <QuickActionEditModal
          visible
          formType={formType}
          courseInstance={courseInstanceForUpdate}
          setShowModal={setShowModal}
          onConfirm={onConfirm}
          toggleCourseInstanceCanceled={toggleCourseInstanceCanceled}
          toggleCourseInstanceConfirmed={toggleCourseInstanceConfirmed}
          deleteCourseInstance={deleteCourseInstance}
          updatingInstructor={updatingInstructor}
          updatingCourse={updatingCourse}
          updatingStatus={updatingStatus}
          deletingCourseInstance={deletingCourseInstance}
          onFilterChange={onFilterChange}
        />
      )}
    </div>
  );
};

export default CourseInstanceResults;
