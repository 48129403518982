import React, { useState, useEffect, useCallback } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import { Geolocation } from '../types/Location';
import {
  getSuggestionByAddress,
  getSuggestionByPlaceId,
} from '../utils/address';
import { useUser } from '../user/userContext';
import countryCodes from '../utils/countryCodes.json';

export type PlaceSuggestion = {
  address: string;
  placeId: string;
  geolocation: Geolocation;
};

type AddressSearchInputProps = {
  initialValue?: string;
  placeholder?: string;
  size?: InputProps['size'];
  placeId?: string;
  disabled?: boolean;
  exactAddressesOnly?: boolean;
  onChange?: (placeSuggestion?: PlaceSuggestion | null) => void;
};

const AddressSearchInput: React.FC<AddressSearchInputProps> = ({
  initialValue,
  placeholder,
  size,
  placeId,
  disabled,
  exactAddressesOnly,
  onChange,
}: AddressSearchInputProps) => {
  const [address, setAddress] = useState<string>('');
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const [user] = useUser();

  const handleSelect = useCallback(
    async (value: string) => {
      setLoadingAddress(true);
      try {
        const suggestion = await getSuggestionByAddress(value);
        setAddress(suggestion.address);
        onChange?.(suggestion);
      } finally {
        setLoadingAddress(false);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (initialValue) {
      setAddress(initialValue);
      handleSelect(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    (async () => {
      if (placeId) {
        setLoadingAddress(true);
        try {
          const suggestion = await getSuggestionByPlaceId(placeId);
          setAddress(suggestion.address);
          onChange?.(suggestion);
        } finally {
          setLoadingAddress(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  const handleChange = useCallback(
    async (value: string) => {
      setAddress(value);
      if (value === '') onChange?.(null);
    },
    [onChange],
  );

  // Bias within Sweden.
  const searchOptions = {
    region: (countryCodes as Record<string, string>)[user?.market.code || ''],
    language: user?.preferredLanguage?.code,
    types: exactAddressesOnly ? ['address'] : ['geocode'],
  };

  return (
    <PlacesAutocomplete
      value={address}
      highlightFirstSuggestion
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const inputProps = getInputProps({
          placeholder: placeholder,
          disabled: loadingAddress,
          loading: loading || loadingAddress,
        });
        return (
          <>
            <Input.Search
              {...inputProps}
              size={size}
              allowClear
              disabled={disabled}
              onKeyDown={inputProps.onKeyDown}
            />
            {!!suggestions.length && (
              <div className="flex flex-col mt-2 py-1 shadow rounded overflow-hidden absolute bg-white z-10">
                {suggestions.map((s) => {
                  return (
                    <div
                      {...getSuggestionItemProps(s, {
                        className: `px-3 py-2 text-gray-800 cursor-pointer hover:bg-gray-200 ${
                          size === 'large' ? 'text-sm' : 'text-xs'
                        } ${s.active ? 'bg-gray-200' : ''}`,
                      })}
                      key={s.placeId}>
                      {s.description}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AddressSearchInput;
