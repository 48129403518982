import React from 'react';
import dayjs from 'dayjs';

const MAX_TICKS = 14;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderLightTick = (props: any, formatter?: (v: any) => string) => {
  const { x, y, textAnchor, textBaseline, value, textX, textY, animatedProps } =
    props;

  return (
    <g transform={`translate(${x}, ${y})`}>
      <text
        x={textX}
        y={textY}
        textAnchor={textAnchor}
        alignmentBaseline={textBaseline}
        style={{ fontSize: '12px', opacity: 0.5 }}
        {...animatedProps}>
        {formatter !== undefined ? formatter(value) : value}
      </text>
    </g>
  );
};

export const computeTickValues = (ticks: Date[]): Date[] => {
  let values = ticks;

  while (values.length > MAX_TICKS) {
    values = values.filter((_, idx) => idx % 2 === 0);
  }

  return values;
};

export const getDateRangeFormat = (
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
): string => {
  return Math.abs(start.diff(end, 'month')) > 12 ? 'D MMM YYYY' : 'D MMM';
};
