import React, { FC } from 'react';
import { CourseInstanceAttendeeStatus } from '../types/CourseInstance';
import { useTranslation } from 'react-i18next';
import Passed from '../../resources/img/passed.svg';
import Failed from '../../resources/img/failed.svg';
import Participated from '../../resources/img/participated.svg';
import DidNotParticipate from '../../resources/img/didNotParticipate.svg';

type CourseInstanceAttendeeStatusBoxProps = {
  status: CourseInstanceAttendeeStatus;
  onClick?: () => void;
};

export const CourseInstanceAttendeeStatusBox: FC<
  CourseInstanceAttendeeStatusBoxProps
> = ({ status, onClick }) => {
  const { t } = useTranslation();

  switch (status) {
    case CourseInstanceAttendeeStatus.Passed:
      return (
        <div className="flex gap-2" onClick={onClick}>
          <img src={Participated} className="w-4" />
          <img src={Passed} className="w-4" />
          {t('components.CourseInstanceAttendanceListModal.statusPassed')}
        </div>
      );
    case CourseInstanceAttendeeStatus.Failed:
      return (
        <div className="flex gap-2" onClick={onClick}>
          <img src={Participated} className="w-4" />
          <img src={Failed} className="w-4" />
          {t('components.CourseInstanceAttendanceListModal.statusFailed')}
        </div>
      );
    case CourseInstanceAttendeeStatus.DidNotParticipate:
      return (
        <div className="flex gap-2" onClick={onClick}>
          <img src={DidNotParticipate} className="w-4" />
          {t(
            'components.CourseInstanceAttendanceListModal.statusDidNotParticipate',
          )}
        </div>
      );
  }
};
