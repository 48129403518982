import { Button, Form, Input, Modal, message } from 'antd';
import React, { useState } from 'react';
import { CertificateLanguage } from '../../certificate/types/Certificate';
import { useTranslation } from 'react-i18next';
import CertificateAPI, {
  CertificateLanguageParams,
} from '../../certificate/CertificateAPI';
import { isAxiosError } from 'axios';

type CreateCertificateLanguageModalProps = {
  visible: boolean;
  onCreate: (certificateLanguage: CertificateLanguage) => void;
  onCancel: () => void;
};

const CreateCertificateLanguageModal: React.FC<
  CreateCertificateLanguageModalProps
> = ({ visible, onCreate, onCancel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onFinish = async (values: CertificateLanguageParams) => {
    setSubmitting(true);
    try {
      const { data: newCertificateLangugage } =
        await CertificateAPI.createCertificateLanguage(values);
      onCreate(newCertificateLangugage);
      resetAndClose();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const resetAndClose = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={t('components.CertificateLanguageModal.title')}
      open={visible}
      onCancel={resetAndClose}
      onOk={form.submit}
      width="60%"
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createCertificateLangugageForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createCertificateLangugageForm"
        layout="vertical"
        form={form}
        onFinish={(values) => onFinish(values)}>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('components.CertificateLanguageModal.form.enterName'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.code')}
          name="code"
          rules={[
            {
              required: true,
              message: t('components.CertificateLanguageModal.form.enterCode'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.certificateLabel')}
          name="certificateLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.courseDateLabel')}
          name="courseDateLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.locationLabel')}
          name="locationLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.validToLabel')}
          name="validToLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.instructorLabel')}
          name="instructorLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t('components.CertificateLanguageModal.form.issuerLabel')}
          name="issuerLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t(
            'components.CertificateLanguageModal.form.authenticationLabel',
          )}
          name="authenticationLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t(
            'components.CertificateLanguageModal.form.awardedByCompanyLabel',
          )}
          name="awardedByCompanyLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input
            placeholder={t(
              'components.CertificateLanguageModal.form.enterAwardedByCompanyLabel',
            )}
          />
        </Form.Item>
        <Form.Item
          label={t(
            'components.CertificateLanguageModal.form.attendeeSignatureLabel',
          )}
          name="attendeeSignatureLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t(
            'components.CertificateLanguageModal.form.instructorSignatureLabel',
          )}
          name="instructorSignatureLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t(
            'components.CertificateLanguageModal.form.issuerSignatureLabel',
          )}
          name="issuerSignatureLabel"
          rules={[
            {
              required: true,
              message: t(
                'components.CertificateLanguageModal.form.enterTranslation',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCertificateLanguageModal;
