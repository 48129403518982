import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, MenuProps, Spin, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  CourseInstanceAttendee,
  CourseInstanceAttendeeStatus,
  DisplayedCourseInstance,
} from '../types/CourseInstance';
import { t } from 'i18next';
import CertificateAPI from '../../certificate/CertificateAPI';
import {
  createCertificateFileName,
  createCertificateZipFileName,
} from '../../certificate/CertificateUtils';
import { CertificateLanguage } from '../../certificate/types/Certificate';
import Certificate from '../../resources/img/certificate.svg';
import CourseAPI from '../../course/CourseAPI';

type DownloadCertificateDropdownProps = {
  courseInstance: DisplayedCourseInstance;
  selectedAttendees: CourseInstanceAttendee[];
};

const DownloadCertificateDropdown: React.FC<
  DownloadCertificateDropdownProps
> = ({ courseInstance, selectedAttendees }) => {
  const [loadingCertificateLanguages, setLoadingCertificateLanguages] =
    useState<boolean>(false);
  const [certificateLanguages, setCertificateLanguages] = useState<
    CertificateLanguage[]
  >([]);
  const [
    certificateNotDownloadableTooltip,
    setCertificateNotDownloadableTooltip,
  ] = useState<string>();

  const getCertificateDownloadTooltip = useCallback(() => {
    const onlyPassedAttendees = selectedAttendees.every(
      (attendee) => attendee.status === CourseInstanceAttendeeStatus.Passed,
    );

    if (selectedAttendees.length === 0) {
      return t('components.CourseInstanceCard.classNoPassedAttendees');
    } else if (!courseInstance.instructor || !courseInstance.startsAt) {
      return t('components.CourseInstanceCard.classMissingInstructorStartsAt');
    } else if (!onlyPassedAttendees) {
      return t(
        'components.CourseInstanceCard.selectionIncludesNonParticipants',
      );
    } else if (certificateLanguages.length == 0) {
      return t('components.CourseInstanceCard.noTemplateAvailable');
    }
    return undefined;
  }, [
    certificateLanguages.length,
    courseInstance.instructor,
    courseInstance.startsAt,
    selectedAttendees,
  ]);

  const downloadCertificates = useCallback<NonNullable<MenuProps['onClick']>>(
    ({ key }) => {
      if (
        certificateNotDownloadableTooltip ||
        !courseInstance.startsAt ||
        key == '-1'
      ) {
        return;
      }

      if (selectedAttendees.length === 1) {
        const attendee = selectedAttendees[0];
        const fileName = createCertificateFileName(
          attendee.name,
          courseInstance.course.name,
          courseInstance.startsAt,
        );
        if (attendee.id) {
          CertificateAPI.getCertificateForAttendee(
            fileName,
            attendee.id,
            parseInt(key),
          );
        } else if (attendee.elsaId) {
          CertificateAPI.getCertificateForElsaParticipant(
            fileName,
            attendee.elsaId,
            courseInstance.id!,
            parseInt(key),
          );
        } else {
          throw Error("Attendee must either have an 'id' or an 'elsaId'");
        }
      } else {
        const attendeeIds = selectedAttendees
          .map((a) => a.id)
          .filter((id) => !!id);
        const attendeeElsaIds = selectedAttendees
          .map((a) => a.elsaId)
          .filter((id) => !!id);

        if (
          (attendeeIds.length === 0 && attendeeElsaIds.length === 0) ||
          (attendeeIds.length > 0 && attendeeElsaIds.length > 0)
        ) {
          throw Error("Attendees must either have an 'id' or an 'elsaId'");
        }

        const fileName = createCertificateZipFileName(
          courseInstance.course.name,
          courseInstance.startsAt,
        );

        CertificateAPI.getCertificateZip(
          fileName,
          courseInstance.course.id,
          courseInstance.id!,
          parseInt(key),
          attendeeIds.map((id) => id!),
          attendeeElsaIds.map((elsaId) => elsaId!),
        );
      }
    },
    [
      certificateNotDownloadableTooltip,
      courseInstance.course.id,
      courseInstance.course.name,
      courseInstance.id,
      courseInstance.startsAt,
      selectedAttendees,
    ],
  );

  const fetchCourseAttendeeLanguages = useCallback(async () => {
    setLoadingCertificateLanguages(true);
    CourseAPI.getCourseCertificateLanguages(courseInstance.course.id)
      .then((response) => {
        setCertificateLanguages(response.data);
      })
      .finally(() => setLoadingCertificateLanguages(false));
  }, [courseInstance.course.id]);

  useEffect(() => {
    fetchCourseAttendeeLanguages();
  }, [fetchCourseAttendeeLanguages]);

  useEffect(() => {
    setCertificateNotDownloadableTooltip(getCertificateDownloadTooltip());
  }, [getCertificateDownloadTooltip]);

  return (
    <Dropdown
      trigger={['click']}
      className={!!certificateNotDownloadableTooltip ? 'bg-gray-100' : ''}
      menu={{
        items: certificateLanguages.map((language) => {
          return {
            label: language.name,
            key: language.id.toString(),
          };
        }),
        onClick: downloadCertificates,
      }}>
      <Tooltip title={certificateNotDownloadableTooltip}>
        <Button
          disabled={!!certificateNotDownloadableTooltip || !courseInstance.id}
          className={`flex border-1 shadow-none rounded h-10 items-center font-medium ${
            !!certificateNotDownloadableTooltip ? '' : 'border-blue-300'
          }`}>
          <img src={Certificate} className="pr-1" />
          {t('components.CourseInstanceCard.downloadCertificate')}
          <div className="flex items-center pl-2">
            {loadingCertificateLanguages ? (
              <Spin size="small" />
            ) : (
              <DownOutlined />
            )}
          </div>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};

export default DownloadCertificateDropdown;
