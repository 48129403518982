import React, { useCallback, useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowsAltOutlined } from '@ant-design/icons';

import InsightsAPI from '../InsightsAPI';
import { CourseChartData, CourseChartSortOrder } from '../types/Graph';
import colors from '../../theme/colors';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';

import CourseDoughnutChart, { ChartOption } from './CourseDoughnutChart';
import CourseInsightModal from './CourseInsightModal';

type CourseChartCardProps = {
  fromDate: Dayjs;
  toDate: Dayjs;
  subsidiaryCompany?: SubsidiaryCompany;
};

const chartListElementCount = 5;
const displayedChartElementsCount = 10;

const CourseChartCard: React.FC<CourseChartCardProps> = ({
  fromDate,
  toDate,
  subsidiaryCompany,
}) => {
  const { t } = useTranslation();
  const [courseChartData, setCourseChartData] = useState<CourseChartData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);

  const getCourseChartData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await InsightsAPI.getCourseChartData(
        CourseChartSortOrder.DESCENDING,
        fromDate.format('YYYY-MM-DD'),
        toDate.format('YYYY-MM-DD'),
        subsidiaryCompany?.id,
      );

      const displayedChartCourses =
        data.length > displayedChartElementsCount
          ? data.slice(0, displayedChartElementsCount).concat({
              courseId: 0,
              courseName: t('common.other'),
              instructorCount: 0,
              courseInstanceCount: data
                .slice(displayedChartElementsCount)
                .map((chartData) => chartData.courseInstanceCount)
                .reduce((a, b) => a + b),
            })
          : data;
      setCourseChartData(displayedChartCourses);
    } finally {
      setLoading(false);
    }
  }, [fromDate, subsidiaryCompany?.id, t, toDate]);

  useEffect(() => {
    getCourseChartData();
  }, [getCourseChartData]);

  useEffect(() => {
    if (!courseChartData) {
      return;
    }
  }, [courseChartData]);

  return (
    <>
      <div
        className="insights-course-chart-card"
        onClick={() => setShowModal(true)}>
        <ArrowsAltOutlined className="insights-course-chart-card-expand" />
        <div className="flex flex-row justify-between items-center px-6">
          <div>
            <div className="grid grid-cols-3">
              <div>
                <div className="flex flex-row gap-2">
                  <h3>{t('common.courses')}</h3>
                  {loading && <Spin />}
                </div>
                <div className="mr-4 text-md text-gray-700">
                  {t('components.CourseChartCard.courseCardText', {
                    subsidiaryCompany:
                      subsidiaryCompany?.name ??
                      t('components.CourseChartCard.allSubsidiaryCompanies'),
                  })}
                </div>
              </div>
              {!loading && courseChartData && (
                <>
                  <CourseDoughnutChart
                    chartOption={ChartOption.ALL}
                    className="pt-2"
                    courseChartData={courseChartData!}
                  />
                  <div className="flex flex-col justify-center pt-4">
                    {courseChartData
                      ?.slice(0, chartListElementCount)
                      ?.map((courseChartDataElement, index) => (
                        <div
                          key={courseChartDataElement.courseId}
                          className="flex flex-row items-center pb-4 text-gray-700">
                          <div
                            style={{
                              backgroundColor: Object.values(
                                colors.doughnutChart,
                              )[index],
                            }}
                            className="h-4 w-4 rounded-lg mr-2"
                          />
                          {courseChartDataElement.courseName}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <CourseInsightModal
        visible={showModal}
        setVisible={setShowModal}
        subsidiaryCompanyId={subsidiaryCompany?.id}
      />
    </>
  );
};

export default CourseChartCard;
