import React from 'react';
import { HeartFilled, HomeFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import User, { MinimalUser } from '../user/types/User';

type UserOptionProps = {
  user?: User | MinimalUser;
  currentUser?: User | null;
  noUserText?: string;
};

const UserOption: React.FC<UserOptionProps> = ({
  user,
  currentUser,
  noUserText,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {user ? (
        <div className="flex">
          <span>{user.name}</span>
          <div className="flex items-center mx-1 gap-1">
            {user.internal && (
              <HomeFilled
                style={{
                  fontSize: '10px',
                  color: '#FFFFFF',
                }}
                className={
                  'h-4 w-4 bg-orange-600 border-2 rounded-full flex items-center justify-center'
                }
              />
            )}
            {user.id &&
              currentUser?.favoriteInstructorIds.includes(user.id) && (
                <HeartFilled
                  className="pr-3"
                  style={{
                    fontSize: '18px',
                    color: '#FF2121',
                  }}
                />
              )}
          </div>
        </div>
      ) : (
        (noUserText ?? t('components.UserOption.noUser'))
      )}
    </>
  );
};

export default UserOption;
