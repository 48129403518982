import dayjs from 'dayjs';

export const createCertificateFileName = (
  attendeeName: string,
  courseName: string,
  courseInstanceStartsAt: string,
) => {
  return `${attendeeName} - ${courseName}, ${dayjs(
    courseInstanceStartsAt,
  ).format('YYYY-MM-DD')}`;
};

export const createCertificateZipFileName = (
  courseName: string,
  courseInstanceStartsAt: string,
) => {
  return `${courseName}, ${dayjs(courseInstanceStartsAt).format('YYYY-MM-DD')}`;
};
