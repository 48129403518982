import { ShopOutlined } from '@ant-design/icons';
import { Drawer, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import SubsidiaryCompanyAPI from '../subsidiaryCompany/SubsidiaryCompanyAPI';
import SubsidiaryCompany from '../subsidiaryCompany/types/SubsidiaryCompany';
import { Permission } from '../user/types/Permission';
import UserAPI from '../user/UserAPI';
import { useUser } from '../user/userContext';
import { userHasPermission } from '../user/userUtils';

type NavigationDrawerProps = {
  open: boolean;
  onClose: () => void;
  menuItems: { label: string; to: string }[];
  openBugReport?: () => void;
  logout?: () => void;
};

const NEW_ORDER_KEY = 'new-order';
const NEW_ORDER_PATH = '/ny-bestallning';

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  open,
  onClose,
  menuItems,
  openBugReport,
  logout,
}) => {
  const [subsidiaries, setSubsidiaries] = useState<SubsidiaryCompany[]>([]);

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useUser();

  useEffect(() => {
    if (!userHasPermission(user, Permission.USER_UPDATE_GLOBAL)) return;
    SubsidiaryCompanyAPI.getSubsidiaryCompanies().then(({ data }) =>
      setSubsidiaries(data),
    );
  }, [user]);

  const currentPath =
    pathname === NEW_ORDER_PATH
      ? NEW_ORDER_KEY
      : menuItems.find(({ to }) => pathname.startsWith(to))?.to;
  const currentSubsidiary = user?.preferredSubsidiaryCompany?.id;

  return (
    <Drawer open={open} onClose={onClose} placement="right">
      <Menu
        mode="inline"
        onClick={({ key }) => {
          if (key.startsWith('/')) {
            navigate(key);
            onClose();
          } else if (key.startsWith('sub.')) {
            if (!user) return;
            UserAPI.updateUser(user.id, {
              email: user.email,
              name: user.name,
              phoneNumber: user.phoneNumber,
              internal: user.internal,
              maxTravelDistance: user.maxTravelDistance,
              defaultCourseInstanceLimit: user.defaultCourseInstanceLimit,
              preferredSubsidiaryCompanyId: Number(
                key.substring('sub.'.length),
              ),
              marketId: user.market.id,
            }).then(({ data }) => {
              setUser(data);
              navigate(0);
            });
          } else if (key === NEW_ORDER_KEY) {
            navigate(NEW_ORDER_PATH);
            onClose();
          } else if (key === 'current-user') {
            if (!user) return;
            navigate(`/anvandare/${user.id}`);
            onClose();
          } else if (key === 'bug-report') {
            openBugReport?.();
          } else if (key === 'logout') {
            logout?.();
          }
        }}
        selectedKeys={
          [
            currentPath,
            currentSubsidiary ? `sub.${currentSubsidiary}` : null,
          ].filter(Boolean) as string[]
        }
        items={
          [
            ...menuItems.map((item) => ({
              key: item.to,
              label: item.label,
            })),
            !user?.isOnlyInstructor && { type: 'divider' },
            !user?.isOnlyInstructor &&
              (userHasPermission(user, Permission.USER_UPDATE_GLOBAL)
                ? {
                    key: 'subsidiaries',
                    label: t('components.NavigationDrawer.subsidiaries'),
                    icon: <ShopOutlined />,
                    children: subsidiaries.map((subsidiary) => ({
                      label: subsidiary.name,
                      key: `sub.${subsidiary.id}`,
                    })),
                  }
                : {
                    key: 'current-subsidiary',
                    label:
                      user?.preferredSubsidiaryCompany?.name ??
                      t('components.NavigationDrawer.noSubsidiarySelected'),
                    disabled: true,
                  }),
            { type: 'divider' },
            { key: 'new-order', label: t('components.Navbar.newOrder') },
            { key: 'current-user', label: t('components.Navbar.myPage') },
            { key: 'bug-report', label: t('components.Navbar.bugReport') },
            {
              key: 'logout',
              label: t('components.Navbar.logout'),
              danger: true,
            },
          ].filter(Boolean) as MenuProps['items']
        }
      />
    </Drawer>
  );
};

export default NavigationDrawer;
