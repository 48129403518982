import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Spin } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import BookingOrderAPI from '../bookingOrders/api/BookingOrderAPI';
import BookingOrder from '../bookingOrders/types/BookingOrder';

const LimeRedirect: React.FC = () => {
  const { limeId, subsidiaryId } = useParams();
  const navigate = useNavigate();
  const [loadingBookingOrder, setLoadingBookingOrder] = useState<boolean>(true);
  const [bookingOrder, setBookingOrder] = useState<BookingOrder | null>(null);
  const [missingBookingOrder, setMissingBookingOrder] = useState<string | null>(
    null,
  );

  const { t } = useTranslation();

  const fetchBookingOrder = useCallback(async () => {
    try {
      const { data } = await BookingOrderAPI.getLimeBookingOrder(
        Number(limeId),
        Number(subsidiaryId),
      );
      setBookingOrder(data);
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        setMissingBookingOrder(t('errors.orderNotFound'));
      } else {
        setMissingBookingOrder(t('errors.somethingWentWrong'));
      }
    } finally {
      setLoadingBookingOrder(false);
    }
  }, [limeId, subsidiaryId, t]);

  useEffect(() => {
    fetchBookingOrder();
  }, [fetchBookingOrder]);

  useEffect(() => {
    if (bookingOrder) {
      navigate(`/bestallningar/${bookingOrder.id}`);
    }
  }, [bookingOrder, navigate]);

  useEffect(() => {
    if (missingBookingOrder) {
      message.error(missingBookingOrder);
    }
  }, [missingBookingOrder]);

  return (
    <div className="flex flex-col">
      {loadingBookingOrder && (
        <Spin size="large" className="mt-4 self-center justify-self-center" />
      )}
      {missingBookingOrder && (
        <h3 className="self-center">{missingBookingOrder}</h3>
      )}
    </div>
  );
};

export default LimeRedirect;
