import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Alert, message } from 'antd';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import Logo from '../../components/Logo';
import AuthAPI from '../AuthAPI';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>();

  const { t } = useTranslation();

  useEffect(() => {
    if (location.search) {
      const queryParams = queryString.parse(location.search);
      setToken(queryParams.token ? String(queryParams.token) : '');
    }
  }, [location.search]);

  const onFinish = async (values: FormValues) => {
    setLoading(true);
    try {
      await AuthAPI.resetPassword({
        token: token ? token : '',
        password: values.password,
      });
      message.success(t('views.ResetPassword.passwordChanged'));
    } finally {
      setLoading(false);
      navigate(
        location.pathname.match(/(.*?)\/nytt-losenord\/.*/)?.[1] ?? 'login',
        { replace: true },
      );
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="flex flex-col justify-center mb-4">
          <Logo className="h-12 fill-current text-red-700 stroke-current" />
          <h2 className="mt-6 text-center text-3xl leading-9 font-bold">
            {t('views.ResetPassword.resetPassword')}
          </h2>
        </div>

        <div className="bg-white p-10 shadow-md rounded-md">
          <Form
            onFinish={(values) => onFinish(values as FormValues)}
            onValuesChange={() => setError(null)}
            layout="vertical">
            <Form.Item
              label={t('views.ResetPassword.newPassword')}
              name="password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('views.ResetPassword.fillInPassword'),
                },
                {
                  min: 8,
                  message: t('views.ResetPassword.minimumPasswordLength'),
                },
              ]}>
              <Input.Password size="large" maxLength={64} />
            </Form.Item>
            <Form.Item
              label={t('views.ResetPassword.confirmPassword')}
              name="confirmPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('views.ResetPassword.fillInPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('views.ResetPassword.passwordsDoNotMatch')),
                    );
                  },
                }),
              ]}>
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                size="large"
                className="w-full mt-4 font-medium bg-primary-800 border-primary-800 hover:bg-primary-700 hover:border-primary-700 focus:bg-primary-900 focus:border-primary-900">
                {t('common.confirm')}
              </Button>
            </Form.Item>
            {error && <Alert message={error} type="error" />}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
