import axios, { AxiosError, AxiosResponse } from 'axios';
import queryString from 'query-string';

import { Token } from '../auth/token';

axios.defaults.paramsSerializer = (params) => queryString.stringify(params);

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

let tokenInterceptor: number;

export const setAuthorizationToken = (token: Token) => {
  const authToken = token ? `Bearer ${token}` : '';

  if (tokenInterceptor != null) {
    client.interceptors.request.eject(tokenInterceptor);
  }

  tokenInterceptor = client.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers.Authorization = authToken;
    }
    return config;
  });
};

let errorInterceptor: number;

export const addResponseInterceptor = (
  errorCallback: (error: AxiosError['response']) => void,
  warningHeaderCallback: (headers: AxiosResponse['headers']) => void,
) => {
  removeErrorInterceptor();

  errorInterceptor = client.interceptors.response.use(
    (response) => {
      warningHeaderCallback(response.headers);
      return response;
    },
    (error: AxiosError) => {
      errorCallback(error.response);
      throw error;
    },
  );
};

export const removeErrorInterceptor = () => {
  if (errorInterceptor != null) {
    client.interceptors.response.eject(errorInterceptor);
  }
};

export default client;
