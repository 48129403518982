import React, { useCallback, useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Chart } from 'react-chartjs-2';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Xarrow from 'react-xarrows';

import InsightsAPI from '../InsightsAPI';
import { CourseInstanceInsightData, PieChartData } from '../types/Graph';

type CourseInstanceCategoryCountProps = {
  id?: string;
  count: number;
};

const CourseInstanceCategoryCount: React.FC<
  CourseInstanceCategoryCountProps
> = ({ id, count }) => {
  return (
    <div
      id={id}
      className="py-1 px-3 rounded-lg bg-offwhite flex justify-center z-10 font-medium">
      {count}
    </div>
  );
};

type CourseInstanceCategoryProps = {
  id?: string;
  className?: string;
  title: string;
  count?: number;
  backgroundColor: string;
};

const CourseInstanceCategory: React.FC<CourseInstanceCategoryProps> = ({
  id,
  className = '',
  title,
  count,
  backgroundColor,
}) => {
  return (
    <div className={`${className} flex flex-row items-center space-between`}>
      <div
        id={id}
        className={`p-3 w-24 rounded-2xl ${backgroundColor} flex justify-center mr-4 text-white`}>
        {title}
      </div>
      {count != undefined && (
        <div className="flex flex-grow justify-center">
          <CourseInstanceCategoryCount count={count} />
        </div>
      )}
    </div>
  );
};

type LineManagerProps = {
  lines: {
    start: string;
    end: string;
    color: string;
  }[];
  headSize: number;
  curveness: number;
  strokeWidth: number;
};

const LineManager: React.FC<LineManagerProps> = ({
  lines,
  headSize,
  curveness,
  strokeWidth,
}) => {
  return (
    <>
      {lines.map((line) => (
        <Xarrow
          key={line.start + '-' + line.end}
          color={line.color}
          start={line.start}
          end={line.end}
          headSize={headSize}
          curveness={curveness}
          strokeWidth={strokeWidth}
          startAnchor="right"
          endAnchor="left"
        />
      ))}
    </>
  );
};

type CourseInstancePieChartProps = {
  id?: string;
  className?: string;
  pieChartPalette: string[];
  pieChartData: PieChartData;
};

const CourseInstancePieChart: React.FC<CourseInstancePieChartProps> = ({
  id,
  className = '',
  pieChartPalette,
  pieChartData,
}) => {
  return (
    <div id={id} className={`${className} flex flex-row items-center`}>
      <div className="h-18 w-18 mr-5">
        {pieChartData.entries.reduce((total, entry) => total + entry.value, 0) >
        0 ? (
          <Chart
            options={{
              events: [],
            }}
            type="pie"
            data={{
              labels: pieChartData?.entries.map((entry) => entry.label),
              datasets: [
                {
                  data: pieChartData?.entries.map((entry) => entry.value),
                  borderWidth: 0,
                  backgroundColor: pieChartPalette,
                },
              ],
            }}
          />
        ) : (
          <Chart
            options={{
              events: [],
            }}
            type="pie"
            data={{
              datasets: [
                {
                  data: [1],
                  borderWidth: 0,
                  backgroundColor: '#D1D2D3',
                },
              ],
            }}
          />
        )}
      </div>

      <div className="space-y-1">
        {pieChartData.entries.map((entry, key) => (
          <div key={key} className="flex flex-row items-center space-x-4">
            <div
              className="flex w-3 h-3 rounded-full flex-shrink-0"
              style={{
                backgroundColor: pieChartPalette[key < 3 ? key : 2],
              }}
            />
            <div className="text-sm">
              <span className="text-gray-700"> {`${entry.label}: `}</span>
              <span className="font-semibold">{entry.value}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

type CourseInstanceFlowProps = {
  fromDate: Dayjs;
  toDate: Dayjs;
};

const CourseInstanceFlow: React.FC<CourseInstanceFlowProps> = ({
  fromDate,
  toDate,
}) => {
  const pieChartPalettes = {
    complete: ['#09867F', '#1EADA5', '#7BDFDA'],
    cancelled: ['#DB4E5E', '#FE9FAA'],
    incomplete: ['#232A43', '#646B82', '#ABB0C2'],
  };

  const linePalettes = {
    complete: '#AED9D6',
    cancelled: '#F1DFDF',
    incomplete: '#CBCED7',
    deleted: '#DAD4D1',
  };

  const { t } = useTranslation();

  const [courseInstanceData, setCourseInstanceData] =
    useState<CourseInstanceInsightData>();
  const [loading, setLoading] = useState<boolean>(true);

  const getCourseInstanceInsightData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await InsightsAPI.getCourseInstanceData(
        fromDate.format('YYYY-MM-DD'),
        toDate.format('YYYY-MM-DD'),
      );
      setCourseInstanceData(data);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getCourseInstanceInsightData();
  }, [getCourseInstanceInsightData]);

  return (
    <div className="py-6 rounded-lg self-center w-full bg-offwhite border-2 border-offwhiteDark border-solid">
      <div className="flex flex-row justify-between items-center px-6">
        <div>
          <div className="flex flex-row gap-2">
            <h3>{t('components.CourseInstanceInsight.classFlow')}</h3>
            {loading && <Spin />}
          </div>
          <span>
            {courseInstanceData?.totalCourseInstanceCount}{' '}
            {t('common.courseInstances')}
          </span>
        </div>
      </div>
      {!loading && courseInstanceData && (
        <div className="grid gap-4 grid-rows-4 mx-5 course-instance-insight-card">
          <CourseInstanceCategory
            id="complete"
            className="col-start-2"
            title={t('components.CourseInstanceInsight.complete')}
            count={courseInstanceData.completeCourseInstanceData.total}
            backgroundColor="bg-courseInstanceComplete"
          />
          <CourseInstancePieChart
            id="completePieChart"
            pieChartPalette={pieChartPalettes.complete}
            pieChartData={{
              total: courseInstanceData.completeCourseInstanceData.total,
              entries: [
                {
                  label: t('components.CourseInstanceInsight.invoiced'),
                  value: courseInstanceData.completeCourseInstanceData.invoiced,
                },
                {
                  label: t('components.CourseInstanceInsight.performed'),
                  value:
                    courseInstanceData.completeCourseInstanceData.performed,
                },
                {
                  label: t('components.CourseInstanceInsight.confirmed'),
                  value:
                    courseInstanceData.completeCourseInstanceData.confirmed,
                },
              ],
            }}
          />
          <div className="flex flex-row items-center">
            <CourseInstanceCategory
              id="total"
              title={t('common.courseInstances')}
              backgroundColor="bg-courseInstanceIncomplete"
            />
            <CourseInstanceCategoryCount
              id="totalCount"
              count={courseInstanceData.totalCourseInstanceCount}
            />
          </div>

          <CourseInstanceCategory
            id="cancelled"
            title={t('components.CourseInstanceInsight.cancelled')}
            count={courseInstanceData.cancelledCourseInstanceData.total}
            backgroundColor="bg-courseInstanceCancelled"
          />
          <CourseInstancePieChart
            id="cancelledPieChart"
            pieChartPalette={pieChartPalettes.cancelled}
            pieChartData={{
              total: courseInstanceData.cancelledCourseInstanceData.total,
              entries: [
                {
                  label: t('components.CourseInstanceInsight.invoiced'),
                  value:
                    courseInstanceData.cancelledCourseInstanceData.invoiced,
                },
                {
                  label: t('components.CourseInstanceInsight.notInvoiced'),
                  value:
                    courseInstanceData.cancelledCourseInstanceData.notInvoiced,
                },
              ],
            }}
          />
          <CourseInstanceCategory
            id="incomplete"
            className="col-start-2"
            title={t('components.CourseInstanceInsight.incomplete')}
            count={courseInstanceData.incompleteCourseInstanceData.total}
            backgroundColor="bg-courseInstanceIncomplete"
          />
          <CourseInstancePieChart
            id="incompletePieChart"
            pieChartPalette={pieChartPalettes.incomplete}
            pieChartData={{
              total: courseInstanceData.incompleteCourseInstanceData.total,
              entries: [
                {
                  label: t(
                    'components.CourseInstanceInsight.missingInstructor',
                  ),
                  value:
                    courseInstanceData.incompleteCourseInstanceData
                      .missingInstructor,
                },
                {
                  label: t('components.CourseInstanceInsight.missingAddress'),
                  value:
                    courseInstanceData.incompleteCourseInstanceData
                      .missingAddress,
                },
                {
                  label: t('components.CourseInstanceInsight.missingTime'),
                  value:
                    courseInstanceData.incompleteCourseInstanceData.missingTime,
                },
              ],
            }}
          />
          <div className="col-start-2 flex flex-row items-center">
            <CourseInstanceCategory
              id="deleted"
              title={t('components.CourseInstanceInsight.deleted')}
              backgroundColor="bg-courseInstanceDeleted"
            />
            <div className="flex flex-grow justify-center">
              <CourseInstanceCategoryCount
                id="deletedCount"
                count={courseInstanceData.deletedCourseInstanceCount}
              />
            </div>
          </div>
          <LineManager
            lines={[
              {
                start: 'total',
                end: 'totalCount',
                color: linePalettes.incomplete,
              },
              {
                start: 'totalCount',
                end: 'complete',
                color: linePalettes.complete,
              },
              {
                start: 'complete',
                end: 'completePieChart',
                color: linePalettes.complete,
              },
              {
                start: 'totalCount',
                end: 'cancelled',
                color: linePalettes.cancelled,
              },
              {
                start: 'cancelled',
                end: 'cancelledPieChart',
                color: linePalettes.cancelled,
              },
              {
                start: 'totalCount',
                end: 'incomplete',
                color: linePalettes.incomplete,
              },
              {
                start: 'incomplete',
                end: 'incompletePieChart',
                color: linePalettes.incomplete,
              },
              {
                start: 'totalCount',
                end: 'deleted',
                color: linePalettes.deleted,
              },
              {
                start: 'deleted',
                end: 'deletedCount',
                color: linePalettes.deleted,
              },
            ]}
            headSize={0}
            curveness={0.7}
            strokeWidth={5}
          />
        </div>
      )}
    </div>
  );
};

export default CourseInstanceFlow;
