import React, { useCallback, useState } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { Geolocation } from '../../types/Location';
import { InstructorMarkerData } from '../types/User';
import UserAPI from '../UserAPI';
import AddressSearchInput, {
  PlaceSuggestion,
} from '../../components/AddressSearchInput';

import InstructorMarkerInfoWindow from './InstructorMarkerInfoWindow';

type InstructorMapProps = {
  initialCenter?: Geolocation;
  initialZoom?: number;
  className?: string;
};

const InstructorMap: React.FC<InstructorMapProps> = ({
  initialCenter,
  initialZoom,
  className,
}) => {
  const { t } = useTranslation();

  const apiKey = process.env.REACT_APP_MAPS_API_KEY ?? '';

  const defaultZoom = 11;
  const [defaultLat, defaultLng] = [59.3293, 18.0686];

  const [zoom, setZoom] = useState(initialZoom ?? defaultZoom);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: initialCenter?.lat ?? defaultLat,
    lng: initialCenter?.lng ?? defaultLng,
  });
  const [markerData, setMarkerData] = useState<InstructorMarkerData[]>();
  const [loadingMarkerData, setLoadingMarkerData] = useState(false);
  const [displayInfoWindow, setDisplayInfoWindow] = useState(false);
  const [infoWindowContent, setInfoWindowContent] =
    useState<InstructorMarkerData>();

  const [map, setMap] = useState<google.maps.Map | null>();
  const [infoWindowPosition, setInfoWindowPosition] =
    useState<google.maps.LatLngLiteral>();

  const onChangeSearchBox = useCallback(
    (suggestion?: PlaceSuggestion | null) => {
      if (suggestion) {
        setCenter({
          lat: suggestion.geolocation.lat,
          lng: suggestion.geolocation.lng,
        });
        setZoom(defaultZoom);
      }
    },
    [],
  );

  const fetchInstructorMarkerData = async (
    _bounds: google.maps.LatLngBounds,
  ) => {
    setLoadingMarkerData(true);
    try {
      const params = {
        southWestLat: _bounds.getSouthWest().lat(),
        southWestLng: _bounds.getSouthWest().lng(),
        northEastLat: _bounds.getNorthEast().lat(),
        northEastLng: _bounds.getNorthEast().lng(),
      };
      const { data } = await UserAPI.getInstructorMarkerData(params);
      setMarkerData(data);
    } finally {
      setLoadingMarkerData(false);
    }
  };

  const onLoad = (map: google.maps.Map) => {
    setMap(map);
  };

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const onIdle = () => {
    const bounds = map?.getBounds();
    if (bounds) {
      fetchInstructorMarkerData(bounds);
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  return (
    <>
      <div>
        <div className="font-semibold text-lg pb-0.5">
          {t('common.address')}
        </div>
        <div className="flex items-center pb-4">
          <div className="w-1/3">
            <AddressSearchInput onChange={onChangeSearchBox} />
          </div>
          <Spin spinning={loadingMarkerData} className="ml-3" />
        </div>
      </div>
      <div className={className} style={{ height: '700px' }}>
        {isLoaded ? (
          <GoogleMap
            mapContainerClassName="w-full h-full rounded-lg"
            clickableIcons={false}
            options={{
              styles: [
                {
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'off' }],
                },
              ],
              mapId: '26a46e9c4fbf9e24',
              mapTypeControl: false,
              streetViewControl: false,
            }}
            onUnmount={onUnmount}
            onLoad={onLoad}
            onIdle={onIdle}
            center={center}
            zoom={zoom}>
            {infoWindowPosition && displayInfoWindow && (
              <InfoWindow
                position={infoWindowPosition}
                options={{ pixelOffset: new window.google.maps.Size(0, -32) }}
                onCloseClick={() => setDisplayInfoWindow(false)}>
                <>
                  {infoWindowContent && (
                    <InstructorMarkerInfoWindow
                      markerData={infoWindowContent}
                    />
                  )}
                </>
              </InfoWindow>
            )}
            {markerData && (
              <MarkerClusterer
                maxZoom={11}
                averageCenter
                minimumClusterSize={4}>
                {(clusterer) => (
                  <>
                    {markerData.map((data) => {
                      const onClickMarker = () => {
                        setInfoWindowPosition(data.location);
                        setDisplayInfoWindow(true);
                        setInfoWindowContent(data);
                      };

                      const onUnmount = () => {
                        if (infoWindowContent?.userId === data.userId) {
                          setDisplayInfoWindow(false);
                        }
                      };

                      return (
                        <Marker
                          key={data.userId}
                          onClick={onClickMarker}
                          title={data.name}
                          clusterer={clusterer}
                          onUnmount={onUnmount}
                          position={
                            new google.maps.LatLng(
                              data.location.lat,
                              data.location.lng,
                            )
                          }
                        />
                      );
                    })}
                  </>
                )}
              </MarkerClusterer>
            )}
          </GoogleMap>
        ) : (
          <Spin />
        )}
      </div>
    </>
  );
};

export default InstructorMap;
