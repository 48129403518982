import { Button, Modal } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import SearchResult from '../types/SearchResult';
import SearchAPI, { SearchParams } from '../SearchAPI';
import PaginationParams from '../../types/PaginationParams';
import PaginationMeta from '../../types/PaginationMeta';

import SearchResults from './SearchResults';

type CalendarSearchResultModalProps = {
  searchParams?: SearchParams;
  date: dayjs.Dayjs;
  onCancel: () => void;
  onBook: (result: SearchResult) => void;
  onBookWithoutInstructor?: (toggle: boolean) => void;
  setShowCalendarSearchResultModal: (toggle: boolean) => void;
  setParams: (value: SearchParams) => void;
};

const CalendarSearchResultModal: React.FC<CalendarSearchResultModalProps> = ({
  searchParams,
  date,
  onCancel,
  onBook,
  onBookWithoutInstructor,
  setShowCalendarSearchResultModal,
  setParams,
}) => {
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchDate, setSearchDate] = useState<dayjs.Dayjs>(date);
  const [loadingSearchResults, setLoadingSearchResults] =
    useState<boolean>(false);
  const [paginationParams, setPaginationParams] =
    useState<PaginationParams | null>({
      page: 1,
    });
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta | null>();

  const { t } = useTranslation();

  const instructorSearch = useCallback(
    async (
      _params: SearchParams,
      _paginationParams?: PaginationParams | null,
    ) => {
      setParams(_params);
      setLoadingSearchResults(true);
      try {
        const { data: res } = await SearchAPI.search(
          _params,
          _paginationParams,
        );
        setPaginationParams({ page: res.meta.page + 1 });
        setSearchResults((prevState) => prevState.concat(res.data));
        setPaginationMeta(res.meta);
      } finally {
        setLoadingSearchResults(false);
      }
    },
    [setParams],
  );

  useEffect(() => {
    if (searchParams) {
      setSearchResults([]);
      instructorSearch(
        {
          ...searchParams,
          from: searchDate.startOf('day').toISOString(),
          to: searchDate.endOf('day').toISOString(),
        },
        paginationParams,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDate]);

  const debounceInstructorSearch = useMemo(
    () =>
      debounce(
        (
          _params: SearchParams,
          _paginationParams?: PaginationParams | null,
        ) => {
          instructorSearch(
            {
              ..._params,
              from: searchDate.startOf('day').toISOString(),
              to: searchDate.endOf('day').toISOString(),
            },
            _paginationParams,
          );
        },
        10,
      ),
    [instructorSearch, searchDate],
  );

  const capitalize = useCallback(
    (str: string) => str.charAt(0).toUpperCase() + str.slice(1),
    [],
  );

  const prev = useCallback(() => {
    setPaginationParams({ page: 1 });
    setPaginationMeta(null);
    setSearchDate((prevState) => dayjs(prevState.subtract(1, 'day')));
  }, []);

  const next = useCallback(() => {
    setPaginationParams({ page: 1 });
    setPaginationMeta(null);
    setSearchDate((prevState) => dayjs(prevState.add(1, 'day')));
  }, []);

  const modalTitle = useCallback(() => {
    return (
      <>
        <div className="flex flex-col sticky">
          <div style={{ width: '15rem' }}>
            {' '}
            <span className="font-semibold text-lg">
              {capitalize(searchDate.format('dddd')) +
                ' ' +
                searchDate.format('D MMMM')}
            </span>
            <span className="text-gray-600 text-sm">
              {' '}
              - {searchDate.format('YYYY')}
            </span>
          </div>

          <div className="flex flex-row mt-1 -mb-1">
            <Button
              onClick={() => prev()}
              className="mr-2"
              disabled={
                searchDate &&
                searchDate.startOf('day') <= dayjs().startOf('day')
              }>
              <LeftOutlined />
            </Button>
            <Button onClick={() => next()}>
              <RightOutlined />
            </Button>
          </div>
        </div>
      </>
    );
  }, [capitalize, next, prev, searchDate]);

  const onBookCallback = useCallback<typeof onBook>(
    (searchResult) => {
      onBook(searchResult);
      setShowCalendarSearchResultModal(false);
    },
    [onBook, setShowCalendarSearchResultModal],
  );

  const onBookWithoutInstructorCallback = useCallback<
    NonNullable<typeof onBookWithoutInstructor>
  >(
    (toggle) => {
      onBookWithoutInstructor?.(toggle);
      setShowCalendarSearchResultModal(false);
    },
    [onBookWithoutInstructor, setShowCalendarSearchResultModal],
  );

  return (
    <Modal
      title={modalTitle()}
      className="w-10/12"
      footer={null}
      open
      onCancel={onCancel}
      bodyStyle={{ overflowX: 'scroll' }}
      maskClosable={false}>
      <div style={{ overflow: 'auto', height: '60vh' }}>
        <InfiniteScroll
          loadMore={() => {
            setLoadingSearchResults(true);
            if (!loadingSearchResults) {
              return debounceInstructorSearch(searchParams!, {
                ...paginationParams,
                page: paginationParams?.page,
              });
            }
          }}
          hasMore={
            !loadingSearchResults &&
            (paginationMeta?.page ?? 1) < (paginationMeta?.lastPage ?? 1)
          }
          useWindow={false}>
          <SearchResults
            results={
              searchResults.length > 0
                ? [
                    {
                      title: t(
                        'components.CalendarSearchResultModal.availableInstructors',
                      ),
                      rows: searchResults,
                    },
                  ]
                : []
            }
            loading={loadingSearchResults}
            onBook={onBookCallback}
            onBookWithoutInstructor={onBookWithoutInstructorCallback}
          />
        </InfiniteScroll>
      </div>
    </Modal>
  );
};

export default CalendarSearchResultModal;
