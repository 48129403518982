import { Spin } from 'antd';
import { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CapacityData } from '../types/Graph';

import Graph from './Graph';

type CapacityGraphCardProps = {
  title?: string;
  dataset: CapacityData[];
  daysShown: number;
  showHeader?: boolean;
  loading?: boolean;
  today: Dayjs;
};

const CapacityGraphCard: React.FC<CapacityGraphCardProps> = ({
  title,
  dataset,
  daysShown,
  showHeader = true,
  loading,
  today,
}) => {
  const [days, setDays] = useState<Dayjs[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const days: Dayjs[] = [];
    const labels: string[] = [];
    for (let i = 0; i < daysShown; i++) {
      const day = today.clone().subtract(daysShown - 1 - i, 'days');
      days[i] = day;
      labels[i] = day.format('D ddd');
    }
    setLabels(labels);
    setDays(days);
  }, [today, daysShown]);

  return (
    <div className="py-6 mb-6 rounded-lg self-center w-full bg-offwhite border-2 border-offwhiteDark border-solid">
      {showHeader && (
        <div className="flex flex-row justify-between items-center px-6">
          <div>
            {title && (
              <div className="flex flex-row gap-2">
                <h3>{title}</h3>
                {loading && <Spin />}
              </div>
            )}
            {<p>{t('views.Insights.instructorOccupancy')}</p>}
          </div>
        </div>
      )}
      <Graph
        dataset={dataset.map(({ percentage }) => percentage)}
        isCurrentWeek={true}
        loading={loading}
        labels={[...labels, '']}
        tooltipableIndexes={[...new Array(30).fill(true)]}
        internalHiddenOverflow={true}
        startValue={null}
        tooltipGenerator={(index) => {
          const data = dataset[index];
          return [
            `${days[index].format('D MMM YYYY')}`,
            `${data.percentage}% ${data.comment}`,
          ];
        }}
        grace={0.3}
        chartHeight={130}
        padding={{ left: 30 }}
        overflowSize={0}
      />
    </div>
  );
};

export default CapacityGraphCard;
