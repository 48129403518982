import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';

import InvoiceRecipient, {
  ViewInvoiceRecipient,
} from '../../bookingOrders/types/InvoiceRecipient';
import { ColumnsType } from '../../types/ColumnTypes';
import Table from '../../components/Table';
import PaginationMeta from '../../types/PaginationMeta';

type InvoiceRecipientResultsProps = {
  invoiceRecipients?: ViewInvoiceRecipient[];
  paginationMeta?: PaginationMeta;
  loading: boolean;
  getLink?: (invoiceRecipient: InvoiceRecipient) => string;
  onPrev?: () => void;
  onNext?: () => void;
  onPageChange?: (page: number) => void;
};
const InvoiceRecipientResults: React.FC<InvoiceRecipientResultsProps> = ({
  invoiceRecipients,
  paginationMeta,
  loading,
  getLink,
  onPrev,
  onNext,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const columns: ColumnsType<ViewInvoiceRecipient> = [
    {
      title: t('common.title'),
      render: (invoiceRecipient) => (
        <div className="text-sm font-medium">{invoiceRecipient.name}</div>
      ),
    },
    {
      title: t('common.corporateId'),
      render: (invoiceRecipient) => (
        <div className="text-sm">{invoiceRecipient.corporateId ?? '-'}</div>
      ),
    },
    {
      title: t('components.InvoiceRecipientResults.vismaId'),
      render: (invoiceRecipient) => (
        <div className="text-sm">{invoiceRecipient.vismaCustomerId ?? '-'}</div>
      ),
    },
    {
      title: t('components.InvoiceRecipientResults.bookingOrders'),
      render: (invoiceRecipient) => (
        <div className="text-sm">{invoiceRecipient.bookingOrders}</div>
      ),
    },
  ];

  return (
    <Table
      rows={invoiceRecipients}
      rowKey={(invoiceRecipient) => invoiceRecipient.id}
      columns={columns}
      loading={loading}
      getRowLink={getLink}
      customLastRow={
        paginationMeta && (
          <tr>
            <td colSpan={columns.length}>
              <div className="py-3 flex justify-around items-center">
                <Button
                  type="link"
                  disabled={paginationMeta.page <= 1}
                  onClick={onPrev}>
                  {t('components.InvoiceRecipientResults.previous')}
                </Button>
                <div>
                  <span className="mr-2">
                    {t('components.InvoiceRecipientResults.page')}
                  </span>
                  <Select value={paginationMeta.page} onChange={onPageChange}>
                    {Array.from(
                      { length: paginationMeta.lastPage },
                      (_, i) => i + 1,
                    ).map((page) => (
                      <Select.Option key={page}>{page}</Select.Option>
                    ))}
                  </Select>
                </div>
                <Button
                  type="link"
                  disabled={paginationMeta.lastPage <= paginationMeta.page}
                  onClick={onNext}>
                  {t('components.InvoiceRecipientResults.next')}
                </Button>
              </div>
            </td>
          </tr>
        )
      }
    />
  );
};

export default InvoiceRecipientResults;
