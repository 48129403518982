import client from '../api/client';

import {
  CapacityGraphData,
  CourseChartData,
  CourseChartSortOrder,
  CourseInstanceInsightData,
  GraphData,
  GraphResource,
  PieChartData,
  TimeUnit,
} from './types/Graph';

export type ExistingInstructorsCount = {
  date: string;
  totalCount: number;
  externalCount: number;
  internalCount: number;
};

const getGraphData = (
  graphResource: GraphResource,
  timeUnit: TimeUnit,
  fromDate: string,
  toDate?: string,
  subsidiaryCompanyId?: number,
) =>
  client.get<GraphData>(`/insights/graph-data`, {
    params: {
      graphResource,
      timeUnit,
      fromDate,
      toDate,
      subsidiaryCompanyId,
    },
  });

const getPieChartData = (
  graphResource: GraphResource,
  timeUnit: TimeUnit,
  fromDate: string,
  toDate?: string,
) =>
  client.get<PieChartData>(`/insights/pie-chart-data`, {
    params: {
      graphResource,
      timeUnit,
      fromDate,
      toDate,
    },
  });

const getCourseInstanceData = (fromDate: string, toDate?: string) =>
  client.get<CourseInstanceInsightData>(`/insights/course-instance-data`, {
    params: {
      fromDate,
      toDate,
    },
  });

const getExistingInstructorsCount = () =>
  client.get<ExistingInstructorsCount>(`/insights/existing-instructors`);

const getCapacityGraphData = (fromDate: string, toDate?: string) =>
  client.get<CapacityGraphData>('/insights/capacity-graph-data', {
    params: {
      fromDate,
      toDate,
    },
  });

const getCourseChartData = (
  sortOrder: CourseChartSortOrder,
  fromDate: string,
  toDate?: string,
  subsidiaryCompanyId?: number,
  courseIds?: number[],
) =>
  client.get<CourseChartData[]>('/insights/course-data', {
    params: {
      sortOrder,
      fromDate,
      toDate,
      subsidiaryCompanyId,
      courseIds,
    },
  });

export default {
  getGraphData,
  getPieChartData,
  getExistingInstructorsCount,
  getCapacityGraphData,
  getCourseInstanceData,
  getCourseChartData,
};
