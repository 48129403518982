import { Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import InstructorsIcon from '../../resources/img/instructorsIcon.svg';
import { ExistingInstructorsCount } from '../InsightsAPI';

type InstructorStatisticsCard = {
  title?: string;
  existingInstructorsCount?: ExistingInstructorsCount;
  loading?: boolean;
};

const InstructorStatisticsCard: React.FC<InstructorStatisticsCard> = ({
  title,
  existingInstructorsCount,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="insights-statistics-graph-card">
      <div className="text-small items-center text-grayMedium mb-1">
        {title}
      </div>
      <div className="flex h-full">
        {loading ? (
          <Spin size="large" className="self-center" />
        ) : (
          <div className="flex flex-col justify-between w-full">
            <div className="font-medium text-3.5xl leading-none">
              {existingInstructorsCount?.totalCount}
            </div>
            <div className="flex flex-row justify-between">
              <div className="text-small font-light leading-tight">
                {t('components.InstructorStatisticsCard.ofTotalInstructors')}
                <div>
                  <span className="font-semibold">
                    {existingInstructorsCount?.internalCount}{' '}
                  </span>
                  {t('components.InstructorStatisticsCard.inHouse')}
                </div>
                <div>
                  <span className="font-semibold">
                    {existingInstructorsCount?.externalCount}{' '}
                  </span>
                  {t('components.InstructorStatisticsCard.freelance')}
                </div>
              </div>
              <img src={InstructorsIcon} className="w-20 h-15" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default InstructorStatisticsCard;
