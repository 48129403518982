import React, { useState, useEffect, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import CreateCourseModal from '../components/CreateCourseModal';
import Course from '../types/Course';
import CourseAPI from '../CourseAPI';
import CourseResults from '../components/CourseResults';
import CourseInstructionsModal from '../components/CourseInstructionsModal';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>();
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [showCourseModal, setShowCourseModal] = useState<boolean>(false);
  const [showInstructions, setShowInstructions] = useState<boolean>(false);
  const [currentUser] = useUser();

  const { t } = useTranslation();

  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const { data } = await CourseAPI.getCourses();
      setCourses(data);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  return (
    <>
      <div className="grid grid-cols-2 items-center">
        <h2>{t('common.courses')}</h2>
        <div className="flex justify-end">
          <Button
            className="mr-2"
            shape="circle"
            icon={<QuestionCircleFilled />}
            type="primary"
            onClick={() => setShowInstructions(true)}></Button>
          {userHasPermission(currentUser, Permission.COURSE_CREATE) && (
            <Button
              type="primary"
              className="text-sm font-medium"
              icon={<PlusOutlined />}
              onClick={() => setShowCourseModal(true)}>
              {t('views.Courses.newCourse')}
            </Button>
          )}
        </div>
      </div>

      <CourseResults
        courses={courses}
        loading={loadingCourses}
        getLink={(course) => String(course.id)}
      />

      {showCourseModal && (
        <CreateCourseModal
          visible
          onCreateCourse={() => {
            setShowCourseModal(false);
            fetchCourses();
          }}
          onCancel={() => setShowCourseModal(false)}
        />
      )}

      <CourseInstructionsModal
        visible={showInstructions}
        onCancel={() => setShowInstructions(false)}
      />

      <Outlet context={{ courses, onClose: fetchCourses }} />
    </>
  );
};

export default Courses;
