import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const LimeSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9053 4.58714C11.8786 4.43657 11.8477 4.28422 11.8087 4.12933C11.496 2.85872 10.7505 1.74577 9.70767 0.992889C8.66487 0.240007 7.39405 -0.102791 6.12635 0.0268407C4.83295 0.149686 3.0899 0.773341 1.83977 2.90698C1.39588 3.64345 1.14293 4.48472 1.10507 5.35043C1.10334 5.38781 1.10334 5.42469 1.1026 5.46183C1.06947 6.73759 1.45685 7.85897 1.46525 8.8471C1.45437 9.57627 1.34608 10.4594 0.363941 11.1384C-0.618201 11.8175 0.684076 11.8676 0.81559 11.876C1.53843 11.9241 2.63675 12.0256 3.90692 11.994C4.04857 11.9905 4.1917 11.9859 4.33731 11.9788C5.02824 11.9654 5.71763 11.9066 6.40125 11.8025C7.3223 11.6542 8.20646 11.3227 9.00434 10.8266C9.26329 10.6625 9.51107 10.4805 9.74597 10.2818C10.6745 9.4987 12.4279 7.53495 11.9043 4.58741M1.113 5.51802V5.52007V5.51802ZM10.2125 7.46144C9.89067 8.12132 9.43674 8.70364 8.88053 9.17009C8.8047 9.23399 8.72765 9.29537 8.64938 9.35424C7.9937 9.84566 7.24179 10.1842 6.44601 10.3462C5.88421 10.4472 5.31622 10.5077 4.74621 10.5272C4.65426 10.5321 4.56005 10.5364 4.46217 10.5405L4.27207 10.5491C3.74972 10.5746 3.24392 10.5764 2.67832 10.5557C2.6578 10.5557 2.56314 10.5502 2.56238 10.5484L2.57375 10.5168C2.7549 9.97703 2.84462 9.40924 2.83901 8.8382C2.82512 8.25386 2.75683 7.67224 2.63506 7.10132C2.58315 6.79382 2.54311 6.51812 2.51589 6.26605C2.39229 5.12458 2.52999 4.47728 3.01229 3.65373C3.51805 2.71227 4.34537 1.99739 5.33406 1.64752C5.44135 1.6114 5.55037 1.58139 5.65988 1.55443C6.17626 1.42531 6.71194 1.39941 7.23782 1.47812C7.95513 1.59594 8.62753 1.91314 9.1824 2.39544C9.73728 2.87775 10.1535 3.50685 10.3863 4.21481C10.4207 4.31161 10.4523 4.41038 10.4812 4.51111C10.4795 4.5045 10.4785 4.4984 10.4767 4.49205L10.4874 4.53173C10.4854 4.52461 10.4832 4.51799 10.4812 4.51111C10.7482 5.49452 10.6524 6.54479 10.2122 7.45992" />
  </svg>
);

const LimeIcon: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon component={LimeSvg} {...props} />
);

export default LimeIcon;
