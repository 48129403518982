export enum Permission {
  // Certificate Langugages
  CERTIFICATE_LANGUAGE_READ = 'CERTIFICATE_LANGUAGE_READ',
  CERTIFICATE_LANGUAGE_CREATE = 'CERTIFICATE_LANGUAGE_CREATE',
  CERTIFICATE_LANGUAGE_UPDATE = 'CERTIFICATE_LANGUAGE_UPDATE',
  CERTIFICATE_LANGUAGE_DELETE = 'CERTIFICATE_LANGUAGE_DELETE',
  // Certificate Images
  CERTIFICATE_IMAGE_READ_LIST = 'CERTIFICATE_IMAGE_READ_LIST',
  CERTIFICATE_IMAGE_CREATE = 'CERTIFICATE_IMAGE_CREATE',
  CERTIFICATE_IMAGE_UPDATE = 'CERTIFICATE_IMAGE_UPDATE',
  CERTIFICATE_IMAGE_DELETE = 'CERTIFICATE_IMAGE_DELETE',
  // Course instance
  COURSE_INSTANCE_READ = 'COURSE_INSTANCE_READ',
  COURSE_INSTANCE_READ_FULL = 'COURSE_INSTANCE_READ_FULL', // Access to the full course instance entity with price information
  COURSE_INSTANCE_READ_GLOBAL = 'COURSE_INSTANCE_READ_GLOBAL', // Access to course instances assigned to any instructor and subsidiary company
  COURSE_INSTANCE_READ_LIST = 'COURSE_INSTANCE_READ_LIST', // Access to end-points that list course instances
  COURSE_INSTANCE_CREATE = 'COURSE_INSTANCE_CREATE',
  COURSE_INSTANCE_UPDATE = 'COURSE_INSTANCE_UPDATE',
  COURSE_INSTANCE_DELETE = 'COURSE_INSTANCE_DELETE',
  COURSE_INSTANCE_ACCEPT = 'COURSE_INSTANCE_ACCEPT',
  // Course
  COURSE_READ = 'COURSE_READ',
  COURSE_READ_GLOBAL = 'COURSE_READ_GLOBAL',
  COURSE_READ_LIST = 'COURSE_READ_LIST',
  COURSE_CREATE = 'COURSE_CREATE',
  COURSE_UPDATE = 'COURSE_UPDATE',
  COURSE_DELETE = 'COURSE_DELETE',
  // Booking order
  BOOKING_ORDER_READ = 'BOOKING_ORDER_READ',
  BOOKING_ORDER_READ_GLOBAL = 'BOOKING_ORDER_READ_GLOBAL',
  BOOKING_ORDER_READ_LIST = 'BOOKING_ORDER_READ_LIST',
  BOOKING_ORDER_CREATE = 'BOOKING_ORDER_CREATE',
  BOOKING_ORDER_UPDATE = 'BOOKING_ORDER_UPDATE',
  BOOKING_ORDER_DELETE = 'BOOKING_ORDER_DELETE',
  BOOKING_ORDER_COMMENT_UPDATE = 'BOOKING_ORDER_COMMENT_UPDATE',
  BOOKING_ORDER_COMMENT_UPDATE_GLOBAL = 'BOOKING_ORDER_COMMENT_UPDATE_GLOBAL',
  BOOKING_ORDER_COMMENT_DELETE = 'BOOKING_ORDER_COMMENT_DELETE',
  BOOKING_ORDER_COMMENT_DELETE_GLOBAL = 'BOOKING_ORDER_COMMENT_DELETE_GLOBAL',
  VISMA_SALES_ORDER_CREATE = 'VISMA_SALES_ORDER_CREATE',
  // Client company
  CLIENT_COMPANY_READ = 'CLIENT_COMPANY_READ',
  CLIENT_COMPANY_READ_LIST = 'CLIENT_COMPANY_READ_LIST',
  CLIENT_COMPANY_CREATE = 'CLIENT_COMPANY_CREATE',
  CLIENT_COMPANY_UPDATE = 'CLIENT_COMPANY_UPDATE',
  CLIENT_COMPANY_DELETE = 'CLIENT_COMPANY_DELETE',
  // User
  USER_READ = 'USER_READ',
  USER_READ_LIST = 'USER_READ_LIST', // Access to end-points that list users
  USER_READ_GLOBAL = 'USER_READ_GLOBAL', // Access to any user
  USER_CREATE = 'USER_CREATE',
  USER_UPDATE = 'USER_UPDATE',
  USER_UPDATE_GLOBAL = 'USER_UPDATE_GLOBAL', // Allow updates for all the user fields
  USER_UPDATE_RESTRICTED = 'USER_UPDATE_RESTRICTED', // Allow updates for some user fields for instructors only
  USER_DELETE = 'USER_DELETE',
  BOOKER_NOTE_READ = 'BOOKER_NOTE_READ',
  // Contact person
  CONTACT_PERSON_READ = 'CONTACT_PERSON_READ',
  CONTACT_PERSON_READ_LIST = 'CONTACT_PERSON_READ_LIST',
  CONTACT_PERSON_CREATE = 'CONTACT_PERSON_CREATE',
  CONTACT_PERSON_UPDATE = 'CONTACT_PERSON_UPDATE',
  CONTACT_PERSON_DELETE = 'CONTACT_PERSON_DELETE',
  // Subsidiary company
  SUBSIDIARY_COMPANY_READ = 'SUBSIDIARY_COMPANY_READ',
  SUBSIDIARY_COMPANY_READ_LIST = 'SUBSIDIARY_COMPANY_READ_LIST',
  SUBSIDIARY_COMPANY_CREATE = 'SUBSIDIARY_COMPANY_CREATE',
  SUBSIDIARY_COMPANY_UPDATE = 'SUBSIDIARY_COMPANY_UPDATE',
  SUBSIDIARY_COMPANY_LIME_UPDATE = 'SUBSIDIARY_COMPANY_LIME_UPDATE', // Allow updates to lime url and API key
  SUBSIDIARY_COMPANY_DELETE = 'SUBSIDIARY_COMPANY_DELETE',
  SUBSIDIARY_COMPANY_GOAL_READ = 'SUBSIDIARY_COMPANY_GOAL_READ',
  SUBSIDIARY_COMPANY_GOAL_READ_LIST = 'SUBSIDIARY_COMPANY_GOAL_READ_LIST',
  SUBSIDIARY_COMPANY_GOAL_CREATE = 'SUBSIDIARY_COMPANY_GOAL_CREATE',
  SUBSIDIARY_COMPANY_GOAL_UPDATE = 'SUBSIDIARY_COMPANY_GOAL_UPDATE',
  SUBSIDIARY_COMPANY_GOAL_DELETE = 'SUBSIDIARY_COMPANY_GOAL_DELETE',
  // Currency
  CURRENCY_READ = 'CURRENCY_READ',
  // Insight
  INSIGHTS_READ = 'INSIGHTS_READ',
  // Invoice recipient
  INVOICE_RECIPIENT_READ = 'INVOICE_RECIPIENT_READ',
  INVOICE_RECIPIENT_READ_LIST = 'INVOICE_RECIPIENT_READ_LIST',
  INVOICE_RECIPIENT_CREATE = 'INVOICE_RECIPIENT_CREATE',
  INVOICE_RECIPIENT_UPDATE = 'INVOICE_RECIPIENT_UPDATE',
  INVOICE_RECIPIENT_DELETE = 'INVOICE_RECIPIENT_DELETE',
  // Visma
  VISMA_CUSTOMER_READ = 'VISMA_CUSTOMER_READ',
  // Search
  AVAILABILITY_SEARCH = 'AVAILABILITY_SEARCH',
  // Dashboard
  DASHBOARD_READ = 'DASHBOARD_READ',
  // Market
  MARKET_READ_LIST = 'MARKET_READ_LIST',
  MARKET_CREATE = 'MARKET_CREATE',
  MARKET_UPDATE = 'MARKET_UPDATE',
  MARKET_DELETE = 'MARKET_DELETE',
  // Certificate signature
  CERTIFICATE_SIGNATURE_UPDATE = 'CERTIFICATE_SIGNATURE_UPDATE',
  CERTIFICATE_SIGNATURE_UPDATE_GLOBAL = 'CERTIFICATE_SIGNATURE_UPDATE_GLOBAL',
}
