import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  InputNumber,
  Space,
  Spin,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';

import Course from '../types/Course';
import Currency from '../../types/Currency';
import * as API from '../../api';
import CourseAPI, { CreateCourseParams } from '../CourseAPI';
import { useUser } from '../../user/userContext';

import CourseResourceEditor from './CourseResourceEditor';
import { isAxiosError } from 'axios';
import { WarningOutlined } from '@ant-design/icons';
import colors from '../../theme/colors';
import { UUID_PATTERN } from '../../utils/validation';
import { useFeatureToggles } from '../../context/featureToggles';

type CreateCourseModalProps = {
  visible: boolean;
  onCreateCourse?: (course: Course) => void;
  onCancel?: () => void;
};

const CreateCourseModal: React.FC<CreateCourseModalProps> = ({
  visible,
  onCreateCourse,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [loadingDefaultCurrency, setLoadingDefaultCurrency] = useState(false);
  const [currentUser] = useUser();

  const { t } = useTranslation();
  const { isElsaIntegrationEnabled } = useFeatureToggles();

  useEffect(() => {
    const fetchDefaultCurrency = async () => {
      setLoadingDefaultCurrency(true);
      try {
        const { data } = await API.getCurrencies(
          currentUser!.market.defaultCurrency.code,
        );
        setDefaultCurrency(data[0]);
      } finally {
        setLoadingDefaultCurrency(false);
      }
    };

    fetchDefaultCurrency();
  }, [currentUser]);

  const onFinish = useCallback(
    async (values: CreateCourseParams) => {
      setSubmitting(true);
      try {
        const { data: course } = await CourseAPI.createCourse({
          name: values.name,
          defaultPrice: values.defaultPrice,
          currencyId: values.currencyId,
          vismaInventoryNumber: values.vismaInventoryNumber || null,
          elsaCourseId: values.elsaCourseId,
        });
        onCreateCourse?.(course);
      } catch (error) {
        if (isAxiosError(error) && !error.response?.data.message) {
          message.error(t('errors.somethingWentWrong'));
        }
      } finally {
        setSubmitting(false);
      }
    },
    [onCreateCourse, t],
  );

  return (
    <Modal
      title={t('components.CreateCourseModal.newCourse')}
      open={visible}
      onCancel={onCancel}
      onOk={form.submit}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createCourseForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createCourseForm"
        form={form}
        onFinish={(values) => {
          if (defaultCurrency) {
            onFinish({
              ...values,
              currencyId: defaultCurrency.id,
            } as CreateCourseParams);
          }
        }}>
        <Form.Item
          label={t('common.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('components.CreateCourseModal.enterName'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item label={t('components.CreateCourseModal.defaultPrice')}>
          <Space>
            <Form.Item name="defaultPrice" noStyle>
              <InputNumber min={0} />
            </Form.Item>
            {defaultCurrency ? (
              defaultCurrency.code
            ) : loadingDefaultCurrency ? (
              <Spin />
            ) : (
              ''
            )}
          </Space>
        </Form.Item>
        <div className="text-base font-medium border-solid border-gray-200 border-0 border-t-2 pt-3 py-3">
          {t('components.CreateCourseModal.courseInformation')}
        </div>
        <CourseResourceEditor />
        <div className="text-base font-medium border-solid border-gray-200 border-0 border-t-2 mt-6 py-3">
          {t('components.CreateCourseModal.vismaId')}
        </div>
        <Form.Item name="vismaInventoryNumber">
          <Input placeholder={t('components.CreateCourseModal.vismaId')} />
        </Form.Item>
        {isElsaIntegrationEnabled && (
          <>
            <div className="text-base font-medium border-solid border-gray-200 border-0 border-t-2 mt-6 py-3">
              {t('common.elsaMapping')}
            </div>
            <Form.Item
              name="elsaCourseId"
              rules={[
                {
                  pattern: UUID_PATTERN,
                  message: t('common.mustBeValidUUID'),
                },
              ]}>
              <Input placeholder={t('common.enterElsaUUID')} />
            </Form.Item>
            <div className="mt-4 flex flex-row gap-4 items-center">
              <WarningOutlined
                style={{ fontSize: '24px', color: colors.warningYellow }}
              />
              <span>{t('common.elsaMappingWarning')}</span>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateCourseModal;
