import React from 'react';
import { SubsidiaryCompanyGoal } from '../types/SubsidiaryCompanyGoal';
import { ColumnsType } from '../../types/ColumnTypes';
import Table from '../../components/Table';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../user/userContext';
import { Permission } from '../../user/types/Permission';
import { userHasPermission } from '../../user/userUtils';
import { formatRevenue } from '../utils/currency';

type SubsidiaryCompanyGoalResultsProps = {
  subsidiaryCompanyGoals?: SubsidiaryCompanyGoal[];
  loading: boolean;
};

const SubsidiaryCompanyGoalResults: React.FC<
  SubsidiaryCompanyGoalResultsProps
> = ({ subsidiaryCompanyGoals, loading }) => {
  const { t } = useTranslation();

  const [currentUser] = useUser();

  const columns: ColumnsType<SubsidiaryCompanyGoal> = [
    {
      title: t('components.SubsidiaryCompanyGoalResults.monthLabel'),
      render: (subsidiaryCompanyGoal) => (
        <div className="flex justify-between">
          <span>{subsidiaryCompanyGoal.activeMonth}</span>
        </div>
      ),
    },
    {
      title: t(
        'components.SubsidiaryCompanyGoalResults.numberOfCourseInstancesLabel',
      ),
      render: ({ goalNumberOfCourseInstances }) => {
        if (
          goalNumberOfCourseInstances === null ||
          goalNumberOfCourseInstances === undefined
        ) {
          return '-';
        }

        return (
          <div className="flex justify-between">
            <span>{goalNumberOfCourseInstances}</span>
          </div>
        );
      },
    },
    {
      title: t('components.SubsidiaryCompanyGoalResults.revenueLabel'),
      render: ({ goalRevenue }) => {
        if (goalRevenue === null || goalRevenue === undefined) {
          return '-';
        }

        return (
          <div className="flex justify-between">
            <span>
              {formatRevenue(
                goalRevenue,
                currentUser?.preferredSubsidiaryCompany?.market.defaultCurrency
                  .code,
              )}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      rows={subsidiaryCompanyGoals}
      rowKey={(goal) => goal?.id}
      columns={columns}
      loading={loading}
      getRowLink={
        userHasPermission(
          currentUser,
          Permission.SUBSIDIARY_COMPANY_GOAL_UPDATE,
        )
          ? (goal) => String(goal.id)
          : undefined
      }
    />
  );
};

export default SubsidiaryCompanyGoalResults;
