import Language from '../types/Language';

import client from './client';

export const getLanguages = (name?: string, code?: string) =>
  client.get<Language[]>(`/languages/`, {
    params: {
      name,
      code,
    },
  });

export const getLanguage = (id: Language['id']) =>
  client.get<Language>(`/languages/${id}`);

export const getAttendeeLanguage = (id: Language['id']) =>
  client.get<Language>(`/languages/attendee/${id}`);
