import { Button } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

type AmountSetter = (amount: number) => void;

export type DashboardBookingOrderTab = {
  key: string;
  title: string;
  content?: (amountSetter?: AmountSetter) => React.ReactNode;
};

type DashboardBookingOrderTabsProps = {
  title: string;
  defaultTab?: string;
  tabs: DashboardBookingOrderTab[];
};

const DashboardBookingOrderTabs: React.FC<DashboardBookingOrderTabsProps> = ({
  title,
  defaultTab,
  tabs,
}) => {
  const [currentTab, setCurrentTab] = useState<string>(
    defaultTab ?? tabs[0].key ?? '',
  );
  const [tabsAmounts, setTabsAmounts] = useState<Record<string, number>>({});

  const tabTitle = useCallback(
    (tab: DashboardBookingOrderTab, current?: boolean) => {
      const tabBookingOrderAmount = tabsAmounts?.[tab.key];
      return (
        <span
          className={current ? 'text-black font-semibold' : 'text-gray-600'}>
          <span className={current ? 'underline' : ''}>{tab.title}</span>
          {typeof tabBookingOrderAmount === 'number' &&
            ` (${tabBookingOrderAmount})`}
        </span>
      );
    },
    [tabsAmounts],
  );

  const tabAmountSetters = useMemo<Record<string, AmountSetter>>(() => {
    const tabAmountSetters: Record<string, AmountSetter> = {};

    tabs.forEach((tab) => {
      tabAmountSetters[tab.key] = (amount) => {
        setTabsAmounts((prevState) => ({ ...prevState, [tab.key]: amount }));
      };
    });

    return tabAmountSetters;
  }, [tabs]);

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-2">
        <h2 className="mb-0">{title}</h2>
        <div>
          {tabs.map((tab) => (
            <Button
              type="text"
              onClick={() => setCurrentTab(tab.key)}
              key={tab.key}
              className="ml-0.5">
              {tabTitle(tab, tab.key === currentTab)}
            </Button>
          ))}
        </div>
      </div>
      <div>
        {tabs.map((tab) => (
          <div
            key={tab.key}
            style={currentTab !== tab.key ? { display: 'none' } : undefined}>
            {tab.content?.(tabAmountSetters[tab.key])}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardBookingOrderTabs;
