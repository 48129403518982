import { Button, Modal } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import dayjs, { Dayjs } from 'dayjs';

import PaginationParams from '../../types/PaginationParams';
import PaginationMeta from '../../types/PaginationMeta';
import {
  CourseInstanceCardData,
  CourseInstanceStatus,
} from '../types/CourseInstance';
import { useUser } from '../../user/userContext';
import CourseInstanceAPI, {
  CourseInstanceApiFilter,
} from '../CourseInstanceAPI';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

import CourseInstanceResults from './CourseInstanceResults';

type DateCourseInstancesModalProps = {
  filter?: CourseInstanceApiFilter;
  date: dayjs.Dayjs;
  onCancel: () => void;
  onCourseInstanceDelete: (date: Dayjs) => void;
};

const DateCourseInstancesModal: React.FC<DateCourseInstancesModalProps> = ({
  filter,
  date,
  onCancel,
  onCourseInstanceDelete,
}) => {
  const [courseInstances, setCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const courseInstancesRef = useRef<CourseInstanceCardData[]>(courseInstances);
  courseInstancesRef.current = courseInstances;
  const [searchDate, setSearchDate] = useState<dayjs.Dayjs>(date);
  const [loadingCourseInstances, setLoadingCourseInstances] =
    useState<boolean>(false);
  const [paginationParams, setPaginationParams] =
    useState<PaginationParams | null>({
      page: 1,
    });
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta | null>();
  const [currentUser] = useUser();

  const fetchCourseInstances = useCallback(
    async (
      _filter?: CourseInstanceApiFilter,
      _paginationParams?: PaginationParams | null,
    ) => {
      if (_filter?.completed) {
        _filter.startsAt = undefined;
        _filter.status = [CourseInstanceStatus.Confirmed];
        _filter.endsAt = dayjs().endOf('day').toISOString();
      }
      if (
        !userHasPermission(currentUser, Permission.COURSE_INSTANCE_READ_GLOBAL)
      ) {
        _filter!.status = [
          CourseInstanceStatus.Confirmed,
          CourseInstanceStatus.Ordered,
          CourseInstanceStatus.Preliminary,
        ];
      }
      setLoadingCourseInstances(true);

      if (_filter?.instructorIds?.includes(0)) {
        _filter = {
          ..._filter,
          instructorIds: _filter.instructorIds
            .filter((id) => id !== 0)
            .concat([null]),
        };
      }
      try {
        const { data: res } = await CourseInstanceAPI.getCourseInstances(
          _filter ?? filter,
          _paginationParams,
        );
        setCourseInstances(courseInstancesRef.current.concat(res.data));
        courseInstancesRef.current = courseInstancesRef.current.concat(
          res.data,
        );
        setPaginationMeta(res.meta);
        setPaginationParams({ page: res.meta.page + 1 });
      } finally {
        setLoadingCourseInstances(false);
      }
    },
    [currentUser, filter],
  );

  const clearAndFetchCourseInstances = useCallback(() => {
    const newPaginationParams = { page: 1 };
    setPaginationParams(newPaginationParams);
    setPaginationMeta(null);
    setCourseInstances([]);
    fetchCourseInstances(
      {
        ...filter,
        startsAt: searchDate.startOf('day').toISOString(),
        endsAt: searchDate.endOf('day').toISOString(),
      },
      newPaginationParams,
    );
  }, [fetchCourseInstances, filter, searchDate]);

  useEffect(() => {
    if (filter) {
      clearAndFetchCourseInstances();
    }
  }, [clearAndFetchCourseInstances, filter]);

  const debounceFetchCourseInstances = useMemo(
    () =>
      debounce(
        (
          _filter: CourseInstanceApiFilter,
          _paginationParams?: PaginationParams | null,
        ) => {
          fetchCourseInstances(
            {
              ...filter,
              startsAt: searchDate.startOf('day').toISOString(),
              endsAt: searchDate.endOf('day').toISOString(),
            },
            paginationParams,
          );
        },
        10,
      ),
    [fetchCourseInstances, filter, paginationParams, searchDate],
  );

  const capitalize = useCallback(
    (str: string) => str.charAt(0).toUpperCase() + str.slice(1),
    [],
  );

  const prev = useCallback(() => {
    setSearchDate((prevState) => dayjs(prevState.subtract(1, 'day')));
  }, []);

  const next = useCallback(() => {
    setSearchDate((prevState) => dayjs(prevState.add(1, 'day')));
  }, []);

  const getLink = useCallback(
    (courseInstance: CourseInstanceCardData) => {
      if (userHasPermission(currentUser, Permission.BOOKING_ORDER_READ_LIST)) {
        return `/bestallningar/${courseInstance.orderId}`;
      } else {
        return `/kurstillfallen/${courseInstance.id}`;
      }
    },
    [currentUser],
  );

  const updateCourseInstance = useCallback(
    (id: number, newCourseInstance: CourseInstanceCardData) => {
      setCourseInstances(
        courseInstances.map((courseInstance) =>
          courseInstance.id === id ? newCourseInstance : courseInstance,
        ),
      );
    },
    [courseInstances],
  );

  const removeCourseInstanceFromList = useCallback(
    (id: number) => {
      setCourseInstances(
        courseInstances.filter((courseInstance) => courseInstance.id !== id),
      );
      onCourseInstanceDelete(date);
    },
    [courseInstances, date, onCourseInstanceDelete],
  );

  const modalTitle = () => {
    return (
      <>
        <div className="flex flex-col sticky">
          <div style={{ width: '15rem' }}>
            {' '}
            <span className="font-semibold text-lg">
              {capitalize(searchDate.format('dddd')) +
                ' ' +
                searchDate.format('D MMMM')}
            </span>
            <span className="text-gray-600 text-sm">
              {' '}
              - {searchDate.format('YYYY')}
            </span>
          </div>

          <div className="flex flex-row mt-1 -mb-1">
            <Button onClick={prev} className="mr-2">
              <LeftOutlined />
            </Button>
            <Button onClick={next}>
              <RightOutlined />
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      title={modalTitle()}
      className="w-11/12"
      open
      footer={null}
      onCancel={onCancel}
      bodyStyle={{ overflowX: 'scroll' }}
      maskClosable={false}>
      <div style={{ overflow: 'auto', height: '60vh' }}>
        <InfiniteScroll
          loadMore={() => {
            setLoadingCourseInstances(true);
            if (!loadingCourseInstances) {
              return debounceFetchCourseInstances(filter!, {
                ...paginationParams,
                page: paginationParams?.page,
              });
            }
          }}
          hasMore={
            !loadingCourseInstances &&
            (paginationMeta?.page ?? 1) < (paginationMeta?.lastPage ?? 1)
          }
          useWindow={false}>
          <CourseInstanceResults
            courseInstances={
              courseInstances.length > 0
                ? [{ title: '', rows: courseInstances }]
                : []
            }
            loading={loadingCourseInstances}
            getLink={getLink}
            updateCourseInstance={updateCourseInstance}
            removeCourseInstanceFromList={removeCourseInstanceFromList}
            onFilterChange={clearAndFetchCourseInstances}
          />
        </InfiniteScroll>
      </div>
    </Modal>
  );
};

export default DateCourseInstancesModal;
