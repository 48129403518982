import React from 'react';
import { SubsidiaryCompanyGoal } from '../types/SubsidiaryCompanyGoal';
import SubsidiaryCompanyGoalAPI, {
  CreateOrUpdateSubsidiaryCompanyGoalParams,
} from '../SubsidiaryCompanyGoalAPI';
import { useUser } from '../../user/userContext';
import PutSubsidiaryCompanyGoalModal from './PutSubsidiaryCompanyGoalModal';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

type CreateSubsidiaryCompanyGoalModalProps = {
  onCreateGoal?: (subsidiaryCompanyGoal: SubsidiaryCompanyGoal) => void;
  onCancel?: () => void;
};

const CreateSubsidiaryCompanyGoalModal: React.FC<
  CreateSubsidiaryCompanyGoalModalProps
> = ({ onCreateGoal, onCancel }) => {
  const { t } = useTranslation();

  const [currentUser] = useUser();

  const subsidiaryCompanyId = currentUser?.preferredSubsidiaryCompany?.id;

  if (!subsidiaryCompanyId) {
    return null;
  }

  return (
    <PutSubsidiaryCompanyGoalModal
      putMethod={(params: CreateOrUpdateSubsidiaryCompanyGoalParams) =>
        SubsidiaryCompanyGoalAPI.createSubsidiaryCompanyGoal(
          subsidiaryCompanyId,
          params,
        )
      }
      onSuccess={(subsidiaryCompanyGoal: SubsidiaryCompanyGoal) => {
        message.success(
          t('components.PutSubsidiaryCompanyGoalModal.onCreatedSuccess'),
        );
        onCreateGoal?.(subsidiaryCompanyGoal);
      }}
      onCancel={onCancel}
    />
  );
};

export default CreateSubsidiaryCompanyGoalModal;
