import { chunk } from 'lodash';
import { CourseInstanceStatus } from '../types/CourseInstanceStatus';
import { CourseInstanceLeadTime } from '../types/CourseInstanceLeadTime';
import dayjs from 'dayjs';
import { BucketSizeOption } from '../types/InsightsFilters';
import { ComputedDatum } from '@nivo/line';

export const bucketGraphData = (
  graphs: {
    id: CourseInstanceStatus | CourseInstanceLeadTime;
    label: string;
    data: { x: Date; y: number }[];
    color: string;
  }[],
  statusBucketSize: number,
) => {
  return graphs.map((g) => ({
    ...g,
    data: chunk(g.data, statusBucketSize).map((d) => ({
      x: d[0].x,
      y: d.reduce((prev, curr) => prev + curr.y, 0),
    })),
  }));
};

export const updateBucketSize = (
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  setBucketSize: (bucketSize: number) => void,
) => {
  const diffInDays = endDate.diff(startDate) / (1000 * 60 * 60 * 24);

  if (diffInDays <= 14) {
    setBucketSize(BucketSizeOption.Daily);
  } else if (diffInDays <= 93) {
    setBucketSize(BucketSizeOption.Weekly);
  } else {
    setBucketSize(BucketSizeOption.Monthly);
  }
};

export const insertBetween = (
  arr: readonly ComputedDatum[],
  valuesToInsert: (a: ComputedDatum, b: ComputedDatum) => ComputedDatum[],
): ComputedDatum[] => {
  const result: ComputedDatum[] = [];

  for (let i = 0; i < arr.length - 1; i++) {
    result.push(arr[i]);
    result.push(...valuesToInsert(arr[i], arr[i + 1]));
  }

  // Add the last element of the array since it won't be included in the loop
  if (arr.length > 0) {
    result.push(arr[arr.length - 1]);
  }

  return result;
};
