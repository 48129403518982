import { capitalize } from 'lodash';
import dayjs from 'dayjs';

export const formatDate = (date: Date | string | undefined) =>
  date ? capitalize(dayjs(date).format('dddd, Do MMMM YYYY')) : '';

export const formatTime = (date: Date | string | undefined) =>
  date ? dayjs(date).format('HH:mm') : '';

export const formatTravelTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const formattedTime = `${hours ? hours + 'h ' : ''}${
    minutes ? minutes + 'm' : ''
  }`;

  return formattedTime !== '' ? formattedTime : '-';
};

export const formatDateTime = (date: Date | string | undefined) =>
  date ? dayjs(date).format('dddd, MMMM Do YYYY') : '';

export const mergeDateTime = (
  date: dayjs.Dayjs,
  time: dayjs.Dayjs,
  removeMilliseconds?: boolean,
) => {
  const fusedData = date.startOf('day').add(time.diff(time.startOf('day')));
  return removeMilliseconds
    ? fusedData.subtract(time.millisecond(), 'milliseconds')
    : fusedData;
};

export const getFullWorkWeek = (date: dayjs.Dayjs) => {
  const monday = date.clone().startOf('isoWeek');
  return Array.from({ length: 5 }).map((_, index) =>
    monday.clone().add(index, 'day'),
  );
};
