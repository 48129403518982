import React, { useState, useContext } from 'react';
import Cookies from 'js-cookie';

import { setAuthorizationToken } from '../api/client';

export type Token = string | null | undefined;

const TokenContext = React.createContext<[Token, (token: Token) => void]>([
  undefined,
  () => {},
]);

export const useToken = () => useContext(TokenContext);

type TokenContextProviderProps = {
  children: React.ReactChild;
};

const TokenContextProvider: React.FC<TokenContextProviderProps> = ({
  children,
}) => {
  const [token, setToken] = useState<Token>(Cookies.get('token') || null);
  setAuthorizationToken(token);

  if (token) {
    Cookies.set('token', token, {
      expires: 7,
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
    });
  } else {
    Cookies.remove('token');
  }

  return (
    <TokenContext.Provider value={[token, setToken]}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenContextProvider;
