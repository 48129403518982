import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubsidiaryCompanyGoalAPI from '../SubsidiaryCompanyGoalAPI';
import { SubsidiaryCompanyGoal } from '../types/SubsidiaryCompanyGoal';
import { useUser } from '../../user/userContext';
import { Button } from 'antd';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';
import { PlusOutlined } from '@ant-design/icons';
import CreateSubsidiaryCompanyGoalModal from '../components/CreateSubsidiaryCompanyGoalModal';
import SubsidiaryCompanyGoalResults from '../components/SubsidiaryCompanyGoalResults';
import { Outlet } from 'react-router-dom';

const SubsidiaryCompanyGoals: React.FC = () => {
  const { t } = useTranslation();

  const [currentUser] = useUser();

  const [loadingGoals, setLoadingGoals] = useState(false);
  const [subsidiaryCompanyGoals, setSubsidiaryCompanyGoals] =
    useState<SubsidiaryCompanyGoal[]>();

  const [showCreateModal, setShowCreateModal] = useState(false);

  const fetchSubsidiaryCompanyGoals = useCallback(async () => {
    if (!currentUser?.preferredSubsidiaryCompany) {
      return;
    }

    setLoadingGoals(true);
    try {
      const { data } = await SubsidiaryCompanyGoalAPI.getSubsidiaryCompanyGoals(
        currentUser.preferredSubsidiaryCompany.id,
      );
      setSubsidiaryCompanyGoals(data);
    } finally {
      setLoadingGoals(false);
    }
  }, [currentUser?.preferredSubsidiaryCompany]);

  useEffect(() => {
    fetchSubsidiaryCompanyGoals();
  }, [fetchSubsidiaryCompanyGoals]);

  return (
    <>
      <div className="grid grid-cols-2 items-center">
        <h2>{t('views.SubsidiaryCompanyGoals.title')}</h2>
        <div className="flex justify-end">
          {userHasPermission(
            currentUser,
            Permission.SUBSIDIARY_COMPANY_GOAL_CREATE,
          ) && (
            <Button
              type="primary"
              className="text-sm font-medium"
              icon={<PlusOutlined />}
              onClick={() => setShowCreateModal(true)}>
              {t('views.SubsidiaryCompanyGoals.newGoal')}
            </Button>
          )}
        </div>
      </div>

      <SubsidiaryCompanyGoalResults
        subsidiaryCompanyGoals={subsidiaryCompanyGoals}
        loading={loadingGoals}
      />

      {showCreateModal && (
        <CreateSubsidiaryCompanyGoalModal
          onCreateGoal={() => {
            setShowCreateModal(false);
            fetchSubsidiaryCompanyGoals();
          }}
          onCancel={() => setShowCreateModal(false)}
        />
      )}

      {userHasPermission(
        currentUser,
        Permission.SUBSIDIARY_COMPANY_GOAL_UPDATE,
      ) && (
        <Outlet
          context={{
            onClose: fetchSubsidiaryCompanyGoals,
            subsidiaryCompanyGoals,
          }}
        />
      )}
    </>
  );
};

export default SubsidiaryCompanyGoals;
