import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import Paragraph from 'antd/lib/typography/Paragraph';
import Link from 'antd/lib/typography/Link';
import Text from 'antd/lib/typography/Text';
import { CalendarFilled } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { useUser } from '../userContext';
import { userHasPermission } from '../userUtils';
import { Permission } from '../types/Permission';

type UserPageInstructionsModalProps = {
  visible: boolean;
  onCancel?: () => void;
};

const UserPageInstructionsModal: React.FC<UserPageInstructionsModalProps> = ({
  visible,
  onCancel,
}) => {
  const [currentUser] = useUser();
  const { t } = useTranslation();

  const tabItems = [
    {
      key: '1',
      label: 'Info',
      children: (
        <>
          {!userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) && (
            <>
              <h3>{t('components.UserPageInstructionsModal.general')}</h3>
              <Paragraph>
                {t(
                  'components.UserPageInstructionsModal.contactAdminToChangeContactInfo',
                )}
              </Paragraph>
            </>
          )}
          {currentUser?.isInstructor && (
            <>
              <h3>
                {t('components.UserPageInstructionsModal.syncCalendar.title')}
              </h3>
              <Paragraph>
                {t('components.UserPageInstructionsModal.syncCalendar.1')}{' '}
                <Button
                  size="small"
                  icon={
                    <CalendarFilled
                      style={{
                        fontSize: `${'14px'}`,
                      }}
                    />
                  }
                  shape="circle"
                  type="primary"
                />{' '}
                {t('components.UserPageInstructionsModal.syncCalendar.2')}
              </Paragraph>
            </>
          )}
          <h3>
            {t('components.UserPageInstructionsModal.profilePicture.title')}
          </h3>
          <Paragraph>
            {t('components.UserPageInstructionsModal.profilePicture.1')}{' '}
            <Link
              href="https://en.gravatar.com/"
              target="_blank"
              rel="norefferer noopener">
              {t('common.gravatar')}{' '}
            </Link>
            {t('components.UserPageInstructionsModal.profilePicture.2')} <br />{' '}
            {t('components.UserPageInstructionsModal.profilePicture.3')}{' '}
            <Text keyboard>Add new image</Text>{' '}
            {t('components.UserPageInstructionsModal.profilePicture.4')}
          </Paragraph>
        </>
      ),
    },
    {
      key: '2',
      label:
        currentUser?.isInstructor ||
        userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL)
          ? t('components.UserPageInstructionsModal.calendar')
          : null,
      children: (
        <>
          {currentUser?.isInstructor &&
            !userHasPermission(
              currentUser,
              Permission.COURSE_INSTANCE_UPDATE,
            ) && (
              <>
                <h3>
                  {t(
                    'components.UserPageInstructionsModal.createTimeSlot.title',
                  )}
                </h3>
                <Paragraph>
                  {t(
                    'components.UserPageInstructionsModal.createTimeSlot.content',
                  )}
                </Paragraph>
                <h3>
                  {t(
                    'components.UserPageInstructionsModal.removeTimeSlot.title',
                  )}
                </h3>
                <Paragraph>
                  {t('components.UserPageInstructionsModal.removeTimeSlot.1')}{' '}
                  <Text keyboard>{t('common.remove')}</Text>
                  {t('components.UserPageInstructionsModal.removeTimeSlot.2')}
                </Paragraph>
                <h3>
                  {t('components.UserPageInstructionsModal.classes.title')}
                </h3>
                <Paragraph>
                  {t('components.UserPageInstructionsModal.classes.content')}
                </Paragraph>
              </>
            )}
          {userHasPermission(currentUser, Permission.USER_UPDATE_GLOBAL) && (
            <>
              <h3>
                {t(
                  'components.UserPageInstructionsModal.createTimeSlotAdmin.title',
                )}
              </h3>
              <Paragraph>
                {t(
                  'components.UserPageInstructionsModal.createTimeSlotAdmin.content',
                )}
              </Paragraph>
              <h3>
                {t(
                  'components.UserPageInstructionsModal.removeTimeSlotAdmin.title',
                )}
              </h3>
              <Paragraph>
                {t(
                  'components.UserPageInstructionsModal.removeTimeSlotAdmin.1',
                )}{' '}
                <Text keyboard>{t('common.remove')}</Text>
                {t(
                  'components.UserPageInstructionsModal.removeTimeSlotAdmin.2',
                )}
              </Paragraph>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Modal
      title={t('components.UserPageInstructionsModal.instructions')}
      open={visible}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Modal>
  );
};

export default UserPageInstructionsModal;
