import React, { useCallback, useState } from 'react';
import InsightsHeader from '../components/InsightsHeader';
import CourseInstanceStatusInsights from '../components/CourseInstanceStatusInsights';
import InsightsExtendedFilters from '../components/InsightsExtendedFilters';

const InsightsV2: React.FC = () => {
  const [showExtendedFilters, setShowExtendedFilters] = useState(false);

  const toggleExtendedFilter = useCallback(() => {
    setShowExtendedFilters((prev) => !prev);
  }, []);

  const hideExtendedFilter = useCallback(() => {
    setShowExtendedFilters(false);
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <InsightsHeader
        toggleShowExtendedFilters={toggleExtendedFilter}
        showExtendedFilters={showExtendedFilters}
      />

      <InsightsExtendedFilters
        show={showExtendedFilters}
        close={hideExtendedFilter}
      />

      <CourseInstanceStatusInsights />

      {/* See SAF-3514
      <CourseInstanceLeadTimeInsights /> */}
    </div>
  );
};

export default InsightsV2;
