import React from 'react';

const ConditionalParent: React.FC<{
  children: React.ReactNode;
  condition?: boolean;
  parent: (children: React.ReactNode) => React.ReactNode;
}> = ({ condition, parent, children }) => (
  <>{condition ? parent(children) : children}</>
);

export default ConditionalParent;
