import client from '../api/client';

import Market from './types/Market';

export const getMarkets = (name?: string, code?: string) =>
  client.get<Market[]>(`/markets/`, {
    params: {
      name,
      code,
    },
  });

export const getMarket = (id: Market['id']) =>
  client.get<Market>(`/markets/${id}`);

export type CreateMarketParams = {
  name: string;
  code: string;
  timeZoneId?: string;
  defaultCurrencyId: number;
  preferredLanguageId: number;
  mapCenterLatitude?: number;
  mapCenterLongitude?: number;
  allowInstructorSelfAssign: boolean;
};

const createMarket = (market: CreateMarketParams) =>
  client.post<Market>('/markets', market);

export type UpdateMarketParams = {
  name: string;
  code: string;
  timeZoneId?: string;
  defaultCurrencyId: number;
  preferredLanguageId: number;
  mapCenterLatitude?: number;
  mapCenterLongitude?: number;
  allowInstructorSelfAssign: boolean;
};

const updateMarket = (id: Market['id'], market: UpdateMarketParams) =>
  client.patch<Market>(`/markets/${id}`, market);

const deleteMarket = (id: Market['id']) =>
  client.delete<Market>(`/markets/${id}`);

export default {
  getMarkets,
  getMarket,
  createMarket,
  updateMarket,
  deleteMarket,
};
