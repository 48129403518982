import ContactPerson from '../types/ContactPerson';
import client from '../../api/client';
import BookingOrder from '../types/BookingOrder';

type CreateContactPersonParams = {
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  note?: string;
};

type UpdateContactPersonParams = {
  firstName: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  note?: string;
};

const getContactPersons = (name?: string, email?: string) =>
  client.get<ContactPerson[]>(`/contact-persons/`, {
    params: {
      name,
      email,
    },
  });

const getContactPerson = (id: ContactPerson['id']) =>
  client.get<ContactPerson>(`/contact-persons/${id}`);

const createContactPerson = (contactPerson: CreateContactPersonParams) =>
  client.post<ContactPerson>('/contact-persons', contactPerson);

const updateContactPerson = (
  id: ContactPerson['id'],
  contactPerson: UpdateContactPersonParams,
) => client.patch<ContactPerson>(`/contact-persons/${id}`, contactPerson);

const deleteContactPerson = (id: ContactPerson['id']) =>
  client.delete<ContactPerson>(`/contact-persons/${id}`);

const deleteBookingOrderContactPerson = (
  contactPersonId: ContactPerson['id'],
  editedBookingOrderId: BookingOrder['id'] | null,
  replacementContactPersonId: ContactPerson['id'],
) =>
  client.delete<ContactPerson>(
    `/booking-order/${editedBookingOrderId}/contact-person/${contactPersonId}/replacement/${replacementContactPersonId}`,
  );
const deleteBookingOrderContactPersonDryRun = (
  contactPersonId: ContactPerson['id'],
  editedBookingOrderId: BookingOrder['id'] | null,
) =>
  client.delete<ContactPerson>(
    `/booking-order/${editedBookingOrderId}/contact-person/${contactPersonId}/dry-run`,
  );

export default {
  getContactPersons,
  getContactPerson,
  createContactPerson,
  updateContactPerson,
  deleteContactPerson,
  deleteBookingOrderContactPerson,
  deleteBookingOrderContactPersonDryRun,
};
