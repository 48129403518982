import client from '../../api/client';
import { ElsaClientCompany } from '../types/ClientCompany';

const getElsaClientCompanies = (subsidiaryCompanyId: number) =>
  client.get<ElsaClientCompany[]>('/elsa/client-companies', {
    params: {
      subsidiaryCompanyId,
    },
  });

type CreateElsaClientCompanyRequestBody = {
  subsidiaryCompanyId: number;
  name: string;
};

const createElsaClientCompany = (params: CreateElsaClientCompanyRequestBody) =>
  client.post<ElsaClientCompany>('/elsa/client-companies', params);

export default {
  getElsaClientCompanies,
  createElsaClientCompany,
};
