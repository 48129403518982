import { intersection } from 'lodash';
import { TFunction } from 'i18next';

import { Permission } from './types/Permission';
import User, { MinimalUser, UserRole } from './types/User';

export const getUserRoleText = (role: UserRole, t: TFunction) =>
  ({
    [UserRole.Admin]: t('utils.user.admin'),
    [UserRole.Instructor]: t('utils.user.instructor'),
    [UserRole.Seller]: t('utils.user.seller'),
    [UserRole.Booker]: t('utils.user.booker'),
  })[role];

export const getMinimalUser = (user: User) =>
  ({
    id: user.id,
    email: user.email,
    name: user.name,
    phoneNumber: user.phoneNumber,
    internal: user.internal,
    isInstructor: user.isInstructor,
    isAdmin: user.isAdmin,
    isSeller: user.isSeller,
    maxTravelDistance: user.maxTravelDistance,
    createdAt: user.createdAt,
    updatedAt: user.updatedAt,
    deletedAt: user.deletedAt,
  }) as MinimalUser;

export const userHasPermission = (
  user: User | null,
  permission: Permission,
) => {
  if (!user?.permissions) {
    return false;
  }
  return user.permissions.includes(permission);
};

export const userHasPermissions = (
  user: User | null,
  ...permissions: Permission[]
) => {
  if (!user?.permissions) {
    return false;
  }
  return permissions.every((permission) =>
    user.permissions!.includes(permission),
  );
};

export const userHasAnyPermission = (
  user: User | null,
  permissions: Permission[],
) => {
  if (!user?.permissions) {
    return false;
  }
  return intersection(user.permissions, permissions).length > 0;
};

export const sortInstructorsByFavorite = (
  instructors: User[],
  currentUser?: User | null,
) => {
  const favoriteInstructors = instructors.filter(
    (instructor) =>
      instructor.id !== null &&
      currentUser?.favoriteInstructorIds.includes(instructor.id),
  );
  const nonFavoriteInstructors = instructors.filter(
    (instructor) => !favoriteInstructors.includes(instructor),
  );

  return favoriteInstructors.concat(nonFavoriteInstructors);
};
