import React, { useState } from 'react';
import { message, Modal, Form, Input, Button, Tooltip } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import InvoiceRecipientAPI from '../api/InvoiceRecipientAPI';
import InvoiceRecipient from '../types/InvoiceRecipient';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

type CreateInvoiceRecipientModalProps = {
  visible: boolean;
  onCreate?: (InvoiceRecipient: InvoiceRecipient) => void;
  onCancel?: () => void;
  invoiceReference?: string;
  updateInvoiceReference?: (newInvoiceReference: string) => void;
};

const CreateInvoiceRecipientModal: React.FC<
  CreateInvoiceRecipientModalProps
> = ({
  visible,
  onCreate,
  onCancel,
  invoiceReference,
  updateInvoiceReference,
}) => {
  const [currentUser] = useUser();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [searchedVismaCustomerId, setSearchedVismaCustomerId] =
    useState<string>();
  const { t } = useTranslation();

  const submit = () => {
    form.submit();
  };

  const onFinish = async (values: InvoiceRecipient) => {
    if (!currentUser || !currentUser.preferredSubsidiaryCompany) {
      return;
    }

    setSubmitting(true);
    try {
      const { data: invoiceRecipient } =
        await InvoiceRecipientAPI.createInvoiceRecipient({
          name: values.name,
          subsidiaryCompanyId: currentUser.preferredSubsidiaryCompany.id,
          corporateId: values.corporateId,
          clientAddress1: values.clientAddress1,
          clientAddress2: values.clientAddress2,
          clientAddress3: values.clientAddress3,
          clientPostalCode: values.clientPostalCode,
          clientCity: values.clientCity,
          invoiceAddress1: values.invoiceAddress1,
          invoiceAddress2: values.invoiceAddress2,
          invoiceAddress3: values.invoiceAddress3,
          invoicePostalCode: values.invoicePostalCode,
          invoiceCity: values.invoiceCity,
          invoiceEmail: values.invoiceEmail,
          vismaCustomerId: searchedVismaCustomerId,
        });
      onCreate?.(invoiceRecipient);
      form.resetFields();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const autofillInvoiceAddressFields = () => {
    form.setFieldsValue({
      invoiceAddress1: form.getFieldValue('clientAddress1'),
      invoiceAddress2: form.getFieldValue('clientAddress2'),
      invoiceAddress3: form.getFieldValue('clientAddress3'),
      invoicePostalCode: form.getFieldValue('clientPostalCode'),
      invoiceCity: form.getFieldValue('clientCity'),
    });
  };

  const fetchFromVisma = async (vismaCustomerId: string) => {
    if (!currentUser || !currentUser.preferredSubsidiaryCompany) {
      return;
    }

    setSubmitting(true);
    try {
      const { data } = await InvoiceRecipientAPI.getInvoiceRecipientVismaData(
        currentUser.preferredSubsidiaryCompany.id,
        vismaCustomerId,
      );

      form.setFieldsValue({
        corporateId: data.corporateId,
        name: data.name,
        vismaCustomerId: data.vismaCustomerId,
        clientAddress1: data.clientAddress1,
        clientAddress2: data.clientAddress2,
        clientAddress3: data.clientAddress3,
        clientPostalCode: data.clientPostalCode,
        clientCity: data.clientCity,
        invoiceAddress1: data.invoiceAddress1,
        invoiceAddress2: data.invoiceAddress2,
        invoiceAddress3: data.invoiceAddress3,
        invoiceCity: data.invoiceCity,
        invoicePostalCode: data.invoicePostalCode,
        invoiceEmail: data.invoiceEmail,
      });
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const AutofillButton: React.FC = () => {
    return (
      <Button onClick={autofillInvoiceAddressFields}>
        {t('components.CreateInvoiceRecipientModal.useCustomerAddress')}
      </Button>
    );
  };

  return (
    <Modal
      title={t(
        'components.CreateInvoiceRecipientModal.createNewInvoiceRecipient',
      )}
      width={1000}
      open={visible}
      onCancel={onCancel}
      onOk={submit}
      zIndex={0}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createInvoiceRecipientForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createInvoiceRecipientForm"
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish(values as InvoiceRecipient)}>
        <div className="md:grid grid-cols-2 gap-x-8 border-solid border-gray-200 border-0 border-b-2">
          <Form.Item
            name="name"
            label={t('components.CreateInvoiceRecipientModal.companyName')}
            rules={[
              {
                required: true,
                message: t(
                  'components.CreateInvoiceRecipientModal.fillOutCompanyName',
                ),
              },
            ]}>
            <Input
              placeholder={t(
                'components.CreateInvoiceRecipientModal.companyName',
              )}
            />
          </Form.Item>
          <Form.Item
            name="corporateId"
            label={t('common.corporateId')}
            rules={[
              {
                pattern: /(^[0-9]{10}$|^[0-9]{6}-[0-9]{4}$)/,
                message: t(
                  'components.CreateInvoiceRecipientModal.writeValidCorporateId',
                ),
              },
            ]}>
            <Input placeholder={t('common.corporateId')} />
          </Form.Item>
          {/* div is inside of the Form.Item due to styling issues*/}
          <Form.Item
            label={t(
              'components.CreateInvoiceRecipientModal.vismaCustomerNumber',
            )}>
            <div className="flex gap-2">
              <Input
                onChange={(e) => setSearchedVismaCustomerId(e.target.value)}
                placeholder={t(
                  'components.CreateInvoiceRecipientModal.vismaCustomerNumber',
                )}
              />
              <Tooltip
                placement="top"
                title={
                  !searchedVismaCustomerId &&
                  t(
                    'components.CreateInvoiceRecipientModal.enterVismaCustomerNumber',
                  )
                }>
                {userHasPermission(
                  currentUser,
                  Permission.VISMA_CUSTOMER_READ,
                ) && (
                  <Button
                    disabled={submitting || !searchedVismaCustomerId}
                    onClick={() =>
                      searchedVismaCustomerId &&
                      fetchFromVisma(searchedVismaCustomerId)
                    }>
                    {t('components.CreateInvoiceRecipientModal.fetchFromVisma')}
                  </Button>
                )}
              </Tooltip>
            </div>
          </Form.Item>
          {
            // If invoiceReference is an empty string then this item shall render
            invoiceReference !== undefined && (
              <Form.Item label={t('common.invoiceReference')}>
                <Input
                  placeholder={t('common.invoiceReference')}
                  value={invoiceReference}
                  onChange={(e) => updateInvoiceReference?.(e.target.value)}
                />
              </Form.Item>
            )
          }
        </div>
        <div className="grid grid-cols-2 pt-4">
          <div>
            <div className="text-lg font-semibold pb-2">
              {t('components.CreateInvoiceRecipientModal.customerAddress')}
            </div>
            <Form.Item
              className="w-4/5 mb-4"
              name="clientAddress1"
              label={t('common.address')}>
              <Input
                placeholder={t('components.CreateInvoiceRecipientModal.rowOne')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="clientAddress2">
              <Input
                placeholder={t('components.CreateInvoiceRecipientModal.rowTwo')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="clientAddress3">
              <Input
                placeholder={t(
                  'components.CreateInvoiceRecipientModal.rowThree',
                )}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="clientPostalCode"
              label={t('components.CreateInvoiceRecipientModal.postalCode')}
              rules={[
                {
                  pattern: /(^[0-9]{5}$|^[0-9]{3}(\s|-)[0-9]{2}$)/,
                  message: t(
                    'components.CreateInvoiceRecipientModal.writeValidPostalCode',
                  ),
                },
              ]}>
              <Input
                placeholder={t(
                  'components.CreateInvoiceRecipientModal.postalCode',
                )}
                maxLength={6}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="clientCity"
              label={t('components.CreateInvoiceRecipientModal.city')}>
              <Input
                placeholder={t('components.CreateInvoiceRecipientModal.city')}
              />
            </Form.Item>
          </div>
          <div>
            <div className="pb-2 flex justify-between w-4/5">
              <span className="text-lg font-semibold">
                {t('components.CreateInvoiceRecipientModal.billingAddress')}
              </span>
              <AutofillButton />
            </div>
            <Form.Item
              className="w-4/5 mb-4"
              name="invoiceAddress1"
              label={t('common.address')}>
              <Input
                placeholder={t('components.CreateInvoiceRecipientModal.rowOne')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="invoiceAddress2">
              <Input
                placeholder={t('components.CreateInvoiceRecipientModal.rowTwo')}
              />
            </Form.Item>
            <Form.Item className="w-4/5 mb-4" name="invoiceAddress3">
              <Input
                placeholder={t(
                  'components.CreateInvoiceRecipientModal.rowThree',
                )}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="invoicePostalCode"
              label={t('components.CreateInvoiceRecipientModal.postalCode')}
              rules={[
                {
                  pattern: /(^[0-9]{5}$|^[0-9]{3}(\s|-)[0-9]{2}$)/,
                  message: t(
                    'components.CreateInvoiceRecipientModal.writeValidPostalCode',
                  ),
                },
              ]}>
              <Input
                placeholder={t(
                  'components.CreateInvoiceRecipientModal.postalCode',
                )}
                maxLength={6}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="invoiceCity"
              label={t('components.CreateInvoiceRecipientModal.city')}>
              <Input
                placeholder={t('components.CreateInvoiceRecipientModal.city')}
              />
            </Form.Item>
            <Form.Item
              className="w-4/5"
              name="invoiceEmail"
              label={t('common.email')}
              rules={[
                {
                  type: 'email',
                  message: t(
                    'components.CreateInvoiceRecipientModal.writeValidEmail',
                  ),
                },
              ]}>
              <Input placeholder={t('common.email')} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateInvoiceRecipientModal;
