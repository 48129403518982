import { Alert, Button, Modal } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../user/userContext';
import Course from '../../course/types/Course';
import { MinimalUser } from '../../user/types/User';
import * as DateUtils from '../../utils/date';
import Gravatar from '../../user/components/Gravatar';
import CourseOption from '../../components/CourseOption';
import DetailsRow from '../../components/DetailsRow';

import { HeaderValues } from './CourseInstanceHeader';
import { CourseInstanceUpdateValues } from './EditCourseInstance';

type CourseInstanceChangesModalProps = {
  editableValues?: HeaderValues;
  showModal?: boolean;
  course?: Course;
  instructor?: MinimalUser;
  setShowModal?: (values: boolean) => void;
  setEditing?: (values: boolean) => void;
  onFinish?: (values: CourseInstanceUpdateValues) => Promise<void>;
  messages: string[];
};

const CourseInstanceChangesModal: React.FC<CourseInstanceChangesModalProps> = ({
  editableValues,
  showModal,
  course,
  instructor,
  setShowModal,
  setEditing,
  onFinish,
  messages,
}) => {
  const [currentUser] = useUser();
  const { t } = useTranslation();

  const submit = async (values?: HeaderValues) => {
    try {
      await onFinish?.({
        instructor: instructor,
        course: course,
        date: values?.date,
        time: values?.time,
      });
    } finally {
      setEditing?.(false);
      setShowModal?.(false);
    }
  };

  return (
    <div>
      <Modal
        title={t('components.CourseInstanceChangesModal.saveChanges')}
        open={showModal}
        onCancel={() => setShowModal?.(false)}
        onOk={() => submit(editableValues)}
        footer={[
          <Button key="back" onClick={() => setShowModal?.(false)}>
            {t('common.close')}
          </Button>,
          <Button
            disabled={editableValues === undefined}
            key="submit"
            type="primary"
            onClick={() => submit(editableValues)}>
            {t('common.save')}
          </Button>,
        ]}
        maskClosable={false}>
        <div>
          <p>
            {t(
              'components.CourseInstanceChangesModal.followingChangesWillBeSaved',
            )}
          </p>
          <DetailsRow
            label={t('common.course')}
            displayValue={<CourseOption user={currentUser} course={course} />}
          />
          {editableValues?.date && editableValues?.time && (
            <DetailsRow
              label={t('common.time')}
              displayValue={
                <div className="flex flex-col">
                  <span>
                    {DateUtils.formatDate(
                      DateUtils.mergeDateTime(
                        dayjs(editableValues?.date),
                        editableValues?.time[0],
                      ).toISOString(),
                    )}
                  </span>
                  <span>
                    {DateUtils.formatTime(
                      DateUtils.mergeDateTime(
                        dayjs(editableValues?.date),
                        editableValues?.time[0],
                      ).toISOString(),
                    )}{' '}
                    -{' '}
                    {DateUtils.formatTime(
                      DateUtils.mergeDateTime(
                        dayjs(editableValues?.date),
                        editableValues?.time[1],
                      ).toISOString(),
                    )}
                  </span>
                </div>
              }
            />
          )}
          <DetailsRow
            label={t('common.instructor')}
            displayValue={
              <div className="pt-1">
                <Gravatar
                  email={instructor?.email}
                  internal={instructor?.internal}
                  isInstructor={instructor?.isInstructor}
                />
                <span className="ml-4">
                  {instructor?.name ??
                    t(
                      'components.CourseInstanceChangesModal.instructorMissing',
                    )}
                </span>
              </div>
            }
          />
          {messages.map((message, index) => (
            <Alert key={index} type="warning" message={message} />
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default CourseInstanceChangesModal;
