import client from '../api/client';
import { ImageMedia } from '../types/ImageMedia';

import SubsidiaryCompany from './types/SubsidiaryCompany';
import Market from '../market/types/Market';

type CreateSubsidiaryCompanyParams = {
  name: string;
  email?: string;
  limeAPIKey?: string;
  limeServerURL?: string;
  elsaSubsidiaryCompanyId?: string;
};

const createSubsidiaryCompany = (company: CreateSubsidiaryCompanyParams) =>
  client.post<SubsidiaryCompany>('/subsidiary-companies', company);

export type GetSubsidiaryCompanyParams = {
  marketId?: Market['id'];
  allMarkets: boolean;
};

const getSubsidiaryCompanies = (params?: GetSubsidiaryCompanyParams) =>
  client.get<SubsidiaryCompany[]>('/subsidiary-companies', {
    params,
  });

const getSubsidiaryCompany = (id: SubsidiaryCompany['id']) =>
  client.get<SubsidiaryCompany>(`/subsidiary-companies/${id}`);

type UpdateSubsidiaryCompanyParams = {
  name: string;
  email?: string;
  limeAPIKey?: string;
  limeServerURL?: string;
  elsaSubsidiaryCompanyId?: string;
};

const updateSubsidiaryCompany = (
  id: SubsidiaryCompany['id'],
  company: UpdateSubsidiaryCompanyParams,
) => client.patch<SubsidiaryCompany>(`/subsidiary-companies/${id}`, company);

const deleteSubsidiaryCompany = (id: SubsidiaryCompany['id']) =>
  client.delete(`/subsidiary-companies/${id}`);

const putCertificateLogo = (id: number, data: FormData) =>
  client.put<ImageMedia>(`/subsidiary-companies/${id}/certificate-logo`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

const deleteCertificateLogo = (id: number) =>
  client.delete<ImageMedia>(`/subsidiary-companies/${id}/certificate-logo`);

export default {
  createSubsidiaryCompany,
  getSubsidiaryCompanies,
  getSubsidiaryCompany,
  updateSubsidiaryCompany,
  deleteSubsidiaryCompany,
  putCertificateLogo,
  deleteCertificateLogo,
};
