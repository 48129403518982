import {
  CourseInstanceLeadTime,
  CourseInstanceLeadTimeStatistic,
  CourseInstanceLeadTimeXMode,
  CourseInstanceLeadTimeYMode,
} from '../types/CourseInstanceLeadTime';
import {
  CourseInstanceStatus,
  CourseInstanceStatusStatistic,
  CourseInstanceStatusXMode,
  CourseInstanceStatusYMode,
} from '../types/CourseInstanceStatus';
import { t } from 'i18next';

export const translateCourseInstanceStatus = (
  status: CourseInstanceStatus,
): string => {
  switch (status) {
    case CourseInstanceStatus.TOTAL:
      return t('components.CourseInstanceStatusInsights.Type.total');
    case CourseInstanceStatus.PRELIMINARY:
      return t('components.CourseInstanceStatusInsights.Type.preliminary');
    case CourseInstanceStatus.CONFIRMED:
      return t('components.CourseInstanceStatusInsights.Type.confirmed');
    case CourseInstanceStatus.INVOICED:
      return t('components.CourseInstanceStatusInsights.Type.invoiced');
    case CourseInstanceStatus.CANCELED:
      return t('components.CourseInstanceStatusInsights.Type.canceled');
  }
};

export const translateCourseInstanceStatusStatistic = (
  statusStatistic: CourseInstanceStatusStatistic,
): string => {
  switch (statusStatistic) {
    case CourseInstanceStatusStatistic.TOTAL:
      return t('components.CourseInstanceStatusInsights.Statistics.total');
    case CourseInstanceStatusStatistic.PRELIMINARY:
      return t(
        'components.CourseInstanceStatusInsights.Statistics.preliminary',
      );
    case CourseInstanceStatusStatistic.CONFIRMED:
      return t('components.CourseInstanceStatusInsights.Statistics.confirmed');
    case CourseInstanceStatusStatistic.INVOICED:
      return t('components.CourseInstanceStatusInsights.Statistics.invoiced');
    case CourseInstanceStatusStatistic.WITHOUT_PRICE:
      return t(
        'components.CourseInstanceStatusInsights.Statistics.withoutPrice',
      );
  }
};

export const translateCourseInstanceLeadTime = (
  leadTime: CourseInstanceLeadTime,
): string => {
  switch (leadTime) {
    case CourseInstanceLeadTime.CREATED:
      return t('components.CourseInstanceLeadTimeInsights.Type.created');
    case CourseInstanceLeadTime.CONFIRMED:
      return t('components.CourseInstanceLeadTimeInsights.Type.confirmed');
    case CourseInstanceLeadTime.INVOICED:
      return t('components.CourseInstanceLeadTimeInsights.Type.invoiced');
  }
};

export const translateCourseInstanceLeadTimeStatistic = (
  leadTimeStatistic: CourseInstanceLeadTimeStatistic,
): string => {
  switch (leadTimeStatistic) {
    case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_CONFIRMED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.timeToConfirmed',
      );
    case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_TO_COMPLETED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.timeToCompleted',
      );
    case CourseInstanceLeadTimeStatistic.AVERAGE_DAYS_CONFIRMED_TO_INVOICED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.timeConfirmedToInvoiced',
      );
    case CourseInstanceLeadTimeStatistic.NOT_INVOICED:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.notInvoiced',
      );
    case CourseInstanceLeadTimeStatistic.INVOICED_IN_OTHER_MONTH:
      return t(
        'components.CourseInstanceLeadTimeInsights.Statistics.invoicedInOtherMonth',
      );
  }
};

export const translateCourseInstanceStatusXMode = (
  xMode: CourseInstanceStatusXMode,
): string => {
  switch (xMode) {
    case CourseInstanceStatusXMode.BOOKING_CREATED:
      return t('components.CourseInstanceStatusInsights.xMode.booking_created');
    case CourseInstanceStatusXMode.COURSE_INSTANCE_DATE:
      return t(
        'components.CourseInstanceStatusInsights.xMode.course_instance_date',
      );
  }
};

export const translateCourseInstanceLeadTimeXMode = (
  xMode: CourseInstanceLeadTimeXMode,
): string => {
  switch (xMode) {
    case CourseInstanceLeadTimeXMode.BOOKING_CREATED:
      return t(
        'components.CourseInstanceLeadTimeInsights.xMode.booking_created',
      );
    case CourseInstanceLeadTimeXMode.COURSE_INSTANCE_DATE:
      return t(
        'components.CourseInstanceLeadTimeInsights.xMode.course_instance_date',
      );
  }
};

export const translateCourseInstanceStatusYMode = (
  yMode: CourseInstanceStatusYMode,
  currencyCode?: string,
): string => {
  switch (yMode) {
    case CourseInstanceStatusYMode.NUMBER_OF_CLASSES:
      return t(
        'components.CourseInstanceStatusInsights.yMode.number_of_classes',
      );
    case CourseInstanceStatusYMode.REVENUE:
      return [
        t('components.CourseInstanceStatusInsights.yMode.revenue'),
        ...(currencyCode ? [`(${currencyCode})`] : []),
      ].join(' ');
  }
};

export const translateCourseInstanceLeadTimeYMode = (
  yMode: CourseInstanceLeadTimeYMode,
): string => {
  switch (yMode) {
    case CourseInstanceLeadTimeYMode.NUMBER_OF_CLASSES:
      return t(
        'components.CourseInstanceLeadTimeInsights.yMode.number_of_classes',
      );
  }
};
