import React from 'react';
import { useTranslation } from 'react-i18next';

import LimeIcon from './LimeIcon';

const BookingOrderFromLimeBadge: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`h-6 px-2 inline-flex text-xs font-semibold rounded-full uppercase bg-lime-100 text-lime-800 ${
        className || ''
      }`}>
      <div className="flex items-center">
        <LimeIcon className="text-blue-lime pr-1" />
        {t('components.BookingOrderFromLimeBadge.fromLime')}
      </div>
    </div>
  );
};

export default BookingOrderFromLimeBadge;
