import React from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';

import { addResponseInterceptor } from '../api/client';
import { useToken } from '../auth/token';

const WARNING_HEADER = 'safe-life-warning';

type ApiErrorHandlerProps = {
  children: React.ReactNode;
};

const ApiErrorHandler: React.FC<ApiErrorHandlerProps> = ({ children }) => {
  const navigate = useNavigate();
  const [msg, contextHolder] = message.useMessage();
  const [, setToken] = useToken();

  const errorHandler = (errorResponse: AxiosError['response']) => {
    if (
      errorResponse?.status === 401 &&
      !errorResponse?.request?.responseURL?.endsWith('/login')
    ) {
      setToken(null);
      navigate(window.location.pathname, {
        state: { sessionExpired: true },
        replace: true,
      });
    }

    const errorMessage =
      errorResponse?.data &&
      typeof errorResponse.data === 'object' &&
      'message' in errorResponse.data &&
      errorResponse?.data?.message;

    if (typeof errorMessage === 'string') {
      msg.open({
        type: 'error',
        content: errorMessage,
        duration: 5,
      });
    }
  };

  const warningHeaderHandler = (headers: AxiosResponse['headers']) => {
    if (headers[WARNING_HEADER])
      msg.open({
        type: 'warning',
        content: headers[WARNING_HEADER],
        duration: 5,
      });
  };

  addResponseInterceptor(errorHandler, warningHeaderHandler);

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};

export default ApiErrorHandler;
