import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { useUser } from '../../user/userContext';
import SubsidiaryCompanyAPI from '../SubsidiaryCompanyAPI';
import UserAPI from '../../user/UserAPI';
import SubsidiaryCompany from '../types/SubsidiaryCompany';
import DownArrow from '../../resources/img/downArrow.svg';

const SubsidiaryCompanyPicker: React.FC = () => {
  const { Option } = Select;
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useUser();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState<boolean>(false);
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();

  const { t } = useTranslation();

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  useEffect(() => {
    if (currentUser?.preferredSubsidiaryCompany) {
      setSubsidiaryCompanies([currentUser?.preferredSubsidiaryCompany]);
    }
    fetchSubsidiaryCompanies();
  }, [currentUser?.preferredSubsidiaryCompany, fetchSubsidiaryCompanies]);

  // Set default preferred subsidiary company for first time users
  useEffect(() => {
    if (
      currentUser &&
      !currentUser.preferredSubsidiaryCompany &&
      subsidiaryCompanies?.[0]
    ) {
      message.open({
        type: 'warning',
        content: (
          <>
            <strong>
              {t(
                'components.SubsidiaryCompanyPicker.noSubsidiaryCompanySelected',
              )}
            </strong>
            <br />
            {t(
              'components.SubsidiaryCompanyPicker.pleaseSelectSubsidiaryCompany',
            )}
          </>
        ),
      });
    }
  }, [currentUser, loadingSubsidiaryCompanies, subsidiaryCompanies, t]);

  const setUserPreferredCompany = useCallback(
    async (subsidiaryCompanyId: number) => {
      if (!currentUser) {
        return;
      }

      try {
        setSubmitting(true);

        const { data } = await UserAPI.updateUser(currentUser!.id, {
          email: currentUser.email,
          name: currentUser.name,
          phoneNumber: currentUser.phoneNumber,
          internal: currentUser!.internal,
          maxTravelDistance: currentUser.maxTravelDistance,
          defaultCourseInstanceLimit: currentUser.defaultCourseInstanceLimit,
          preferredSubsidiaryCompanyId: subsidiaryCompanyId,
          preferredLanguageId: currentUser.preferredLanguage?.id,
          marketId: currentUser.market.id,
        });

        setCurrentUser(data);
      } finally {
        setSubmitting(false);
        navigate(0);
      }
    },
    [currentUser, navigate, setCurrentUser],
  );

  return (
    <div>
      <Select
        defaultValue={currentUser?.preferredSubsidiaryCompany?.id}
        className="preferred-subsidiary-selector text-right rounded-md text-base font-medium text-white fill-white"
        popupClassName="w-64"
        bordered={false}
        loading={submitting || loadingSubsidiaryCompanies}
        disabled={submitting || loadingSubsidiaryCompanies}
        popupMatchSelectWidth={false}
        onSelect={(value) => setUserPreferredCompany(value)}
        placeholder={
          <span className="text-white text-base">
            {t('components.SubsidiaryCompanyPicker.selectSubsidiaryCompany')}
          </span>
        }
        suffixIcon={<img src={DownArrow} />}>
        {subsidiaryCompanies?.map((subsidiaryCompany, key) => {
          return (
            <Option key={key} value={subsidiaryCompany.id}>
              {subsidiaryCompany.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SubsidiaryCompanyPicker;
