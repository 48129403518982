import React from 'react';
import { useTranslation } from 'react-i18next';

import ContactPerson from '../types/ContactPerson';
import ClientCompany from '../types/ClientCompany';
import { getFullContactName } from '../utils/contactPerson';

type CreateContactPersonModalProps = {
  clientCompany?: ClientCompany;
  contactPerson?: ContactPerson;
  className?: string;
};

const BookingOrderInformation: React.FC<CreateContactPersonModalProps> = ({
  clientCompany,
  contactPerson,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} flex flex-row bg-grayLight py-5 px-8`}>
      {clientCompany && (
        <div className="flex flex-col gap-0.5 border-solid border-white border-0 border-r pr-10">
          <div className="font-medium text-blueGrayDark">
            {t('components.BookingOrderInformation.company')}
          </div>
          <div className="font-bold">{clientCompany.name}</div>
        </div>
      )}
      {contactPerson && (
        <div className="flex flex-col gap-0.5 pl-10">
          <div className="font-medium text-blueGrayDark">Beställare</div>
          <div className="flex flex-row">
            <div className="font-semibold pr-4">
              {getFullContactName(contactPerson)}
            </div>
            {contactPerson.phoneNumber && (
              <div className="border-solid border-white border-0 border-l px-4">
                {contactPerson.phoneNumber}
              </div>
            )}
            <div className="border-solid border-white border-0 border-l pl-4">
              {contactPerson.email}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingOrderInformation;
