import React, { useState } from 'react';
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  FormInstance,
  InputNumber,
  Modal,
  message,
} from 'antd';
import { SubsidiaryCompanyGoal } from '../types/SubsidiaryCompanyGoal';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { AxiosResponse, isAxiosError } from 'axios';
import { CreateOrUpdateSubsidiaryCompanyGoalParams } from '../SubsidiaryCompanyGoalAPI';
import { useUser } from '../../user/userContext';
import { formatRevenue } from '../utils/currency';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

type PutSubsidiaryCompanyGoalModalProps = {
  subsidiaryCompanyGoal?: SubsidiaryCompanyGoal;
  putMethod: (
    params: CreateOrUpdateSubsidiaryCompanyGoalParams,
  ) => Promise<AxiosResponse<SubsidiaryCompanyGoal>>;
  onSuccess?: (subsidiaryCompanyGoal: SubsidiaryCompanyGoal) => void;
  onCancel?: () => void;
  deleteGoal?: (subsidiaryCompanyGoal?: SubsidiaryCompanyGoal) => Promise<void>;
};

type PutSubsidiaryCompanyGoalFormValues = {
  activeMonth: Dayjs;
  goalNumberOfCourseInstances?: number;
  goalRevenue?: number;
};

const PutSubsidiaryCompanyGoalModal: React.FC<
  PutSubsidiaryCompanyGoalModalProps
> = ({ subsidiaryCompanyGoal, putMethod, onSuccess, onCancel, deleteGoal }) => {
  const { t } = useTranslation();

  const [currentUser] = useUser();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const submit = () => {
    form.submit();
  };

  const onFinish = async (values: PutSubsidiaryCompanyGoalFormValues) => {
    setSubmitting(true);
    try {
      const params: CreateOrUpdateSubsidiaryCompanyGoalParams = {
        activeMonth: values.activeMonth.format('YYYY-MM'),
        goalNumberOfCourseInstances: values.goalNumberOfCourseInstances,
        goalRevenue: values.goalRevenue,
      };

      const { data: goal } = await putMethod(params);

      onSuccess?.(goal);
    } catch (err) {
      if (isAxiosError(err) && !err.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onChange: DatePickerProps['onChange'] = (date) => {
    form.setFieldValue('activeMonth', date);
  };

  const validateFields = (formInstance: FormInstance) => ({
    message: t('components.PutSubsidiaryCompanyGoalModal.mustSelectAtLeastOne'),
    validator() {
      if (
        formInstance.getFieldValue('goalRevenue') === undefined &&
        formInstance.getFieldValue('goalNumberOfCourseInstances') === undefined
      ) {
        return Promise.reject(new Error());
      }
      return Promise.resolve();
    },
  });

  const currencyCode =
    currentUser?.preferredSubsidiaryCompany?.market.defaultCurrency.code;

  return (
    <Modal
      title={t('components.PutSubsidiaryCompanyGoalModal.newGoal')}
      open
      onCancel={onCancel}
      onOk={submit}
      footer={
        <div className="flex flex-row items-center">
          {userHasPermission(
            currentUser,
            Permission.SUBSIDIARY_COMPANY_GOAL_DELETE,
          ) &&
            subsidiaryCompanyGoal && (
              <Button
                key="delete"
                type="primary"
                onClick={async () => {
                  await deleteGoal?.(subsidiaryCompanyGoal);
                }}>
                {t('common.delete')}
              </Button>
            )}
          <Button key="back" onClick={onCancel} className="ml-auto">
            {t('common.close')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={submitting}
            form="putSubsidiaryCompanyGoalForm"
            htmlType="submit">
            {t('common.save')}
          </Button>
        </div>
      }
      maskClosable={false}>
      <Form
        id="putSubsidiaryCompanyGoalForm"
        form={form}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
        onFinish={(values) => onFinish(values)}>
        <Form.Item
          label={t('components.PutSubsidiaryCompanyGoalModal.activeMonth')}
          name="activeMonth"
          initialValue={
            subsidiaryCompanyGoal?.activeMonth
              ? dayjs(subsidiaryCompanyGoal.activeMonth)
              : undefined
          }
          rules={[
            {
              required: true,
              type: 'date',
              message: t('components.PutSubsidiaryCompanyGoalModal.enterMonth'),
            },
          ]}>
          <DatePicker
            onChange={onChange}
            picker="month"
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          label={t('components.PutSubsidiaryCompanyGoalModal.revenue')}
          name="goalRevenue"
          initialValue={subsidiaryCompanyGoal?.goalRevenue}
          rules={[
            {
              type: 'number',
            },
            (formInstance) => validateFields(formInstance as FormInstance),
          ]}>
          <InputNumber
            min={0}
            addonAfter={currencyCode}
            style={{ width: '100%' }}
            formatter={(value) => formatRevenue(value)}
          />
        </Form.Item>

        <Form.Item
          label={t(
            'components.PutSubsidiaryCompanyGoalModal.numberOfCourseInstances',
          )}
          name="goalNumberOfCourseInstances"
          initialValue={subsidiaryCompanyGoal?.goalNumberOfCourseInstances}
          rules={[
            {
              type: 'number',
            },
            (formInstance) => validateFields(formInstance as FormInstance),
          ]}>
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PutSubsidiaryCompanyGoalModal;
