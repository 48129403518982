import client from '../api/client';
import dayjs from 'dayjs';
import {
  CourseInstanceStatusXMode,
  CourseInstanceStatusYMode,
  CourseInstanceStatusDataResponse,
} from './types/CourseInstanceStatus';
import {
  CourseInstanceLeadTimeDataResponse,
  CourseInstanceLeadTimeXMode,
} from './types/CourseInstanceLeadTime';
import { SubsidiaryCompanyGoal } from '../subsidiaryCompany/types/SubsidiaryCompany';

const getCourseInstanceStatusData = (
  xMode: CourseInstanceStatusXMode,
  yMode: CourseInstanceStatusYMode,
  fromDate: dayjs.Dayjs,
  toDate: dayjs.Dayjs,
  marketId?: number,
  subsidiaryCompanyIds?: number[],
  clientCompanyIds?: number[],
  instructorIds?: number[],
  courseIds?: number[],
  responsibleBookerIds?: number[],
  invoiceRecipientIds?: number[],
) =>
  client.get<CourseInstanceStatusDataResponse>(
    '/insights/course-instance-status-data',
    {
      params: {
        xMode,
        yMode,
        fromDate: fromDate.format('YYYY-MM-DD'),
        toDate: toDate.format('YYYY-MM-DD'),
        marketId,
        subsidiaryCompanyIds,
        clientCompanyIds,
        instructorIds,
        courseIds,
        responsibleBookerIds,
        invoiceRecipientIds,
      },
    },
  );

const getCourseInstanceLeadTimeData = (
  xMode: CourseInstanceLeadTimeXMode,
  fromDate: dayjs.Dayjs,
  toDate: dayjs.Dayjs,
  marketId?: number,
  subsidiaryCompanyIds?: number[],
  clientCompanyIds?: number[],
  instructorIds?: number[],
  courseIds?: number[],
  responsibleBookerIds?: number[],
  invoiceRecipientIds?: number[],
) =>
  client.get<CourseInstanceLeadTimeDataResponse>(
    '/insights/course-instance-lead-time-data',
    {
      params: {
        xMode,
        fromDate: fromDate.format('YYYY-MM-DD'),
        toDate: toDate.format('YYYY-MM-DD'),
        marketId,
        subsidiaryCompanyIds,
        clientCompanyIds,
        instructorIds,
        courseIds,
        responsibleBookerIds,
        invoiceRecipientIds,
      },
    },
  );

const getSubsidiaryCompanyGoals = (
  subsidiaryCompanyId: number,
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
) =>
  client.get<SubsidiaryCompanyGoal[]>(
    `subsidiary-companies/${subsidiaryCompanyId}/goals/`,
    {
      params: {
        subsidiaryCompanyId,
        from: from.format('YYYY-MM'),
        to: to.format('YYYY-MM'),
      },
    },
  );

export default {
  getCourseInstanceStatusData,
  getCourseInstanceLeadTimeData,
  getSubsidiaryCompanyGoals,
};
