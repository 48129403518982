import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Select,
  Button,
  DatePicker,
  Empty,
  Switch,
  Row,
  Col,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import Course from '../../course/types/Course';
import User, { UserRole } from '../../user/types/User';
import UserAPI from '../../user/UserAPI';
import { useUser } from '../../user/userContext';
import { sortInstructorsByFavorite } from '../../user/userUtils';
import CourseAPI from '../../course/CourseAPI';
import AddressSearchInput, {
  PlaceSuggestion,
} from '../../components/AddressSearchInput';
import UserOption from '../../components/UserOption';
import NumberPicker from '../../components/NumberPicker';
import CourseOption from '../../components/CourseOption';
import ConditionalTooltip from '../../components/ConditionalTooltip';
import { SearchParams } from '../SearchAPI';

type FilterFormValues = {
  courseId?: Course['id'];
  location?: PlaceSuggestion;
  userIds?: User['id'][];
  minLength?: number;
  from?: dayjs.Dayjs;
  to?: dayjs.Dayjs;
  internal?: boolean;
  maxDistance?: boolean;
};

const defaultFormValues: FilterFormValues = {
  internal: false,
  maxDistance: true,
  minLength: 3,
};

type SearchFilterProps = {
  onSearch: (
    filter: SearchParams,
    placeSuggestion?: PlaceSuggestion | null,
    course?: Course,
  ) => void;
  disableListSearch: boolean;
  initialFilterValues?: FilterFormValues;
  loading?: boolean;
};

const SearchFilter: React.FC<SearchFilterProps> = ({
  onSearch,
  disableListSearch,
  initialFilterValues,
  loading,
}) => {
  const [form] = Form.useForm();
  const [courses, setCourses] = useState<Course[]>([]);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [instructors, setInstructors] = useState<User[]>([]);
  const [loadingInstructors, setLoadingInstructors] = useState<boolean>(false);
  const [placeSuggestion, setPlaceSuggestion] =
    useState<PlaceSuggestion | null>();
  const [placeId, setPlaceId] = useState<string>();
  const [toTouched, setToTouched] = useState<boolean>(false);
  const [currentUser] = useUser();

  const { t } = useTranslation();

  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const { data } = await CourseAPI.getCourses();
      setCourses(data);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  const fetchInstructors = useCallback(async () => {
    setLoadingInstructors(true);
    try {
      const { data } = await UserAPI.getUsers({
        roles: [UserRole.Instructor],
      });
      const sortedInstructors = sortInstructorsByFavorite(data, currentUser);
      setInstructors(sortedInstructors);
    } finally {
      setLoadingInstructors(false);
    }
  }, [currentUser]);

  useEffect(() => {
    fetchCourses();
    fetchInstructors();
    if (initialFilterValues?.location) {
      setPlaceId(initialFilterValues?.location.placeId);
      setPlaceSuggestion(initialFilterValues?.location);
    }
  }, [fetchCourses, fetchInstructors, initialFilterValues?.location]);

  const onFinish = useCallback(
    async (values: FilterFormValues) => {
      const searchParams: SearchParams = {
        courseId: values.courseId!,
        userIds: values.userIds!,
        minLength: values?.minLength || undefined,
        lat: values.location!.geolocation.lat!,
        lng: values.location!.geolocation.lng!,
        placeId: values.location!.placeId,
        from: values.from?.startOf('day').toISOString(),
        to: values.to?.endOf('day').toISOString(),
        internal: values.internal,
        maxDistance: values.maxDistance,
        useCourseInstanceLimit: true,
        fetchMileage: true,
      };

      onSearch?.(
        searchParams,
        placeSuggestion,
        courses.find((x) => x.id === values.courseId),
      );
    },
    [courses, onSearch, placeSuggestion],
  );

  const setToValue = useCallback(
    (value?: Dayjs | null) => {
      if (
        !toTouched &&
        form.getFieldValue('from') !== undefined &&
        value != undefined
      ) {
        const toDate = dayjs(value).add(7, 'days').endOf('day');
        form.setFieldsValue({ to: toDate });
      }
      if (form.getFieldValue('to') === undefined) {
        setToTouched(false);
      }
    },
    [form, toTouched],
  );

  const onPlaceSuggestionChange = useCallback(
    (suggestion?: PlaceSuggestion | null) => {
      setPlaceSuggestion(suggestion || null);
    },
    [],
  );

  const gutter = 16;

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => onFinish(values as FilterFormValues)}
      className="w-full"
      initialValues={{
        ...defaultFormValues,
        courseId: initialFilterValues?.courseId,
        from: dayjs(initialFilterValues?.from),
        to: dayjs(initialFilterValues?.from)?.add(7, 'days').endOf('day'),
      }}>
      <Row gutter={gutter} className="my-5">
        <Col xs={24} md={12}>
          <Form.Item
            label={t('common.course')}
            name="courseId"
            rules={[
              {
                required: true,
                message: t('components.SearchFilter.selectCourse'),
              },
            ]}>
            <Select
              showSearch
              size="large"
              placeholder={t('components.SearchFilter.selectCourse')}
              loading={loadingCourses}
              filterOption={(input, option) =>
                option?.props.children.props.course.name
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('components.SearchFilter.noCoursesFound')}
                />
              }>
              {courses?.map((course) => (
                <Select.Option key={course.id} value={course.id}>
                  <CourseOption
                    user={currentUser}
                    course={course}
                    size="large"
                  />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('common.location')}
            name="location"
            rules={[
              {
                required: true,
                message: t('components.SearchFilter.fillOutAddress'),
              },
            ]}>
            <AddressSearchInput
              placeholder={t('common.address')}
              size="large"
              placeId={placeId}
              onChange={onPlaceSuggestionChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col xs={12} md={6}>
          <ConditionalTooltip
            component={
              <Form.Item
                label={t('components.SearchFilter.from')}
                name="from"
                rules={[
                  {
                    required: !disableListSearch,
                    message: t('components.SearchFilter.selectDate'),
                  },
                ]}>
                <DatePicker
                  size="large"
                  format="YYYY-MM-DD"
                  className="w-full"
                  onChange={(value) => setToValue(value)}
                  disabled={disableListSearch}
                  disabledDate={(currentDate) =>
                    currentDate < dayjs().startOf('day')
                  }
                />
              </Form.Item>
            }
            condition={disableListSearch}
            displayTitle={t('components.SearchFilter.notUsedInCalendar')}
          />
        </Col>
        <Col xs={12} md={6}>
          <ConditionalTooltip
            component={
              <Form.Item
                label={t('components.SearchFilter.to')}
                name="to"
                rules={[
                  {
                    required: !disableListSearch,
                    message: t('components.SearchFilter.selectDate'),
                  },
                ]}>
                <DatePicker
                  size="large"
                  format="YYYY-MM-DD"
                  className="w-full"
                  onClick={() => setToTouched(true)}
                  onChange={() => setToValue()}
                  disabled={disableListSearch}
                  disabledDate={(currentDate) =>
                    currentDate < dayjs().startOf('day')
                  }
                />
              </Form.Item>
            }
            condition={disableListSearch}
            displayTitle={t('components.SearchFilter.notUsedInCalendar')}
          />
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('components.SearchFilter.smallestLength')}
            name="minLength">
            <NumberPicker size="large" min={0} max={24} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('common.instructor')}
            name="userIds"
            rules={[{ required: false }]}>
            <Select
              mode="multiple"
              showSearch
              size="large"
              placeholder={t('components.SearchFilter.selectInstructor')}
              loading={loadingInstructors}
              allowClear
              filterOption={(input, option) =>
                option?.props.children.props.user.name
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t('components.SearchFilter.noInstructorsFound')}
                />
              }>
              {instructors?.map(
                (instructor) =>
                  instructor.id && (
                    <Select.Option key={instructor.id} value={instructor.id}>
                      <UserOption user={instructor} currentUser={currentUser} />
                    </Select.Option>
                  ),
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            label={t('components.SearchFilter.onlyInhouseInstructors')}
            name="internal"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
        <Col xs={12} md={6}>
          <Form.Item
            label={t('components.SearchFilter.maxDistance')}
            name="maxDistance"
            valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <div className="flex justify-end">
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          {t('components.SearchFilter.search')}
        </Button>
      </div>
    </Form>
  );
};

export default SearchFilter;
