import client from '../api/client';
import { SubsidiaryCompanyGoal } from './types/SubsidiaryCompanyGoal';

const getSubsidiaryCompanyGoals = (subsidiaryCompanyId: number) =>
  client.get<SubsidiaryCompanyGoal[]>(
    `/subsidiary-companies/${subsidiaryCompanyId}/goals`,
    {},
  );

export type CreateOrUpdateSubsidiaryCompanyGoalParams = {
  activeMonth: string;
  goalNumberOfCourseInstances?: number;
  goalRevenue?: number;
};

const createSubsidiaryCompanyGoal = (
  subsidiaryCompanyId: number,
  params: CreateOrUpdateSubsidiaryCompanyGoalParams,
) =>
  client.post<SubsidiaryCompanyGoal>(
    `/subsidiary-companies/${subsidiaryCompanyId}/goals`,
    params,
  );

const updateSubsidiaryCompanyGoal = (
  subsidiaryCompanyId: number,
  goalId: number,
  params: CreateOrUpdateSubsidiaryCompanyGoalParams,
) =>
  client.patch<SubsidiaryCompanyGoal>(
    `/subsidiary-companies/${subsidiaryCompanyId}/goals/${goalId}`,
    params,
  );

const deleteSubsidiaryCompanyGoal = (
  subsidiaryCompanyId: number,
  goalId: number,
) =>
  client.delete(`/subsidiary-companies/${subsidiaryCompanyId}/goals/${goalId}`);

export default {
  getSubsidiaryCompanyGoals,
  createSubsidiaryCompanyGoal,
  updateSubsidiaryCompanyGoal,
  deleteSubsidiaryCompanyGoal,
};
