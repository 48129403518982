import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Row } from 'antd';

const NoCourseInstances: React.FC = () => {
  return (
    <div className="w-full h-full ml-[23%] content-center text-center">
      <Row className="justify-center">
        <SearchOutlined className="mr-4" style={{ fontSize: '180%' }} />
        <span className="text-3xl">No course instances were found</span>
      </Row>
      <Row className="justify-center">
        <span className="text-lg">Try changing the filters</span>
      </Row>
    </div>
  );
};

export default NoCourseInstances;
