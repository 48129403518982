import React, { useMemo } from 'react';
import { Alert } from 'antd';
import {
  DistanceCategory,
  formatDistance,
  getDistanceCategory,
} from '../../utils/distance';
import Mileage from '../../search/types/Mileage';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';

type DistanceWithType = {
  distance: number;
  distanceType: 'linear' | 'mileage';
};

type MaxTravelDistanceAlertProps = {
  mileage?: Mileage;
  maxTravelDistance: number;
  distance: number;
  className?: string;
};

const MaxTravelDistanceAlert: React.FC<MaxTravelDistanceAlertProps> = ({
  mileage,
  maxTravelDistance,
  distance,
  className,
}) => {
  const { t } = useTranslation();

  const distanceWithType = useMemo<DistanceWithType>(() => {
    if (mileage !== undefined) {
      return {
        distance: mileage.distanceInMeters,
        distanceType: 'mileage',
      };
    } else {
      return {
        distance: distance,
        distanceType: 'linear',
      };
    }
  }, [mileage, distance]);

  const alertMessage = useMemo(() => {
    if (distanceWithType?.distanceType === 'mileage') {
      return t('components.CreateCourseInstanceModal.drivingDistance', {
        distance: formatDistance(distanceWithType.distance, t),
        instructorMax: maxTravelDistance,
      });
    } else if (distanceWithType?.distanceType === 'linear') {
      return t('components.CreateCourseInstanceModal.directDistance', {
        distance: formatDistance(distanceWithType.distance, t),
        instructorMax: maxTravelDistance,
      });
    }
  }, [maxTravelDistance, t, distanceWithType]);

  const alertType = useMemo(() => {
    switch (
      getDistanceCategory(distanceWithType.distance, maxTravelDistance * 1000)
    ) {
      case DistanceCategory.WithinLimit:
        return 'success';
      case DistanceCategory.SlightlyOverLimit:
        return 'warning';
      case DistanceCategory.ExceedsLimit:
        return 'error';
    }
  }, [maxTravelDistance, distanceWithType]);

  return (
    <div className={className}>
      <Alert
        message={alertMessage}
        type={alertType}
        showIcon={alertType === 'error'}
        icon={<WarningOutlined />}
      />
    </div>
  );
};

export default MaxTravelDistanceAlert;
