import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import UserAPI from '../../user/UserAPI';
import User, {
  CardDataUser,
  MinimalUser,
  UserRole,
} from '../../user/types/User';
import Course from '../../course/types/Course';
import {
  getMinimalUser,
  sortInstructorsByFavorite,
} from '../../user/userUtils';
import { useUser } from '../../user/userContext';
import {
  CourseInstanceCardData,
  CourseInstanceStatus,
} from '../types/CourseInstance';
import CourseAPI from '../../course/CourseAPI';
import UserOption from '../../components/UserOption';
import CourseOption from '../../components/CourseOption';

import SwapInstructorModal from './SwapInstructorModal';

export enum FormType {
  SelectInstructorForm = 'instructorId',
  SwapInstructorForm = 'swapInstructor',
  SelectCourseForm = 'courseId',
  ConfirmForm = 'confirm',
  CancelForm = 'cancel',
  DeleteForm = 'delete',
}

interface InstructorForm {
  formType: FormType.SelectInstructorForm;
  instructor: CardDataUser;
}

export type FormValues = InstructorForm;

export interface EditableFormValues {
  instructor?: CardDataUser | null;
  course?: Course;
}

const getTitle = (
  t: TFunction,
  formType?: FormType,
  courseInstanceStatus?: CourseInstanceStatus,
) => {
  switch (formType) {
    case FormType.SelectInstructorForm:
      return t('components.QuickActionEditModal.selectInstructor');
    case FormType.SelectCourseForm:
      return t('components.QuickActionEditModal.selectCourse');
    case FormType.CancelForm:
      return courseInstanceStatus !== CourseInstanceStatus.Canceled
        ? t('components.QuickActionEditModal.cancelCourseInstance')
        : t('components.QuickActionEditModal.undoCancelCourseInstance');
    case FormType.ConfirmForm:
      return courseInstanceStatus !== CourseInstanceStatus.Confirmed
        ? t('components.QuickActionEditModal.confirmCourseInstance')
        : t('components.QuickActionEditModal.undoConfirmCourseInstance');
  }
};

const getSubtitle = (
  t: TFunction,
  formType?: FormType,
  courseInstanceStatus?: CourseInstanceStatus,
) => {
  switch (formType) {
    case FormType.SelectInstructorForm:
      return t('components.QuickActionEditModal.selectInstructorSubtitle');
    case FormType.SelectCourseForm:
      return t('components.QuickActionEditModal.selectCourseSubtitle');
    case FormType.CancelForm:
      return courseInstanceStatus !== CourseInstanceStatus.Canceled
        ? t('components.QuickActionEditModal.cancelCourseInstanceSubtitle')
        : t('components.QuickActionEditModal.undoCancelCourseInstanceSubtitle');
    case FormType.ConfirmForm:
      return courseInstanceStatus !== CourseInstanceStatus.Confirmed
        ? t('components.QuickActionEditModal.confirmCourseInstanceSubtitle')
        : t(
            'components.QuickActionEditModal.undoConfirmCourseInstanceSubtitle',
          );
  }
};

type QuickActionEditModalProps = {
  courseInstance: CourseInstanceCardData;
  formType?: FormType;
  visible: boolean;
  setShowModal?: (value: boolean) => void;
  onConfirm?: (values: EditableFormValues, formType: FormType) => void;
  toggleCourseInstanceConfirmed?: () => void;
  toggleCourseInstanceCanceled?: () => void;
  deleteCourseInstance?: () => void;
  updatingInstructor?: boolean;
  updatingCourse?: boolean;
  updatingStatus?: boolean;
  deletingCourseInstance?: boolean;
  onFilterChange?: () => void;
};

const QuickActionEditModal: React.FC<QuickActionEditModalProps> = ({
  courseInstance,
  formType,
  visible,
  setShowModal,
  onConfirm,
  toggleCourseInstanceConfirmed,
  toggleCourseInstanceCanceled,
  deleteCourseInstance,
  updatingInstructor,
  updatingCourse,
  updatingStatus,
  deletingCourseInstance,
  onFilterChange,
}) => {
  const initialFormValues = { instructor: courseInstance.instructor };
  const [currentFormValues, setCurrentFormValues] = useState<
    EditableFormValues | undefined
  >(initialFormValues);

  const { t } = useTranslation();

  const displayForm = (form?: string) => {
    switch (form) {
      case FormType.SelectInstructorForm:
        return (
          <ModalForm
            formType={FormType.SelectInstructorForm}
            onChange={setCurrentFormValues}
            currentFormValues={currentFormValues}
          />
        );
      case FormType.SelectCourseForm:
        return (
          <ModalForm
            formType={FormType.SelectCourseForm}
            onChange={setCurrentFormValues}
            currentFormValues={currentFormValues}
          />
        );
      case FormType.DeleteForm:
        return <ModalForm formType={FormType.DeleteForm} />;
    }
  };

  switch (formType) {
    case 'swapInstructor':
      return (
        <SwapInstructorModal
          firstCourseInstance={courseInstance}
          setShowModal={setShowModal}
          onSubmit={onFilterChange}
        />
      );
    case 'confirm':
      return (
        <Modal
          title={getTitle(t, FormType.ConfirmForm, courseInstance.status)}
          open={visible}
          onCancel={() => {
            setShowModal?.(false);
          }}
          footer={[
            <Button key="cancel" onClick={() => setShowModal?.(false)}>
              {t('common.cancel')}
            </Button>,
            <Button
              key="confirm"
              type="primary"
              loading={updatingStatus}
              onClick={toggleCourseInstanceConfirmed}>
              {courseInstance.status !== CourseInstanceStatus.Confirmed
                ? t('common.confirm')
                : t('components.QuickActionEditModal.undoConfirm')}
            </Button>,
          ]}
          maskClosable={false}>
          {getSubtitle(t, FormType.ConfirmForm, courseInstance.status)}
        </Modal>
      );
    case 'cancel':
      return (
        <Modal
          title={getTitle(t, FormType.CancelForm, courseInstance.status)}
          open={visible}
          onCancel={() => {
            setShowModal?.(false);
          }}
          footer={[
            <Button key="cancel" onClick={() => setShowModal?.(false)}>
              {t('common.cancel')}
            </Button>,
            <Button
              key="cancelCourseInstance"
              type="primary"
              loading={updatingStatus}
              onClick={toggleCourseInstanceCanceled}>
              {courseInstance.status !== CourseInstanceStatus.Canceled
                ? t('components.QuickActionEditModal.cancel')
                : t('components.QuickActionEditModal.undoCancel')}
            </Button>,
          ]}
          maskClosable={false}>
          {getSubtitle(t, FormType.CancelForm, courseInstance.status)}
        </Modal>
      );
    case 'delete':
      return (
        <Modal
          title={t('components.QuickActionEditModal.deleteCourseInstance')}
          open={visible}
          onCancel={() => {
            setShowModal?.(false);
          }}
          footer={[
            <Button key="cancel" onClick={() => setShowModal?.(false)}>
              {t('common.cancel')}
            </Button>,
            <Button
              danger
              key="delete"
              type="primary"
              loading={deletingCourseInstance}
              onClick={deleteCourseInstance}>
              {t('common.delete')}
            </Button>,
          ]}
          maskClosable={false}>
          {t('components.QuickActionEditModal.confirmDeleteCourseInstance')}
        </Modal>
      );
    default:
      return (
        <Modal
          title={getTitle(t, formType)}
          open={visible}
          onOk={
            currentFormValues &&
            formType &&
            (() => onConfirm?.(currentFormValues, formType))
          }
          okButtonProps={{
            disabled: isEqual(initialFormValues, currentFormValues),
            loading: updatingInstructor || updatingCourse,
          }}
          onCancel={() => {
            setShowModal?.(false);
            if (setCurrentFormValues) setCurrentFormValues(undefined);
          }}
          maskClosable={false}>
          <p>{getSubtitle(t, formType)}</p>
          {displayForm(formType)}
        </Modal>
      );
  }
};

const instructorSelector = (
  t: TFunction,
  loading: boolean,
  instructors?: MinimalUser[],
  currentUser?: User | null,
) => {
  return (
    <Select
      placeholder={t('components.QuickActionEditModal.selectInstructor')}
      showSearch
      allowClear
      loading={loading}
      filterOption={(input, option) =>
        option?.props.children.props.user?.name
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }>
      {
        <>
          <Select.Option key={0} value={0}>
            <UserOption
              user={undefined}
              noUserText={t('components.QuickActionEditModal.noInstructor')}
            />
          </Select.Option>
          {instructors?.map(
            (instructor) =>
              instructor.id && (
                <Select.Option key={instructor.id} value={instructor.id}>
                  <UserOption user={instructor} currentUser={currentUser} />
                </Select.Option>
              ),
          )}
        </>
      }
    </Select>
  );
};

const courseSelector = (
  t: TFunction,
  loading: boolean,
  currentUser: User | null,
  courses?: Course[],
) => {
  return (
    <Select
      placeholder={t('components.QuickActionEditModal.selectCourse')}
      showSearch
      allowClear
      loading={loading}
      filterOption={(input, option) =>
        option?.props.children.props.course.name
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }>
      {courses?.map((course) => (
        <Select.Option key={course.id} value={course.id}>
          <CourseOption user={currentUser} course={course} />
        </Select.Option>
      ))}
    </Select>
  );
};

const ModalForm: React.FC<{
  onChange?: (values: EditableFormValues) => void;
  formType: FormType;
  currentFormValues?: EditableFormValues;
}> = ({ onChange, formType, currentFormValues }) => {
  const [form] = Form.useForm();
  const [instructors, setInstructors] = useState<MinimalUser[]>();
  const [courses, setCourses] = useState<Course[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser] = useUser();

  const { t } = useTranslation();

  const fetchInstructors = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await UserAPI.getUsers({ roles: [UserRole.Instructor] });
      const sortedInstructors = sortInstructorsByFavorite(data, currentUser);
      setInstructors(
        sortedInstructors.map((instructor) => getMinimalUser(instructor)),
      );
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  const fetchCourses = useCallback(async (instructorId?: User['id']) => {
    setLoading(true);
    try {
      const { data } = await CourseAPI.getCourses({
        userIds: instructorId ? [instructorId] : undefined,
      });
      setCourses(data);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      instructorId: currentFormValues?.instructor?.id ?? 0,
    });
  }, [currentFormValues, form]);

  useEffect(() => {
    switch (formType) {
      case FormType.SelectInstructorForm:
        fetchInstructors();
        break;
      case FormType.SelectCourseForm:
        fetchCourses(currentFormValues?.instructor?.id);
        break;
    }
  }, [
    currentFormValues?.instructor?.id,
    fetchCourses,
    fetchInstructors,
    formType,
  ]);

  const selectorOption = useCallback(() => {
    switch (formType) {
      case FormType.SelectInstructorForm:
        return instructorSelector(t, loading, instructors, currentUser);
      case FormType.SelectCourseForm:
        return courseSelector(t, loading, currentUser, courses);
    }
  }, [courses, currentUser, formType, instructors, loading, t]);

  return (
    <>
      <Form
        form={form}
        onValuesChange={(values) => {
          onChange?.({
            instructor:
              values.instructorId !== undefined
                ? values.instructorId === 0
                  ? null
                  : instructors?.find(
                      (instructor) => instructor.id === values.instructorId,
                    )
                : currentFormValues?.instructor,
            course: values.courseId
              ? courses?.find((course) => course.id === values.courseId)
              : currentFormValues?.course,
          });
        }}>
        <Form.Item name={`${formType}`}>{selectorOption()}</Form.Item>
      </Form>
    </>
  );
};

export default QuickActionEditModal;
