import React, { useCallback, useState } from 'react';
import { message, Modal, Form, Input, Button } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import ContactPersonAPI from '../api/ContactPersonAPI';
import ContactPerson from '../types/ContactPerson';

type CreateContactPersonModalProps = {
  title?: string;
  visible: boolean;
  onCreate?: (contactPerson: ContactPerson) => void;
  onCancel?: () => void;
};

const CreateContactPersonModal: React.FC<CreateContactPersonModalProps> = ({
  title,
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { t } = useTranslation();

  const onFinish = useCallback(
    async (values: ContactPerson) => {
      setSubmitting(true);
      try {
        const { data: contactPerson } =
          await ContactPersonAPI.createContactPerson({
            firstName: values.firstName,
            lastName: values.lastName || undefined,
            email: values.email,
            phoneNumber: values.phoneNumber,
            note: values.note,
          });
        onCreate?.(contactPerson);
        form.resetFields();
      } catch (error) {
        if (isAxiosError(error) && !error.response?.data.message) {
          message.error(t('errors.somethingWentWrong'));
        }
      } finally {
        setSubmitting(false);
      }
    },
    [form, onCreate, t],
  );

  return (
    <Modal
      title={
        title || t('components.CreateContactPersonModal.createNewContactPerson')
      }
      open={visible}
      onCancel={onCancel}
      onOk={form.submit}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createContactPersonForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createContactPersonForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <div className="flex gap-5 w-full">
          <Form.Item
            name="firstName"
            label={t('common.firstName')}
            className="w-1/2"
            rules={[
              {
                required: true,
                message: t('components.CreateContactPersonModal.fillOutName'),
              },
            ]}>
            <Input placeholder={t('common.firstName')} />
          </Form.Item>
          <Form.Item
            name="lastName"
            className="w-1/2"
            label={t('common.lastName')}>
            <Input placeholder={t('common.lastName')} />
          </Form.Item>
        </div>
        <Form.Item
          name="email"
          label={t('common.email')}
          rules={[
            {
              required: true,
              type: 'email',
              message: t('components.CreateContactPersonModal.invalidEmail'),
            },
          ]}>
          <Input placeholder={t('common.email')} />
        </Form.Item>
        <Form.Item name="phoneNumber" label={t('common.phoneNumber')}>
          <Input placeholder={t('common.phoneNumber')} />
        </Form.Item>
        <Form.Item name="note" label={t('common.note')}>
          <Input placeholder={t('common.note')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateContactPersonModal;
