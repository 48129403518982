import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CertificateLanguage } from '../../certificate/types/Certificate';
import CertificateAPI from '../../certificate/CertificateAPI';
import { ColumnsType } from '../../types/ColumnTypes';
import Table from '../../components/Table';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateCertificateLanguageModal from '../components/CreateCertificateLanguageModal';
import { Outlet } from 'react-router-dom';
import { Permission } from '../../user/types/Permission';
import { userHasPermission } from '../../user/userUtils';
import { useUser } from '../../user/userContext';

const CertificateLanguages: React.FC = () => {
  const [languages, setLangugages] = useState<CertificateLanguage[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { t } = useTranslation();
  const [currentUser] = useUser();

  const fetchLanguages = useCallback(() => {
    CertificateAPI.getCertificatesLanguages().then(({ data }) => {
      setLangugages(data);
    });
  }, []);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const columns: ColumnsType<CertificateLanguage> = useMemo(
    () => [
      {
        width: '50%',
        title: t('views.CertificateLanguages.columns.name'),
        render: (language) => (
          <div className="overflow-hidden text-ellipsis">{language.name}</div>
        ),
      },
      {
        width: '50%',
        title: t('views.CertificateLanguages.columns.code'),
        render: (language) => (
          <div className="overflow-hidden text-ellipsis">{language.code}</div>
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <div className="flex justify-between">
        <h2>{t('views.CertificateLanguages.title')}</h2>
        {userHasPermission(
          currentUser,
          Permission.CERTIFICATE_LANGUAGE_CREATE,
        ) && (
          <>
            <Button
              type="primary"
              className="text-sm font-medium"
              icon={<PlusOutlined />}
              onClick={() => setShowCreateModal(true)}>
              {t('views.CertificateLanguages.newLanguage')}
            </Button>
            <CreateCertificateLanguageModal
              visible={showCreateModal}
              onCancel={() => setShowCreateModal(false)}
              onCreate={(language) => setLangugages([...languages, language])}
            />
          </>
        )}
      </div>

      <Table
        rowKey={(language) => language.id}
        columns={columns}
        rows={languages}
        loading={!!languages.length}
        getRowLink={(language) => `${language.id}`}
      />

      <Outlet
        context={{
          onChange: fetchLanguages,
          certificateLanguages: languages,
        }}
      />
    </>
  );
};

export default CertificateLanguages;
