export enum CourseInstanceLeadTime {
  CREATED = 'CREATED',
  CONFIRMED = 'CONFIRMED',
  INVOICED = 'INVOICED',
}

export enum CourseInstanceLeadTimeStatistic {
  AVERAGE_DAYS_TO_CONFIRMED = 'AVERAGE_DAYS_TO_CONFIRMED',
  AVERAGE_DAYS_TO_COMPLETED = 'AVERAGE_DAYS_TO_COMPLETED',
  AVERAGE_DAYS_CONFIRMED_TO_INVOICED = 'AVERAGE_DAYS_CONFIRMED_TO_INVOICED',
  NOT_INVOICED = 'NOT_INVOICED',
  INVOICED_IN_OTHER_MONTH = 'INVOICED_IN_OTHER_MONTH',
}

export enum CourseInstanceLeadTimeXMode {
  BOOKING_CREATED = 'BOOKING_CREATED',
  COURSE_INSTANCE_DATE = 'COURSE_INSTANCE_DATE',
}

export enum CourseInstanceLeadTimeYMode {
  NUMBER_OF_CLASSES = 'NUMBER_OF_CLASSES',
}

export const CourseInstanceLeadTimeColor = {
  [CourseInstanceLeadTime.CREATED]: '#722ED1',
  [CourseInstanceLeadTime.CONFIRMED]: '#36CFC9',
  [CourseInstanceLeadTime.INVOICED]: '#FA8C16',
};

export type CourseInstanceLeadTimeDataResponse = {
  graphs: {
    id: CourseInstanceLeadTime;
    data: number[];
  }[];
  statistics: Map<CourseInstanceLeadTimeStatistic, number>;
  from: string;
  to: string;
  marketId: string;
  xMode: CourseInstanceLeadTimeXMode;
};

export type CourseInstanceLeadTimeData = {
  graphs: {
    id: CourseInstanceLeadTime;
    label: string;
    data: { x: Date; y: number }[];
    color: string;
  }[];
  statistics: Map<CourseInstanceLeadTimeStatistic, number | null>;
};
