export enum CourseInstanceStatus {
  TOTAL = 'TOTAL',
  PRELIMINARY = 'PRELIMINARY',
  CONFIRMED = 'CONFIRMED',
  INVOICED = 'INVOICED',
  CANCELED = 'CANCELED',
}

export enum CourseInstanceStatusStatistic {
  TOTAL = 'TOTAL',
  PRELIMINARY = 'PRELIMINARY',
  CONFIRMED = 'CONFIRMED',
  INVOICED = 'INVOICED',
  WITHOUT_PRICE = 'WITHOUT_PRICE',
}

export enum CourseInstanceStatusXMode {
  BOOKING_CREATED = 'BOOKING_CREATED',
  COURSE_INSTANCE_DATE = 'COURSE_INSTANCE_DATE',
}

export enum CourseInstanceStatusYMode {
  NUMBER_OF_CLASSES = 'NUMBER_OF_CLASSES',
  REVENUE = 'REVENUE',
}

export const CourseInstanceStatusColor = {
  [CourseInstanceStatus.TOTAL]: '#1677FF',
  [CourseInstanceStatus.PRELIMINARY]: '#8A57D7',
  [CourseInstanceStatus.CONFIRMED]: '#72C140',
  [CourseInstanceStatus.INVOICED]: '#F3AD56',
  [CourseInstanceStatus.CANCELED]: '#F5222D',
};

export type CourseInstanceStatusDataResponse = {
  graphs: {
    id: CourseInstanceStatus;
    data: number[];
  }[];
  statistics: Map<CourseInstanceStatusStatistic, number>;
  from: string;
  to: string;
  marketId: string;
  xMode: CourseInstanceStatusXMode;
};

export type CourseInstanceStatusData = {
  graphs: {
    id: CourseInstanceStatus;
    label: string;
    data: { x: Date; y: number }[];
    color: string;
  }[];
  statistics: Map<CourseInstanceStatusStatistic, number>;
};

export const mapCourseInstanceStatisticToCourseInstanceStatus = (
  courseInstanceStatusStatistics: CourseInstanceStatusStatistic,
): CourseInstanceStatus | undefined => {
  switch (courseInstanceStatusStatistics) {
    case CourseInstanceStatusStatistic.TOTAL:
      return CourseInstanceStatus.TOTAL;
    case CourseInstanceStatusStatistic.PRELIMINARY:
      return CourseInstanceStatus.PRELIMINARY;
    case CourseInstanceStatusStatistic.CONFIRMED:
      return CourseInstanceStatus.CONFIRMED;
    case CourseInstanceStatusStatistic.INVOICED:
      return CourseInstanceStatus.INVOICED;
    default:
      return undefined;
  }
};
