import React, { useMemo } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SubsidiaryCompanyGoal } from '../types/SubsidiaryCompanyGoal';
import SubsidiaryCompanyGoalAPI, {
  CreateOrUpdateSubsidiaryCompanyGoalParams,
} from '../SubsidiaryCompanyGoalAPI';
import { useUser } from '../../user/userContext';
import PutSubsidiaryCompanyGoalModal from './PutSubsidiaryCompanyGoalModal';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

const EditSubsidiaryCompanyGoalModal: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { goalId } = useParams();
  const id = useMemo(() => Number(goalId), [goalId]);
  const { subsidiaryCompanyGoals, onClose } = useOutletContext<{
    subsidiaryCompanyGoals?: SubsidiaryCompanyGoal[];
    onClose?: () => void;
  }>();
  const subsidiaryCompanyGoal = subsidiaryCompanyGoals?.find(
    (g) => g.id === id,
  );

  const [currentUser] = useUser();

  const close = () => {
    onClose?.();
    navigate('..', { replace: true });
  };

  const subsidiaryCompanyId = currentUser?.preferredSubsidiaryCompany?.id;

  if (!subsidiaryCompanyId || !subsidiaryCompanyGoal) {
    return null;
  }

  const deleteGoal = async (subsidiaryCompanyGoal?: SubsidiaryCompanyGoal) => {
    if (!subsidiaryCompanyGoal) {
      return;
    }
    await SubsidiaryCompanyGoalAPI.deleteSubsidiaryCompanyGoal(
      subsidiaryCompanyId,
      subsidiaryCompanyGoal.id,
    );
    message.success(
      t('components.PutSubsidiaryCompanyGoalModal.onDeletedSuccess'),
    );
    close();
  };

  return (
    <PutSubsidiaryCompanyGoalModal
      subsidiaryCompanyGoal={subsidiaryCompanyGoal}
      putMethod={(params: CreateOrUpdateSubsidiaryCompanyGoalParams) =>
        SubsidiaryCompanyGoalAPI.updateSubsidiaryCompanyGoal(
          subsidiaryCompanyId,
          subsidiaryCompanyGoal.id,
          params,
        )
      }
      onSuccess={() => {
        message.success(
          t('components.PutSubsidiaryCompanyGoalModal.onEditedSuccess'),
        );
        close();
      }}
      onCancel={close}
      deleteGoal={deleteGoal}
    />
  );
};

export default EditSubsidiaryCompanyGoalModal;
