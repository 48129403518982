import React from 'react';
import { Button, Form, Modal, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import * as DateUtils from '../../utils/date';
import SearchResult from '../../search/types/SearchResult';
import DetailsRow from '../../components/DetailsRow';

import { HeaderValues } from './CourseInstanceHeader';

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

type EditCourseInstanceModalProps = {
  suggestion: SearchResult;
  visible?: boolean;
  onClose: () => void;
  onFormSubmit?: (values: HeaderValues) => void;
  setSelectedInstructorName: (value?: string) => void;
};

const EditCourseInstanceModal: React.FC<EditCourseInstanceModalProps> = ({
  suggestion,
  visible,
  onClose,
  onFormSubmit,
  setSelectedInstructorName,
}) => {
  const { t } = useTranslation();

  const onFinish = (values: HeaderValues) => {
    if (onFormSubmit) {
      onFormSubmit({
        ...values,
        date: dayjs(suggestion.start),
        instructorId: suggestion.user.id,
        courseId: suggestion.course.id,
      });
      setSelectedInstructorName(suggestion.user.name);
    }
    onClose();
  };

  return (
    <Modal
      title={t('components.EditCourseInstanceModal.selectTimeForInstance')}
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="editCourseInstanceForm">
          {t('common.select')}
        </Button>,
      ]}
      maskClosable={false}>
      <DetailsRow
        label={t('common.instructor')}
        displayValue={suggestion.user.name}
      />
      <DetailsRow
        label={t('common.course')}
        displayValue={suggestion.course.name}
      />
      <DetailsRow
        label={t('common.date')}
        displayValue={capitalize(dayjs(suggestion.start).format('dddd D MMMM'))}
      />

      <DetailsRow
        label={t('components.EditCourseInstanceModal.instructorTimeSlot')}
        displayValue={
          <div className="flex flex-col align-center">
            <span>
              {DateUtils.formatTime(suggestion.start)} -{' '}
              {DateUtils.formatTime(suggestion.end)}
            </span>
          </div>
        }
      />
      <DetailsRow
        label={t('components.EditCourseInstanceModal.timeForCourseInstance')}
        displayValue={
          <Form
            id="editCourseInstanceForm"
            onFinish={(values) => onFinish(values as HeaderValues)}
            fields={[
              {
                name: ['time'],
                value: [dayjs(suggestion.start), dayjs(suggestion.end)],
              },
            ]}>
            <Form.Item
              label={t('common.time') + ':'}
              name="time"
              rules={[
                {
                  required: true,
                  message: t('components.EditCourseInstanceModal.selectTime'),
                },
              ]}>
              <TimePicker.RangePicker
                minuteStep={5}
                format="HH:mm"
                changeOnBlur={true}
              />
            </Form.Item>
          </Form>
        }
      />
    </Modal>
  );
};

export default EditCourseInstanceModal;
