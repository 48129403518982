import React, { useState, useEffect, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Button, Form, Select, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import * as DateUtils from '../../utils/date';
import User from '../../user/types/User';
import Course from '../../course/types/Course';
import {
  CourseInstanceCardData,
  CourseInstanceStatus,
  DisplayedCourseInstance,
} from '../types/CourseInstance';
import Gravatar from '../../user/components/Gravatar';
import InstructorSuggestions from '../../search/component/InstructorSuggestions';
import CourseInstanceAPI from '../CourseInstanceAPI';
import InstructorCourseInstanceAlert from '../../components/InstructorCourseInstanceAlert';

import CourseInstanceStatusBadge from './CourseInstanceStatusBadge';
import CourseInstanceHeaderForm from './CourseInstanceHeaderForm';
import { CourseInstanceUpdateValues } from './EditCourseInstance';

type InfoCellProps = {
  title: string;
  component: React.ReactNode;
};

export type HeaderValues = {
  instructorId?: User['id'];
  courseId?: Course['id'];
  date?: dayjs.Dayjs;
  time?: [dayjs.Dayjs, dayjs.Dayjs];
};

type FormCellProps = {
  editable?: boolean;
  nonEditingContent?: React.ReactNode;
  editingContent?: React.ReactNode;
  size?: string;
  onFinish?: (values: CourseInstanceUpdateValues) => Promise<void>;
};

type CourseInstanceHeaderProps = {
  displayedCourseInstance?: DisplayedCourseInstance;
  editable?: boolean;
  onFinish?: (values: CourseInstanceUpdateValues) => Promise<void>;
  clientName?: string;
};

const InfoCell: React.FC<InfoCellProps> = ({ title, component }) => {
  return (
    <div className="sm:flex sm:flex-col">
      <div className="text-base font-bold pb-1">{title}</div>
      <div>{component}</div>
    </div>
  );
};

const FormCell: React.FC<FormCellProps> = ({
  editable,
  nonEditingContent,
  editingContent,
  onFinish,
}) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const { t } = useTranslation();

  const submit = useCallback(
    async (values: CourseInstanceUpdateValues) => {
      setUpdating(true);
      try {
        await onFinish?.(values);
      } finally {
        setUpdating(false);
        setEditing(false);
      }
    },
    [onFinish],
  );

  return (
    <div className="text-2xl font-bold pr-4">
      {!editing ? (
        <div className="flex">
          {nonEditingContent}
          {editable && (
            <Tooltip placement="top" title={t('common.editField')}>
              <Button
                type="text"
                size="small"
                icon={<EditOutlined />}
                className="ml-auto"
                onClick={() => setEditing(true)}
              />
            </Tooltip>
          )}
        </div>
      ) : (
        <Form onFinish={(values) => submit(values)} size="middle">
          {editingContent}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={updating}
              disabled={updating}>
              {t('common.save')}
            </Button>
            <Button className="ml-2" onClick={() => setEditing(false)}>
              {t('common.cancel')}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

const CourseInstanceHeader: React.FC<CourseInstanceHeaderProps> = ({
  displayedCourseInstance,
  editable,
  onFinish,
  clientName,
}) => {
  const initialValues: HeaderValues = useMemo(
    () => ({
      courseId: displayedCourseInstance?.course.id,
      instructorId: displayedCourseInstance?.instructor?.id,
      time:
        displayedCourseInstance?.startsAt && !!displayedCourseInstance?.endsAt
          ? [
              dayjs(displayedCourseInstance?.startsAt),
              dayjs(displayedCourseInstance?.endsAt),
            ]
          : undefined,
      date: !!displayedCourseInstance?.startsAt
        ? dayjs(displayedCourseInstance?.startsAt)
        : undefined,
    }),
    [
      displayedCourseInstance?.course.id,
      displayedCourseInstance?.endsAt,
      displayedCourseInstance?.instructor?.id,
      displayedCourseInstance?.startsAt,
    ],
  );

  const [editing, setEditing] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [changedValues, setChangedValues] = useState<boolean>(false);
  const [editableValues, setEditableValues] =
    useState<HeaderValues>(initialValues);
  const [selectedInstructorName, setSelectedInstructorName] =
    useState<string>();
  const [instructorCourseInstances, setInstructorCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const [loadingCourseInstances, setLoadingCourseInstances] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const fetchCourseInstances = useCallback(async () => {
    if (editableValues?.instructorId) {
      setLoadingCourseInstances(true);
      try {
        const { data } = await CourseInstanceAPI.getCourseInstances({
          instructorIds: [editableValues.instructorId],
          startsAt: dayjs(editableValues.date).startOf('day').toISOString(),
          endsAt: dayjs(editableValues.date).endOf('day').toISOString(),
          status: [
            CourseInstanceStatus.Confirmed,
            CourseInstanceStatus.Ordered,
            CourseInstanceStatus.Preliminary,
          ],
        });
        setInstructorCourseInstances(
          data.data.filter(
            (courseInstance) =>
              courseInstance.id !== displayedCourseInstance?.id,
          ),
        );
      } finally {
        setLoadingCourseInstances(false);
      }
    }
  }, [
    displayedCourseInstance?.id,
    editableValues.date,
    editableValues.instructorId,
  ]);

  const checkRequiredFields = useCallback((values: HeaderValues) => {
    if (!values.courseId) {
      return false;
    }

    // If a date or time is selected, both date and time must be assigned
    if (!!values.date !== !!values.time) {
      return false;
    }

    // Date & time are mandatory if an instructor is assigned
    if (!!values.instructorId && (!values.date || !values.time)) {
      return false;
    }

    return true;
  }, []);

  useEffect(() => {
    if (
      isEqual(initialValues, editableValues) ||
      !checkRequiredFields(editableValues)
    ) {
      setChangedValues(false);
    } else {
      setChangedValues(true);
    }

    if (editableValues?.instructorId) {
      fetchCourseInstances();
    } else {
      setInstructorCourseInstances([]);
    }
  }, [
    checkRequiredFields,
    editableValues,
    fetchCourseInstances,
    initialValues,
  ]);

  const resetEdit = useCallback(() => {
    setEditableValues(initialValues);
    setEditing(false);
    setShowError(false);
  }, [initialValues]);

  const durationHours = dayjs(displayedCourseInstance?.endsAt).diff(
    displayedCourseInstance?.startsAt,
    'hour',
  );
  const durationMinutes =
    dayjs(displayedCourseInstance?.endsAt).diff(
      displayedCourseInstance?.startsAt,
      'minute',
    ) % 60;

  const durationText = `${
    durationHours > 0
      ? `${durationHours} ${t('components.CourseInstanceHeader.hours')} `
      : ''
  }${
    durationMinutes > 0
      ? `${durationMinutes} ${t('components.CourseInstanceHeader.minutes')}`
      : ''
  }`;

  return (
    <div className="sm:flex sm:flex-col">
      <div className="pb-4 text-gray-800 text-base border-solid border-gray-200 border-0 border-b-2 sm:flex sm:flex-row sm:justify-between ">
        <div className="sm:flex sm:flex-row justify-center">
          {clientName && (
            <div className="truncate sm:max-w-xs text-xl font-bold mr-2">
              {clientName}
            </div>
          )}
          <FormCell
            editable={false}
            nonEditingContent={
              <CourseInstanceStatusBadge
                status={displayedCourseInstance?.status}
              />
            }
            editingContent={
              <Form.Item
                name="status"
                initialValue={displayedCourseInstance?.status}>
                <Select>
                  {[
                    CourseInstanceStatus.Confirmed,
                    CourseInstanceStatus.Preliminary,
                    CourseInstanceStatus.Ordered,
                    CourseInstanceStatus.Canceled,
                  ].map((status) => (
                    <Select.Option key={status} value={status}>
                      <CourseInstanceStatusBadge status={status} />
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
            onFinish={onFinish}
          />
        </div>
        {editable && (
          <div>
            {!editing ? (
              <Button
                type="primary"
                className="h-8 w-25 text-xs"
                onClick={() => setEditing(true)}>
                {t('components.CourseInstanceHeader.makeChanges')}
              </Button>
            ) : (
              <div className="sm:grid sm:grid-cols-2 gap-5">
                <Button
                  className="h-8 w-25 text-xs"
                  onClick={() => resetEdit()}>
                  {t('common.cancel')}
                </Button>
                <Button
                  type="primary"
                  className="h-8 w-25 text-xs"
                  disabled={showError || !changedValues}
                  onClick={() => setShowModal(true)}>
                  {t('components.CourseInstanceHeader.saveChanges')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {!editing ? (
        <div className="sm:grid sm:grid-cols-3 sm:py-5 gap-5 px-4">
          <InfoCell
            title={t('common.course')}
            component={<div>{displayedCourseInstance?.course?.name}</div>}
          />
          <InfoCell
            title={t('common.time')}
            component={
              <div className="flex flex-col">
                <span>
                  {DateUtils.formatDate(displayedCourseInstance?.startsAt)}
                </span>
                <span>
                  {DateUtils.formatTime(displayedCourseInstance?.startsAt)} -{' '}
                  {DateUtils.formatTime(displayedCourseInstance?.endsAt)}
                </span>
                <div className="text-xs text-gray-500 pt-1">
                  {durationText}{' '}
                </div>
              </div>
            }
          />
          <InfoCell
            title={t('common.instructor')}
            component={
              <div className="pt-1">
                <Link
                  to={`/anvandare/${displayedCourseInstance?.instructor?.id}`}
                  {...(!displayedCourseInstance?.instructor && {
                    className: 'pointer-events-none text-black',
                  })}>
                  <div className="flex items-center">
                    <Gravatar
                      email={displayedCourseInstance?.instructor?.email}
                      internal={displayedCourseInstance?.instructor?.internal}
                      isInstructor={
                        displayedCourseInstance?.instructor?.isInstructor
                      }
                    />
                    <span className="ml-4">
                      {displayedCourseInstance?.instructor?.name ??
                        t('components.CourseInstanceHeader.instructorMissing')}
                    </span>
                  </div>
                </Link>
              </div>
            }
          />
        </div>
      ) : (
        <div>
          <CourseInstanceHeaderForm
            displayedCourseInstance={displayedCourseInstance}
            setEditing={setEditing}
            onFinish={onFinish}
            checkRequiredFields={checkRequiredFields}
            showModal={showModal}
            setError={setShowError}
            setShowModal={setShowModal}
            editableValues={editableValues}
            setEditableValues={setEditableValues}
            selectedInstructorName={selectedInstructorName}
            setSelectedInstructorName={setSelectedInstructorName}
          />
        </div>
      )}
      {editing && editableValues && (
        <>
          {selectedInstructorName && instructorCourseInstances.length > 0 && (
            <InstructorCourseInstanceAlert
              instructorName={selectedInstructorName}
              loadingCourseInstances={loadingCourseInstances}
              courseInstanceCardData={instructorCourseInstances}
              className={'mb-3'}
            />
          )}
          <InstructorSuggestions
            displayedCourseInstance={displayedCourseInstance}
            checkRequiredFields={checkRequiredFields}
            onFormSubmit={setEditableValues}
            editableValues={editableValues}
            setSelectedInstructorName={setSelectedInstructorName}
          />
        </>
      )}
    </div>
  );
};

export default CourseInstanceHeader;
