import { EllipsisOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { ReactElement, useState } from 'react';

const defaultIcon = (
  <div className="rounded-lg p-1 -ml-1">
    <EllipsisOutlined style={{ fontSize: '200%' }} />
  </div>
);

export type ActionPopoverElement = {
  rowElement: ReactElement;
  action: (id?: number) => void;
  hidden?: boolean;
  disabled?: boolean;
  tooltip?: string;
};

type ActionPopoverMenuProps = {
  setPopoverVisible: (visible: boolean) => void;
  actionPopoverElements: ActionPopoverElement[];
};

const ActionPopoverMenu: React.FC<ActionPopoverMenuProps> = ({
  setPopoverVisible,
  actionPopoverElements,
}) => {
  const onClick = (actionPopoverElements: ActionPopoverElement) => {
    setPopoverVisible(false);
    actionPopoverElements.action();
  };

  const roundedStyle = (elementIndex: number) => {
    if (elementIndex === 0) {
      return 'rounded-t-md';
    } else if (elementIndex === actionPopoverElements.length - 1) {
      return 'rounded-b-md';
    }
  };

  return (
    <>
      {actionPopoverElements?.map((actionPopoverElement, index) => (
        <div
          hidden={actionPopoverElement.hidden}
          key={index}
          className="overflow-hidden w-48"
          onClick={
            !actionPopoverElement.disabled
              ? () => onClick(actionPopoverElement)
              : undefined
          }>
          <Tooltip title={actionPopoverElement.tooltip} placement="right">
            <div
              className={`flex items-center hover:bg-blueGrayLight py-1 px-4 text-sm
            ${!actionPopoverElement.disabled ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'}
            ${roundedStyle(index)}`}>
              {actionPopoverElement.rowElement}
            </div>
            {index < actionPopoverElements.length - 1 && (
              <div className="border-solid border-gray-200 border-0 border-b-2" />
            )}
          </Tooltip>
        </div>
      ))}
    </>
  );
};

type ActionPopoverButtonProps = {
  className?: string;
  tooltipText?: string;
  icon?: ReactElement;
  placement?: TooltipPlacement;
  actionPopoverElements: ActionPopoverElement[];
  absolutePlacement?: boolean;
};

const ActionPopoverButton: React.FC<ActionPopoverButtonProps> = ({
  className,
  tooltipText,
  icon,
  placement,
  actionPopoverElements,
  absolutePlacement = true,
}) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <div
      className={`action-button flex items-center cursor-pointer ${
        className ?? ''
      }`}>
      <Popover
        overlayClassName="actionPopover"
        open={visible}
        onOpenChange={handleVisibleChange}
        className={absolutePlacement ? 'absolute ' : '' + 'p-0 right-4'}
        overlayInnerStyle={{ padding: 0 }}
        style={{ top: '4rem', padding: 0 }}
        placement={placement ?? 'bottom'}
        content={
          <ActionPopoverMenu
            actionPopoverElements={actionPopoverElements}
            setPopoverVisible={setVisible}
          />
        }
        trigger="click">
        <Tooltip title={tooltipText}>{icon ? icon : defaultIcon}</Tooltip>
      </Popover>
    </div>
  );
};

export default ActionPopoverButton;
