import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Edit from '../../resources/img/edit.svg';
import Remove from '../../resources/img/remove.svg';
import Swap from '../../resources/img/swap.svg';
import InvoiceRecipient from '../types/InvoiceRecipient';
import { buildAddressString } from '../../utils/address';
import FieldSelect from '../../components/FieldSelect';

import InformationCard from './InformationCard';
import CreateInvoiceRecipientModal from './CreateInvoiceRecipientModal';
import EditInvoiceRecipientModal from './EditInvoiceRecipientModal';

type InvoiceRecipientFieldProps = {
  bookingOrderId?: number;
  selectedInvoiceRecipient?: InvoiceRecipient;
  setSelectedInvoiceRecipient: (invoiceRecipient?: InvoiceRecipient) => void;
  invoiceRecipients?: InvoiceRecipient[];
  fetchInvoiceRecipients: () => void;
  invoiceReference: string;
  updateInvoiceReference: (newInvoiceReference: string) => void;
  setDeleteInvoiceRecipientOnSave?: (invoiceRecipient: number) => void;
  disableDelete?: boolean;
  loading?: boolean;
  loadingOptions?: boolean;
  onSearch?: (value: string) => void;
};

const InvoiceRecipientField: React.FC<InvoiceRecipientFieldProps> = ({
  bookingOrderId,
  selectedInvoiceRecipient,
  setSelectedInvoiceRecipient,
  invoiceRecipients,
  fetchInvoiceRecipients,
  invoiceReference,
  updateInvoiceReference,
  setDeleteInvoiceRecipientOnSave,
  disableDelete,
  loading,
  loadingOptions,
  onSearch,
}) => {
  const [showCreateInvoiceRecipientModal, setShowCreateInvoiceRecipientModal] =
    useState<boolean>(false);
  const [showEditInvoiceRecipientModal, setShowEditInvoiceRecipientModal] =
    useState<boolean>(false);
  const [fieldSelectDefaultOpen, setFieldSelectDefaultOpen] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const actionPopoverElements = [
    {
      rowElement: (
        <>
          <img src={Swap} className="pr-2" />
          {t('common.switch')}
        </>
      ),
      action: () => {
        setFieldSelectDefaultOpen(true);
        fetchInvoiceRecipients();
        setSelectedInvoiceRecipient(undefined);
      },
      hidden: !setSelectedInvoiceRecipient,
    },
    {
      rowElement: (
        <>
          <img src={Edit} className="pr-2" />
          {t('common.edit')}
        </>
      ),
      action: () => setShowEditInvoiceRecipientModal(true),
    },
    {
      rowElement: (
        <>
          <img src={Remove} className="pr-2" />
          {t('common.remove')}
        </>
      ),
      action: () => {
        setFieldSelectDefaultOpen(false);
        fetchInvoiceRecipients();
        setSelectedInvoiceRecipient(undefined);
      },
      hidden: !setSelectedInvoiceRecipient,
    },
  ];

  return (
    <>
      {selectedInvoiceRecipient ? (
        <InformationCard
          title={t('common.invoiceRecipient')}
          actionPopoverElements={actionPopoverElements}>
          <div className="gap-y-1 informationCardText pb-2">
            <div className="font-medium">{selectedInvoiceRecipient.name}</div>
            <div>{selectedInvoiceRecipient.corporateId}</div>
            <div>
              {buildAddressString(
                selectedInvoiceRecipient.invoiceAddress1,
                selectedInvoiceRecipient.invoiceAddress2,
                selectedInvoiceRecipient.invoiceAddress3,
                selectedInvoiceRecipient.invoicePostalCode,
                selectedInvoiceRecipient.invoiceCity,
              )}
            </div>
          </div>
        </InformationCard>
      ) : (
        <FieldSelect
          className="w-64 ml-2"
          title={t('common.invoiceRecipient')}
          placeholderText={t(
            'components.InvoiceRecipientField.selectInvoiceRecipient',
          )}
          loading={loading}
          defaultOpen={fieldSelectDefaultOpen}
          elements={invoiceRecipients?.map((invoiceRecipient) => ({
            primaryText: invoiceRecipient.name,
            id: invoiceRecipient.id,
          }))}
          createButtonText={t(
            'components.InvoiceRecipientField.createInvoiceRecipient',
          )}
          onCreate={() => setShowCreateInvoiceRecipientModal(true)}
          onSelect={(selectedId) =>
            setSelectedInvoiceRecipient(
              invoiceRecipients?.find(
                (invoiceRecipient) => invoiceRecipient.id === selectedId,
              ),
            )
          }
          onSearch={onSearch}
          loadingOptions={loadingOptions}
        />
      )}
      <CreateInvoiceRecipientModal
        visible={showCreateInvoiceRecipientModal}
        onCreate={(invoiceRecipient) => {
          setSelectedInvoiceRecipient(invoiceRecipient);
          setShowCreateInvoiceRecipientModal(false);
        }}
        onCancel={() => setShowCreateInvoiceRecipientModal(false)}
        invoiceReference={invoiceReference}
        updateInvoiceReference={updateInvoiceReference}
      />
      {selectedInvoiceRecipient && (
        <EditInvoiceRecipientModal
          bookingOrderId={bookingOrderId}
          visible={showEditInvoiceRecipientModal}
          invoiceRecipient={selectedInvoiceRecipient}
          setInvoiceRecipient={(invoiceRecipient) => {
            setSelectedInvoiceRecipient(invoiceRecipient);
          }}
          onDelete={() => {
            setDeleteInvoiceRecipientOnSave?.(selectedInvoiceRecipient.id);
            fetchInvoiceRecipients();
            setSelectedInvoiceRecipient(undefined);
            setShowEditInvoiceRecipientModal(false);
          }}
          disableDelete={disableDelete}
          onCancel={() => setShowEditInvoiceRecipientModal(false)}
          invoiceReference={invoiceReference}
          updateInvoiceReference={updateInvoiceReference}
          setShowEditInvoiceRecipientModal={setShowEditInvoiceRecipientModal}
        />
      )}
    </>
  );
};

export default InvoiceRecipientField;
