import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useTranslation } from 'react-i18next';

type CourseInstructionsModalProps = {
  visible: boolean;
  onCancel?: () => void;
};

const CourseInstructionsModal: React.FC<CourseInstructionsModalProps> = ({
  visible,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('components.CourseInstructionsModal.instructions')}
      open={visible}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}>
      <h3>{t('components.CourseInstructionsModal.markAsFavourite')}</h3>
      <Paragraph>
        {t('components.CourseInstructionsModal.markAsFavouriteInstructions.1')}{' '}
        {t('components.CourseInstructionsModal.markAsFavouriteInstructions.2')}{' '}
      </Paragraph>
    </Modal>
  );
};

export default CourseInstructionsModal;
