import { ExportOutlined } from '@ant-design/icons';
import { Alert, List } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

import CourseInstanceStatusBadge from '../courseInstance/components/CourseInstanceStatusBadge';
import { CourseInstanceCardData } from '../courseInstance/types/CourseInstance';
import * as DateUtils from '../utils/date';

type InstructorCourseInstanceAlertProps = {
  instructorName: string;
  loadingCourseInstances: boolean;
  courseInstanceCardData: CourseInstanceCardData[];
  className?: string;
};

const InstructorCourseInstanceAlert: React.FC<
  InstructorCourseInstanceAlertProps
> = ({
  instructorName,
  loadingCourseInstances,
  courseInstanceCardData,
  className = '',
}) => (
  <Alert
    type="info"
    showIcon
    className={`pb-3 ${className}`}
    message={
      <div>
        {t(
          'components.CreateCourseInstanceModal.instructorAlreadyBookedInstances',
          { instructorName: instructorName },
        )}
      </div>
    }
    description={
      <List<CourseInstanceCardData>
        className="-mt-3"
        loading={loadingCourseInstances}
        dataSource={courseInstanceCardData}
        renderItem={(courseInstance) => (
          <List.Item className="py-2">
            <List.Item.Meta
              title={
                <Link
                  to={`/bestallningar/${courseInstance.orderId}`}
                  target="_blank"
                  rel="norefferer noopener">
                  {courseInstance.course?.name}{' '}
                  <ExportOutlined className="mt-2" />
                </Link>
              }
              description={
                courseInstance.startsAt &&
                courseInstance.endsAt && (
                  <>
                    {DateUtils.formatDate(courseInstance.startsAt)},{' '}
                    {DateUtils.formatTime(courseInstance.startsAt)} -{' '}
                    {DateUtils.formatTime(courseInstance.endsAt)}
                    <br />
                    {courseInstance.address}
                  </>
                )
              }
            />
            <CourseInstanceStatusBadge status={courseInstance.status} />
          </List.Item>
        )}
      />
    }
  />
);

export default InstructorCourseInstanceAlert;
