import { DatePicker, Modal, Select, Spin } from 'antd';
import { Chart } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InsightsAPI from '../InsightsAPI';
import {
  GraphData,
  GraphResource,
  PieChartData,
  TimeUnit,
} from '../types/Graph';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import SubsidiaryCompanyAPI from '../../subsidiaryCompany/SubsidiaryCompanyAPI';
import { useLanguage } from '../../context/language';

import StatisticsGraph from './StatisticsGraph';

const { RangePicker } = DatePicker;

type StatisticsModalProps = {
  title?: string;
  resource?: GraphResource;
  unit?: string;
  tooltipContentGenerator?: (count: number) => string;
  visible?: boolean;
  setVisible?: (value: boolean) => void;
  subsidiaryCompanyId?: number;
};

type RangePickerType = 'date' | 'month' | 'year';
const pieChartPalette = ['#0A3466', '#2A5C96', '#C1DAF7'];

const StatisticsModal: React.FC<StatisticsModalProps> = ({
  title,
  resource,
  unit,
  tooltipContentGenerator,
  visible,
  setVisible,
  subsidiaryCompanyId = -1,
}) => {
  const [rangePickerType, setRangePickerType] =
    useState<RangePickerType>('date');
  const [pickerDates, setPickerDates] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(29, 'day'),
    dayjs(),
  ]);
  const [loadingGraphData, setLoadingGraphData] = useState(false);
  const [loadingPieChartData, setLoadingPieChartData] = useState(false);
  const [graphData, setGraphData] = useState<GraphData>();
  const [pieChartData, setPieChartData] = useState<PieChartData>();
  const [subsidiaryCompanies, setSubsidiaryCompanies] =
    useState<SubsidiaryCompany[]>();
  const [selectedSubsidiaryCompanyId, setSelectedSubsidiaryCompanyId] =
    useState(subsidiaryCompanyId);
  const [loadingSubsidiaryCompanies, setLoadingSubsidiaryCompanies] =
    useState(false);

  const [language] = useLanguage();
  const { t } = useTranslation();

  const rangePickerTypeToTimeUnit = useCallback((type: RangePickerType) => {
    switch (type) {
      case 'date':
        return TimeUnit.DAY;
      case 'month':
        return TimeUnit.MONTH;
      case 'year':
        return TimeUnit.YEAR;
    }
  }, []);

  const fetchGraphData = useCallback(async () => {
    if (resource) {
      setLoadingGraphData(true);
      try {
        const { data } = await InsightsAPI.getGraphData(
          resource,
          rangePickerTypeToTimeUnit(rangePickerType),
          pickerDates[0].format('YYYY-MM-DD'),
          pickerDates[1].format('YYYY-MM-DD'),
          selectedSubsidiaryCompanyId === -1
            ? undefined
            : selectedSubsidiaryCompanyId,
        );
        setGraphData(data);
      } finally {
        setLoadingGraphData(false);
      }
    }
  }, [
    pickerDates,
    rangePickerType,
    rangePickerTypeToTimeUnit,
    resource,
    selectedSubsidiaryCompanyId,
  ]);

  const fetchPieChartData = useCallback(async () => {
    if (resource) {
      setLoadingPieChartData(true);
      try {
        const { data } = await InsightsAPI.getPieChartData(
          resource,
          rangePickerTypeToTimeUnit(rangePickerType),
          pickerDates[0].format('YYYY-MM-DD'),
          pickerDates[1].format('YYYY-MM-DD'),
        );
        setPieChartData(data);
      } finally {
        setLoadingPieChartData(false);
      }
    }
  }, [pickerDates, rangePickerType, rangePickerTypeToTimeUnit, resource]);

  const fetchSubsidiaryCompanies = useCallback(async () => {
    setLoadingSubsidiaryCompanies(true);
    try {
      const { data } = await SubsidiaryCompanyAPI.getSubsidiaryCompanies();
      setSubsidiaryCompanies(data);
    } finally {
      setLoadingSubsidiaryCompanies(false);
    }
  }, []);

  useEffect(() => {
    fetchPieChartData();
  }, [rangePickerType, pickerDates, fetchPieChartData]);

  useEffect(() => {
    if (!loadingSubsidiaryCompanies) {
      fetchGraphData();
    }
  }, [fetchGraphData, loadingSubsidiaryCompanies]);

  useEffect(() => {
    setSelectedSubsidiaryCompanyId(subsidiaryCompanyId);
  }, [subsidiaryCompanyId, visible]);

  useEffect(() => {
    fetchSubsidiaryCompanies();
  }, [fetchSubsidiaryCompanies]);

  const onChangeRangePickerType = useCallback(
    (rangePickerType: RangePickerType) => {
      if (
        rangePickerType !== 'date' &&
        pickerDates[0].isSame(pickerDates[1], rangePickerType)
      )
        setPickerDates(() => [
          pickerDates[0].subtract(1, rangePickerType),
          pickerDates[1],
        ]);

      setRangePickerType(rangePickerType);
    },
    [pickerDates],
  );

  const getTimeUnitIntervalString = useCallback(() => {
    switch (rangePickerType) {
      case 'date':
        return t('components.StatisticsModal.specificDates');
      case 'month':
        return t('components.StatisticsModal.specificMonths');
      case 'year':
        return t('components.StatisticsModal.specificYears');
    }
  }, [rangePickerType, t]);

  const total =
    graphData?.entries
      .map((entry) => entry.value)
      .reduce((previous, current) => previous + current, 0) ?? 0;

  const DateTypePicker: React.FC = useCallback(() => {
    return (
      <div className="w-32">
        <div className="text-grayDark text-xs">
          {t('components.StatisticsModal.timePeriod')}
        </div>
        <Select
          className="flex flex-grow"
          defaultValue={rangePickerType}
          onChange={onChangeRangePickerType}>
          <Select.Option value={'date'}>
            {t('components.StatisticsModal.perDay')}
          </Select.Option>
          <Select.Option value={'month'}>
            {t('components.StatisticsModal.perMonth')}
          </Select.Option>
          <Select.Option value={'year'}>
            {t('components.StatisticsModal.perYear')}
          </Select.Option>
        </Select>
      </div>
    );
  }, [onChangeRangePickerType, rangePickerType, t]);

  const IntervalPicker: React.FC = () => {
    return (
      <div>
        <div className="text-grayDark text-xs">
          {getTimeUnitIntervalString()}
        </div>
        <RangePicker
          picker={rangePickerType}
          defaultValue={pickerDates}
          disabledDate={(date) =>
            !!pickerDates.find((pickerDate) =>
              pickerDate.isSame(date, rangePickerType),
            )
          }
          allowClear={false}
          onChange={(dates) => {
            if (dates && dates[0] && dates[1]) {
              setPickerDates([dates[0], dates[1]]);
            }
          }}
        />
      </div>
    );
  };

  const SubsidiaryCompanyPicker: React.FC = useCallback(() => {
    return (
      <div>
        <div className="text-grayDark text-xs">{t('common.companies')}</div>
        <Select
          className="max-w-lg"
          style={{ minWidth: '128px' }}
          onChange={(value: number) => setSelectedSubsidiaryCompanyId(value)}
          loading={loadingSubsidiaryCompanies}
          disabled={loadingSubsidiaryCompanies}
          defaultValue={selectedSubsidiaryCompanyId}>
          <Select.Option key={-1} value={-1}>
            {t('components.StatisticsModal.allCompanies')}
          </Select.Option>
          {subsidiaryCompanies?.map((subsidiaryCompany, key) => {
            return (
              <Select.Option key={key} value={subsidiaryCompany.id}>
                <div>{subsidiaryCompany.name}</div>
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }, [
    loadingSubsidiaryCompanies,
    selectedSubsidiaryCompanyId,
    subsidiaryCompanies,
    t,
  ]);

  const Header: React.FC = () => {
    return (
      <div className="bg-beige py-5 px-8 space-y-4">
        <SubsidiaryCompanyPicker />
        <div className="flex flex-row space-x-8">
          <DateTypePicker />
          <IntervalPicker />
        </div>
      </div>
    );
  };

  const getPieChartLegendTitle = (resource?: GraphResource) => {
    switch (resource) {
      case GraphResource.COURSE_INSTANCES:
        return t('components.StatisticsModal.courseInstancePerCompany');
      case GraphResource.CREATED_BOOKING_ORDERS:
        return t('components.StatisticsModal.createdBookingOrdersPerCompany');
      case GraphResource.REVENUE:
        return t('components.StatisticsModal.revenuePerCompany');
    }
  };

  const PieChartLegend: React.FC = () => {
    return (
      <div className="space-y-2">
        <div className="text-xl font-semibold">
          {getPieChartLegendTitle(resource)}
        </div>
        <div className="space-y-2">
          {pieChartData &&
            pieChartData.entries.map((entry, key) => (
              <div key={key} className="flex flex-row items-center space-x-4">
                <div
                  className="w-6 h-6 rounded-full"
                  style={{
                    backgroundColor: pieChartPalette[key < 3 ? key : 2],
                  }}
                />
                <div className="text-lg">
                  <span>{`${entry.label}: `}</span>
                  <span className="font-semibold">
                    {[entry.value?.toLocaleString(language?.code), unit].join(
                      ' ',
                    )}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const getMomentFormat = () => {
    switch (rangePickerType) {
      case 'date':
        return 'D MMM YYYY';
      case 'month':
        return 'MMM YYYY';
      case 'year':
        return 'YYYY';
    }
  };

  return (
    <>
      <Modal
        width={900}
        open={visible}
        onCancel={() => setVisible?.(false)}
        title={
          <div className="flex flex-row items-center h-8">
            <span className="text-xl font-medium">{title}</span>
            {(loadingGraphData || loadingPieChartData) && (
              <Spin className="ml-2" />
            )}
          </div>
        }
        bodyStyle={{ paddingTop: '0px' }}
        footer={null}>
        <div className="-mx-6">
          <Header />
        </div>
        <div className="my-3">
          <div className="text-base font-semibold">
            {t('components.StatisticsModal.total')}
          </div>
          <div className="text-4xl font-semibold">
            {[total?.toLocaleString(language?.code), unit].join(' ')}
          </div>
        </div>
        <StatisticsGraph
          graphData={graphData}
          unit={unit}
          tooltipContentGenerator={tooltipContentGenerator}
          momentFormat={getMomentFormat()}
        />
        {pieChartData &&
          (pieChartData.total > 0 ? (
            <div className="flex flex-row space-x-10 pl-5">
              <div className="pt-12">
                <Chart
                  type="pie"
                  data={{
                    labels: pieChartData?.entries.map((entry) => entry.label),
                    datasets: [
                      {
                        data: pieChartData?.entries.map((entry) => entry.value),
                        borderWidth: 0,
                        backgroundColor: pieChartPalette,
                      },
                    ],
                  }}
                />
              </div>
              <div className="flex justify-center items-center mx-3">
                <PieChartLegend />
              </div>
              <div />
            </div>
          ) : (
            <h2 className="pt-6 pl-4">
              {t('components.StatisticsModal.noDataForPieChart')}
            </h2>
          ))}
      </Modal>
    </>
  );
};

export default StatisticsModal;
