import ContactPerson from '../types/ContactPerson';

export function makeContactString(
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  email?: string,
): string {
  const contactInfoArray = [];
  if (firstName) {
    const name = lastName ? `${firstName} ${lastName}` : firstName;
    contactInfoArray.push(name);
  }

  if (phoneNumber) contactInfoArray.push(phoneNumber);
  if (email) contactInfoArray.push(email);

  return contactInfoArray.join(', ');
}

export function getFullContactName(contactPerson: ContactPerson) {
  if (contactPerson.lastName) {
    return `${contactPerson.firstName} ${contactPerson.lastName}`;
  } else {
    return contactPerson.firstName;
  }
}
