import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

type SelectManagersProps = {
  managerEmails?: string[];
  skipLabel?: boolean;
};

export const SelectManagers: FC<SelectManagersProps> = ({
  managerEmails,
  skipLabel = false,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="managerEmails"
      className="m-0 mb-6"
      initialValue={managerEmails}
      {...(!skipLabel && { label: t('components.SelectManagers.managers') })}>
      <Select
        value={managerEmails}
        placeholder={t('components.SelectManagers.enterManagers')}
        mode="tags"
        allowClear>
        {managerEmails?.map((m) => (
          <Select.Option key={m} value={m} title={m}>
            {m}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
