import dayjs from 'dayjs';

export const stringifyValues = (
  values: Record<string, unknown | unknown[]>,
  ignore: (value: unknown | unknown[]) => boolean = (value) =>
    value === undefined,
) => {
  const stringified: Record<string, string | string[]> = {};

  Object.entries(values).forEach(([key, val]) => {
    if (ignore(val)) return;
    stringified[key] = Array.isArray(val) ? val.map(String) : String(val);
  });

  return stringified;
};

export function getSearchParam<T = string>(
  searchParams: URLSearchParams,
  name: string,
  what: 'list',
): T[] | undefined;
export function getSearchParam<T = string>(
  searchParams: URLSearchParams,
  name: string,
  what: 'first',
): T | undefined;
export function getSearchParam(
  searchParams: URLSearchParams,
  name: string,
  what: 'bool',
): boolean | undefined;
export function getSearchParam(
  searchParams: URLSearchParams,
  name: string,
  what: 'moment',
): dayjs.Dayjs | undefined;
export function getSearchParam<T = string>(
  searchParams: URLSearchParams,
  name: string,
  what: 'list' | 'first' | 'bool' | 'moment',
) {
  switch (what) {
    case 'list': {
      const content = searchParams.getAll(name);
      return content.length ? (content as unknown as T[]) : undefined;
    }
    case 'first': {
      return (searchParams.get(name) as T | null) ?? undefined;
    }
    case 'bool': {
      const content = searchParams.get(name);
      return content === null ? undefined : content === 'true';
    }
    case 'moment': {
      const value = searchParams.get(name);
      return value ? dayjs(value) : undefined;
    }
  }
}

export function getSearchParamsAnyOf<T extends string>(
  searchParams: URLSearchParams,
  name: string,
  fromList: T[],
): T | undefined {
  const value = searchParams.get(name) as T;
  return fromList.includes(value) ? value : undefined;
}
