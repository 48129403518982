import React, {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

/**
 * Define all your desired runtime feature toggles in this type definition.
 * e.g.
 *
 *     isFeatureAbcEnabled: boolean
 *
 * Enable or disable features setting the environment variables or by using URL parameters (e.g., `?isFeatureAbcEnabled=true`)
 * or by manually setting the `feature-toggles` key in session storage as a JSON string (e.g., `{"isFeatureAbcEnabled": true}`).
 *
 * To check if a feature is enabled, use the following pattern
 *
 *     const { isFeatureAbcEnabled } = useFeatureToggles();
 *
 */
type FeatureToggles = {
  isElsaIntegrationEnabled: boolean;
};

const initialFeatureToggles: FeatureToggles = {
  isElsaIntegrationEnabled: false,
};

const environmentVariableFeatureToggles: FeatureToggles = {
  isElsaIntegrationEnabled:
    process.env.REACT_APP_FEATURE_TOGGLE_ELSA_INTEGRATION_ENABLED === 'true',
};

type FeatureTogglesContextValue = FeatureToggles;

const FeatureTogglesContext = createContext<
  FeatureTogglesContextValue | undefined
>(undefined);

type FeatureTogglesProviderProps = {
  children: ReactNode;
};

const FEATURE_TOGGLES_SESSION_STORAGE_KEY = 'feature-toggles';

export const FeatureToggleProvider: FC<FeatureTogglesProviderProps> = ({
  children,
}) => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggles>(
    initialFeatureToggles,
  );

  useEffect(() => {
    const storedFeaturesJsonString = sessionStorage.getItem(
      FEATURE_TOGGLES_SESSION_STORAGE_KEY,
    );
    const storedFeatureToggles: FeatureToggles | undefined =
      storedFeaturesJsonString && JSON.parse(storedFeaturesJsonString);

    const urlParams = new URLSearchParams(window.location.search);

    const updatedFeatureToggles: FeatureToggles = initialFeatureToggles;

    (Object.keys(initialFeatureToggles) as Array<keyof FeatureToggles>).forEach(
      (key) => {
        updatedFeatureToggles[key] = environmentVariableFeatureToggles[key];

        if (storedFeatureToggles && storedFeatureToggles[key] !== undefined) {
          updatedFeatureToggles[key] = storedFeatureToggles[key];
        }

        if (urlParams.has(key)) {
          updatedFeatureToggles[key] = urlParams.get(key) === 'true';
        }
      },
    );

    const hasFeatureToggles = Object.values(updatedFeatureToggles).some(
      (value) => value !== undefined && value !== null,
    );

    if (hasFeatureToggles) {
      sessionStorage.setItem(
        FEATURE_TOGGLES_SESSION_STORAGE_KEY,
        JSON.stringify(updatedFeatureToggles),
      );
    } else {
      sessionStorage.removeItem(FEATURE_TOGGLES_SESSION_STORAGE_KEY);
    }

    setFeatureToggles(updatedFeatureToggles);
  }, []);

  return (
    <FeatureTogglesContext.Provider value={featureToggles}>
      {children}
    </FeatureTogglesContext.Provider>
  );
};

export const useFeatureToggles = (): FeatureTogglesContextValue => {
  const context = useContext(FeatureTogglesContext);
  if (!context) {
    throw new Error(
      'useFeatureToggles must be used within a FeatureToggleProvider',
    );
  }
  return context;
};
