import { ImageMedia } from '../../types/ImageMedia';
import User from '../../user/types/User';

export enum AttendeeSignatureType {
  NoSignature = 'no_signature',
  PhysicalSignature = 'physical_signature',
}

export enum SignatureType {
  NoSignature = AttendeeSignatureType.NoSignature,
  PhysicalSignature = AttendeeSignatureType.PhysicalSignature,
  DigitalSignature = 'digital_signature',
}

export enum CertificateDateFormat {
  MONTH_DAY_YEAR = 'MM-dd-yyyy',
  YEAR_MONTH_DAY = 'yyyy-MM-dd',
  DAY_MONTH_YEAR = 'dd-MM-yyyy',
}

export interface CertificateLanguage {
  id: number;
  name: string;
  code: string;
  certificateLabel: string;
  courseDateLabel: string;
  locationLabel: string;
  validToLabel: string;
  instructorLabel: string;
  issuerLabel: string;
  authenticationLabel: string;
  awardedByCompanyLabel: string;
  attendeeSignatureLabel: string;
  instructorSignatureLabel: string;
  issuerSignatureLabel: string;
}

export interface LanguageTextContent {
  title: string;
  description: string;
  certificateLanguage: CertificateLanguage;
}

export type CertificateTemplate = {
  id: number;
  courseId: number;
  languageTextContents: LanguageTextContent[];
  instructorSignatureType: SignatureType;
  attendeeSignatureType: AttendeeSignatureType;
  issuerSignatureType: SignatureType;
  issuer: User | null;
  validForMonths: number | null;
  subsidiaryCompanyId: number;
  dateFormat: CertificateDateFormat;
  stampImage1: ImageMedia | null;
  stampImage2: ImageMedia | null;
  footerText1: string;
  footerText2: string;
  createdAt: string;
  updatedAt: string;
};
