import React from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';
import { BucketSizeOption } from '../types/InsightsFilters';

type BucketSizePickerProps = {
  bucketSize: number;
  setBucketSize: (v: number) => void;
};

const BucketSizePicker: React.FC<BucketSizePickerProps> = ({
  bucketSize,
  setBucketSize,
}) => {
  const { t } = useTranslation();

  const bucketSizeOptions = [
    {
      value: BucketSizeOption.Monthly,
      label: t('components.InsightsFilters.BucketSizeOptions.monthly'),
    },
    {
      value: BucketSizeOption.Weekly,
      label: t('components.InsightsFilters.BucketSizeOptions.weekly'),
    },
    {
      value: BucketSizeOption.Daily,
      label: t('components.InsightsFilters.BucketSizeOptions.daily'),
    },
  ];

  return (
    <Radio.Group
      options={bucketSizeOptions}
      value={bucketSize}
      onChange={({ target: { value } }: RadioChangeEvent) =>
        setBucketSize(value)
      }
      optionType="button"
    />
  );
};

export default BucketSizePicker;
