import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import CertificateAPI from '../../certificate/CertificateAPI';
import { ImageMedia } from '../../types/ImageMedia';
import { verifyImageUpload } from '../../utils/upload';

type CreateCertificateImageModalProps = {
  visible: boolean;
  onCreate: (certificateImageMedia: ImageMedia) => void;
  onCancel: () => void;
};

const CreateCertificateImageModal: React.FC<
  CreateCertificateImageModalProps
> = ({ visible, onCreate, onCancel }) => {
  const [file, setFile] = useState<File>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onFinish = async (values: { name: string }) => {
    if (!file) {
      return;
    }

    setSubmitting(true);

    try {
      const fmData = new FormData();
      fmData.append('image', file);
      fmData.append('name', values.name);
      const extension = file.name.split('.').pop();
      if (!!extension) {
        fmData.append('extension', extension);
      }

      const { data: newImage } =
        await CertificateAPI.createCertificateImage(fmData);
      onCreate(newImage);
      resetAndClose();
    } catch {
      message.error(t('errors.somethingWentWrong'));
    } finally {
      setSubmitting(false);
    }
  };

  const resetAndClose = () => {
    setFile(undefined);
    form.resetFields();
    onCancel();
  };

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      setFile(undefined);
    },
    beforeUpload: (file) => {
      if (!verifyImageUpload(file, t)) {
        return Upload.LIST_IGNORE;
      }

      setFile(file);
      return false;
    },
    maxCount: 1,
    listType: 'picture',
  };

  return (
    <Modal
      title={t('components.CertificateImageModal.title')}
      open={visible}
      onCancel={resetAndClose}
      onOk={form.submit}
      width="60%"
      footer={[
        <Button key="back" onClick={onCancel}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form="createCertificateImageForm"
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}
      maskClosable={false}>
      <Form
        id="createCertificateImageForm"
        layout="vertical"
        form={form}
        onFinish={(values) => onFinish(values)}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>
                {t('common.selectFile')}
              </Button>
            </Upload>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={t('components.CertificateImageModal.form.name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('components.CertificateImageModal.form.enterName'),
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateCertificateImageModal;
