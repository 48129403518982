import { Button, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ApiResponse from '../../types/ApiResponse';
import BookingOrder from '../../bookingOrders/types/BookingOrder';
import PaginationMeta from '../../types/PaginationMeta';
import BookingOrderCard from '../../bookingOrders/components/BookingOrderCard';
import { userHasPermission } from '../../user/userUtils';
import { useUser } from '../../user/userContext';
import { Permission } from '../../user/types/Permission';

type BookingOrderDashboardListProps = {
  bookingOrderLoader: (
    page: number,
    pageSize: number,
  ) => Promise<AxiosResponse<ApiResponse<BookingOrder[], PaginationMeta>>>;
  showMoreLink?: string;
  noBookingOrdersText?: string;
  maxPages?: number;
  setTabBookingOrderAmount?: (amount: number) => void;
};

const DashboardBookingOrderList: React.FC<BookingOrderDashboardListProps> = ({
  showMoreLink = '/bestallningar',
  noBookingOrdersText,
  bookingOrderLoader,
  maxPages = 3,
  setTabBookingOrderAmount,
}) => {
  const [currentUser] = useUser();
  const [bookingOrders, setBookingOrders] = useState<BookingOrder[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>();
  const [nextPage, setNextPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const { t } = useTranslation();

  const loadMore = useCallback(async () => {
    if (!canLoadMore) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await bookingOrderLoader(nextPage, 5);
      setPaginationMeta(data.meta);
      setBookingOrders((prevState) => [...prevState, ...data.data]);
    } finally {
      setLoading(false);
    }
  }, [bookingOrderLoader, canLoadMore, nextPage]);

  useEffect(() => {
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!paginationMeta) return;

    setNextPage(paginationMeta.page + 1);
    setCanLoadMore(
      paginationMeta.page < maxPages &&
        paginationMeta.page < paginationMeta.lastPage,
    );
    setTabBookingOrderAmount?.(paginationMeta.total);
  }, [maxPages, paginationMeta, setTabBookingOrderAmount]);

  return (
    <div className="flex flex-col items-center">
      {bookingOrders.length > 0 ? (
        <div className="w-full">
          {bookingOrders.map((bookingOrder) => (
            <BookingOrderCard
              key={bookingOrder.id}
              bookingOrder={bookingOrder}
              size="small"
            />
          ))}
        </div>
      ) : (
        !loading && (
          <div className="text-lg text-semibold my-4">
            {noBookingOrdersText}
          </div>
        )
      )}
      {loading && <Spin />}
      {!loading &&
        (canLoadMore ? (
          <Button onClick={loadMore}>
            {t('components.DashboardBookingOrderList.showMore')}
          </Button>
        ) : (
          <Button
            href={showMoreLink}
            disabled={
              !userHasPermission(currentUser, Permission.BOOKING_ORDER_READ)
            }>
            {t('components.DashboardBookingOrderList.toBookingOrders')}
          </Button>
        ))}
    </div>
  );
};

export default DashboardBookingOrderList;
