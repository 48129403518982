export interface GraphData {
  resource: GraphResource;
  entries: GraphEntry<number>[];
}

export interface GraphEntry<T> {
  label: string;
  value: T;
}

export interface CapacityValue {
  totalCourseInstances: number;
  totalCourseInstanceLimit: number;
}

export type CapacityGraphData = GraphEntry<CapacityValue>[];

export type CapacityData = {
  date: string;
  percentage: number;
  comment: string;
};

export interface PieChartData {
  resource?: GraphResource;
  total: number;
  entries: PieChartEntry[];
}

export interface PieChartEntry {
  label: string;
  value: number;
}

export enum GraphResource {
  CREATED_BOOKING_ORDERS = 'CREATED_BOOKING_ORDERS',
  COURSE_INSTANCES = 'COURSE_INSTANCES',
  REVENUE = 'REVENUE',
}

export enum TimeUnit {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface CourseInstanceInsightData {
  totalCourseInstanceCount: number;
  deletedCourseInstanceCount: number;
  completeCourseInstanceData: {
    total: number;
    invoiced: number;
    performed: number;
    confirmed: number;
  };
  cancelledCourseInstanceData: {
    total: number;
    invoiced: number;
    notInvoiced: number;
  };
  incompleteCourseInstanceData: {
    total: number;
    missingInstructor: number;
    missingAddress: number;
    missingTime: number;
  };
}

export interface CourseChartData {
  courseId: number;
  courseName: string;
  instructorCount: number;
  courseInstanceCount: number;
}

export enum CourseChartSortOrder {
  DESCENDING = 'DESCENDING',
  ASCENDING = 'ASCENDING',
}
