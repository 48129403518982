import React from 'react';

import { InstructorMarkerData } from '../types/User';

import Gravatar from './Gravatar';

const InstructorMarkerInfoWindow: React.FC<{
  markerData: InstructorMarkerData;
}> = ({ markerData }) => {
  return (
    <div className="flex align-center items-center">
      <div className="mr-3">
        <a href={`anvandare/${markerData.userId}`}>
          <Gravatar
            email={markerData.email}
            internal={markerData.internal}
            isInstructor
          />
        </a>
      </div>

      <div className="grid gap-y-0.5">
        <div className="font-semibold">
          <a href={`anvandare/${markerData.userId}`} className="text-black">
            {markerData.name}
          </a>
        </div>
        <div>
          <a href={`mailto:${markerData.email}`}>{markerData.email}</a>
        </div>
        <div>{markerData.phoneNumber}</div>
      </div>
    </div>
  );
};

export default InstructorMarkerInfoWindow;
