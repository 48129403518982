import React, { FC } from 'react';
import {
  CourseInstanceStatus,
  CourseInstanceStatusData,
} from '../types/CourseInstanceStatus';
import { useInsights } from '../../context/insights';
import { CourseInstanceLegend } from './CourseInstanceLegend';

type CourseInstanceStatusLegendProps = {
  data?: CourseInstanceStatusData;
};

export const CourseInstanceStatusLegend: FC<
  CourseInstanceStatusLegendProps
> = ({ data }) => {
  const { selectedStatus, setSelectedStatus } = useInsights();

  return (
    <CourseInstanceLegend
      data={data}
      isSelected={(id) => selectedStatus === id}
      isNotSelected={(id) => !!selectedStatus && selectedStatus !== id}
      onMouseEnter={(id) => setSelectedStatus(id as CourseInstanceStatus)}
      onMouseLeave={() => setSelectedStatus(null)}
    />
  );
};
