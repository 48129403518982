import React from 'react';
import { useTranslation } from 'react-i18next';

import SubsidiaryCompany from '../types/SubsidiaryCompany';
import { ColumnsType } from '../../types/ColumnTypes';
import Table from '../../components/Table';

type SubsidiaryCompanyResultsProps = {
  subsidiaryCompanies?: SubsidiaryCompany[];
  loading: boolean;
  getLink: (subsidiaryCompany: SubsidiaryCompany) => string;
  canOpenSubsidiaryCompanyDetails?: boolean;
};

const SubsidiaryCompanyResults: React.FC<SubsidiaryCompanyResultsProps> = ({
  subsidiaryCompanies,
  loading,
  getLink,
  canOpenSubsidiaryCompanyDetails = true,
}) => {
  const { t } = useTranslation();

  const columns: ColumnsType<SubsidiaryCompany> = [
    {
      width: '50%',
      title: t('common.name'),
      render: (company) => (
        <div className="overflow-hidden text-ellipsis">{company?.name}</div>
      ),
      notLinked: !canOpenSubsidiaryCompanyDetails,
    },
    {
      width: '50%',
      title: t('common.email'),
      render: (company) => (
        <div className="overflow-hidden text-ellipsis">
          {company?.email ?? '-'}
        </div>
      ),
      notLinked: !canOpenSubsidiaryCompanyDetails,
    },
  ];

  return (
    <Table
      rowKey={(subsidiaryCompany) => subsidiaryCompany?.id}
      columns={columns}
      rows={subsidiaryCompanies}
      loading={loading}
      getRowLink={getLink}
    />
  );
};

export default SubsidiaryCompanyResults;
