import { EventApi } from '@fullcalendar/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SearchResultBoxParam = {
  event: EventApi;
  opacity: string;
};

const SearchResultBox: React.FC<SearchResultBoxParam> = ({
  event,
  opacity,
}) => {
  const [hovering, setHovering] = useState(false);
  const { t } = useTranslation();

  const detailedInformation = () => {
    return (
      hovering && (
        <p>
          {event.extendedProps.internalInstructorCount > 0 && (
            <>
              {event.extendedProps.internalInstructorCount}{' '}
              {t('components.SearchResultBox.inhouse')} <br />
            </>
          )}{' '}
          {event.extendedProps.externalInstructorCount > 0 && (
            <>
              {event.extendedProps.externalInstructorCount}{' '}
              {t('components.SearchResultBox.freelance')}
            </>
          )}
        </p>
      )
    );
  };

  const unassignedCourses = () => {
    return (
      event.extendedProps.unassignedCourseInstanceCount > 0 && (
        <p>
          {t('components.SearchResultBox.coursesMissingInstructor', {
            count: event.extendedProps.unassignedCourseInstanceCount,
          })}
        </p>
      )
    );
  };

  const dynamicBox = () => {
    return (
      <div
        className={`w-4 h-4 ${
          event.extendedProps.instructorCount >= 7
            ? 'bg-safeLifeMedium'
            : 'bg-safeLifeLight'
        } border-2 rounded-sm mx-0 col-span-1 max-w-0`}
        {...(event.extendedProps.instructorCount < 7 && {
          style: { opacity: opacity },
        })}
      />
    );
  };

  return (
    <div
      className="grid grid-row-2 grid-col-4 ml-1 gap-x-2 gap-y-4 md:w-full items-center"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      {dynamicBox()}
      <div className="flex flex-col col-start-2 col-span-4 m-0 text-sm">
        {t('components.SearchResultBox.instructors', {
          count: event.extendedProps.instructorCount,
        })}
      </div>
      <div className="row-start-2 col-start-2 col-span-4">
        {detailedInformation()}
        {unassignedCourses()}
      </div>
    </div>
  );
};

export default SearchResultBox;
