import { Button, DatePicker, Spin } from 'antd';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFullWorkWeek } from '../../utils/date';

import Graph from './Graph';

type WeekData = Record<
  string,
  {
    shortDay: string;
    longDay: string;
    day: string;
    month: string;
  }
>;

type WeekGraphCardProps = {
  title?: string;
  subtitle?: (value: number) => string;
  weekData: number[];
  startValue?: number;
  endValue?: number;
  displayDate: dayjs.Dayjs;
  onNext?: () => void;
  onPrev?: () => void;
  isCurrentWeek?: boolean;
  setWeek?: (moment: dayjs.Dayjs) => void;
  showHeader?: boolean;
  loading?: boolean;
};

dayjs.extend(isoWeek);

const WeekGraphCard: React.FC<WeekGraphCardProps> = ({
  title,
  subtitle,
  weekData,
  displayDate,
  onNext,
  isCurrentWeek,
  onPrev,
  setWeek,
  showHeader = true,
  loading,
}) => {
  const [fullWeek, setFullWeek] = useState<WeekData>({});
  const [labels, setLabels] = useState<string[]>([]);
  const [sumData, setSumData] = useState<number>();
  const { t } = useTranslation();

  useEffect(() => {
    const dataset = weekData.slice(0);
    setSumData(dataset.reduce((prev, curr) => prev + curr, 0));
  }, [weekData]);

  useEffect(() => {
    const week = getFullWorkWeek(displayDate);
    const weekData: WeekData = {};
    const labels: string[] = [];
    week.forEach((date) => {
      const shortDay = capitalize(date.format('ddd'));
      const longDay = capitalize(date.format('dddd'));
      const day = date.format('DD');
      const month = date.format('MMMM');
      labels.push(shortDay);
      weekData[shortDay] = { shortDay, longDay, day, month };
    });
    setFullWeek(weekData);
    setLabels(labels);
  }, [displayDate]);

  const timespan = () => {
    const fullWorkWeek = getFullWorkWeek(displayDate);
    const from = fullWeek[capitalize(fullWorkWeek[0].format('ddd'))];
    const to = fullWeek[capitalize(fullWorkWeek[4].format('ddd'))];
    if (from?.month === to?.month)
      return t('components.WeekGraph.forDatesSingleMonth', {
        fromDay: from?.day,
        toDay: to?.day,
        month: to?.month,
      });
    return t('components.WeekGraph.forDatesMultiMonth', {
      fromDay: from?.day,
      toDay: to?.day,
      fromMonth: from?.month,
      toMonth: to?.month,
    });
  };

  return (
    <div className="py-4 mb-10 rounded-lg overflow-x-hidden self-center w-full bg-white shadow-md">
      {showHeader && (
        <div className="flex flex-row justify-between items-center px-6">
          <div>
            {title && (
              <div className="flex flex-row gap-2">
                <h3>{title}</h3>
                {loading && <Spin />}
              </div>
            )}
            {subtitle && (
              <p>
                <>
                  {sumData !== undefined && subtitle(sumData)}
                  <span className="text-gray-600"> {timespan()}</span>
                </>
              </p>
            )}
          </div>
          <DatePicker
            picker="week"
            value={displayDate}
            onChange={(date) => {
              if (date) setWeek?.(date);
            }}
            format={(date) =>
              dayjs().isSame(date, 'week')
                ? t('components.WeekGraph.thisWeek')
                : t('components.WeekGraph.weekYear', {
                    week: date.isoWeek(),
                    year: date.year(),
                  })
            }
            allowClear={false}
            disabled={loading}
            disabledDate={(date) => {
              return date > dayjs().endOf('week');
            }}
            renderExtraFooter={() => (
              <div className="flex items-center justify-center">
                <Button
                  type="link"
                  onClick={() => setWeek?.(dayjs())}
                  disabled={dayjs().isSame(displayDate, 'week')}>
                  {t('components.WeekGraph.thisWeek')}
                </Button>
              </div>
            )}
          />
        </div>
      )}
      <Graph
        dataset={weekData}
        onNext={onNext}
        onPrev={onPrev}
        isCurrentWeek={isCurrentWeek}
        loading={loading}
        labels={['', ...labels, '']}
        tooltipableIndexes={[false, ...new Array(5).fill(true), false]}
        tooltipGenerator={(index) => {
          const label = labels[index - 1];
          const dailyData = fullWeek[label];
          return [
            `${dailyData.longDay}, ${dailyData.day} ${dailyData.month}`,
            `${weekData[index - 1]} ${t('views.Dashboard.nOrders')}`,
          ];
        }}
        chartHeight={250}
      />
    </div>
  );
};

export default WeekGraphCard;
