import React from 'react';

type LogoProps = {
  className?: string;
};

const Logo: React.FC<LogoProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.039 512.039"
    className={className}>
    <g fill="#c53030">
      <path
        d="M255.874 67.497C144.265-30.121 2.356 55.649.029 171.924c-.593 29.633 7.961 58.498 24.988 85.109h110.364l19.63-32.718c5.7-9.499 19.494-9.805 25.517-.335l41.417 65.083 60.373-127.451c5.265-11.121 20.956-11.474 26.763-.69l51.752 96.111h125.898c93.262-145.76-91.055-311.811-230.857-189.536z"
        data-original="#000000"
        data-old_color="#000000"
      />
      <path
        d="M338.667 279.144l-41.936-77.881-59.301 125.19c-5.045 10.651-19.884 11.576-26.211 1.632l-42.97-67.523-11.513 19.188a15 15 0 01-12.862 7.282H48.746c2.982 3.121-12.911-12.74 196.548 195.634 5.85 5.821 15.307 5.822 21.158 0C472.688 277.498 460.024 290.147 463 287.032H351.874a14.996 14.996 0 01-13.207-7.888z"
        data-original="#000000"
        data-old_color="#000000"
      />
    </g>
  </svg>
);

export default Logo;
