import React, { useState } from 'react';
import { Button, Form, Spin, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type DetailsRowProps = {
  label: string;
  displayValue?: string | React.ReactNode;
  loading?: boolean;
  editable?: boolean;
  formContent?: React.ReactNode;
  disableSave?: boolean;
  onFinish?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    setUpdating?: (updating: boolean) => void,
    setFieldChanged?: (changed: boolean) => void,
  ) => Promise<void>;
};

const DetailsRow: React.FC<DetailsRowProps> = ({
  label,
  displayValue,
  loading,
  editable,
  formContent,
  disableSave,
  onFinish,
}) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = async (values: any) => {
    setUpdating(true);
    try {
      await onFinish?.(values, setUpdating, setEditing);
    } finally {
      setUpdating(false);
      setEditing(false);
    }
  };

  return (
    <div className="odd:bg-white even:bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">
        {label}{' '}
        <Spin size="small" spinning={updating || !!loading} className="ml-2" />
      </dt>
      <dd className="mt-1 text-sm sm:mt-0 sm:col-span-3">
        {!editing ? (
          <div className="flex">
            {displayValue}
            {editable && (
              <Tooltip placement="top" title={t('common.editField')}>
                <Button
                  type="text"
                  size="small"
                  icon={<EditOutlined />}
                  className="ml-auto"
                  onClick={() => setEditing(true)}
                />
              </Tooltip>
            )}
          </div>
        ) : (
          <Form onFinish={(values) => submit(values)}>
            {formContent}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={updating}
                disabled={disableSave}>
                {t('common.save')}
              </Button>
              <Button className="ml-2" onClick={() => setEditing(false)}>
                {t('common.cancel')}
              </Button>
            </Form.Item>
          </Form>
        )}
      </dd>
    </div>
  );
};

export default DetailsRow;
