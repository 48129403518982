import { RobotOutlined, EllipsisOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';

import { default as BookingOrderCommentType } from '../types/BookingOrderComment';
import Gravatar from '../../user/components/Gravatar';
import ActionPopoverButton from '../../components/ActionPopoverButton';
import { useUser } from '../../user/userContext';
import { userHasPermission } from '../../user/userUtils';
import { Permission } from '../../user/types/Permission';

type BookingOrderCommentProps = {
  comment: BookingOrderCommentType;
  onDelete(commentId: number): void;
  onUpdate(commentId: number, newText: string): void;
};

const BookingOrderComment: React.FC<BookingOrderCommentProps> = ({
  comment,
  onDelete,
  onUpdate,
}) => {
  const [currentUser] = useUser();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [newCommentText, setNewCommentText] = useState<string>(comment.text);

  const isUserAllowedToEdit =
    comment?.user != null &&
    (userHasPermission(
      currentUser,
      Permission.BOOKING_ORDER_COMMENT_UPDATE_GLOBAL,
    ) ||
      (userHasPermission(
        currentUser,
        Permission.BOOKING_ORDER_COMMENT_UPDATE,
      ) &&
        currentUser?.id === comment?.user?.id));

  const isUserAllowedToDelete =
    comment?.user != null &&
    (userHasPermission(
      currentUser,
      Permission.BOOKING_ORDER_COMMENT_DELETE_GLOBAL,
    ) ||
      (userHasPermission(
        currentUser,
        Permission.BOOKING_ORDER_COMMENT_DELETE,
      ) &&
        currentUser?.id === comment?.user?.id));

  const editMenu = (comment: BookingOrderCommentType) => {
    return [
      ...(isUserAllowedToEdit
        ? [
            {
              rowElement: <>{t('common.edit')}</>,
              action: () => setEditMode(true),
            },
          ]
        : []),
      ...(isUserAllowedToDelete
        ? [
            {
              rowElement: <>{t('common.delete')}</>,
              action: () => onDelete(comment.id),
            },
          ]
        : []),
    ];
  };

  const onClickUpdate = () => {
    onUpdate(comment.id, newCommentText);
    setEditMode(false);
  };

  const onClickCancel = () => {
    setEditMode(false);
    setNewCommentText(comment.text);
  };

  return (
    <div>
      <div className="flex flex-row">
        {!!comment.user ? (
          <Gravatar email={comment.user?.email} />
        ) : (
          <div className="flex justify-center items-center rounded-full bg-gray-300 h-10 w-10">
            <RobotOutlined style={{ fontSize: '24px', color: '#545B6F' }} />
          </div>
        )}
        {editMode ? (
          <>
            <div className="flex flex-col mb-2 ml-2 gap-2 flex-grow">
              <Input.TextArea
                value={newCommentText}
                style={{ resize: 'none' }}
                autoSize={{ minRows: 1, maxRows: 4 }}
                onChange={(e) => setNewCommentText(e.target.value)}
              />

              <div className="flex flex-row justify-end">
                <Button key="back" onClick={onClickCancel}>
                  {t('common.cancel')}
                </Button>
                <Button
                  key="submit"
                  className="ml-2"
                  type="primary"
                  onClick={onClickUpdate}
                  disabled={newCommentText === comment.text}
                  form="editInvoiceRecipientForm"
                  htmlType="submit">
                  {t('common.save')}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row items-center">
              <span className="px-1 font-medium ml-2">
                {comment.user?.name ||
                  t('components.BookingOrderComment.systemComment')}
              </span>
              <span
                className="text-gray-500 font-normal"
                style={{ whiteSpace: 'nowrap' }}>{`· ${dayjs(
                comment.updatedAt,
              ).format('D MMM HH:mm')}`}</span>
            </div>
            {(isUserAllowedToEdit || isUserAllowedToDelete) && (
              <div
                className="flex"
                style={{ flexGrow: 1, flexDirection: 'row-reverse' }}>
                <ActionPopoverButton
                  icon={<EllipsisOutlined />}
                  actionPopoverElements={editMenu(comment)}
                  absolutePlacement={false}
                />
              </div>
            )}
          </>
        )}
      </div>
      {!editMode && (
        <p className="pl-3 whitespace-pre-line" style={{ marginLeft: 40 }}>
          {comment.text}
        </p>
      )}
    </div>
  );
};

export default BookingOrderComment;
