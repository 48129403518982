import React, { useState } from 'react';
import { Modal, Form, Input, Button, message, Switch, Select } from 'antd';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import CourseInstanceAPI from '../CourseInstanceAPI';
import {
  CourseInstanceAttendee,
  CourseInstanceAttendeeComplete,
  CourseInstanceAttendeeStatus,
} from '../types/CourseInstance';
import { CourseInstanceAttendeeStatusBox } from './CourseInstanceAttendeeStatusBox';

type CreateCourseInstanceAttendeeModalProps = {
  courseInstanceId: number;
  isMappedInElsa: boolean;
  visible: boolean;
  onCreateAttendees: (attendees: CourseInstanceAttendeeComplete[]) => void;
  onCancel: () => void;
};

const CreateCourseInstanceAttendeeModal: React.FC<
  CreateCourseInstanceAttendeeModalProps
> = ({
  courseInstanceId,
  isMappedInElsa,
  visible,
  onCreateAttendees,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [createMore, setCreateMore] = useState<boolean>(false);

  const { t } = useTranslation();

  const onFinish = async (values: CourseInstanceAttendee) => {
    setSubmitting(true);
    try {
      const { data: newAttendees } = await CourseInstanceAPI.createAttendees(
        courseInstanceId,
        [values],
      );
      onCreateAttendees(newAttendees);

      (createMore ? form.resetFields : resetAndClose)();
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const resetAndClose = () => {
    form.resetFields();
    onCancel();
  };

  const submit = () => {
    form.submit();
  };

  const formId = `createAttendeeForm-${courseInstanceId}`;

  return (
    <Modal
      title={t('components.CreateCourseInstanceAttendeeModal.title')}
      open={visible}
      onCancel={resetAndClose}
      onOk={submit}
      footer={[
        <Button key="back" onClick={resetAndClose}>
          {t('common.close')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={submitting}
          form={formId}
          htmlType="submit">
          {t('common.save')}
        </Button>,
      ]}>
      <Form
        id={formId}
        layout="vertical"
        form={form}
        onFinish={(values) => onFinish(values)}>
        <Form.Item
          label={t('components.CreateCourseInstanceAttendeeModal.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t(
                'components.CreateCourseInstanceAttendeeModal.fillOutName',
              ),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('common.email')}
          rules={[
            {
              type: 'email',
              message: t('components.CreateContactPersonModal.invalidEmail'),
            },
            ...(isMappedInElsa
              ? [
                  {
                    required: true,
                    message: t(
                      'components.CreateCourseInstanceAttendeeModal.enterEmail',
                    ),
                  },
                ]
              : []),
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="status"
          label={t('components.CreateCourseInstanceAttendeeModal.status')}>
          <Select>
            <Select.Option value={undefined}>&nbsp;</Select.Option>
            <Select.Option value={CourseInstanceAttendeeStatus.Passed}>
              <CourseInstanceAttendeeStatusBox
                status={CourseInstanceAttendeeStatus.Passed}
              />
            </Select.Option>
            <Select.Option value={CourseInstanceAttendeeStatus.Failed}>
              <CourseInstanceAttendeeStatusBox
                status={CourseInstanceAttendeeStatus.Failed}
              />
            </Select.Option>
            <Select.Option
              value={CourseInstanceAttendeeStatus.DidNotParticipate}>
              <CourseInstanceAttendeeStatusBox
                status={CourseInstanceAttendeeStatus.DidNotParticipate}
              />
            </Select.Option>
          </Select>
        </Form.Item>
        {!isMappedInElsa && (
          <Form.Item
            label={t('components.CreateCourseInstanceAttendeeModal.comment')}
            name="comment">
            <Input />
          </Form.Item>
        )}
      </Form>
      <Form.Item
        label={t('components.CreateCourseInstanceAttendeeModal.createMore')}>
        <div className="flex justify-end">
          <Switch onChange={setCreateMore} />
        </div>
      </Form.Item>
    </Modal>
  );
};

export default CreateCourseInstanceAttendeeModal;
