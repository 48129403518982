import {
  CalendarFilled,
  DeleteFilled,
  QuestionCircleFilled,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Skeleton, Tabs, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import UserAPI from '../UserAPI';
import UserCalendar from '../components/UserCalendar';
import ContentCard from '../../components/ContentCard';
import Gravatar from '../components/Gravatar';
import UserInfo from '../components/UserInfo';
import UserPageInstructionsModal from '../components/UserPageInstructionsModal';
import { useUser } from '../userContext';
import User from '../types/User';
import { userHasPermission } from '../userUtils';
import { Permission } from '../types/Permission';
import UserSignature from '../components/UserSignature';

const UserDetails: React.FC = () => {
  const { userId } = useParams();
  const [currentUser] = useUser();
  const id = useMemo(() => {
    return userId ? Number(userId) : currentUser?.id;
  }, [userId, currentUser]);
  const [user, setUser] = useState<User>();
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);
  const [restoringUser, setRestoringUser] = useState<boolean>(false);
  const naviagte = useNavigate();
  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  const { t } = useTranslation();

  const fetchUser = useCallback(async () => {
    if (!id) {
      return;
    }

    setLoadingUser(true);
    try {
      const { data } = await UserAPI.getUser(id);
      setUser(data);
    } finally {
      setLoadingUser(false);
    }
  }, [id]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const deleteUser = async () => {
    setDeletingUser(true);
    try {
      await UserAPI.deleteUser(user!.id);
      fetchUser();
    } finally {
      setDeletingUser(false);
    }
  };

  const restoreUser = async () => {
    setRestoringUser(true);
    try {
      await UserAPI.restoreUser(user!.id);
      fetchUser();
    } finally {
      setRestoringUser(false);
    }
  };

  const openSyncCalendarModal = () => {
    naviagte('synka-kalender', { state: { fromCalendar: true } });
  };

  const displaySignatureTab =
    (currentUser?.id === user?.id &&
      userHasPermission(
        currentUser,
        Permission.CERTIFICATE_SIGNATURE_UPDATE,
      )) ||
    userHasPermission(
      currentUser,
      Permission.CERTIFICATE_SIGNATURE_UPDATE_GLOBAL,
    );

  const tabItems = [
    {
      key: 'info',
      label: t('common.info'),
      children: (
        <UserInfo user={user} loading={loadingUser} onUpdate={fetchUser} />
      ),
    },
    ...(user?.isInstructor
      ? [
          {
            key: 'calendar',
            label: t('common.calendar'),
            children: user && <UserCalendar user={user} />,
          },
        ]
      : []),
    ...(displaySignatureTab
      ? [
          {
            key: 'signature',
            label: t('views.UserDetails.signature'),
            children: <UserSignature user={user} />,
          },
        ]
      : []),
  ];

  return (
    <>
      <Skeleton
        loading={loadingUser}
        avatar={{ size: 64, shape: 'circle' }}
        title={{ width: '15rem' }}
        paragraph={false}
        round
        active>
        <div className="flex justify-between items-end text-sm">
          <h2 className="flex items-center m-0">
            <Gravatar
              email={user?.email}
              internal={user?.internal}
              isInstructor={user?.isInstructor}
              size="large"
            />
            <span className="ml-4">{`${user?.name} ${
              user?.deletedAt != null ? t('views.UserDetails.removed') : ''
            }`}</span>
          </h2>
          <div className="flex">
            {currentUser?.id === user?.id && currentUser?.isInstructor && (
              <div className="">
                <Tooltip
                  placement="top"
                  title={t('views.UserDetails.synchCalendar')}>
                  <Button
                    icon={<CalendarFilled />}
                    shape="circle"
                    type="primary"
                    onClick={openSyncCalendarModal}
                  />
                </Tooltip>
              </div>
            )}
            <Button
              icon={<QuestionCircleFilled />}
              type="primary"
              shape="circle"
              className="self-end mb-2 ml-2"
              onClick={() => setShowInstructions(true)}></Button>
            {userHasPermission(currentUser, Permission.USER_DELETE) &&
              !user?.deletedAt && (
                <Popconfirm
                  title={t('views.UserDetails.confirmDeleteUser')}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  placement="bottomRight"
                  className="ml-2 mr-2"
                  onConfirm={() => deleteUser()}>
                  <Tooltip
                    placement="top"
                    title={t('views.UserDetails.deleteUser')}>
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      loading={deletingUser}
                      icon={<DeleteFilled />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            {userHasPermission(currentUser, Permission.USER_CREATE) &&
              user?.deletedAt && (
                <Popconfirm
                  title={t('views.UserDetails.restoreUserConfirmation')}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  placement="bottomRight"
                  onConfirm={() => restoreUser()}
                  className="ml-2 mr-2">
                  <Tooltip
                    placement="top"
                    title={t('views.UserDetails.restoreUser')}>
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      loading={restoringUser}
                      icon={<ReloadOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
          </div>
        </div>
      </Skeleton>
      <ContentCard className="mx-1">
        <Tabs size="large" animated={{ tabPane: true }} items={tabItems} />
      </ContentCard>
      <Outlet context={{ user }} />
      <UserPageInstructionsModal
        visible={showInstructions}
        onCancel={() => setShowInstructions(false)}
      />
    </>
  );
};

export default UserDetails;
