import React, { useEffect, useRef, useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { EventInput } from '@fullcalendar/core';

import { CalendarCourseInstanceInfo } from '../types/CourseInstance';
import { useLanguage } from '../../context/language';
import { getFullCalendarLocale } from '../../utils/language';
import CalendarHeader from '../../components/CalendarHeader';

import { CourseInstanceBoxComp } from './CourseInstanceBox';

type CourseInstanceCalendarPropsParam = {
  setDateString?: (values: string) => void;
  fetchedEvents: CalendarCourseInstanceInfo[];
  onClickDay: (date: Date | null) => void;
  loadingCourseInstances?: boolean;
};

const CourseInstanceCalendar: React.FC<CourseInstanceCalendarPropsParam> = ({
  setDateString,
  fetchedEvents,
  onClickDay,
  loadingCourseInstances,
}) => {
  const calendarRef = useRef<FullCalendar>(null);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [currentLanguage] = useLanguage();

  const opacityArray = ['0.1', '0.25', '0.35', '0.5', '0.7', '0.8', '1'];

  useEffect(() => {
    const _events: EventInput[] = fetchedEvents.map((event, index) => ({
      id: String(index),
      title: String(event.nBookings),
      date: event.date,
      textColor: '#000000',
      backgroundColor: '#FFFFFF00',
      borderColor: '#FFFFFF00',
    }));
    setEvents(_events);
  }, [fetchedEvents]);

  const capitalize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <>
      <CalendarHeader
        calendar={calendarRef}
        setDateString={setDateString}
        loading={loadingCourseInstances}
      />
      <FullCalendar
        weekNumbers
        ref={calendarRef}
        dayCellClassNames="hover:bg-calendar"
        dayCellWillUnmount={() => setEvents([])}
        headerToolbar={false}
        events={events}
        selectMirror={true}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        eventClick={(info) => {
          onClickDay?.(info.event.start);
        }}
        dateClick={(info) => {
          onClickDay?.(info.date);
        }}
        contentHeight={900}
        dayHeaderContent={function (arg) {
          return capitalize(arg.text);
        }}
        firstDay={1}
        locale={getFullCalendarLocale(currentLanguage?.code)}
        eventClassNames={
          'hover:bg-calendar m-0 absolute w-full h-80 flex justify-self-center overflow-hidden'
        }
        eventContent={function (arg) {
          return (
            <CourseInstanceBoxComp
              opacity={opacityArray[+arg.event.title]}
              event={arg.event}
            />
          );
        }}
      />
    </>
  );
};

export default CourseInstanceCalendar;
