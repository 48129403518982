import { TFunction } from 'i18next';

import BookingOrder from '../types/BookingOrder';
import { CourseInstanceStatus } from '../../courseInstance/types/CourseInstance';
import User from '../../user/types/User';

export const getInfoText = (bookingOrder: BookingOrder, t: TFunction) => {
  const textList: string[] = [];
  const courseInstances = bookingOrder.courseInstances?.filter(
    (courseInstance) => courseInstance.status !== CourseInstanceStatus.Canceled,
  );

  if (!courseInstances || courseInstances?.length === 0) {
    textList.push(t('utils.bookingOrder.missingCourseInstances'));
  } else {
    const missingInstructor = courseInstances
      .map((courseInstance) => courseInstance.instructor)
      .includes(null);

    if (missingInstructor) {
      textList.push(t('utils.bookingOrder.missingInstructor'));
    }

    const missingTime =
      courseInstances.filter((courseInstance) => !courseInstance.startsAt)
        .length > 0;

    if (missingTime) {
      textList.push(t('utils.bookingOrder.missingTime'));
    }
  }
  if (!bookingOrder.invoiceRecipient) {
    textList.push(t('utils.bookingOrder.missingInvoiceRecipient'));
  }

  return textList.length > 0 ? textList : undefined;
};

export const shouldShowClientCompanyElsaMappingInformation = (
  currentUser: User | null,
  isElsaIntegrationEnabled?: boolean,
  bookingOrder?: BookingOrder,
) => {
  if (!isElsaIntegrationEnabled) {
    // Globally disabled
    return false;
  }

  if (!!bookingOrder?.subsidiaryCompany?.elsaSubsidiaryCompanyId) {
    // Always display if subsidiary is mapped to ELSA
    return true;
  }

  if (!bookingOrder) {
    // When creating a new booking order, show if the users selected subsidiary is mapped to ELSA
    return !!currentUser?.preferredSubsidiaryCompany?.elsaSubsidiaryCompanyId;
  }
  return false;
};
