import React, { useCallback, useEffect, useState } from 'react';

import User from '../types/User';
import { Button, Col, Form, Row, Upload, UploadProps, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import UserAPI from '../UserAPI';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { verifyImageUpload } from '../../utils/upload';

type UserSignatureProps = {
  user?: User;
};

const UserSignature: React.FC<UserSignatureProps> = ({ user }) => {
  const { t } = useTranslation();

  const [certificateSignatureForm] = Form.useForm();
  const [signatureUrl, setSignatureUrl] = useState<string>();
  const [signatureFile, setSignatureFile] = useState<File>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      return;
    }
    setSignatureUrl(user.certificateSignature?.url);
  }, [user]);

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      setSignatureFile(undefined);
    },
    beforeUpload: (file) => {
      if (!verifyImageUpload(file, t)) {
        return Upload.LIST_IGNORE;
      }
      setSignatureFile(file);
      return false;
    },
    maxCount: 1,
    listType: 'picture',
  };

  const onSubmit = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    setSubmitting(true);
    try {
      const fmData = new FormData();
      if (!!signatureFile) {
        fmData.append('image', signatureFile);
        const extension = signatureFile?.name.split('.').pop();
        if (!!extension) {
          fmData.append('extension', extension);
        }
      } else {
        return;
      }
      await UserAPI.putUserCertificateSignature(user.id, fmData).then(
        (response) => setSignatureUrl(response.data.url),
      );
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSubmitting(false);
    }
  }, [signatureFile, t, user?.id]);

  const onDelete = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    setSubmitting(true);
    try {
      await UserAPI.deleteUserCertificateSignature(user.id);
    } catch (error) {
      if (isAxiosError(error) && !error.response?.data.message) {
        message.error(t('errors.somethingWentWrong'));
      }
    } finally {
      setSignatureUrl(undefined);
      setSubmitting(false);
    }
  }, [t, user?.id]);

  return (
    <>
      <Row className="mt-16" gutter={[16, 16]}>
        {signatureUrl && (
          <Col xs={24} md={12}>
            <img
              src={signatureUrl}
              className="w-full border-dashed border-gray-500"
            />
          </Col>
        )}
        <Col>
          <Upload {...uploadProps}>
            <Button
              loading={submitting}
              disabled={submitting}
              icon={<UploadOutlined />}>
              {t('common.selectFile')}
            </Button>
          </Upload>
        </Col>
      </Row>
      <div>
        <Form
          id="putCertificateSignatureForm"
          layout="vertical"
          form={certificateSignatureForm}
          onFinish={onSubmit}
          className="flex justify-end gap-2">
          {signatureUrl && (
            <Button
              danger
              type="primary"
              onClick={onDelete}
              loading={submitting}>
              {t('views.UserDetails.deleteSignature')}
            </Button>
          )}
          <Button type="primary" onClick={onSubmit} loading={submitting}>
            {t('common.save')}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default UserSignature;
