import React from 'react';
import { HomeFilled, ExclamationOutlined } from '@ant-design/icons';
import gravatar from 'gravatar';

export declare type GravatarSize = 'small' | 'medium' | 'large';

type GravatarProps = {
  className?: string;
  email?: string | null;
  internal?: boolean;
  isInstructor?: boolean;
  size?: GravatarSize;
};

const UserBadge: React.FC<GravatarProps> = ({
  email,
  internal,
  isInstructor,
  size,
}) => {
  if (!email) {
    return (
      <ExclamationOutlined className={`exclamation-badge ${size}-badge`} />
    );
  }

  if (internal && isInstructor) {
    return <HomeFilled className={`internal-badge ${size}-badge`} />;
  }

  return null;
};

const Gravatar: React.FC<GravatarProps> = ({
  className,
  email,
  internal,
  isInstructor,
  size = 'medium',
}) => {
  return (
    <div className={`relative inline-flex ${className || ''}`}>
      <img
        className={`inline-block object-cover rounded-full gravatar-${size}`}
        src={
          email
            ? gravatar.url(email)
            : gravatar.url('default@default.com', { default: 'mp' })
        }
        alt=""
      />
      <UserBadge
        email={email}
        internal={internal}
        isInstructor={isInstructor}
        size={size}
      />
    </div>
  );
};

export default Gravatar;
